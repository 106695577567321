import React, { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { AutocompleteLongList } from 'Ui/Form/Autocomplete';
import TTaskForInput from '../types/TTaskForInput';

function EventTasksSection({ options }: { options: TTaskForInput[] }) {
    const { control } = useFormContext();
    const project = useWatch({
        control,
        name: 'project',
    });

    const tasksOptions = useMemo(() => {
        const result = options.filter(value => !project?.id || value.projectId === project.id);
        return result;
    }, [options, project?.id]);

    return (
        <AutocompleteLongList
            name="tasks"
            label="Задача"
            options={tasksOptions}
            blurOnSelect
        />
    );
}

export default EventTasksSection;
