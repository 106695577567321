function recursion(items, url) {
    for (const elem of items) {
        if (elem.hash === url) {
            return {
                currentId: elem.id,
                parentId: elem?.parent_id,
            };
        }

        if (elem.data) {
            const result = recursion(elem.data, url);
            if (result) {
                return result;
            }
        }
    }

    return null;
}

export default recursion;
