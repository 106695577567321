import { subprojectId } from '../../../config/constants.js';

export default (data) => {
    const currentProjectId = +$$('project').getValue();
    if (currentProjectId) {
        const { projectsId, tagsId } = data;
        const currentSubprojecestId = projectsId.reduce((acc, el) => (el.id === currentProjectId ? el.tags.split(',') : acc), []);
        const resultSubprojeces = tagsId.filter((tags) => {
            const result = currentSubprojecestId.includes(tags.id.toString());
            return result;
        });
        const list = $$(subprojectId).getPopup().getList();
        list.clearAll();
        list.parse(resultSubprojeces);
        $$(subprojectId).setValue('');
        $$(subprojectId).enable();
    } else {
        const list = $$(subprojectId).getPopup().getList();
        list.clearAll();
        $$(subprojectId).setValue('');
        $$(subprojectId).disable();
    }
    
};
