import { nodePath } from 'Config/path.js';

const getTimeTracks = async (taskId) => {
    const data = await webix
        .ajax(`${nodePath}/tasks/getTimeTracks?taskId=${taskId}`)
        .then(res => res.json());

    return data;
};

export default getTimeTracks;
