import getCurrentPage from './getCurrentPage.js';

function getHandler(router, events) {
    return () => {
        events.emit('mainmenu:loaded');
        const sidebarElements = $$('main-menu').serialize();
        const currentRoute = router.getCurrentLocation();

        const { currentId, parentId } = getCurrentPage(sidebarElements, currentRoute.url);

        $$('main-menu').addCss(currentId, 'webix_selected');
        $$('main-menu').addCss(parentId, 'webix_selected');
    };
}

export default getHandler;
