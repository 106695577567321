import {
    historyStatesTableId,
    formAddTaskId,
} from '../../../../../config/constants.js';
import getHistoryStates from '../services/getHistoryStates.js';
import runLoaderBar from '../../../../../utils/runLoaderBar.js';
import stopLoaderBar from '../../../../../utils/stopLoaderBar.js';

export default () => {
    const values = $$(formAddTaskId).getValues();
    const { taskId } = values;

    if (!+taskId) return;

    runLoaderBar(historyStatesTableId);
    getHistoryStates(taskId)
        .then((res) => {
            stopLoaderBar(historyStatesTableId);

            $$(historyStatesTableId).getColumnConfig('stateId').collection.parse(res.states, '', true);
            $$(historyStatesTableId).parse(res.data, '', true);
        });
};
