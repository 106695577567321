import React, { ChangeEvent } from 'react';
import { useFormContext, useController, UseControllerReturn } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

interface IProps {
    name: string;
    label: string;
    callback?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function CheckboxRHF(props: IProps) {
    const { name, label, callback } = props;
    const { control } = useFormContext();
    const controller: UseControllerReturn = useController({
        name,
        control,
    });

    return (
        <Typography>
            <Checkbox
                checked={controller.field.value}
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={e => (callback
                    ? callback(e)
                    : controller.field.onChange(e.target.checked))}
            />
            { label }
        </Typography>
    );
}

export default CheckboxRHF;
