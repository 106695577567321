import IFunctionWithPath from 'CommonTypes/Functions/IFunctionWithPath';
import TLimitMeasureIds from 'CommonTypes/ITServices/TLimitMeasureIds';
import dayjs, { Dayjs } from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import calculateDeadlineByLimitMeasure from './calculateDeadlineByLimitMeasure';

async function getLimitAndType(limitType: string, startDate: Dayjs | undefined) {
    const splittedStr = limitType.split('|');
    const limit: string = splittedStr?.[0] || '';
    const type: TLimitMeasureIds = splittedStr?.[1] as unknown as TLimitMeasureIds;
    const date = await calculateDeadlineByLimitMeasure(
        +limit,
        type,
        startDate,
    );

    return date;
}

export default async (
    currentDate: Dayjs,
    functions: string[],
    currentFunctions: IFunctionWithPath[],
    startDate?: Dayjs,
) => {
    const datePromises = [];

    for (let i = 0; i < currentFunctions.length; i++) {
        const func = currentFunctions[i];

        let limitType = '';
        for (let index = 0; index < functions.length; index++) {
            const el = functions[index].split('-');
            if (func.id === +el[0]) {
                [, limitType] = el;
                break;
            }
        }
        datePromises.push(getLimitAndType(limitType, startDate));
    }

    const results = await Promise.all(datePromises);
    results.push(currentDate);
    dayjs.extend(minMax);
    const maxDate = dayjs.max(results);

    return maxDate;
};
