import { slimPath } from 'Config/path.js';
import { fetchPost } from 'Services/fetchService.js';

import { IFillTemplate } from 'CommonTypes/DocsTemplates/IFillTemplate';

export default async (data: IFillTemplate) => {
    const response = await fetchPost(`${slimPath}/pdf/outbox`, data);

    return response;
};
