import { FilterOptionsState } from '@mui/material';
import { IItServicesWithPath } from 'CommonTypes/Tasks/QuickAddTaskForm/IResponse';

type TProjectValue = {
    services: string | number[],
}

function getFilterOptions(projectValue: TProjectValue) {
    return (
        options: IItServicesWithPath[],
        stateFilter: FilterOptionsState<IItServicesWithPath>,
    ) => {
        const { inputValue } = stateFilter;
        const servicesStr = typeof projectValue?.services === 'string' ? projectValue?.services : '';

        const servicesIds: number[] = Array.isArray(projectValue?.services) ? projectValue?.services : servicesStr.split(',').filter(el => el).map((id: string) => +id);

        const servicesFilteredByProject = servicesIds.length
            ? options.filter(srv => servicesIds.includes(srv.id)) : options;

        const servicesFilteredOptions = servicesFilteredByProject.filter(srv => (
            srv.path.toLowerCase().includes(inputValue.toLowerCase())
        ));

        return servicesFilteredOptions;
    };
}

export default getFilterOptions;
