import React from 'react';

export default function SpinnerCubeGrid() {
    return (
        <div className="sk-spinner sk-spinner-cube-grid">
            <div className="sk-cube" />
            <div className="sk-cube" />
            <div className="sk-cube" />
            <div className="sk-cube" />
            <div className="sk-cube" />
            <div className="sk-cube" />
            <div className="sk-cube" />
            <div className="sk-cube" />
            <div className="sk-cube" />
        </div>
    );
}
