/* eslint-disable no-undef */
/* eslint-disable lines-between-class-members */
import { Root } from 'react-dom/client';
import getFactory from 'Services/factory';
import headerReactView from 'Bases/headerTemplate/headerReactView';
import uiBlock, { setTitle, getRender } from '../config/blocks';
import events from '../utils/events';
import store, { injectReducer } from '../utils/store/store';
import { router } from '../appRouter';
import type { IRenderProps } from '../config/types';

type Constructor = <T>(className: new (...args: any) => T) => T;

class Container {
    ui = uiBlock;
    events = events;
    router = router;
    renderHeaderReact = headerReactView;
    setTitle = setTitle;
    store = store;
    injectReducer = injectReducer;
    get = getFactory(this);
    getView!: (config: Constructor | webix.ui.baseview | JSX.Element) => webix.ui.baseviewConfig;
    render!: (
        config: Constructor | webix.ui.baseview | JSX.Element,
        {
            container, fullHeight, flexColumn, animate,
        }: IRenderProps
    ) => webix.ui.baseview | Root;

    constructor() {
        this.getRender();
    }
    getRender() {
        const { render, getView } = getRender(this);
        this.render = render;
        this.getView = getView;
    }
}

export default new Container();

export type TContainer = InstanceType<typeof Container>;
