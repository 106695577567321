import { createSlice } from '@reduxjs/toolkit';
import { ITasksTabsState, TTabs } from '../types/TasksTabsState';

const initialState: ITasksTabsState = {
    activeTab: null,
    activatedTabs: {},
};

export const tasksTabsSlice = createSlice({
    name: 'TASKS_TABS_SLICE',
    initialState,
    reducers: {
        activeTab(state, action) {
            state.activeTab = action.payload;
            state.activatedTabs[action.payload as TTabs] = true;
        },
        resetTabs(state) {
            state.activeTab = null;
            state.activatedTabs = {};
        },
    },
});

export const {
    activeTab,
    resetTabs,
} = tasksTabsSlice.actions;

export default tasksTabsSlice.reducer;
