import React, { MouseEvent, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useGetSubmissionsTaskTemplatesSimpleSetQuery } from 'Endpoints/Tasks/tasksSubmissions.api';
import createTaskFromSubTemplateOld from './services/createTaskFromSubTemplateOld.js';

interface IProps {
    taskId: number;
}

function LinksSubmissionsTemplatesCreate({ taskId }: IProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

    const { data: options = [] } = useGetSubmissionsTaskTemplatesSimpleSetQuery({ taskId });

    const handleClickButton = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (
        event: MouseEvent<HTMLElement>,
        subId: number,
    ) => {
        if (subId > 0) {
            createTaskFromSubTemplateOld({ taskId, submissionId: subId });
        }
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickButton}
            >
                Связанные процессы
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {options.map(option => (
                    <MenuItem
                        key={option.id}
                        onClick={e => handleMenuItemClick(e, option.id)}
                    >
                        {option.value}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default LinksSubmissionsTemplatesCreate;
