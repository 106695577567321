import { initModule } from 'Services/initModule.js';

export default {
    '/budgets-tree-table': {
        as: 'budgetsTreeTable',
        uses() {
            import(
                /* webpackChunkName: "budgetstreetable" */ '../../Budgets/controllers/BudgetsTreeTableReportController'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
