import React from 'react';

import progressStyleController from '../models/progressStyleController';

import classes from '../../../assets/style.scss';

function ProgressLine({ progress }: { progress: number | null }) {
    const styleProgress = progress ? progressStyleController(progress) : '';
    return (
        <div className={`${classes.progress} ${classes['progress-mini']}`}>
            <div style={{ width: `${progress}%` }} className={`${classes['progress-bar']} ${classes[`${styleProgress}`]}`}>
                <span className={`${classes.small} ${classes['progress-bar-text']}`}>
                    {progress}
                    %
                </span>
            </div>
        </div>
    );
}

export default ProgressLine;
