import { userId } from '../../../config/constants.js';

export default (data) => {
    const currentProjectId = +$$('project').getValue();
    if (currentProjectId) {
        const { projectsId, usersId } = data;
        const currentUsersId = projectsId.reduce((acc, el) => (el.id === currentProjectId ? el.users.split(',') : acc), []);
        const resultUsers = usersId.filter(user => currentUsersId.includes(user.id.toString()));
        const list = $$(userId).getPopup().getList();
        list.clearAll();
        list.parse(resultUsers);
        $$(userId).setValue('');
        $$(userId).enable();
    } else {
        const list = $$(userId).getPopup().getList();
        list.clearAll();
        $$(userId).setValue('');
        $$(userId).disable();
    }
};
