import di from 'Services/di.js';
import {
    formAddTaskId, taskNameId, mainFormTask, topLayoutId,
} from '../../config/constants.js';
import generalToolbar from './components/generalToolbar/generalToolbar.js';
import topTabbar from './components/topTabbar/topTabbar.js';
import cellsTopTabbar from './components/cellsTopTabbar/cellsTopTabbar.js';
import bottomTabbar from './components/bottomTabbar/bottomTabbar.js';
import cellsBottomTabbar from './components/cellsBottomTabbar/cellsBottomTabbar.js';
import rightSidebar from './components/rightSidebar/rightSidebar.js';
import toggleSaveBtn from '../../models/toggleSaveBtn.js';

webix.protoUI({
    name: 'formtemplateOfTaskForm',
    setValue(value, config) {
        const old = this.getValues.value;
        this.setValues({ value });
        this.callEvent('onChange', [value, old, config]);
    },
    getValue() {
        return this.getValues().value;
    },
}, webix.ui.template);

export default (allProperty, dataTask, fieldsConfig, dataFiles) => {
    const { store } = di;
    const { reducerUser } = store.getState();

    return {
        id: formAddTaskId,
        view: 'form',
        scroll: true,
        rows: [
            generalToolbar(fieldsConfig, dataTask, allProperty, dataFiles),
            {
                view: 'flexlayout',
                cols: [
                    {
                        autoheight: true,
                        id: mainFormTask,
                        scroll: true,
                        rows: [
                            {
                                id: topLayoutId,
                                rows: [
                                    {
                                        id: taskNameId,
                                        name: taskNameId,
                                        view: 'text',
                                        placeholder: 'Наименование задачи (Тема)',
                                        required: true,
                                        invalidMessage: 'Укажите наименование задачи',
                                    },
                                    topTabbar,
                                    cellsTopTabbar,
                                ],
                            },
                            { view: 'resizer' },
                            {
                                rows: [
                                    bottomTabbar,
                                    cellsBottomTabbar(dataFiles),
                                ],
                            },
                        ],
                    },
                    { view: 'resizer' },
                    rightSidebar(allProperty, reducerUser, fieldsConfig),
                ],
                borderless: true,
            },
        ],
        on: {
            onChange() {
                toggleSaveBtn(dataTask);
            },
        },
    };
};
