export default function getInfo(it, template) {
    const taskData = {
        type: 'task',
        params: {
            data: template,
            observersIds: template?.observers || [],
        },
        // callback: hundleCashUpdateByNewTask,
    };

    window.events.emit('tasks__formAddTask:addNewTask', taskData);
}
