import {
    historyStatesToolbarId,
} from '../../../../../../config/constants.js';
import resetHistoryStates from './models/resetHistoryStates.js';
import addHistoryItem from './models/addHistoryItem.js';

export default {
    id: historyStatesToolbarId,
    view: 'toolbar',
    height: 40,
    elements: [
        {
            cols: [
                {
                    view: 'button',
                    width: 45,
                    tooltip: 'Добавить запись',
                    label: '<span class="fa fa-plus">',
                    click: addHistoryItem,
                },
                {},
                {
                    view: 'button',
                    width: 210,
                    css: 'webix_danger',
                    label: 'Обнулить историю',
                    click: resetHistoryStates,
                },
            ],
        },
    ],
};
