import { formEditTimeTrackId, windowEditTimeTrackId } from '../../../../../../../config/constants.js';
import addEditTimeTrack from './models/addEditTimeTrack.js';

export default data => ({
    id: formEditTimeTrackId,
    view: 'form',
    elements: [
        {
            view: 'datepicker',
            label: 'Начало:',
            labelWidth: 100,
            name: 'startDate',
            value: data.startDate ?? new Date(),
            format: '%d.%m.%Y %H:%i:%s',
            suggest: {
                type: 'calendar',
                body: {
                    timepicker: true,
                },
            },
        },
        {
            view: 'datepicker',
            label: 'Завершение:',
            labelWidth: 100,
            name: 'endDate',
            value: data.endDate ?? new Date(),
            format: '%d.%m.%Y %H:%i:%s',
            suggest: {
                type: 'calendar',
                body: {
                    timepicker: true,
                },
            },
        },
        {
            view: 'label',
            label: 'Комментарий:',
            inputWidth: 100,
            align: 'left',
        },
        {
            name: 'comment',
            view: 'ckeditor5',
            value: data.comment,
        },
        {
            cols: [
                {},
                {
                    view: 'button',
                    width: 80,
                    label: 'Отмена',
                    on: {
                        onItemClick() { $$(windowEditTimeTrackId).destructor(); },
                    },
                },
                {
                    view: 'button',
                    width: 80,
                    css: 'webix_primary',
                    label: `${data.id ? 'Сохранить' : 'Добавить'}`,
                    on: {
                        onItemClick() { addEditTimeTrack(data); },
                    },
                },
            ],
        },
    ],
});
