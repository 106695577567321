import {
    formAddTaskId,
} from 'fsrc/AddTaskWindow/config/constants.js';
import di from 'Services/di.js';

webix.protoUI({
    name: 'commentsCkeditor',
    defaults: {
        keepButtonVisible: true,
    },
    $init(config) {
        config.url += `?taskId=${config.taskId}`;
        config.rows.splice(1, 0, { view: 'resizer' });
    },
    $configForm() {
        const ui = {
            cols: [
                {
                    view: 'ckeditor5',
                    name: 'text',
                    height: 90,
                },
                {
                    rows: [
                        {
                            view: 'button',
                            disabled: false,
                            tooltip: 'Расширенный комментарий',
                            css: 'webix_comments_send webix_primary',
                            label: '<span class="fa fa-external-link">',
                            click: webix.bind(this.sendComment, this),
                        },
                        {
                            view: 'button',
                            disabled: false,
                            css: 'webix_comments_send webix_primary',
                            value: '<i class="fa fa-paper-plane-o"></i> Отправить',
                            width: 150,
                            click: webix.bind(this.sendAndSaveComment, this),
                        },
                        {
                            view: 'button',
                            disabled: false,
                            css: 'webix_primary',
                            value: 'Сохранить',
                            width: 150,
                            click: webix.bind(this.saveComment, this),
                        },
                    ],
                },
                {
                    hidden: true,
                    view: 'textarea',
                    localId: 'textarea',
                },
            ],
        };
        return ui;
    },
    getCount() {
        // TODO: возможно есть способ более элегантный, чем в тупую...
        const dryData = this.getList().serialize();
        if (dryData[0]?.id === '$more') {
            return dryData.length - 1 + dryData[0].value;
        }

        return dryData.length;
    },
    getList() {
        return this.getChildViews()[0]; // FIXME: как-то взять это по-человечески
    },
    getEditor() {
        return this.getChildViews()[2].getChildViews()[0]; // FIXME: как-то взять это по-человечески
    },
    saveComment() {
        const editor = this.getEditor();
        const newRow = {
            text: editor.getValue(),
            user_id: this.config.currentUser,
            date: new Date(),
        };

        if (!newRow.text.trim()) {
            return null;
        }

        this.add(newRow);
        editor.setValue('');

        const list = this.getList();
        list.showItem(list.getLastId());

        return this.callEvent('onSaveComment', [newRow, '', 'user']);
    },
    sendAndSaveComment() {
        if ($$(formAddTaskId)) {
            const formValues = $$(formAddTaskId).getValues();
            const store = di.store.getState();
            const { root } = store.reducerBase;
            const { postId } = store.reducerUser;

            const dataSend = {
                id_task: formValues.taskId,
                text: this.getEditor().getValue(),
                post_id: postId,
                contacts: [
                    formValues.currentContact, ...formValues.currentObservers,
                ].map(el => el.id),
            };

            webix.message({
                text: 'Отправка писем...',
                type: 'debug',
            });

            app.fetch(`${root}/ajax/gmail/v3_send_for_task.php`, dataSend, 'POST')
                .then(((res) => {
                    if (!res?.success) {
                        webix.message({
                            text: 'Письма не были отправлены.',
                            type: 'error',
                        });
                    }

                    if (res?.success) {
                        app.fetch('ajax/gmail/outbox_queue.php?send')
                            .then((result) => {
                                webix.message({
                                    text: `Отправлено писем - ${result.length ?? '0'}`,
                                    type: 'success',
                                });
                            });
                    }
                }));

            this.saveComment();
        }
    },
    sendComment() {
        const data = {
            textComment: this.getEditor().getValue(),
            taskId: this.config.taskId,
        };
        window.events.emit('tasks__comments:advancedCommentEditing', data);
        this.getEditor().setValue('');
    },
}, webix.ui.comments);
