/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';

import {
    ButtonGroup, Grid, Button, Icon,
} from '@mui/material';

import { Editor } from 'Ui/Form/Editor';

import { IClassicEditor } from 'Ui/Editor/types/ICKEditorClassic';
import {
    FormProvider, useForm, UseFormProps, UseFormReturn,
} from 'react-hook-form';

import { usePostCommentByTaskIdMutation } from 'Endpoints/Comments/comments.api';

interface IComment {
    comment: string;
}

interface IProps {
    taskId: number;
}

function ControlSection({ taskId }: IProps) {
    const commentRef = useRef<IClassicEditor | null>(null);
    const form: UseFormReturn<IComment, UseFormProps> = useForm<IComment>({
        defaultValues: {
            comment: '',
        },
    });

    const [postNewComment] = usePostCommentByTaskIdMutation();

    const onSubmit = ({ comment }: IComment) => {
        postNewComment({ comment, taskId });
        form.reset({ comment: '' });
        commentRef.current?.setData('');
    };

    return (
        <Grid container wrap="nowrap" justifyContent="space-between">
            <Grid item width="95%">
                <FormProvider {...form}>
                    <Editor
                        name="comment"
                        editorRef={commentRef}
                        config={{ placeholder: 'Комментарии' }}
                    />
                </FormProvider>
            </Grid>
            <Grid item>
                <ButtonGroup
                    orientation="vertical"
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        size="square"
                        title="Расширенный комментарий"
                    >
                        <Icon><i className="fa fa-external-link" /></Icon>
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="square"
                        title="Отправить на почту"
                    >
                        <Icon><i className="fa fa-paper-plane-o" /></Icon>
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="square"
                        title="Сохранить"
                        onClick={form.handleSubmit(onSubmit)}
                    >
                        <Icon><i className="fa fa-floppy-o" /></Icon>
                    </Button>
                </ButtonGroup>
            </Grid>
        </Grid>
    );
}

export default ControlSection;
