import { formAddHistoryItemId, windowAddHistoryItemId } from '../../../../../../../config/constants.js';
import addHistoryItem from './models/addHistoryItem.js';
import validateEndDate from './models/validateEndDate.js';
import { seconds } from './../../../../../../../../../../assets/lib/moment/src/lib/duration/get';
import calcDaysDuration from './models/calcDaysDuration.js';

export default data => ({
    id: formAddHistoryItemId,
    view: 'form',
    rules: {
        duration: webix.rules.isNumber,
    },
    rules: {
        endDate: validateEndDate,
    },
    elements: [
        {
            view: 'select',
            labelWidth: 160,
            name: 'stateId',
            label: 'Состояние:',
            options: data.states,
            value: data.states[0]?.id ?? '',
            required: true,
        },
        {
            view: 'datepicker',
            label: 'Дата начала:',
            labelWidth: 160,
            name: 'startDate',
            value: new Date(),
            format: '%d.%m.%Y %H:%i:%s',
            suggest: {
                type: 'calendar',
                body: {
                    timepicker: true,
                    icons: true,
                },
            },
            on: {
                onChange: calcDaysDuration,
            },
        },
        {
            view: 'datepicker',
            label: 'Дата окончания:',
            labelWidth: 160,
            name: 'endDate',
            format: '%d.%m.%Y %H:%i:%s',
            invalidMessage: 'Дата окончания не может быть меньше даты начала.',
            suggest: {
                type: 'calendar',
                body: {
                    timepicker: true,
                    icons: true,
                },
            },
            on: {
                onChange: calcDaysDuration,
            },
        },
        {
            view: 'text',
            labelWidth: 160,
            name: 'durationDays',
            label: 'Длительность (дни):',
            required: true,
            value: 0,
        },
        {
            cols: [
                {},
                {
                    view: 'button',
                    width: 150,
                    label: 'Отмена',
                    click() { $$(windowAddHistoryItemId).destructor(); },
                },
                {
                    view: 'button',
                    width: 150,
                    css: 'webix_primary',
                    label: 'Добавить',
                    click: addHistoryItem,
                },
            ],
        },
    ],
});
