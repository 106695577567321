import { handleResponse } from '../../services/services.js';

export default function favoritesData() {
    const requestOptions = {
        method: 'GET',
    };
    return new Promise((resolve, reject) => {
        fetch('./ajax/task_favorite.php?action=select', requestOptions)
            .then(handleResponse)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
