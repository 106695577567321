import { fetchPut } from 'Services/fetchService.js';
import { nodePath } from 'Config/path.js';

export default async (newData) => {
    const response = await fetchPut(`${nodePath}/requirements/update-order`, newData);
    if (response) {
        return response;
    }

    return [];
};
