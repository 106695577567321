import { IFlags } from 'CommonTypes/Tasks/Comments/IResponse';

type IClassName = {
    [key in keyof IFlags]: 'fa fa-bug' | 'fa fa-calendar-check-o' | 'fa fa-envelope-o' | 'fa fa-code-fork';
};

export default (flags: IFlags) => {
    const className: IClassName = {
        flagBug: 'fa fa-bug',
        flagDeadline: 'fa fa-calendar-check-o',
        flagEmail: 'fa fa-envelope-o',
        flagState: 'fa fa-code-fork',
    };
    const flagsNames = Object.keys(flags) as Array<keyof IFlags>;
    const currentClassName = flagsNames.reduce((acc, flag) => {
        if (flags[flag]) return className[flag];

        return acc;
    }, '');

    return currentClassName || undefined;
};
