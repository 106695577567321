import React from 'react';

import TabPanelTitle from '../TabPanelTitle/TabPanelTitle';

function TabNotification() {
    return (
        <TabPanelTitle icon="fa fa-comments-o" title="Уведомления">
            Общее количество уведомлений 7 или Уведомления пусты
        </TabPanelTitle>
    );
}

export default TabNotification;
