import React from 'react';
import { Confirm } from 'Ui/Modal';

interface IProps {
    title?: string;
    descr?: string;
    callback: (result: boolean) => void;
}

function ConfirmDeleteModal({ title, descr, callback }: IProps) {
    return (
        <Confirm
            open
            title={title ?? 'Удалить запись?'}
            onClose={callback}
        >
            <p>
                <strong>Предупреждение!</strong>
                <br />
                { descr ?? 'Запись будет удалена!' }
            </p>
        </Confirm>
    );
}

export default ConfirmDeleteModal;
