import { nodePath } from 'Config/path.js';

const addCreatedTaskToLinks = async (data) => {
    const result = await webix.ajax()
        .post(`${nodePath}/tasks/taskToLinks`, data)
        .then(res => res.json());

    return result;
};

export default addCreatedTaskToLinks;
