const getFields = (field: string) => {
    switch (field) {
    case 'buisnessProcess':
        return 'Бизнес процесс';
    case 'serviceClass':
        return 'Класс обслуживания';
    case 'contact':
        return 'Контакт';
    case 'project':
        return 'Проект';
    case 'role':
        return 'Роль';
    case 'assignee':
        return 'Исполнитель';
    case 'dateStart':
        return 'Начало';
    case 'deadline':
        return 'Срок';
    case 'dateCreate':
        return 'Дата создания';
    case 'dateComplete':
        return 'Дата завершения';
    case 'observers':
        return 'Наблюдатели';
    case 'priority':
        return 'Приоритет';
    case 'itservice':
        return 'Сервис';
    case 'owner':
        return 'Автор';
    default:
        return '';
    }
};

export default getFields;
