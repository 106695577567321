import { nodePath } from 'Config/path.js';

const getCreatedRelatedTask = async (taskId) => {
    const data = await webix
        .ajax(`${nodePath}/tasks/getCreatedRelatedTask?taskId=${taskId}`)
        .then(res => res.json());

    return data;
};

export default getCreatedRelatedTask;
