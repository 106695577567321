import updateFinishedChecklistService from '../services/updateFinishedChecklistService.js';
import { checklistView } from '../../../../../../config/constants.js';

export default (ev, obj) => {
    const id = {
        taskId: obj.row,
    };
    updateFinishedChecklistService(id);
    const elem = $$(checklistView).getItemNode(obj.row);
    if (elem.classList.contains('complete-task')) {
        $$(checklistView).removeCellCss(obj.row, 'name', 'complete-task');
    } else {
        $$(checklistView).addCellCss(obj.row, 'name', 'complete-task');
    }
};
