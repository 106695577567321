export const DEFAULT_COLUMN_VISIBILITY = {
    assetName: true,
    assigneeId: true,
    contactName: true,
    dateCompleteTask: true,
    dateCreateTask: true,
    duration: true,
    endDate: true,
    functionNames: true,
    id: true,
    moduleNames: true,
    name: true,
    priorityId: true,
    progress: true,
    projectId: true,
    roleName: true,
    routeName: true,
    serviceClassId: true,
    serviceName: true,
    startDate: true,
    stateName: true,
    typeName: true,
    __detail_panel_toggle__: true,
    descriptionNoHTML: false,
};

export const STATE_FILTER = {
    actual: 'actual',
    draft: 'draft',
    finished: 'finished',
};

export const PROGRESS_BREAKPOINTS = {
    low: 30,
    medium: 70,
};
