import emptySplitApi from 'Endpoints/emptySplitApi';
import {
    ITasksRoleUserStateConfigRequest,
    ITasksRoleUserStateConfig,
    ITasksRoleUserStateConfigUpdateResponse,
    ITasksRoleUserStateConfigUpdateRequest,
} from 'CommonTypes/Roles/TasksRoleUserStateConfig';

export const roleUserConfigByTaskApi = emptySplitApi.injectEndpoints({
    endpoints: build => ({

        getTasksRoleUserStateConfig: build.query<
            ITasksRoleUserStateConfig[],
            ITasksRoleUserStateConfigRequest
        >({
            query: ({ taskId }) => ({
                url: `roles/routestates-config/tasks/${taskId}`,
            }),
        }),

        updateTasksroleUserStateConfig: build.mutation<
            ITasksRoleUserStateConfigUpdateResponse,
            ITasksRoleUserStateConfigUpdateRequest
        >({
            query: ({ taskId, ...rest }) => ({
                url: `roles/routestates-config/tasks/${taskId}`,
                method: 'PATCH',
                body: rest,
            }),
            async onQueryStarted({ taskId, recordId, ...rest }, { dispatch, queryFulfilled }) {
                const { data: response } = await queryFulfilled;
                if (response && response?.success) {
                    dispatch(
                        roleUserConfigByTaskApi.util.updateQueryData(
                            'getTasksRoleUserStateConfig',
                            { taskId },
                            draft => draft.map((record) => {
                                if (record.id === recordId) {
                                    return {
                                        ...record,
                                        roleId: rest.roleId,
                                        userId: rest.userId,
                                        planDuration: rest.planDuration,
                                    };
                                }

                                return record;
                            }),
                        ),
                    );
                }
            },
        }),

    }),
});

export const {
    useGetTasksRoleUserStateConfigQuery,
    useUpdateTasksroleUserStateConfigMutation,
} = roleUserConfigByTaskApi;
