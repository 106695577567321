import { initModule } from '../services/initModule.js';

export default {
    '/spinners': {
        as: 'spinners',
        uses: () => {
            import(
                /* webpackChunkName: "spinners" */ '../../SpinnersTestPage/SpinnersTestController'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
