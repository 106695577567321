import buttonClickHandler from 'Domain/LayoutConfiguration/components/settingButton/buttonClickHandler';
import bugReport from 'fsrc/BugReport/bugReport.js';
import di from 'Services/di.js';
import { nodePath } from 'Config/path.js';
import closeTaskForm from '../../../models/closeTaskForm.js';
import openStory from '../../../models/openStory';
import deleteTask from '../../../models/deleteTask.js';

const mapping = {
    fields: buttonClickHandler,
    bug: () => bugReport(),
    openTask(formAddTaskId, typeTaskId, dataTask) {
        const { standalone } = $$(formAddTaskId).getValues();
        if (!standalone) closeTaskForm(dataTask);
        const { taskId } = dataTask;
        window.open(`/task/${taskId}`);
    },
    storyTask(formAddTaskId, typeTaskId, dataTask) {
        openStory(dataTask.taskId);
    },
    deleteTask(formAddTaskId, typeTaskId, dataTask) {
        deleteTask(dataTask.taskId);
    },
    createTask(formAddTaskId, typeTaskId, dataTask) {
        if (dataTask?.taskId) {
            return di.events.emit('modalComment:showModal', dataTask.taskId);
        }
        return webix.message({ text: 'Функция не доступна для новой задачи', type: 'debug' });
    },
    async resetAccess(formAddTaskId, typeTaskId, dataTask) {
        const taskId = dataTask?.taskId || 0;
        if (!taskId) return false;

        const result = await webix.ajax()
            .patch(`${nodePath}/tasks/access/${taskId}`)
            .then(res => res.json());
        webix.message({ text: 'Доступы к задаче обновлены', type: 'success' });
        return result;
    },
};

export default mapping;
