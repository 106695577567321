import di from 'Services/di.js';
import {
    subTasksId,
    bottomTabbarId,
    subtasksTableId,
    formAddTaskId,
} from '../../../../../config/constants.js';
import getSubtasks from '../services/getSubtasks.js';
import runLoaderBar from '../../../../../utils/runLoaderBar.js';
import stopLoaderBar from '../../../../../utils/stopLoaderBar.js';

const { events } = di;

export default (isReload) => {
    const values = $$(formAddTaskId).getValues();
    const { taskId } = values;

    if (!+taskId) return;

    if (!isReload) {
        const result = $$(subtasksTableId).serialize();
        if (result.length) return;
    }

    const unsibscribe = events.on('oldTaskWindow:onClose', () => {
        $$(subtasksTableId).isModalOpen = false;
    });

    events.once('taskWindow:onClose', unsibscribe);

    runLoaderBar(subtasksTableId);
    getSubtasks(taskId)
        .then((res) => {
            stopLoaderBar(subtasksTableId);

            $$(subtasksTableId).parse(res, '', true);
            $$(bottomTabbarId).getOption(subTasksId).value = `Подзадачи (${res.length})`;
        });
};
