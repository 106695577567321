import React from 'react';

import classes from '../../assets/style.scss';

function Item({ item, value }: { item: string, value: string }) {
    return (
        <p className={classes.item}>
            <strong>
                {item}
                :
            </strong>
            <span>{value}</span>
        </p>
    );
}

export default Item;
