export default (taskData, callback) => {
    app.modules.use('app.sales').then(() => {
        app.sales.one.print(taskData, (id, type, isContract) => {
            document.getElementById('saleDocsPrintModal')?.close();
            if (typeof callback === 'function') {
                callback(id, type, isContract);
            }
        });
    });
};
