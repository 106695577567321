import { fileDescriptionId, filesListId, popupId } from '../../../../../../../../../config/constants.js';

export default (id) => {
    const { column } = id;
    if (column === fileDescriptionId) {
        const popup = $$(popupId);
        const { width } = $$(filesListId).getColumnConfig(fileDescriptionId);
        switch (popup) {
        case undefined: {
            webix.editors.$popup.text = {
                view: 'popup',
                id: popupId,
                body: {
                    view: 'textarea',
                    width,
                    height: 200,
                },
            };
            break;
        }
        default:
            popup.getBody().define('width', width);
            popup.resize();
            break;
        }
    }
};
