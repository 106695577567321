import { filesListId, fileDescriptionId } from '../../../../../../../../config/constants.js';
import listFilesButtons from './template/listFilesButtons.hbs';
import btnDataTable from './template/assets/scss/btnDataTable.scss';
import deleteFile from '../../models/deleteFile.js';
import fileNameTemplate from './template/fileNameTemplate.js';
import createAttachViewer from './models/createAttachViewer.js';
import resizePopupFileDescription from './models/resizePopupFileDescription.js';
import fileOwnerTemplate from './template/fileOwnerTemplate.js';
import fileDateCreateTemplate from './template/fileDateCreateTemplate.js';
import updateFileDescription from './models/updateFileDescription.js';
import fileDescriptionValidationRules from './models/fileDescriptionValidationRules.js';

export default () => ({
    id: filesListId,
    view: 'datatable',
    resizeColumn: { size: 6 },
    css: 'webix_header_border',
    editable: true,
    // select: 'row', //TODO Заккоментировано из-за бага Webix после удаления файла из аплоудера
    columns: [
        {
            id: 'name',
            header: 'Имя файла',
            adjust: true,
            template: fileNameTemplate,
        },
        {
            id: fileDescriptionId,
            header: 'Описание',
            editor: 'popup',
            fillspace: true,
        },
        {
            id: 'sname',
            header: 'Имя пользователя',
            adjust: true,
            template: fileOwnerTemplate,
        },
        {
            id: 'dateAdded',
            header: 'Дата',
            adjust: true,
            template: fileDateCreateTemplate,
        },
        {
            id: 'button',
            header: '',
            width: 75,
            tooltip: true,
            template: listFilesButtons({ styles: btnDataTable }),
        },
    ],
    rules: {
        fileDescriptionId: fileDescriptionValidationRules,
    },
    onClick: {
        deleteFile,
    },
    on: {
        onAfterRender: createAttachViewer,
        onBeforeEditStart: resizePopupFileDescription,
        onAfterEditStop: updateFileDescription,
    },
});
