import updateFileDescription from '../../../../../../../../../service/updateFileDescription.js';
import { formAddTaskId } from '../../../../../../../../../config/constants.js';

export default async (obj, id) => {
    const { taskId } = $$(formAddTaskId).getValues();
    const { value, old } = obj;
    if (value !== old) {
        switch (true) {
        case value.length > 2048:
            webix.message({
                text: `Максимальное количество символов (2048) превышено (${value.length}).`,
                type: 'error',
            });
            break;
        default: {
            const idFile = id.row;
            const result = taskId ? await updateFileDescription({ value, idFile }) : false;
            if (result) {
                webix.message({
                    text: 'Описание файла обновлено',
                    type: 'success',
                });
            }
            break;
        }
        }
    }
};

// export default (currentNewValueId, id) => {
//   const { value } = $$(id).getList().getItem(currentNewValueId);
//   switch (id) {
//   case 'modules':
//       webix.message({
//           text: `${value} не является модулем`,
//           type: 'error',
//       });
//       break;
//   case 'functionId':
//       webix.message({
//           text: `${value} не является функцией`,
//           type: 'error',
//       });
//       break;
//   default:
//       break;
//   }
// };
