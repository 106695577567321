const configuratorHyperlinks = [
    {
        id: 'checkList',
        icon: 'fa fa-list-ul',
        value: 'Чек-лист',
    },
    {
        id: 'subtask',
        icon: 'fa fa-clone',
        value: 'Подзадачи',
    },
    {
        id: 'relatedTasks',
        icon: 'fa fa-usb',
        value: 'Связанные задачи',
    },
    {
        id: 'access',
        icon: 'fa fa-users',
        value: 'Доступ',
    },
    {
        id: 'services',
        icon: 'fa fa-table',
        value: 'Услуги',
    },
    {
        id: 'timeTracking',
        icon: 'fa fa-clock-o',
        value: 'Учет времени',
    },
    {
        id: 'story',
        icon: 'fa fa-history',
        value: 'История',
    },
];

export default configuratorHyperlinks;
