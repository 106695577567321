import React from 'react';
import Backdrop from '@mui/material/Backdrop';

import Spinner from 'Ui/Spinners';

export default function Suspend({ suspend = false }: { suspend?: boolean }) {
    return (
        <Backdrop
            sx={{ backgroundColor: '#ffffffb3', zIndex: theme => theme.zIndex.drawer + 1, position: 'absolute' }}
            open={suspend}
        >
            <Spinner />
        </Backdrop>
    );
}
