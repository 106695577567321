import di from 'Services/di.js';
import insertComment from 'fsrc/AddTaskWindow/components/formAddTask/components/cellsBottomTabbar/components/taskComments/services/insertComment.js';
import getDataTaskBySubmissionId from '../services/getDataTaskBySubmissionId';
import updateSubmissionsTasks from '../services/updateSubmissionsTasks';

export default async (eventData, messageData) => {
    const { taskId } = eventData;
    const { isSend, dataMessage, currentContacts } = messageData;
    const store = di.store.getState();
    const { root } = store.reducerBase;
    const { postId } = store.reducerUser;

    const dataTask = await getDataTaskBySubmissionId(eventData);
    const newTask = await app.fetch(`${root}/ajax/qick_add_task.php`, dataTask, 'POST');
    const { success, id } = newTask;

    if (!success) {
        webix.message({
            text: 'Не удалось создать новую задачу',
            type: 'error',
        });

        return false;
    }

    webix.message({
        text: `Создана задача №${id}`,
        type: 'success',
    });

    const { submissionType } = dataTask;
    const dataUpdateSubmissionsTasks = {
        submissionType,
        taskId: id,
        parentId: taskId,
    };
    updateSubmissionsTasks(dataUpdateSubmissionsTasks)
        .then((result) => {
            webix.message({
                text: result
                    ? `Связи задач №${taskId} и №${id} обнолены`
                    : `Не удалось связать задачи №${taskId} и №${id}`,
                type: result
                    ? 'success'
                    : 'error',
            });
        });

    if (dataMessage.description) {
        if (isSend) {
            const contacts = currentContacts.map(contact => contact.contactId);
            const dataSend = {
                id_task: id,
                text: dataMessage.description,
                post_id: postId,
                contacts,
            };

            app.fetch(`${root}/ajax/gmail/v3_send_for_task.php`, dataSend, 'POST')
                .then(((res) => {
                    if (res && res.success) {
                        app.fetch('ajax/gmail/outbox_queue.php?send')
                            .then((result) => {
                                webix.message({
                                    text: result?.length
                                        ? `Отправлено писем - ${result.length}`
                                        : 'Не удалось отправить письма',
                                    type: result?.length
                                        ? 'success'
                                        : 'error',
                                });
                            });
                    }
                }));
        }

        insertComment(id, dataMessage.description)
            .then((result) => {
                webix.message({
                    text: result
                        ? `Комментарий, для задачи №${id}, успешно добавлен`
                        : `Не удалось добавить комментарий для задачи №${id}`,
                    type: result
                        ? 'success'
                        : 'error',
                });
            });
    }
    return dataTask;
};
