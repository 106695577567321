import { FilterOptionsState } from '@mui/material';
import IFunctionWithPath from 'CommonTypes/Functions/IFunctionWithPath';
import { FieldValues, UseControllerReturn } from 'react-hook-form';

function getFilterOptions(serviceController: UseControllerReturn<FieldValues, 'services'>) {
    return (
        options: IFunctionWithPath[],
        stateFilter: FilterOptionsState<IFunctionWithPath>,
    ) => {
        const { inputValue } = stateFilter;
        const { value } = serviceController.field;
        const functionsRoles = value?.functionsRoles || '';
        const functionsTmp: string[] = functionsRoles.length > 0 ? functionsRoles.split(',') : [];
        const functionsIds = Array.from(new Set(functionsTmp.map((funcRole: string) => +funcRole.split('-')[0])));

        const functionsFilteredByService = functionsIds.length
            ? options.filter(func => functionsIds.includes(func.id)) : options;

        const functionsFilteredOptions = functionsFilteredByService.filter(func => (
            func.path.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
        ));

        return functionsFilteredOptions;
    };
}

export default getFilterOptions;
