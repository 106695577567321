import di from 'Services/di.js';
import getWikiButton from './getWikiButton.js';
import tempLayout from './tempLayout.js';
import rebuildLayout from './rebuildLayout.js';

const { events } = di;

const wikiButton = () => {
    events.once('mainmenu:loaded', rebuildLayout);
    const sidebarElements = $$('main-menu').serialize();

    if (sidebarElements.length > 0) {
        return getWikiButton();
    }
    return { hidden: true };
};

export default wikiButton;
