import React from 'react';
import { Root } from 'react-dom/client';
import di from 'Services/container';
import ReactHeaderComponent from './ReactHeaderComponent';

// eslint-disable-next-line no-undef
export default function renderHeaderReact(title: string): Root {
    const { render } = di;
    return (
        render(
            <ReactHeaderComponent title={title} />,
            { container: 'header' },
        ) as Root
    );
}
