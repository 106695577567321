import React from 'react';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function SettingsButtons({ open, callback }: { open: boolean, callback: () => void }) {
    return (
        <>
            <Button variant="contained" color="secondary" size="square">
                <Icon><i className="fa fa-pencil" /></Icon>
            </Button>
            <Button variant="contained" color="secondary" size="square">
                <Icon><i className="fa fa-ellipsis-v" /></Icon>
            </Button>
            <Button variant="contained" color="secondary" size="square" onClick={callback}>
                {open
                    ? <ArrowForwardIosIcon fontSize="inherit" />
                    : <ArrowBackIosNewIcon fontSize="inherit" />}
            </Button>
        </>
    );
}

export default SettingsButtons;
