import { descriptionTaskId, formAddTaskId } from '../../../config/constants.js';

export default (dataTask) => {
    const dirty = $$(formAddTaskId).isDirty();
    if (!dirty) return false;
    const { desc = '' } = dataTask || {};
    const dirtyValues = $$(formAddTaskId).getDirtyValues();
    const checkIsDirty = Object.entries(dirtyValues);
    const result = checkIsDirty.reduce((acc, cv, ind, array) => {
        const arrLeng = array.length === 1;
        const id = cv[0] === descriptionTaskId;
        const value = cv[1] === desc;

        return arrLeng && id && value;
    }, '');

    return !result;
};
