import React from 'react';

export default function SpinnerDoubleBounce() {
    return (
        <div className="sk-spinner sk-spinner-double-bounce">
            <div className="sk-double-bounce1" />
            <div className="sk-double-bounce2" />
        </div>
    );
}
