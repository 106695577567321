import di from 'Services/di.js';
import showTask from './models/showTask';
import addTask from './models/addTask';
import addTaskNewOrOldForm from './models/addTaskNewOrOldForm.js';

export default () => {
    const { events } = di;
    events.on('oldAppInbox:openTask', showTask, false);
    events.on('oldAppInbox:addTask', addTask, false);
    events.on('tasks_oldAppTask:addTask', addTaskNewOrOldForm, false);
};
