import React from 'react';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import configuratorHyperlinks from '../../utils/configuratorHuperlinks';
import DetailsAccordion from '../DetailsAccordion/DetailsAccordion';

import IHyperlink from '../../types/IHyperlink';

import classes from '../../assets/style.scss';

function HyperlinksAccordion({ hyperlink }: { hyperlink: string }) {
    const currentHyperlinks = configuratorHyperlinks.find(
        (el: IHyperlink) => el.id === hyperlink,
    )!;

    const { icon, value } = currentHyperlinks;

    return (
        <Accordion expanded>
            <AccordionSummary>
                <Typography className={classes.accordion_summary}>
                    <Icon><i className={icon} /></Icon>
                    <strong>{value}</strong>
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <DetailsAccordion hyperlink={hyperlink} />
            </AccordionDetails>
        </Accordion>
    );
}

export default HyperlinksAccordion;
