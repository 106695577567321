import { subtasksTableId } from '../../../../../../config/constants.js';
import showInfoTask from '../../../../../../utils/showInfoTask.js';
import showDescriptionTask from './models/showDescriptionTask.js';
import subview from './subview.js';

export default {
    id: subtasksTableId,
    view: 'datatable',
    select: 'row',
    resizeColumn: { size: 6 },
    data: [],
    scheme: {
        $init: (obj) => {
            const parser = webix.Date.strToDate('%Y-%m-%d');
            obj.startDate = parser(obj.startDate);
            obj.endDate = parser(obj.endDate);

            return obj;
        },
    },
    columns: [
        {
            id: 'id',
            header: [
                { text: '№', content: 'excelFilter', mode: 'number' },
            ],
            css: 'rank',
            width: 100,
            sort: 'int',
            template: '{common.subrow()} #wsId#',
            adjust: true,
        },
        {
            id: 'name',
            header: [
                { text: 'Наименование', content: 'excelFilter', mode: 'text' },
            ],
            sort: 'string',
            fillspace: true,
        },
        {
            id: 'progress',
            header: [
                { text: 'Прогресс', content: 'excelFilter', mode: 'number' },
            ],
            sort: 'int',
            template: '#progress#%',
            adjust: true,
        },
        {
            id: 'projectName',
            header: [
                { text: 'Проект', content: 'excelFilter', mode: 'text' },
            ],
            sort: 'string',
            adjust: true,
        },
        {
            id: 'assigneeName',
            header: [
                { text: 'Исполнитель', content: 'excelFilter', mode: 'text' },
            ],
            sort: 'string',
            adjust: true,
        },
        {
            id: 'startDate',
            header: [
                { text: 'Дата начала', content: 'excelFilter', mode: 'date' },
            ],
            sort: 'date',
            format: webix.Date.dateToStr('%d.%m.%Y'),
            adjust: true,
        },
        {
            id: 'endDate',
            header: [
                { text: 'Дата окончания', content: 'excelFilter', mode: 'date' },
            ],
            sort: 'date',
            format: webix.Date.dateToStr('%d.%m.%Y'),
            adjust: true,
        },
        {
            id: 'stateName',
            header: [
                { text: 'Состояние', content: 'excelFilter', mode: 'text' },
            ],
            sort: 'text',
            adjust: true,
        },
    ],
    subview,
    on: {
        onItemDblClick: showInfoTask,
        onSubViewCreate: showDescriptionTask,
    },
};
