import { btnSaveId } from '../config/constants.js';
import checkIsDirty from '../components/formAddTask/models/checkIsDirty.js';

export default (taskData) => {
    const isDirty = checkIsDirty(taskData);
    switch (true) {
    case !isDirty:
        return webix.html.removeCss($$(btnSaveId).getNode(), 'webix_primary');
    default:
        return $$(btnSaveId).define('css', 'webix_primary');
    }
};
