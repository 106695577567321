import { formAddTaskId } from '../../../../../../../config/constants.js';
import addCreatedTaskToTable from './addCreatedTaskToTable.js';

export default () => {
    const parentTaskInfo = $$(formAddTaskId).getValues();

    const parentItem = {
        id: parentTaskInfo.taskId,
        name: parentTaskInfo.name,
    };
    const data = {
        projectId: parentTaskInfo.project,
        planId: parentTaskInfo.planId,
    };

    window.events.emit('tasks__formAddTask:addNewTask', {
        type: 'submissionTask',
        params: { data, parentItem },
        callback: result => addCreatedTaskToTable(result),
    });
};
