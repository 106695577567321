import React from 'react';
import IProjectSimpleSet from 'CommonTypes/Projects/IProjectSimpleSet';

import { Autocomplete } from 'Ui/Form/Autocomplete';

function EventsProjectSection(props: { data: IProjectSimpleSet[] }) {
    const { data = [] } = props;

    return (
        <Autocomplete<IProjectSimpleSet>
            name="project"
            label="Проект"
            options={data}
            blurOnSelect
        />
    );
}

export default EventsProjectSection;
