import { nodePath } from 'Config/path.js';

const getSubtasks = async (parentId) => {
    const data = await webix
        .ajax(`${nodePath}/tasks/getSubtasks?parentId=${parentId}`)
        .then(res => res.json());

    return data;
};

export default getSubtasks;
