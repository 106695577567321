const runLoaderBar = (tableId) => {
    webix.extend($$(tableId), webix.ProgressBar);
    $$(tableId).showProgress({
        position: 0,
        delay: 2000,
        type: 'icon',
        icon: 'wxi-sync',
        hide: false,
    });
};

export default runLoaderBar;
