import { IMessageFilesById } from 'CommonTypes/Tasks/Messages/IResponse';

export default (messageFiles: IMessageFilesById[]) => {
    if (!messageFiles.length) return [];

    const defaultFiles = messageFiles.map((file) => {
        const defaultFile = {
            fileDescription: '',
            ...file,
            id: `${file.id}`,
            fileName: file.name,
        };

        return defaultFile;
    });

    return defaultFiles;
};
