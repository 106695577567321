import { initModule } from '../services/initModule.js';

export default {
    '/plans': {
        as: 'plans',
        uses: () => {
            import(
                /* webpackChunkName: "plans" */ '../../PlansModule/PlansController.js'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
