import { formAddTaskId } from '../../../config/constants.js';
import updateDataTask from '../../../service/updateDataTask.js';
import getTaskById from '../../../service/getTaskById.js';
import updateRequestsTable from './updateRequestsTable.js';

export default async () => {
    const data = $$(formAddTaskId).getValues();
    const dataFormName = Object.keys(data);
    dataFormName.forEach((el) => {
        data[el] = data[el] ? data[el] : null;
    });
    if (data.functionId) data.functionId = data.functionId.split(',');
    if (data.observers) data.observers = data.observers.split(',');
    if (data.modules) data.modules = data.modules.split(',').map(el => (el.includes('c') ? el.slice(1) : el));
    if (data.assetsId) data.assetsId = data.assetsId.split(',').map(el => el.slice(1));
    if (!data.desc) data.desc = '';
    if (data.tagsId || data.subprojectId) {
        const tags = data?.tagsId ?? '';
        const subProject = data?.subprojectId ?? '';
        const resultTags = tags ? (`${tags},${subProject}`) : subProject;
        data.tags = resultTags.split(',').filter(el => !!el);
    }
    data.dateChange = new Date();
    const update = await updateDataTask(data);
    if (update) {
        const dataTask = await getTaskById(data.taskId);
        updateRequestsTable(dataTask);
        return true;
    }
    return update;
};
