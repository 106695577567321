import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    DataGrid, GridActionsCellItem, GridActionsColDef, GridColumns, GridRowParams,
} from '@mui/x-data-grid';
import { Button, Icon } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';

import { isHistoryTabActivated } from 'fsrc/Task/store/activeTabsFromTasksFormSelector';

import { IStatesSimpleSetResponse } from 'CommonTypes/Tasks/States/StatesTypes';
import useEnqueueSnackbar from 'Lib/Notistack/useEnqueueSnackbar';
import {
    useAddTasksHistoryNewRecordMutation,
    useClearTasksHistoryByTaskIdMutation,
    useDeleteTasksHistoryRecordMutation,
    useGetTasksHistoryRecordsByTaskIdQuery,
    useGetTasksStatesByRouteIdQuery,
    useUpdateTasksHistoryRecordDateMutation,
    useUpdateTasksHistoryRecordStateIdMutation,
} from 'Endpoints/Tasks/tasks.api';
import {
    ITasksHistoryAddRecordResponse,
    ITasksHistoryClearByTaskIdResponse,
    ITasksHistoryDeleteRecordResponse,
    ITasksHistoryUpdateRecordsDatesResponse,
    ITasksHistoryUpdateStateIdResponse,
} from 'CommonTypes/Tasks/TasksTypes/TasksHistory';
import { useCanUserByTaskIdActionQuery } from 'Endpoints/Roles/rolesMatching.api';
import valueFormatterDateTime from 'Domain/Helpers/valueFormatterDateTime';
import ModalConfirm from './ModalConfirm';

interface IProps {
    taskId: number;
}

const columns: GridColumns = [
    {
        field: 'id',
        headerName: '№',
        width: 100,
    },
    {
        field: 'stateId',
        headerName: 'Наименование',
        width: 250,
        type: 'singleSelect',
        valueOptions: [],
    },
    {
        field: 'startDate',
        headerName: 'Дата начала',
        type: 'dateTime',
        width: 200,
        valueFormatter: valueFormatterDateTime,
    },
    {
        field: 'endDate',
        headerName: 'Дата окончания',
        type: 'dateTime',
        width: 200,
        valueFormatter: valueFormatterDateTime,
    },
    {
        field: 'durationDays',
        headerName: 'Длительность (дней)',
        type: 'number',
        width: 200,
    },
    {
        field: 'durationHours',
        headerName: 'Длительность (ч.)',
        type: 'number',
        width: 200,
    },
    {
        field: 'authorName',
        headerName: 'Автор',
        flex: 1,
    },
];

function HistoryTableView({ taskId }: IProps) {
    const tabIsActivated = useSelector(isHistoryTabActivated);

    const [updateRecordStateId] = useUpdateTasksHistoryRecordStateIdMutation();
    const [updateRecordDates] = useUpdateTasksHistoryRecordDateMutation();
    const [deleteRecord] = useDeleteTasksHistoryRecordMutation();
    const [clearRecords] = useClearTasksHistoryByTaskIdMutation();
    const [addNewRecord] = useAddTasksHistoryNewRecordMutation();

    const [recordIdForDelete, setRecordIdForDelete] = useState(0);
    const [isClearModalOpen, setIsClearModalOpen] = useState(false);

    const { data = [] } = useGetTasksHistoryRecordsByTaskIdQuery(
        { taskId },
        {
            skip: !tabIsActivated,
        },
    );
    const selectedRouteId = useMemo(() => (data?.length ? data[0].routeId : 0), [data]);

    const { success, error, warning } = useEnqueueSnackbar();

    const { data: states = [] } = useGetTasksStatesByRouteIdQuery(
        { routeId: selectedRouteId },
        { skip: !selectedRouteId },
    );

    const { data: canRecords } = useCanUserByTaskIdActionQuery({ taskId, action: 'tasks__history:editRecords' });

    const messageResponse = useCallback((field: 'stateId' | 'startDate' | 'endDate' | 'delete' | 'clear' | 'add', response: ITasksHistoryUpdateStateIdResponse | ITasksHistoryUpdateRecordsDatesResponse | ITasksHistoryDeleteRecordResponse | ITasksHistoryClearByTaskIdResponse | ITasksHistoryAddRecordResponse) => {
        if (response?.success) {
            if (field === 'delete') {
                success('Запись успешно удалена');
            } else if (field === 'clear') {
                success('Записи успешно очищены');
            } else if (field !== 'add') {
                const name = columns.find(column => column.field === field) || { headerName: '' };
                success(`Поле ${name.headerName} успешно изменено`);
            }
        } else if (response?.permissions?.rolesMatching === false) {
            warning('Нет прав на редактирование истории');
        } else {
            error('Во время изменения записи возникла ошибка');
        }
    }, [error, success, warning]);

    const columnsUpdated = useMemo(() => {
        const resultColumns = columns.map((column) => {
            if (column.field === 'stateId') {
                return {
                    ...column,
                    valueOptions: states,
                    valueFormatter({ value }: IStatesSimpleSetResponse) {
                        const option = states.find(state => +state.value === +value);
                        return option?.label;
                    },
                    editable: Boolean(canRecords?.can),
                };
            }

            if (canRecords?.can && ['startDate', 'endDate'].includes(column.field)) {
                return {
                    ...column,
                    editable: true,
                };
            }

            return {
                ...column,
            };
        });

        if (canRecords?.can) {
            const actions: GridActionsColDef = {
                field: 'actions',
                type: 'actions',
                width: 80,
                getActions: (params: GridRowParams) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Удалить"
                        onClick={() => setRecordIdForDelete(+(params?.id || 0))}
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        showInMenu={undefined}
                    />,
                ],
            };

            resultColumns.push(actions);
        }

        return resultColumns;
    }, [canRecords?.can, states]);

    const onModalDeleteCloseHandler = useCallback((result: boolean) => {
        if (result) {
            const deleteData = {
                recordId: recordIdForDelete,
                taskId,
            };
            deleteRecord(deleteData).unwrap().then((response) => {
                messageResponse('delete', response);
                setRecordIdForDelete(0);
            });
        } else {
            setRecordIdForDelete(0);
        }
    }, [deleteRecord, messageResponse, recordIdForDelete, taskId]);

    const onModalClearHistoryCloseHandler = useCallback((result: boolean) => {
        if (result) {
            clearRecords({ taskId }).unwrap().then(response => messageResponse('clear', response));
        }
        setIsClearModalOpen(false);
    }, [clearRecords, messageResponse, taskId]);

    const addRecordButtonClickHandler = useCallback(() => {
        addNewRecord({ taskId }).unwrap().then(response => messageResponse('add', response));
    }, [addNewRecord, messageResponse, taskId]);

    return (
        <>
            { canRecords?.can ? (
                <div
                    style={{
                        display: 'flex',
                        gap: '10px',
                        marginBottom: '15px',
                    }}
                >
                    <Button variant="contained" onClick={addRecordButtonClickHandler} startIcon={<Icon><i className="fa fa-plus" /></Icon>}>
                        Добавить запись
                    </Button>
                    <Button variant="contained" onClick={() => setIsClearModalOpen(true)} color="error" startIcon={<Icon><i className="fa fa-remove" /></Icon>}>
                        Обнулить историю
                    </Button>
                </div>
            ) : null}

            <DataGrid
                columns={columnsUpdated as GridColumns}
                rows={data}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            durationHours: false,
                        },
                    },
                }}
                onCellEditCommit={(params) => {
                    if (params.field === 'stateId') {
                        const updateStateData = {
                            taskId,
                            recordId: +(params.id || 0) as number,
                            stateId: +(params.value || 0) as number,
                        };
                        updateRecordStateId(updateStateData).unwrap().then(response => messageResponse('stateId', response));
                    }

                    if (params.field === 'startDate' || params.field === 'endDate') {
                        const updateDatesData = {
                            taskId,
                            recordId: +(params.id || 0) as number,
                            dateType: params.field.replace('Date', ''),
                            newDate: dayjs(params.value).format('YYYY-MM-DD HH:mm:ss'),
                        };
                        updateRecordDates(updateDatesData).unwrap().then(
                            response => messageResponse(params.field as 'startDate' | 'endDate', response),
                        );
                    }
                }}
            />

            {Boolean(recordIdForDelete)
            && <ModalConfirm closeHandle={onModalDeleteCloseHandler} title="Вы действительно хотите удалить запись?" describe="Запись будет удалена..." />}

            {isClearModalOpen && <ModalConfirm closeHandle={onModalClearHistoryCloseHandler} title="Вы действительно хотите очистить историю?" describe="История будет очищена..." />}
        </>
    );
}

export default HistoryTableView;
