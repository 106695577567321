import { IFieldsConfig } from 'Domain/LayoutConfiguration/types/IFieldsConfig';

const config: IFieldsConfig = {
    layout: {
        id: 'layout__formAddTaskId',
        name: 'Создание задачи (webix)',
    },
    fields: [
        {
            id: 'changeRequests__wsId',
            name: 'Регистрационный номер',
        },
        {
            id: 'changeRequests__progress',
            name: 'Прогресс',
        },
        {
            id: 'changeRequests__taskTypeId',
            name: 'Тип',
        },
        {
            id: 'changeRequests__department',
            name: 'Подразделение',
        },
        {
            id: 'changeRequests__priority',
            name: 'Приоритет',
        },
        {
            id: 'changeRequests__assignee',
            name: 'Исполнитель',
        },
        {
            id: 'changeRequests__role',
            name: 'Роль',
        },
        {
            id: 'changeRequests__it_service',
            name: 'Сервис',
        },
        {
            id: 'changeRequests__functionId',
            name: 'Функции',
        },
        {
            id: 'changeRequests__assetsId',
            name: 'Активы',
        },
        {
            id: 'changeRequests__modules',
            name: 'Модули',
        },
        {
            id: 'changeRequests__date',
            name: 'Дата начала',
        },
        {
            id: 'changeRequests__deadline',
            name: 'Дата окончания',
        },
        {
            id: 'changeRequests__dateCreate',
            name: 'Дата создания',
        },
        {
            id: 'changeRequests__date_complete',
            name: 'Дата завершения',
        },
        {
            id: 'changeRequests__observers',
            name: 'Наблюдатели',
        },
        {
            id: 'changeRequests__subprojectId',
            name: 'Подпроект',
        },
        {
            id: 'changeRequests__planId',
            name: 'План',
        },
        {
            id: 'changeRequests__sprint',
            name: 'Спринт',
        },
        {
            id: 'changeRequests__tagsId',
            name: 'Теги',
        },
        {
            id: 'changeRequests__owner',
            name: 'Автор',
        },
        {
            id: 'changeRequests__scrollview',
            name: 'Кнопки состояния задачи',
        },
        {
            id: 'changeRequests__desc',
            name: 'Описание',
        },
        {
            id: 'changeRequests__checkListId',
            name: 'Чек-лист',
        },
        {
            id: 'changeRequests__requirementsId',
            name: 'Требования',
        },
        {
            id: 'changeRequests__uploader_filesId',
            name: 'Файлы',
        },
        {
            id: 'changeRequests__commentsId',
            name: 'Комментарии',
        },
        {
            id: 'changeRequests__subTasksId',
            name: 'Подзадачи',
        },
        {
            id: 'changeRequests__relatedTasksId',
            name: 'Связанные задачи',
        },
        {
            id: 'changeRequests__timeManagementId',
            name: 'Учёт времени',
        },
        {
            id: 'changeRequests__storyId',
            name: 'История',
        },
        {
            id: 'changeRequests__serviceClassId',
            name: 'Класс обслуживания',
        },
        {
            id: 'changeRequests__plannedTaskId',
            name: 'Плановая задача',
        },
    ],
};
export default config;
