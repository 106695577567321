import toggleStylesBtnSave from 'Domain/LayoutConfiguration/models/toggleStylesBtnSave';
import { formId } from 'Domain/LayoutConfiguration/config/constans';
import { IFormData } from '../types/IFormData';
import { IFormFieldsConfigController } from '../types/IFormFieldsConfigController';

function createBody(fieldsConfig: IFormFieldsConfigController): webix.ui.formConfig {
    const { fields } = fieldsConfig.formConfig;
    const elements = fields.reduce((acc: webix.ui.checkboxConfig[], el) => {
        const checkbox: webix.ui.checkboxConfig = {};
        checkbox.view = 'checkbox';
        checkbox.label = el.name;
        checkbox.labelWidth = 520;
        checkbox.id = el.id;
        checkbox.name = el.id;
        const value: number = (fieldsConfig.settings as IFormData)?.[el?.id] ?? 1;
        checkbox.value = value as number;
        acc.push(checkbox);
        return acc;
    }, []);
    return {
        view: 'form',
        id: formId,
        scroll: true,
        elements,
        on: {
            onChange: toggleStylesBtnSave,
        },
    };
}
export default createBody;
