/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import getContractsOptionsService from 'fsrc/Sales/services/getContractsOptionsService';
import setContractToTaskService from 'fsrc/Sales/services/setContractToTaskService';
import connectorOldContractAddModel from 'fsrc/Sales/models/connectorOldContractAddModel.js';

type TProps = {
    taskId: number,
    contractId: number | null,
    contragentId: number | null,
    firmId: number | null,
    projectId: number | null,
    assigneeId: number | null,
    date: string | null,
    handleContractAdded: (newContractId: number) => void,
}

type TResponse = {
    id: number,
    subject: string,
}

function ContractSelect({
    taskId, contractId, contragentId, firmId, projectId, assigneeId, date,
    handleContractAdded,
}: TProps) {
    const [options, setOptions] = useState<TResponse[]>([]);
    const [value, setValue] = useState<TResponse | null>(null);

    const onChangeHandle = useCallback((
        e: React.SyntheticEvent<Element, Event>,
        newValue: TResponse | null,
    ) => {
        setValue(newValue);
        setContractToTaskService({ taskId, contractId: newValue?.id || 0 });
    }, [taskId]);

    const onClickLinkHandle = useCallback(() => {
        window.open(`//${window.location.host}/outbox/${value?.id || 0}`, '_blank', 'noreferrer');
    }, [value?.id]);

    const handleClickPlusButton = useCallback(() => {
        const taskData = {
            id: taskId,
            id_contragent: contragentId,
            id_firm: firmId,
            id_project: projectId,
            id_assignee: assigneeId,
            date,
        };
        connectorOldContractAddModel(taskData, (id: number, type: number, isContract: boolean) => {
            if (!isContract) {
                return false;
            }
            getContractsOptionsService({ contragentId, firmId }).then((result) => {
                setOptions(result);
                setTimeout(() => {
                    handleContractAdded(+id);
                    setContractToTaskService({ taskId, contractId: +id });
                }, 500);
            });

            return true;
        });
    }, [assigneeId, contragentId, date, firmId, handleContractAdded, projectId, taskId]);

    useEffect(() => {
        if (!contragentId || !firmId) {
            setOptions([]);
        } else {
            getContractsOptionsService({ contragentId, firmId }).then(result => setOptions(result));
        }
    }, [contragentId, firmId]);

    useEffect(() => {
        const currentValue = options?.find(option => +option.id === contractId) || null;
        if (currentValue) {
            setValue(currentValue);
        }
    }, [contractId, options]);

    return (
        <Autocomplete
            size="small"
            options={options}
            value={value}
            blurOnSelect
            handleHomeEndKeys={false}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Договор"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {Boolean(value) && (
                                    <InputAdornment position="end">
                                        <Tooltip title="Открыть Договор">
                                            <IconButton onClick={onClickLinkHandle}>
                                                <i className="fa fa-external-link" />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )}
                                <InputAdornment position="end">
                                    <Tooltip title="Добавить Договор">
                                        <IconButton onClick={handleClickPlusButton}>
                                            <i className="fa fa-plus" />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            onChange={onChangeHandle}
            getOptionLabel={option => option.subject}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.subject}
                </li>
            )}
            isOptionEqualToValue={(
                option: TResponse,
                currentValue: TResponse,
            ) => option.id === currentValue.id}
        />
    );
}

export default ContractSelect;
