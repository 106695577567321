function getDownloadItem(dataId) {
    return (id) => {
        if (id === 'excel') {
            webix.toExcel($$(dataId));
        } else if (id === 'pdf') {
            webix.toPDF($$(dataId));
        } else if (id === 'png') {
            webix.toPNG($$(dataId));
        } else if (id === 'csv') {
            webix.toCSV($$(dataId));
        }
    };
}

export default set => (set ? {
    view: 'menu',
    width: 32,
    css: 'bitbucket default',
    data: [
        {
            value: "<i class='fa fa-download'></i>",
            submenu: [
                {
                    id: 'excel',
                    view: 'icon',
                    icon: 'wxi-download',
                    value: 'в Excel',
                },
                {
                    id: 'pdf',
                    view: 'icon',
                    icon: 'wxi-download',
                    value: 'в PDF',
                },
                {
                    id: 'png',
                    view: 'icon',
                    icon: 'wxi-download',
                    value: 'в PNG',
                },
                {
                    id: 'csv',
                    view: 'icon',
                    icon: 'wxi-download',
                    value: 'в CSV',
                },
            ],
        },
    ],
    on: {
        onMenuItemClick: getDownloadItem(set),
    },
} : {
    hidden: true,
});
