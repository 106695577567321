import { commentsId, bottomTabbarId, bottomMultiviewId } from 'fsrc/AddTaskWindow/config/constants.js';

import commentsPlaceholder from './commentsPlaceholderView.js';

export default {
    id: commentsId,
    tabbarParent: bottomTabbarId,
    multiviewParent: bottomMultiviewId,
    borderless: true,
    rows: [
        commentsPlaceholder,
    ],
};
