import {
    subtasksToolbarId,
    subtasksFilterStateBtnsId,
    subtasksFilterInworkId,
    subtasksFilterDraftId,
    subtasksFilterFinishId,
} from '../../../../../../config/constants.js';
import filterSubtasksByState from './models/filterSubtasksByState.js';
import addSubTask from './models/addSubTask.js';

export default dataFiles => ({
    id: subtasksToolbarId,
    view: 'toolbar',
    height: 40,
    elements: [
        {
            cols: [
                {
                    view: 'button',
                    width: 45,
                    tooltip: 'Добавить подзадачу',
                    label: '<span class="fa fa-plus">',
                    click: () => addSubTask(dataFiles),
                },
                {
                    id: subtasksFilterStateBtnsId,
                    view: 'segmented',
                    width: 630,
                    value: subtasksFilterInworkId,
                    options: [
                        {
                            id: subtasksFilterInworkId,
                            value: 'В работе',
                        },
                        {
                            id: subtasksFilterDraftId,
                            value: 'Черновик',
                        },
                        {
                            id: subtasksFilterFinishId,
                            value: 'Завершено',
                        },
                    ],
                    on: {
                        onAfterTabClick: filterSubtasksByState,
                    },
                },
                {},
            ],
        },
    ],
});
