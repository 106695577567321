/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useController, UseControllerReturn, useFormContext } from 'react-hook-form';

interface IProps {
    name: string;
    label?: string;
}

function DateTimePickerRHF(props: IProps) {
    const { name, label } = props;
    const { control } = useFormContext();
    const controller: UseControllerReturn = useController({
        name,
        control,
    });

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="ru"
        >
            <DateTimePicker
                label={label}
                value={controller.field.value}
                onChange={controller.field.onChange}
                renderInput={params => (
                    <TextField sx={{ width: '100%' }} size="small" {...params} />
                )}
            />
        </LocalizationProvider>
    );
}

export default DateTimePickerRHF;
