import { useGetTasksLinksCountByTaskIdQuery } from 'Endpoints/Tasks/tasks.api';
import React from 'react';

interface IProps {
    taskId: number;
}

function LinksTabCount({ taskId }: IProps) {
    const { data } = useGetTasksLinksCountByTaskIdQuery({ taskId });

    return (
        <>
            { data?.count
                ? (
                    <span>
                        (
                        {data.count}
                        )
                    </span>
                )
                : null}
        </>
    );
}

export default LinksTabCount;
