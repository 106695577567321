import { nodePath } from '../configModule/config.js';
import template from './utils/template.js';

export default (handlerItemClick, handlerAfterLoad, collapsed) => ({
    view: 'sidebar',
    id: 'main-menu',
    container: 'main-menu',
    css: 'main-menu',
    collapsed,
    drag: true,
    scrollY: true,
    width: 220,
    scroll: true,
    select: true,
    url: `${nodePath}/sidebar/sidebarUser`,
    template,
    on: {
        onAfterSelect: handlerItemClick,
        onAfterLoad: handlerAfterLoad,
    },
});
