import { ThemeProvider } from '@mui/material';
import React, { StrictMode } from 'react';
import { Root, createRoot } from 'react-dom/client';

import StyledSnackbarProvider from 'Lib/Notistack/StyledSnackbarProvider';
import Action from 'Lib/Notistack/DefaultAction';
import theme from 'Lib/Mui/theme';

import container from 'Services/container';
import DescriptionFieldComponent from '../components/DescriptionField/DescriptionFieldComponent';

type TData = {
    outboxId: number;
}

let rootContainer: Root;

function initDescriptionField(data: TData) {
    const { outboxId } = data;
    const root = document.getElementById(`docInOutboxDescription_${outboxId}`) as HTMLDivElement;
    rootContainer = createRoot(root);

    rootContainer.render(
        <StrictMode>
            <ThemeProvider theme={theme}>
                <StyledSnackbarProvider
                    action={snackbarId => Action(snackbarId)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    hideIconVariant
                >
                    <DescriptionFieldComponent outboxId={outboxId} />
                </StyledSnackbarProvider>
            </ThemeProvider>
        </StrictMode>,
    );
}

function unmountDescriptionField() {
    rootContainer?.unmount();
}

export default () => {
    const { events } = container;
    events.on(
        'outboxMessages__descriptionField:initField',
        initDescriptionField,
        false,
    );
    events.on(
        'router:before-route',
        unmountDescriptionField,
        false,
    );
};
