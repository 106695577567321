import dayjs from 'dayjs';
import TLimitMeasureIds, { limitMeasureIds } from 'CommonTypes/ITServices/TLimitMeasureIds';
import controller from './controller';

function calculateDeadlineByLimitMeasure(
    limitation: number,
    limitMeasure: TLimitMeasureIds,
    startDate = dayjs(),
) {
    if (!limitMeasureIds.includes(limitMeasure)) {
        return dayjs();
    }
    return controller[limitMeasure](limitation, startDate);
}

export default calculateDeadlineByLimitMeasure;
