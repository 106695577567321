import { modulesId, projectsId } from '../../../config/constants.js';

export default (data) => {
    const modules = data.modulesId;
    const currentProjectId = $$(projectsId).getValue();
    if (currentProjectId) {
        const currentDataProject = $$(projectsId).getList().getItem(currentProjectId);
        const { companyId } = currentDataProject;
        const resultModules = modules.filter(module => module.companyId === companyId);
        const list = $$(modulesId).getPopup().getList();
        list.clearAll();
        list.parse(webix.copy(resultModules));
        $$(modulesId).setValue('');
        $$(modulesId).enable();
    } else {
        const list = $$(modulesId).getPopup().getList();
        list.clearAll();
        $$(modulesId).setValue('');
        $$(modulesId).disable();
    }
};
