/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

function ContactChip({ id, name }: { id: number, name: string }) {
    const onClickHandler = () => {
        console.log(id, name); // FIXME: Удалить этот console log
    };

    return (
        <Tooltip title={name}>
            <IconButton
                aria-label="tap-contact"
                onClick={onClickHandler}
                style={{ fontSize: '10px', marginRight: '5px' }}
            >
                {name}
            </IconButton>
        </Tooltip>
    );
}

function ContactsSection({ contacts = [] }: { contacts: string[] }) {
    if (!contacts.length) return null;

    const parseContact = (contact: string) => {
        const [id, contactInfo] = contact.split('~');
        return {
            id: +id,
            name: contactInfo.split(' | ')?.[0],
        };
    };

    return (
        <Typography>
            <i className="fa fa-th-large" style={{ marginRight: '5px' }} />
            {contacts.map((contactString) => {
                const { id, name } = parseContact(contactString);
                return <ContactChip key={id} id={id} name={name} />;
            })}
        </Typography>
    );
}

export default ContactsSection;
