/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { IPlanDayState } from '../types/IState';

const initialState: IPlanDayState = {
    date: dayjs().toISOString(),
    currentSubordinate: {
        subordinateId: 0,
        subordinateName: 'Мой календарь',
        sort: 1,
        rolesIds: null,
    },
    projectId: 0,
    eventEditing: {
        editing: false,
        eventData: null,
        type: null,
    },
};

export const planDaySlice = createSlice({
    name: 'PLAN_DAY_SLICE',
    initialState,
    reducers: {
        changeDate(state, action) {
            state.date = action.payload;
        },
        changeSubordinate(state, action) {
            state.currentSubordinate = action.payload;
        },
        startEditing(state, action) {
            const { eventData, type } = action.payload;
            state.eventEditing = {
                editing: true,
                eventData,
                type,
            };
        },
        stopEditing(state) {
            state.eventEditing = {
                editing: false,
                eventData: null,
                type: null,
            };
        },
        changeProject(state, action) {
            const { projectId } = action.payload;
            state.projectId = projectId || 0;
        },
    },
});

export const {
    changeDate,
    changeSubordinate,
    startEditing,
    stopEditing,
    changeProject,
} = planDaySlice.actions;
export default planDaySlice.reducer;
