import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    root: window.location.origin,
};

export const baseSlice = createSlice({
    name: 'BASE_SLICE',
    initialState,
    reducers: {},
});

export default baseSlice.reducer;
