import di from 'Services/di.js';
import bugReport from '../../BugReport/bugReport.js';

export default {
    view: 'toolbar',
    css: 'sidemenu__button',
    cols: [
        {
            view: 'button',
            value: '<a id="buttonDanger" class="btn btn-danger"><i class="fa fa-bug"></i></a>',
            click() {
                window.events.emit('tasks__formAddTask:addNewTask', { type: 'issue' });
                $$('menu').hide();
            },
        },
        {
            view: 'button',
            value: '<a id="buttonAddTask" class="btn btn-white"><i class="fa fa-plus"></i></a>',
            click() {
                window.events.emit('tasks__formAddTask:addNewTask', { type: 'task' });
                $$('menu').hide();
            },
        },
        {
            view: 'button',
            value: '<a id="buttonHelp" class="btn btn-white"><i class="fa fa-keyboard-o"></i></a>',
            click() {
                app.hotkeys.help();
                $$('menu').hide();
            },
        },
        {
            view: 'button',
            value: '<a id="buttonHelp" class="btn btn-white"><i class="fa fa-info-circle"></i></a>',
            click() {
                app.hotkeys.help();
                $$('menu').hide();
            },
        },
        {
            view: 'button',
            value: '<a class="btn btn-white"><i class="fa fa-history"></i></a>',
            click() {
                window.forceReload();
            },
        },
    ],
};
