import emptySplitApi from 'Endpoints/emptySplitApi';
import IContactSimpleSet from 'CommonTypes/Contacts/IContactSimpleSet';

export const contactsApi = emptySplitApi
    .injectEndpoints({
        endpoints: build => ({
            getContactsSimpleSet: build.query<IContactSimpleSet[], void>({
                query: () => ({
                    url: 'contacts',
                }),
                providesTags: ['ContactsSimpleSet'],
            }),
        }),
    });

export const {
    useGetContactsSimpleSetQuery,
} = contactsApi;
