/* eslint-disable func-names */

const oldRoutes = {
    'tasks/:id/edit': function ({ data }) {
        app.modules.use('app.tasks.params').then(() => {
            app.setFavicon(
                'assets/img/favicons/tasks-16x16.png',
                'assets/img/favicons/tasks-32x32.png',
                'assets/img/favicons/tasks-96x96.png',
            );
            app.tasks.params(data.id);
        });
    },
    'tasks/:mode': function ({ data }) {
        app.modules.use('app.tasks').then(() => {
            app.setTitle('Мои задачи');
            app.setFavicon(
                'assets/img/favicons/tasks-16x16.png',
                'assets/img/favicons/tasks-32x32.png',
                'assets/img/favicons/tasks-96x96.png',
            );
            app.tasks.init(data.mode);
            // if(/[a-z]+/.test(data.mode)){}
        });
    },

    'inbox/:mid': function ({ data }) {
        app.modules.use('app.inbox').then(() => {
            app.setTitle('Входящие');
            app.setFavicon(
                'assets/img/favicons/mail-16x16.png',
                'assets/img/favicons/mail-32x32.png',
                'assets/img/favicons/mail-96x96.png',
            );

            app.inbox.loadOneMsg(data.mid, () => {
                // document.querySelector('.list-item__header-subject').dispatchEvent(new Event('click'))
            });
        });
    },
    inbox({ data, params }) {
        app.modules.use('app.inbox').then(() => {
            app.setTitle('Входящие');
            app.setFavicon(
                'assets/img/favicons/mail-16x16.png',
                'assets/img/favicons/mail-32x32.png',
                'assets/img/favicons/mail-96x96.png',
            );

            app.inbox.init(data, params && Object.entries(params));
        });
    },
    'contacts/:id/edit': function ({ data }) {
        app.modules.use('app.contacts.edit').then(() => {
            app.setFavicon(
                'assets/img/favicons/contacts-16x16.png',
                'assets/img/favicons/contacts-32x32.png',
                'assets/img/favicons/contacts-96x96.png',
            );

            app.contacts.edit.init(data.id, 'inline');
        });
    },
    'contacts/:id': function ({ data }) {
        app.modules.use('app.contacts.one').then(() => {
            app.setFavicon(
                'assets/img/favicons/contacts-16x16.png',
                'assets/img/favicons/contacts-32x32.png',
                'assets/img/favicons/contacts-96x96.png',
            );
            app.contacts.one.init(data.id);
        });
    },

    contacts(action, query) {
        app.modules.use('app.contacts.list').then(() => {
            app.setFavicon(
                'assets/img/favicons/contacts-16x16.png',
                'assets/img/favicons/contacts-32x32.png',
                'assets/img/favicons/contacts-96x96.png',
            );
            app.contacts.list.init(action, query);
        });
    },

    'projects/:id/edit': function ({ data }) {
        app.modules.use('app.projects.edit').then(() => {
            app.setFavicon(
                'assets/img/favicons/pj-16x16.png',
                'assets/img/favicons/pj-32x32.png',
                'assets/img/favicons/pj-96x96.png',
            );
            app.projects.edit.init(data.id);
        });
    },
    'projects/:id': function ({ data }) {
        app.modules.use('app.projects.one').then(() => {
            app.setFavicon(
                'assets/img/favicons/pj-16x16.png',
                'assets/img/favicons/pj-32x32.png',
                'assets/img/favicons/pj-96x96.png',
            );
            app.projects.one.init(data.id);
        });
    },

    projects(action) {
        app.modules.use('app.projects.list').then(() => {
            app.setFavicon(
                'assets/img/favicons/pj-16x16.png',
                'assets/img/favicons/pj-32x32.png',
                'assets/img/favicons/pj-96x96.png',
            );
            app.projects.list.init();
        });
    },
    settings(action) {
        app.modules.use('app.settings').then(() => {
            app.setFavicon(
                'assets/img/favicons/pj-16x16.png',
                'assets/img/favicons/pj-32x32.png',
                'assets/img/favicons/pj-96x96.png',
            );
            app.settings.init();
        });
    },

    'contragents/:id/finance': function ({ data }) {
        app.modules.use('app.contragents.finance').then(() => {
            app.setFavicon(
                'assets/img/favicons/cts-16x16.png',
                'assets/img/favicons/cts-32x32.png',
                'assets/img/favicons/cts-96x96.png',
            );
            app.contragents.finance.init(data.id, 'inline');
        });
    },
    'contragents/:id/edit': function ({ data }) {
        app.modules.use('app.contragents.edit').then(() => {
            app.setFavicon(
                'assets/img/favicons/cts-16x16.png',
                'assets/img/favicons/cts-32x32.png',
                'assets/img/favicons/cts-96x96.png',
            );
            app.contragents.edit.init(data.id, 'inline');
        });
    },
    'contragents/:id': function ({ data }) {
        app.modules.use('app.contragents.one').then(() => {
            app.setFavicon(
                'assets/img/favicons/cts-16x16.png',
                'assets/img/favicons/cts-32x32.png',
                'assets/img/favicons/cts-96x96.png',
            );
            app.contragents.one.init(data.id);
        });
    },

    contragents(action, query) {
        app.modules.use('app.contragents.list').then(() => {
            app.setFavicon(
                'assets/img/favicons/cts-16x16.png',
                'assets/img/favicons/cts-32x32.png',
                'assets/img/favicons/cts-96x96.png',
            );
            app.contragents.list.init(action, query);
        });
    },

    timesheet(action, query) {
        app.modules.use('app.timesheet').then(() => {
            app.timesheet.init(action, query);
        });
    },

    'sales/:id/edit': function ({ data }) {
        app.modules.use('app.sales.edit').then(() => {
            app.setFavicon(
                'assets/img/favicons/cts-16x16.png',
                'assets/img/favicons/cts-32x32.png',
                'assets/img/favicons/cts-96x96.png',
            );
            app.contragents.edit.init(data.id, 'inline');
        });
    },
    'sales/:id': function ({ data }) {
        app.modules.use('app.sales.one').then(() => {
            app.sales.one(data.id);
        });
    },

    sales(action, query) {
        app.modules.use('app.sales').then(() => {
            app.sales.init(action, query);
        });
    },

    profile({ data }) {
        app.modules.use('app.profile').then(() => {
            app.profile.init();
        });
    },

    'profile/integrations/service/:id': function ({ data }) {
        app.modules.use('app.profile.integrations.service').then(() => {
            app.setTitle('Настройка сервисов');
            app.profile.integrations.service.init(data.id);
        });
    },

    'profile/integrations/gmail/:id': function ({ data }) {
        app.modules.use('app.profile.integrations.gmail').then(() => {
            app.setTitle('Настройка Почты GMAIL');
            app.profile.integrations.gmail.init();
        });
    },
    'profile/integrations': function ({ data }) {
        app.modules.use('app.profile.integrations').then(() => {
            app.setTitle('Настройка интеграции');
            app.profile.integrations.init();
        });
    },

    'profile/notif': function ({ data }) {
        app.modules.use('app.profile.notif').then(() => {
            app.setTitle('Настройка уведомлений');
            app.profile.notif.init();
        });
    },

    main({ data }) {
        app.modules.use('app.reports').then(() => {
            app.reports.init();
        });
    },

    'main/:id': function (data, q) {
        app.modules.use(`app.report_${data.id}`).then(
            () => {
                if (typeof app.modules.list[`app.report_${data.id}`] === 'undefined') {
                    app.el.pageContent
                        .empty()
                        .append(
                            crEl(
                                { c: 'alert alert-warning' },
                                `К сожалению отчет #${
                                    data.id
                                } пока не доступен в этой версии.`,
                            ),
                        );
                    return false;
                }

                console.log(eval(app[`report_${data.id}`]));
                app[`report_${data.id}`].init(q);
            },
            () => {
                app.el.pageContent
                    .empty()
                    .append(
                        crEl(
                            { c: 'alert alert-warning' },
                            `К сожалению отчет #${
                                data.id
                            } пока не доступен в этой версии.`,
                        ),
                    );
                return false;
            },
        );
    },

    reports({ data }) {
        app.modules.use('app.reports').then(() => {
            app.reports.init();
        });
    },

    'reports/:id': function ({ data }) {
        const q = '';
        app.modules.use(`app.report_${data.id}`).then(
            () => {
                if (typeof app.modules.list[`app.report_${data.id}`] === 'undefined') {
                    app.el.pageContent
                        .empty()
                        .append(
                            crEl(
                                { c: 'alert alert-warning' },
                                `К сожалению отчет #${
                                    data.id
                                } пока не доступен в этой версии.`,
                            ),
                        );
                    return false;
                }
                app.el.pageContent.classList.add('root-flex-column');
                console.log(eval(app[`report_${data.id}`]));
                app[`report_${data.id}`].init(q);
            },
            () => {
                app.el.pageContent
                    .empty()
                    .append(
                        crEl(
                            { c: 'alert alert-warning' },
                            `К сожалению отчет #${
                                data.id
                            } пока не доступен в этой версии.`,
                        ),
                    );
                return false;
            },
        );
    },

    'outbox/:id/edit': function ({ data }) {
        app.modules.use('app.outbox.edit').then(() => {
            app.outbox.edit.init(data.id);
        });
    },
    'outbox/:id': function ({ data }) {
        app.modules.use('app.outbox.one').then(() => {
            app.outbox.one(data.id);
        });
    },
    outbox(data, q) {
        app.modules.use('app.outbox').then(() => {
            app.outbox.init(data, q);
        });
    },

    olditservices(data, q) {
        app.modules.use('app.itservices').then(() => {
            app.itservices.init();
        });
    },
    services(data, q) {
        app.modules.use('app.tests').then(() => {
            app.tests.init();
        });
    },

    regnums(data, q) {
        app.modules.use('app.regnums').then(() => {
            app.regnums.init(data, q);
        });
    },

    'users/:id': function ({ data }) {
        app.modules.use('app.users').then(() => {
            app.users.one(data.id);
        });
    },
    '/users': function () {
        app.modules.use('app.users').then(() => {
            app.users.init();
        });
    },

    budget() {
        app.modules.use('app.budgetprojects').then(() => {
            app.budgetprojects.init();
        });
    },

    prices(data, q) {
        app.modules.use('app.prices').then(() => {
            app.prices.init(data, q);
        });
    },

    oauth(data, q) {
        app.msg(`asd${q}`);
    },

    gantt() {
        loader.use('GanttModule', 'index.js');
    },

    profile_new() {
        loader.use('ProfileNew', 'index.js', (module) => {
            module.default();
        });
    },
    test(data, q) {
        app.modules.use('app.services').then(() => {
            app.services.init(data, q);
        });
    },
    kanban() {
        app.modules.use('app.kanban').then(() => {
            app.kanban.init();
        });
    },
    projectsplans: () => {
        app.modules.use('app.projectsplans').then(() => {
            app.projectsplans.init();
        });
    },
    'quest-options(.*)?': () => {
        app.modules.use('app.quest').then(() => {
            app.quest.init();
        });
    },
    'autotsk(.*)?': () => {
        app.modules.use('app.autotasks').then(() => {
            app.autotasks.init();
        });
    },
    'newprice(.*)?': () => {
        app.modules.use('app.pricereact').then(() => {
            app.pricereact.init();
        });
    },
    // 'orgstructure(.*)?': () => {
    //     app.modules.use('app.orgstructure').then(() => {
    //         app.orgstructure.init();
    //     });
    // },

    // '*': function () {
    //     // app.modules.use('ProfileNew').then(function() {
    //     //     app.setTitle('myProject');
    //     //     app.home.init();
    //     // });
    //     loader.use('ProfileNew', 'index.js', (module) => {
    //         module.default();
    //     });
    // },
};

export default oldRoutes;
