import React, { useState } from 'react';

import {
    Grid, Paper, Divider, Chip,
} from '@mui/material';

import { useGetCommentsByTaskIdQuery } from 'Endpoints/Comments/comments.api';

import Comment from '../Comment/Comment';
import CommentSkeleton from '../CommentSkeleton/CommentSkeleton';

import defaultGetCountComments from '../../constans/constans';

interface IProps {
    taskId: number;
}

function CommentSection({ taskId }: IProps) {
    const [commentsPosition, setCommentsPosition] = useState(0);

    const { data, isLoading, isFetching } = useGetCommentsByTaskIdQuery({
        taskId,
        pos: commentsPosition,
        count: defaultGetCountComments,
    });

    const emptyArray = Array(defaultGetCountComments);
    const { commentsCount = 0 } = data?.at(-1) ?? {};

    const hundleCommentPosition = () => {
        setCommentsPosition(state => state + defaultGetCountComments);
    };

    return (
        <Paper style={{ padding: '40px 20px' }}>
            {isFetching && (
                <Grid container wrap="nowrap" spacing={2} direction="column">
                    {Array.from(
                        emptyArray,
                        (_, ind) => (
                            <CommentSkeleton
                                key={ind}
                            />
                        ),
                    )}
                </Grid>
            )}
            {!isLoading && (
                <>
                    {!isFetching && !!commentsCount && (
                        <Divider>
                            <Chip
                                label={`Еще комментарии ...(${commentsCount})`}
                                onClick={hundleCommentPosition}
                            />
                        </Divider>
                    )}
                    <Grid container wrap="nowrap" spacing={2} direction="column-reverse">
                        {data!.map(comment => (
                            <Comment
                                key={comment.commentId}
                                comment={comment}
                            />
                        ))}
                    </Grid>
                </>
            )}
        </Paper>
    );
}

export default CommentSection;
