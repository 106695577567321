import fieldsConfig from '../../../config/fieldsConfig';
import { ICurrentFormConfig } from '../../../../Application/Domain/LayoutConfiguration/types/ICurrentFormConfig';

export default () => {
    const { fields } = fieldsConfig;
    const defaultCinfig = fields.reduce((acc: ICurrentFormConfig, el) => {
        const { id } = el;
        acc[id] = 1;
        return acc;
    }, {});

    return defaultCinfig;
};
