import React, { ChangeEvent, useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';

import Autocomplete from 'Ui/Form/Autocomplete/Autocomplete';
import Spinner from 'Ui/Spinners/Spinner';
import { TextField } from 'Ui/Form/TextField';

import { IServiceClassesResponse } from 'CommonTypes/ServiceClasses/ServiceClassesSimpleSet';
import IFunctionWithPath from 'CommonTypes/Functions/IFunctionWithPath';
import { IItServicesWithPath } from 'CommonTypes/Tasks/QuickAddTaskForm/IResponse';
import { ITagsWithProjectLinkByTaskResponse } from 'CommonTypes/Tags/TagsWithProjectLinkByTask';

import getFilterOptionsForService from 'fsrc/ItServices/models/filterServicesByProject';
import getFilterOptionsForFunctions from 'fsrc/ItServices/models/filterFunctionsByService';

import useFieldsVisibleHook from 'Endpoints/Forms/useFieldsVisibleHook';
import TFormProps from '../types/TFormProps';

const filterSubprojects = createFilterOptions<ITagsWithProjectLinkByTaskResponse>();

function FormView({
    services,
    functions,
    servicesByProject,
    submitHandler,
    setDeadline,
    addSubproject,
    setDeadlineByFunctions,
    subprojects,
    serviceClasses,
    taskTypeId,
    formTypeId,
}: TFormProps) {
    const form = useFormContext();
    const serviceController = useController({ name: 'services', control: form.control });
    const functionsController = useController({ name: 'functions', control: form.control });
    const subprojectsController = useController({ name: 'subprojects', control: form.control });
    const serviceClassController = useController({ name: 'serviceClass', control: form.control });
    const planDurationController = useController({ name: 'planDuration', control: form.control });

    const filterFunctions = useCallback(
        getFilterOptionsForFunctions(serviceController),
        [serviceController],
    );

    const filterServices = useCallback(
        getFilterOptionsForService({ services: servicesByProject }),
        [servicesByProject],
    );

    const onChangeService = useCallback((newValue: IItServicesWithPath) => {
        if (newValue?.limitation > 0 && newValue?.limit_measure !== null) {
            setDeadline(newValue.limitation, newValue.limit_measure);
        }
        serviceController.field.onChange(newValue);
        submitHandler();
    }, [
        serviceController.field,
        setDeadline,
        submitHandler,
    ]);

    const onChangeFunctions = useCallback((newValue: IFunctionWithPath[]) => {
        const currentService = serviceController.field.value;
        setDeadlineByFunctions(currentService?.functionsLimitation, newValue);
        functionsController.field.onChange(newValue);
        submitHandler();
    }, [
        functionsController.field,
        serviceController.field.value,
        setDeadlineByFunctions,
        submitHandler,
    ]);

    const handleOnChangeSubprojects = useCallback((
        newValue: ITagsWithProjectLinkByTaskResponse[],
    ) => {
        if (newValue.length && newValue.slice(-1)[0].id === 0) {
            const newSubproject = newValue.slice(-1)[0];
            const newName = typeof newSubproject === 'string' ? newSubproject : newSubproject.value.replace('Добавить "', '').replace('"', '').trim();
            addSubproject(newName);
        } else {
            subprojectsController.field.onChange(newValue);
            submitHandler();
        }
    }, [addSubproject, submitHandler, subprojectsController.field]);

    const handleOnChangeServiceClass = useCallback((
        newValue: IServiceClassesResponse,
    ) => {
        serviceClassController.field.onChange(newValue);
        submitHandler();
    }, [serviceClassController.field, submitHandler]);

    const handleOnChangePlanDuration = useCallback((
        newValue: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        planDurationController.field.onChange(newValue?.target?.value || 0);
        submitHandler();
    }, [planDurationController.field, submitHandler]);

    const { fieldsVisible, isLoading } = useFieldsVisibleHook({ taskTypeId, formTypeId, fieldsIds: ['serviceClass', 'services', 'functions', 'subprojects', 'planDuration'] });

    return (
        <>
            { isLoading && <Spinner />}

            { !isLoading && (
                <form style={{ display: 'flex', width: '100%', padding: '0 15px' }}>
                    <Grid item container columnSpacing={2}>
                        { fieldsVisible?.serviceClass && (
                            <Grid item xs={2}>
                                <Autocomplete<IServiceClassesResponse>
                                    name="serviceClass"
                                    label="Класс обслуживания"
                                    disableClearable
                                    options={serviceClasses}
                                    blurOnSelect
                                    callback={handleOnChangeServiceClass}
                                />
                            </Grid>
                        ) }
                        { fieldsVisible?.services && (
                            <Grid item xs={3}>
                                <Autocomplete<IItServicesWithPath>
                                    name="services"
                                    label="Сервис"
                                    options={services}
                                    filterOptions={filterServices}
                                    blurOnSelect
                                    listValue="path"
                                    inputValue="path"
                                    callback={onChangeService}
                                />
                            </Grid>
                        )}
                        { fieldsVisible?.functions && (
                            <Grid item xs={3}>
                                <Autocomplete<IFunctionWithPath[]>
                                    name="functions"
                                    label="Функции"
                                    multiple
                                    options={functions}
                                    filterOptions={filterFunctions}
                                    blurOnSelect
                                    listValue="path"
                                    callback={onChangeFunctions}
                                />
                            </Grid>
                        )}
                        { fieldsVisible?.subprojects && (
                            <Grid item xs={3}>
                                <Autocomplete<ITagsWithProjectLinkByTaskResponse[]>
                                    name="subprojects"
                                    label="Подпроекты"
                                    multiple
                                    options={subprojects}
                                    blurOnSelect
                                    callback={handleOnChangeSubprojects}
                                    filterOptions={(option, params) => {
                                        const filtered = filterSubprojects(subprojects, params);

                                        if (params.inputValue !== '' && !filtered.length) {
                                            filtered.push({
                                                id: 0,
                                                value: `Добавить "${params.inputValue}"`,
                                            });
                                        }

                                        return filtered;
                                    }}
                                />
                            </Grid>
                        )}
                        { fieldsVisible?.planDuration && (
                            <Grid item xs={1}>
                                <TextField
                                    name="planDuration"
                                    type="number"
                                    label="Плановая длительность"
                                    callback={handleOnChangePlanDuration}
                                    inputProps={{ maxLength: 13 }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </form>
            )}
        </>
    );
}

export default FormView;
