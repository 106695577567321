import { servicesId, topTabbarId, topMultiviewId } from 'fsrc/AddTaskWindow/config/constants.js';

import servicesPlaceholder from './servicesPlaceholder.js';

export default {
    id: servicesId,
    tabbarParent: topTabbarId,
    multiviewParent: topMultiviewId,
    borderless: true,
    rows: [
        servicesPlaceholder,
    ],
};
