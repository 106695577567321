import React, { StrictMode } from 'react';
import { createRoot, Root } from 'react-dom/client';

import di from 'Services/di.js';
import ModalComment from '../ModalComment';
import callbackAdapter from '../models/callbackAdapter';

import { IDataComment } from '../types/IDataComment';
import { IDataSubmission } from '../types/IDataSubmission';
import { IData } from '../types/IData';

let rootContainer: Root;

const eventsTaskController = (data: IData) => {
    const root = document.createElement('div');
    root.className = 'flex-full-height root-flex-column';
    rootContainer = createRoot(root);
    document.body.append(root);
    const callback = callbackAdapter(data);
    rootContainer.render(
        <StrictMode>
            <ModalComment
                eventData={data}
                callback={callback}
            />
        </StrictMode>,
    );
};
const closeCommentModal = () => {
    rootContainer.unmount();
};

export default () => {
    const { events } = di;
    events.on(
        'tasks__comments:advancedCommentEditing',
        (dataComment: IDataComment) => eventsTaskController(
            {
                ...dataComment,
                eventName: 'tasks__comments:advancedCommentEditing',
            },
        ),
        false,
    );
    events.on(
        'tasks__submissions:createTask',
        (dataSubmission: IDataSubmission) => eventsTaskController(
            {
                ...dataSubmission,
                eventName: 'tasks__submissions:createTask',
                textComment: '',
            },
        ),
        false,
    );
    events.on('modalComment:closeModal', closeCommentModal, false);
};
