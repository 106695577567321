import {
    subtasksTableId,
    subtasksFilterStateBtnsId,
    subtasksFilterInworkId,
    subtasksFilterDraftId,
    subtasksFilterFinishId,
} from '../../../../../../../config/constants.js';

const cache = {
    historyClicked: '',
};

export default (id, ev) => {
    if (id === subtasksFilterInworkId) {
        $$(subtasksTableId).filter(obj => obj.stateStart !== 1 && obj.stateFinish !== 1);
    }

    if (id === subtasksFilterDraftId) {
        $$(subtasksTableId).filter(obj => obj.stateStart === 1);
    }

    if (id === subtasksFilterFinishId) {
        $$(subtasksTableId).filter(obj => obj.stateFinish === 1);
    }

    if (id === cache.historyClicked) {
        $$(subtasksFilterStateBtnsId).setValue('');
        $$(subtasksTableId).filter('stateId', '');
    }

    cache.historyClicked = id !== cache.historyClicked ? id : '';
};
