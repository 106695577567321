import React from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

function ParticipantChip({ id, name }: { id: number, name: string }) {
    const onClickHandler = () => {
        console.log(id, name); // FIXME: Удалить этот console log
    };

    return (
        <Tooltip title={name}>
            <IconButton
                aria-label="tap-participant"
                onClick={onClickHandler}
                style={{ fontSize: '10px', marginRight: '5px' }}
            >
                {name}
            </IconButton>
        </Tooltip>
    );
}

function ParticipantsSection({ participants }: { participants: string[] }) {
    if (!participants.length) return null;

    return (
        <Typography>
            <i className="fa fa-users" style={{ marginRight: '5px' }} />
            {participants.map((user) => {
                const [id, name] = user.split('|');
                return <ParticipantChip id={+id} name={name} />;
            })}
        </Typography>
    );
}

export default ParticipantsSection;
