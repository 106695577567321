import React from 'react';

import di from 'Services/di.js';
import useEnqueueSnackbar from 'Lib/Notistack/useEnqueueSnackbar';
import { useGetTaskViewFormQuery } from 'Endpoints/Tasks/tasks.api';
import { Modal } from 'Ui/Modal';
import TaskViewForm from './components/TaskViewForm/TaskViewForm';

function ModalWrapper({ taskId }: { taskId: number }) {
    const { events } = di;
    const { error } = useEnqueueSnackbar();
    const { isError, data, isLoading } = useGetTaskViewFormQuery(taskId);

    if (isError) error('Не удалось загрузить задачу');

    return (
        <>
            {data && (
                <Modal
                    title={`${data.buisnessProcess}: ${data.name}`}
                    open
                    onClose={() => events.emit('tasks__taskViewForm:closeTask')}
                    height={900}
                    width={1200}
                    suspend={isLoading}
                >
                    <TaskViewForm data={data} />
                </Modal>
            )}
        </>
    );
}

export default ModalWrapper;
