import React from 'react';

import { Divider, Grid, Skeleton } from '@mui/material';

function CommentSkeleton() {
    return (
        <Grid
            item
            container
            direction="column"
            spacing={1}
        >
            <Grid
                item
                container
                wrap="nowrap"
                spacing={2}
            >
                <Grid
                    item
                    alignItems="center"
                >
                    <Skeleton variant="circular" width={40} height={40} />
                </Grid>
                <Grid
                    item
                    container
                    direction="column"
                >
                    <Grid item>
                        <Skeleton variant="text" sx={{ fontSize: '14px', width: '150px' }} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="text" sx={{ fontSize: '9px', width: '150px' }} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Skeleton variant="text" sx={{ fontSize: '13px' }} />
                <Skeleton variant="text" sx={{ fontSize: '13px' }} />
            </Grid>
            <Grid item>
                <Divider />
            </Grid>
        </Grid>
        //     ),
        // )
    );
}

export default CommentSkeleton;
