import IFunctionWithPath from 'CommonTypes/Functions/IFunctionWithPath';

export default (functions: IFunctionWithPath[], functionsIds: number[] | string[]) => {
    if (!functionsIds || !functionsIds.length) return [];

    const selectedFunctions = functions.filter(
        value => functionsIds.some(func => +func === value.id),
    );

    return (selectedFunctions || []) as IFunctionWithPath[];
};
