import emptySplitApi from 'Endpoints/emptySplitApi';
import ITimesheetRecord from 'CommonTypes/Timesheets/ITimesheetRecord';
import ITimesheetToggleResponse from 'CommonTypes/Timesheets/ITimesheetToggleResponse';
import ITimesheetToggleRequest from 'CommonTypes/Timesheets/ITimesheetToggleRequest';

export const timersApi = emptySplitApi.enhanceEndpoints({ addTagTypes: ['TimersStarted'] })
    .injectEndpoints({
        endpoints: build => ({

            getTimersStarted: build.query<ITimesheetRecord[], void>({
                query: () => ({
                    url: 'timer/started',
                }),
                providesTags: ['TimersStarted'],
            }),

            toggleTimers: build.mutation<ITimesheetToggleResponse, ITimesheetToggleRequest>({
                query: data => ({
                    url: 'timer',
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: ['TimersStarted'],
            }),

        }),
    });

export const {
    useGetTimersStartedQuery,
    useToggleTimersMutation,
} = timersApi;
