import oldRoutes from './oldRoutes.js';
import sidebar from './sidebar.js';
import ganttPage from './ganttPage.js';
import orgstructure from './orgstructure.js';
import scheduler from './scheduler.js';
import quests from './quests.js';
import pivot from './pivot.js';
import contactsPage from './contactsPage.js';
import sprints from './sprints.js';
import dataViewPage from './dataViewPage.js';
import sidemenu from './sidemenu.js';
import contactsViewRoutes from './contactsViewRoutes.js';
import reportManager from './reportManager.js';
import plansPage from './plansPage.js';
import diagramPage from './diagramPage.js';
import sprintsTable from './sprintsTable.js';
import controlPanel from './controlPanel.js';
import bpmPage from './bpmPage.js';
import itServices from './itServices.js';
import changeRequests from './changeRequests.js';
import assetsModules from './assetsModules.js';
import assets from './assets';
import kanbanWebix from './kanbanWebix.js';
import layoutConfiguration from './layoutConfiguration';
import userStoryList from './userStoryList.js';
import task from './task';
import userStory from './userStory';
import buttons from './buttons';
import testQuest from './testQuest';
import spinnersTestPage from './spinnersTestPage';
import buttonsMui from './buttonsMui';
import planDay from './planDay.ts';
import testTaskPanel from './testTaskPanel';
import docsTemplates from './docsTemplates';
import roles from './roles';
import services from './services.js';
import yandexAccount from './yandexAccount';
import budgets from './budgets';
import forms from './forms';
import configs from './configs';

export default {
    ...sidebar,
    ...ganttPage,
    ...oldRoutes,
    ...orgstructure,
    ...scheduler,
    ...quests,
    ...pivot,
    ...contactsPage,
    ...sprints,
    ...dataViewPage,
    ...sidemenu,
    ...contactsViewRoutes,
    ...reportManager,
    ...plansPage,
    ...diagramPage,
    ...sprintsTable,
    ...controlPanel,
    ...bpmPage,
    ...itServices,
    ...changeRequests,
    ...assetsModules,
    ...assets,
    ...kanbanWebix,
    ...layoutConfiguration,
    ...userStoryList,
    ...task,
    ...userStory,
    ...buttons,
    ...testQuest,
    ...spinnersTestPage,
    ...buttonsMui,
    ...planDay,
    ...testTaskPanel,
    ...docsTemplates,
    ...roles,
    ...services,
    ...yandexAccount,
    ...budgets,
    ...forms,
    ...configs,
    '': {
        as: 'home',
        uses: () => {
            // loader.use('ProfileNew', 'index.js', (module) => {
            //     module.default();
            // });
            // app.modules.use('app.kanban').then(() => {
            //     app.kanban.init();
            // });
        },
    },
};
