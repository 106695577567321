import { sprintId, planId } from '../../../config/constants.js';

export default (data) => {
    const currentPlanId = +$$(planId).getValue();
    if (currentPlanId) {
        const { sprintsId } = data;
        const resultSprints = sprintsId.filter(sprint => sprint.planId === currentPlanId);
        const list = $$(sprintId).getPopup().getList();
        list.clearAll();
        list.parse(resultSprints);
        $$(sprintId).setValue('');
        $$(sprintId).enable();
    } else {
        const list = $$(sprintId).getPopup().getList();
        list.clearAll();
        $$(sprintId).setValue('');
        $$(sprintId).disable();
    }
};
