import { nodePath } from 'Config/path.js';

const getRelatedTasks = async (parentId) => {
    const data = await webix
        .ajax(`${nodePath}/tasks/getRelatedTasks?parentId=${parentId}`)
        .then(res => res.json());

    return data;
};

export default getRelatedTasks;
