import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';

import useEnqueueSnackbar from 'Lib/Notistack/useEnqueueSnackbar';
import { IPlanUser } from 'CommonTypes/Scheduler/IResponse';
import ProgressLine from './components/ProgressLine';
import CardHeader from './components/CardHeader';
import CardActionsGroup from './components/CardActionsGroup';

import classes from '../../assets/style.scss';
import ParticipantsSection from './components/ParticipantsSection';
import ContactsSection from './components/ContactsSection';

interface ICardTask {
    plan: IPlanUser;
    onClickCard: (taskId: number) => Promise<void>;
    onClickDelete?: (schedulerId: number) => Promise<void> | undefined;
    onClickEdit?: (
        schedulerName: string,
        comment: string,
        schedulerId: number,
        finished: number,
    ) => void | undefined;
}

function CardTask({
    plan, onClickCard, onClickDelete, onClickEdit,
}: ICardTask) {
    const { error } = useEnqueueSnackbar();
    const {
        taskId, progress, comment, taskDuration, finished,
        projectId, projectName, duration,
    } = plan;

    const [startDateStr, endDateStr] = useMemo(() => {
        const startDate = dayjs(plan.startDateEvent);
        const endDate = dayjs(plan.endDateEvent);

        if (plan.allDay) {
            return ['', ''];
        }

        const format = 'HH:mm:ss';

        return [
            startDate.format(format),
            endDate.format(format),
        ];
    }, [plan.allDay, plan.startDateEvent, plan.endDateEvent]);

    return (
        <Card
            variant="plan"
            sx={{
                color: '#676a6c',
                position: 'relative',
                backgroundColor: finished ? '#d4edda' : '#fff',
            }}
        >
            <CardActionArea
                onDoubleClick={() => {
                    if (taskId) {
                        return onClickCard(taskId);
                    }
                    return error('План не привязан к задаче');
                }}
            >
                <CardContent className={classes.drawer__item}>
                    <CardHeader
                        plan={plan}
                    />
                    <Typography
                        variant="planSubtitle"
                        className={classes.card_comment}
                        component="p"
                        gutterBottom
                        dangerouslySetInnerHTML={{ __html: comment }}
                    />
                    {projectId && <Typography variant="planSubtitle" component="p" gutterBottom>{projectName}</Typography>}
                    <ContactsSection contacts={plan.eventContacts} />
                    <ParticipantsSection participants={plan.participantsFio} />
                    {taskId && (
                        <>
                            {duration && (
                                <Typography variant="planSubtitle" component="p" gutterBottom>
                                    Длительность:
                                    {' '}
                                    {duration}
                                </Typography>
                            )}
                            <div style={{
                                paddingRight: '90px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                            }}
                            >
                                <Typography sx={{ marginBottom: 0 }} variant="planSubtitle" component="p" gutterBottom>{taskDuration}</Typography>
                                <ProgressLine progress={progress} />
                            </div>
                        </>
                    )}
                    {startDateStr !== '' && (
                        <Typography variant="planSmallText">
                            <i className="fa fa-clock-o" />
                            {' '}
                            {startDateStr}
                            {' '}
                            -
                            {' '}
                            {endDateStr}
                        </Typography>
                    )}
                </CardContent>
            </CardActionArea>
            {(onClickDelete && onClickEdit) && (
                <CardActionsGroup
                    plan={plan}
                    onClickDelete={onClickDelete}
                    onClickEdit={onClickEdit}
                />
            )}
        </Card>
    );
}

export default CardTask;
