import React from 'react';

import { Confirm } from 'Ui/Modal';
import actionDeleteTask from '../utils/actionDeleteTask';

interface IModalConfirmProps {
  name: string;
  taskId: number;
  idTaskPrice: string;
  modalClose: () => void;
  deleteRow: (idRow: string) => void;
}

function ModalConfirm({
    name, taskId, idTaskPrice, modalClose, deleteRow,
}: IModalConfirmProps) {
    const onCloseConfirm = (result: boolean) => {
        if (result) {
            actionDeleteTask(taskId, idTaskPrice, deleteRow);
        }
        modalClose();
    };

    return (
        <Confirm
            open
            title={`Удалить "${name}"?`}
            onClose={onCloseConfirm}
        >
            <p>
                <strong>Предупреждение!</strong>
                <br />
                {`"${name}" будет удален безвозвратно!` }
            </p>
        </Confirm>
    );
}

export default ModalConfirm;
