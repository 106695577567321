import { relatedTasksId, bottomTabbarId, bottomMultiviewId } from '../../../../config/constants.js';
import relatedTasksTable from './components/relatedTasksTable/relatedTasksTable.js';
import toolbar from './components/toolbar/toolbar.js';

export default {
    id: relatedTasksId,
    tabbarParent: bottomTabbarId,
    multiviewParent: bottomMultiviewId,
    rows: [
        toolbar,
        relatedTasksTable,
    ],
};
