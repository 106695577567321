import { Dayjs } from 'dayjs';
import callOldApiInboxCreate from './callOldApiInboxCreate.js';

export type TData = {
    type: number,
    contactId: number,
    name: string,
    desc: string,
    docDate: Dayjs,
    innumber: string,
    total: number,
}

type TResponse = {
    success: boolean,
    uid: string,
    error?: string,
}

export default async ({
    type, contactId, name, desc, docDate, innumber, total,
}: TData): Promise<TResponse> => {
    const idate = docDate?.unix() || 0;

    const data = {
        type,
        id_contact: contactId,
        name,
        desc,
        idate,
        innumber,
        total,
    };

    const response = await callOldApiInboxCreate(data) as TResponse;

    return response;
};
