import React from 'react';

import classes from '../assets/style.scss';

function ValueLabelComponent(props: any) {
    const { value, children } = props;

    return (
        <span className={classes['value-label']}>
            {value}
            {children}
        </span>
    );
}

export default ValueLabelComponent;
