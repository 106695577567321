/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useGetTagsWithRouteSortQuery } from 'Endpoints/Tags/tags.api';
import { TasksSetTagsRequest } from 'CommonTypes/Tags/TasksTags';
import setTagsToTask from './services/setTagsToTask';

type TProps = {
    taskId: number,
    tagsIds: number[],
    routeId: number,
    handleTagsChange: (ids: number[]) => void,
}

type TValue = {
    value: number,
    label: string,
}

function TagsForTask({
    taskId, tagsIds, routeId, handleTagsChange,
}: TProps) {
    const { data: tags = [] } = useGetTagsWithRouteSortQuery(
        { routeId },
        {
            skip: !routeId,
            selectFromResult: ({ data: response = [] }) => ({
                data: response.map(
                    record => ({ value: record.id, label: record.title }),
                ) as TValue[],
            }),
        },
    );

    const onChangeHandle = useCallback((
        event: React.SyntheticEvent<Element, Event>,
        newValue: TValue[],
    ) => {
        const ids = newValue?.map(tag => tag.value);
        handleTagsChange(ids);
        const data: TasksSetTagsRequest = {
            taskId,
            tagsIds: ids,
            preserveSubprojectTags: 1,
        };
        setTagsToTask(data);
    }, [handleTagsChange, taskId]);

    const values = useMemo(() => {
        const result = tags.filter(tag => tagsIds.includes(+tag.value));
        return result;
    }, [tags, tagsIds]);

    return (
        <Autocomplete
            size="small"
            options={tags}
            value={values}
            multiple
            blurOnSelect
            handleHomeEndKeys={false}
            renderInput={params => <TextField label="Тэги" {...params} />}
            renderOption={(props, option) => (
                <li {...props} key={option.value}>
                    {option.label}
                </li>
            )}
            onChange={onChangeHandle}
            isOptionEqualToValue={(
                option: TValue,
                currentValue: TValue,
            ) => option.value === currentValue.value}
            limitTags={1}
        />
    );
}

export default TagsForTask;
