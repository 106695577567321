import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import { useGetTimersStartedQuery, useToggleTimersMutation } from 'Endpoints/Timesheets/timers.api';

function TimerComponent({ eventId }: { eventId: number }) {
    const [toggleTimer] = useToggleTimersMutation();

    const {
        data: currentTimer,
    } = useGetTimersStartedQuery(
        undefined,
        {
            selectFromResult: ({ data, isLoading, isFetching }) => {
                if (!data) {
                    return {
                        data: {
                            taskId: 0,
                            eventId: 0,
                            start: '',
                            finish: '',
                            duration: 0,
                        },
                        isLoading,
                        isFetching,
                    };
                }

                const currentsEventTimer = data.find(value => value.eventId === eventId);
                return {
                    data: currentsEventTimer,
                    isLoading,
                    isFetching,
                };
            },
        },
    );
    const onClickHandler = () => {
        toggleTimer({
            eventId,
            taskId: 0,
        });
    };

    return (
        <Tooltip title="Таймер по событию">
            <IconButton
                aria-label="events-timer"
                onClick={onClickHandler}
            >
                <i className={currentTimer?.eventId ? 'fa fa-stop-circle-o' : 'fa fa-play-circle-o'} />
            </IconButton>
        </Tooltip>
    );
}

export default TimerComponent;
