import { IFormData } from 'Domain/LayoutConfiguration/types/IFormData';
import { IFormFieldsConfigController } from '../types/IFormFieldsConfigController';
import { IData } from '../types/IData';

export default (formData: IFormData, fieldsConfig: IFormFieldsConfigController): IData => {
    const formId = fieldsConfig.formConfig.layout.id as string;
    const layoutName = fieldsConfig.formConfig.layout.name as string;
    const settings = JSON.stringify(formData);
    const layoutId = formId.slice(formId.indexOf('__') + 2);
    const { isUser, typeTaskId } = fieldsConfig;
    const data = {
        layoutId, isUser, settings, typeTaskId, layoutName,
    };

    return data;
};
