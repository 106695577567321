import { filesId, bottomTabbarId, bottomMultiviewId } from '../../../../../../config/constants.js';
import listFilesView from './components/listFiles/listFilesView.js';
import toolbarUploader from './components/toolbarUploader/toolbarUploader.js';

export default () => ({
    id: filesId,
    tabbarParent: bottomTabbarId,
    multiviewParent: bottomMultiviewId,
    rows: [
        toolbarUploader(),
        listFilesView(),
    ],
});
