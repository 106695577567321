import { checklistView } from '../../../../../../config/constants.js';
import deleteChecklistService from '../services/deleteChecklistService.js';

export default (ev, obj) => {
    const id = obj.row;
    const treeTable = $$(checklistView);
    webix.confirm({
        title: 'Действительно удалить?',
        ok: 'Да',
        cancel: 'Нет',
        text: 'Вы действительно хотите удалить этот чек-лист?',
    }).then(() => {
        deleteChecklistService(id)
            .then((result) => {
                if (result) {
                    treeTable.remove(id);
                } else {
                    webix.alert('Возникла ошибка!');
                }
            });
    });
};
