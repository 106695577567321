import { typeTaskId } from '../../../config/constants.js';

export default (dataForm) => {
    const arrOfDataFrom = Object.entries(dataForm);
    const data = arrOfDataFrom.reduce((acc, el) => {
        if (el[1]) {
            const [key, value] = el;
            acc[key] = value instanceof Date ? Date.parse(value) / 1000 : value;
        }
        return acc;
    }, {});
    if (data.functionId) data.functionId = data.functionId.split(',');
    if (data.observers) data.observers = data.observers.split(',');
    if (data.modules) data.modules = data.modules.split(',');
    if (data.assetsId) data.assetsId = data.assetsId.split(',').map(el => el.slice(1));
    if (data.tagsId || data.subprojectId) {
        const tags = data?.tagsId ?? '';
        const subProject = data?.subprojectId ?? '';
        const resultTags = tags ? (`${tags},${subProject}`) : subProject;
        data.tags = resultTags.split(',').filter(el => !!el);
    }
    if (!data.assignee) data.assignee = 0;
    const { taskTypeId } = data;
    data.routeId = $$(typeTaskId).getList().getItem(taskTypeId).routeId;
    if (data.plannedTaskId) data.id_parent = data.plannedTaskId;

    return data;
};
