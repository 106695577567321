import { planId, projectsId } from '../../../config/constants.js';

export default (data) => {
    const currentProjectId = +$$(projectsId).getValue();
    if (currentProjectId) {
        const { plansId } = data;
        const resultPlans = plansId.filter(plan => plan.projectId === currentProjectId);
        const list = $$(planId).getPopup().getList();
        list.clearAll();
        list.parse(resultPlans);
        $$(planId).setValue('');
        $$(planId).enable();
    } else {
        const list = $$(planId).getPopup().getList();
        list.clearAll();
        $$(planId).setValue('');
        $$(planId).disable();
    }
};
