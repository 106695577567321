import { createContext } from 'react';
import TNewValue from '../types/TNewValue';

interface TContext {
    onChangeProjectByContact?: (newValue: TNewValue) => void;
    onChangeUsersAndRolesByProject?: (newValue: TNewValue) => void;
    onChangeUsersByRoles?: (newValue: TNewValue) => void;
    onChangeRolesByUsers?: (newValue: TNewValue) => void;
    defaultFunction?: () => void;
    onChangeContactsByDefault?: () => void;
    onChangeUsersByDefault?: () => void;
}

const Context = createContext<TContext>({
    defaultFunction() {},
});

export default Context;
