import changeButtonsByCurrentState from '../components/formAddTask/models/changeButtonsByCurrentState.js';
import { formAddTaskId } from '../config/constants.js';
import updateFormTask from '../components/formAddTask/models/updateFormTask.js';

export default ({ name, routestates }, data, userCkick, { moveTask = null } = {}) => {
    const { taskId } = $$(formAddTaskId).getValues();
    app.fetch(`${app.root}ajax/task.state.save.php`, { id_routestate: routestates, id_task: taskId }, 'POST').then((res) => {
        if (res && res.success) {
            if (moveTask) moveTask(taskId, +res.state_id);
            const newData = data?.state ? { ...data } : {};
            newData.currentState = res.state_id;
            newData.state = routestates;
            if (!newData.states) newData.states = data;
            changeButtonsByCurrentState(newData);
            if (userCkick) $$(formAddTaskId).setValues({ flagStateUpdate: true }, true);

            if (!data?.assignee && data?.role && data?.taskId) {
                updateFormTask(data?.taskId);
            }
            webix.message({ text: `Состояние задачи №${taskId} изменено на '${name}'`, type: 'success' });
        }
        if (res.error) {
            webix.message({ text: `${res.error}`, type: 'error' });
        }
    });
};
