import { initModule } from '../services/initModule.js';

export default {
    '/orgstructure': {
        as: 'orgstructure',
        uses: () => {
            import(
                /* webpackChunkName: "orgstructure" */ '../../OrgModule/Orgstructure/controllers/EditOrgstructure'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
    '/orgstructure/roles': {
        as: 'roles',
        uses: () => {
            import(
                /* webpackChunkName: "roles" */ '../../OrgModule/Roles/RolesController.js'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
    '/orgstructure/competence': {
        as: 'competence',
        uses: () => {
            import(
                /* webpackChunkName: "competence" */ '../../OrgModule/Competencies/CompController.js'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
    '/orgstructure/funcs': {
        as: 'funcs',
        uses: () => {
            import(
                /* webpackChunkName: "funcs" */ '../../OrgModule/Functions/FuncsController.js'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
