import React from 'react';
import { ThemeProvider } from '@mui/material/styles';

import StyledSnackbarProvider from 'Lib/Notistack/StyledSnackbarProvider';
import Action from 'Lib/Notistack/DefaultAction';
import theme from 'Lib/Mui/theme';
import DataGrid from '../DataGrid';

function DataGridView({ taskId }: {taskId: number}) {
    return (
        <ThemeProvider theme={theme}>
            <StyledSnackbarProvider
                action={snackbarId => Action(snackbarId)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                hideIconVariant
            >
                <DataGrid taskId={taskId} />
            </StyledSnackbarProvider>
        </ThemeProvider>
    );
}

export default DataGridView;
