/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ListChildComponentProps } from 'react-window';
import { Avatar } from '../Avatar';

function renderRow(prop: ListChildComponentProps) {
    const { data, index, style } = prop;
    const dataSet = data[index];
    const [{ avatar, ...otherProps }, option] = dataSet;
    const inlineStyle = {
        ...style,
        top: (style.top as number) + 8,
        gap: 15,
        flexWrap: 'nowrap',
        margin: 0,
        // whiteSpace: 'nowrap',
        // textOverflow: 'ellipsis',
    };

    return (
        <li {...otherProps} style={inlineStyle}>
            {avatar && <Avatar options={option} />}
            <span
                style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }}
            >
                {option.value}
            </span>
        </li>
    );
}

export default renderRow;
