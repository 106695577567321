import { IPlanUser, IResponsePlanDay } from 'CommonTypes/Scheduler/IResponse';
import { IGroupingTasksObj } from '../components/GroupTasks';

const groupingTasks = (plans: IResponsePlanDay) => (
    plans.reduce((acc: IGroupingTasksObj, obj: IPlanUser) => {
        const property = obj.serviceClassName ?? 'Класс обслуживания не установлен';
        acc[property] = acc[property] || [];
        acc[property].push(obj);
        return acc;
    }, {})
);

export default groupingTasks;
