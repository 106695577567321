import EventEmitter from 'Bases/events/EventEmitter.js';
import Navigo from 'navigo';
import { killWebix } from './config/blocks';
import routes from './Routes/routes.js';
import loader from '../LoaderModule/typeLoaderWrapper.js';

const router = new Navigo('/');
function initRouter(events: EventEmitter) {
    router.hooks({
        before(done) {
            loader.killReact();
            loader.killReactChangePage();
            killWebix();
            events.emit('router:before-route');
            done();
        },
    });
    router.on(routes).resolve();
}

export { router, initRouter };
