import { fetchGet, fetchPostFile } from 'Services/fetchService.js';
import { uploaderId, formAddTaskId, filesListId } from '../../../../../../../config/constants.js';
import changeAmountOfFiles from './changeAmountOfFiles.js';
import createAttachViewer from '../components/listFiles/models/createAttachViewer.js';

export default (ev, obj) => {
    const { taskId } = $$(formAddTaskId).getValues();
    if (!taskId) {
        $$(uploaderId).files.remove(obj.row);
        return changeAmountOfFiles();
    }

    const { name } = $$(filesListId).getItem(obj.row);
    webix.confirm({
        title: `Удалить файл ${name} ?`,
        ok: 'Да',
        cancel: 'Нет',
    }).then(async () => {
        const form = new FormData();
        form.append('id_file', obj.row);
        form.append('id_task', taskId);
        await fetchPostFile(`${app.root}files/task_attach_delete.php`, form);
        const dataFiles = await fetchGet(`${app.root}ajax/task_load_related_files_info.php?id=${taskId}`);
        $$(uploaderId).files.clearAll();
        $$(uploaderId).files.parse(dataFiles);
        createAttachViewer();
        changeAmountOfFiles();
    });

    return true;
};
