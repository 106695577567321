import { formId, btnSaveId } from 'Domain/LayoutConfiguration/config/constans';

export default function toggleStylesBtnSave(): void {
    const isDirty: boolean = ($$(formId) as webix.ui.form).isDirty();
    if (isDirty) {
        $$(btnSaveId).enable();
        return $$(btnSaveId).define('css', 'webix_primary');
    }
    $$(btnSaveId).disable();
    return webix.html.removeCss($$(btnSaveId).getNode(), 'webix_primary');
}
