import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import PersistentDrawer from '../PersistentDrawer/PersistentDrawer';
import Header from '../Header/Header';
import StateButtons from '../StateButtons/StateButtons';
import MainInformation from '../MainInformation/MainInformation';
import HyperlinksWrapper from '../HyperlinksWrapper/HyperlinksWrapper';
import getVisibleAccordions from '../../utils/selectors';
import HyperlinksAccordion from '../HyperlinksAccordion/HyperlinksAccordion';
import Comments from '../../../../Ui/Comments/App';

import classes from '../../assets/style.scss';

function TaskViewForm({ data }: { data: any }) {
    const [open, setOpen] = useState(false);
    const [widthRightSide, setWidthRightSide] = useState(240);
    const visibleAccordions = useSelector(getVisibleAccordions);

    const onClickVisibleDrawer = () => setOpen(!open);

    const onChangeWidth = (width: number) => setWidthRightSide(width);

    return (
        <Box className={classes['modal-content']}>
            <div className={classes['modal-content-wrapper']}>
                <Header open={open} callback={onClickVisibleDrawer} data={data} />
                <StateButtons />
                <MainInformation data={data} />
                <Divider />
                <HyperlinksWrapper />
                {!!visibleAccordions.length && (
                    visibleAccordions.map((accordion: string) => (
                        <HyperlinksAccordion
                            key={accordion}
                            hyperlink={accordion}
                        />
                    ))
                )}
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: data.description }} />
                <Comments taskId={data.id} />
            </div>
            <div>
                <PersistentDrawer
                    open={open}
                    widthRightSide={widthRightSide}
                    callback={onChangeWidth}
                    data={data}
                />
            </div>
        </Box>
    );
}

export default TaskViewForm;
