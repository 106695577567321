import {
    formAddTaskId, btnSaveId, btnEdit, typeTaskId, btnTimerId, descriptionTaskId,
} from '../config/constants.js';

export default ({ isReadOnly }) => {
    const { elements } = $$(formAddTaskId);
    const setElements = Object.keys(elements);
    setElements.forEach((element) => {
        if ($$(element)) {
            const { config } = $$(element);
            if (config.readonly && element !== 'desc') {
                $$(element).define('readonly', false);
                const clear = (Object.prototype.hasOwnProperty.call(config, 'clear'));
                if (clear) $$(element).define('clear', true);
                $$(element).refresh();
                const editor = $$(descriptionTaskId).getEditor();
                editor.disableReadOnlyMode('editor');
            } else {
                $$(element).define('readonly', true);
                const editor = $$(descriptionTaskId).getEditor();
                editor.enableReadOnlyMode('editor');
                const clear = (Object.prototype.hasOwnProperty.call(config, 'clear'));
                if (clear) {
                    $$(element).define('clear', false);
                    $$(element).refresh();
                }
            }
        }
    });
    $$(typeTaskId).define('readonly', isReadOnly);
    if ($$(btnSaveId).isVisible()) {
        $$(btnSaveId).hide();
        $$(btnEdit).show();
        $$(btnTimerId).show();
    } else {
        $$(btnSaveId).show();
        $$(btnEdit).hide();
        $$(btnTimerId).hide();
    }
};
