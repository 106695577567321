import {
    relatedTasksToolbarId,
    relatedTasksFilterStateBtnsId,
    relatedTasksFilterInworkId,
    relatedTasksFilterDraftId,
    relatedTasksFilterFinishId,
} from '../../../../../../config/constants.js';
import filterRelatedTasksByState from './models/filterRelatedTasksByState.js';
import addRelatedTask from './models/addRelatedTask.js';

export default {
    id: relatedTasksToolbarId,
    view: 'toolbar',
    height: 40,
    elements: [
        {
            cols: [
                {
                    view: 'button',
                    width: 45,
                    tooltip: 'Добавить задачу',
                    label: '<span class="fa fa-plus">',
                    click: addRelatedTask,
                },
                {
                    id: relatedTasksFilterStateBtnsId,
                    view: 'segmented',
                    width: 630,
                    value: relatedTasksFilterInworkId,
                    options: [
                        {
                            id: relatedTasksFilterInworkId,
                            value: 'В работе',
                        },
                        {
                            id: relatedTasksFilterDraftId,
                            value: 'Черновик',
                        },
                        {
                            id: relatedTasksFilterFinishId,
                            value: 'Завершено',
                        },
                    ],
                    on: {
                        onAfterTabClick: filterRelatedTasksByState,
                    },
                },
                {},
            ],
        },
    ],
};
