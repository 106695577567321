import { IService } from 'CommonTypes/Tasks/Services/IService';
import postTaskSavePrice from '../services/postTaskSavePrice.js';
import taskPriceRecompute from './taskPriceRecompute';

import { IDataUpdateRow } from '../types/IDataUpdateRow';
import { IRateVatList } from '../types/IRateVatList';

function saveTaskPrice(row: IService, rateVatList: IRateVatList[]) {
    const vat = rateVatList.find((el: IRateVatList) => +el.id === +row.id_vat)!;
    const vatPurchase = rateVatList.find((el: IRateVatList) => +el.id === +row.purchase_vat)!;

    const vatInSum = +vat.in_amount === 1;
    const vatPurchaseInSum = +vatPurchase.in_amount === 1;

    const data: IDataUpdateRow = {
        id: row.id,
        id_task: row.id_task,
        id_price: row.id_price,
        count: +row.count || 0, // Кол-во
        /* Продажа */
        price: +row.price || 0, // Цена за единицу
        id_vat: +row.id_vat, // Индекс НДС
        vat: +vat.rate, //  Процент НДС
        vat_in: vatInSum ? 1 : 0, // НДС в сумме
        discount_pct: +row.discount_pct || 0, // Процент скидки
        /* Закупка */
        purchase_vat: +vatPurchase.rate, // Процент НДС
        id_purchase_vat: +row.purchase_vat, // Индекс НДС
        purchase_price: +row.purchase_price || 0, // Цена за единицу
    };

    data.summ = data.count * data.price; // Сумма продажи
    data.summ -= (data.summ * (data.discount_pct / 100)); // Сумма со скидкой

    data.sum_vat = vatInSum
        ? (data.summ * (data.vat / (100 + data.vat))) : (data.summ * (data.vat / 100));

    data.purchase_sum_vat = vatPurchaseInSum
        ? (data.purchase_price * (+vatPurchase.rate / (100 + (+vatPurchase.rate))))
        : (data.purchase_price * (+vatPurchase.rate / 100));

    data.income = vatInSum ? data.summ : +(data.sum_vat + data.summ);

    data.expenses = vatPurchaseInSum
        ? (data.count * data.purchase_price)
        : ((data.count * data.purchase_price) + data.purchase_sum_vat);

    postTaskSavePrice(data)
        .then((res) => {
            if (!res.success) {
                return webix.message({
                    text: 'Не удалось сохранить',
                    type: 'error',
                });
            }
            taskPriceRecompute(res.id, 0);

            return webix.message({
                text: 'Данные успешно обновлены',
                type: 'success',
            });
        });
}

export default saveTaskPrice;
