import { nodePath } from 'Config/path.js';
import toggleReadonly from 'fsrc/AddTaskWindow/models/toggleReadonly.js';
import { formAddTaskId, topLayoutId } from '../../../config/constants.js';
import createDataForAddTask from './createDataForAddTask.js';
import uploudedNewTaskFiles from '../components/cellsBottomTabbar/components/taskFilesUploader/models/uploudedNewTaskFiles.js';
import readonlyMode from '../../../models/readonlyMode.js';
import getTaskById from '../../../service/getTaskById.js';
import changeStateTask from '../../../models/changeStateTask.js';
import updateRequestsProjectsList from './updateRequestsProjectsList.js';
import updateRequestsTable from './updateRequestsTable.js';
import buttonsToolbar from '../components/buttonsToolbar/buttonsToolbar.js';

export default async ({ moveTask = null }) => {
    if ($$(formAddTaskId).validate()) {
        const dataTaskForm = $$(formAddTaskId).getValues();
        const data = createDataForAddTask(dataTaskForm);
        data.attach = await uploudedNewTaskFiles();

        app.fetch(`${app.root}ajax/qick_add_task.php`, data, 'POST').then(async (res) => {
            if (res && res.success) {
                webix.ajax().patch(`${nodePath}/tasks/access/${+res.id}`);
                webix.message({ text: `Создана задача №${res.id}`, type: 'success' });
                const dataTask = await getTaskById(res.id);
                await readonlyMode(dataTask);
                toggleReadonly({ isReadOnly: true });
                $$(formAddTaskId).refresh();
                $$(formAddTaskId).setDirty();
                const { states } = dataTask;
                const [{
                    routestates, name,
                }] = states.filter(el => el.id === dataTask.currentState);
                $$(topLayoutId).addView(buttonsToolbar(dataTask), 1);
                changeStateTask({ routestates, name }, dataTask);
                if (!moveTask) {
                    updateRequestsProjectsList(dataTask);
                    updateRequestsTable(dataTask, true);
                }
            } else {
                webix.message({ text: 'Задача не создалась. Ошибка серверного запроса', type: 'error' });
            }
        });
    }
};
