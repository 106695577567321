/* eslint-disable max-len */
import { AnyAction, combineReducers } from 'redux';
import { configureStore, Reducer } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query/react';

import emptySplitApi from 'Endpoints/emptySplitApi';
import rtkQueryErrorLogger from 'Utils/rtkQueryErrorLogger';

import userReducer from './userSlice';
import baseReducer from './baseSlice';
import triggerActionMiddleware from './triggerActionMiddleware';

import { TAsyncReducers } from './TAsyncReducers';

const asyncReducers: TAsyncReducers = {};

const staticReducers = {
    reducerUser: userReducer,
    reducerBase: baseReducer,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
};

const store = configureStore({
    reducer: {
        ...staticReducers,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware()
        .concat([
            emptySplitApi.middleware,
            triggerActionMiddleware,
            rtkQueryErrorLogger,
        ]),
});

setupListeners(store.dispatch);

export function injectReducer<T>(name: string, reducer: Reducer<T, AnyAction>) {
    asyncReducers[name] = reducer;

    return store.replaceReducer(
        combineReducers({
            ...staticReducers,
            ...asyncReducers,
        }),
    );
}

export type RootState = ReturnType<typeof store.getState>

export default store;
