import { IPatchEventsChecklistRequest, IPatchEventsChecklistResponse } from 'CommonTypes/Scheduler/IPatchEventsChecklistRequestResponse';
import emptySplitApi from 'Endpoints/emptySplitApi';

interface IPatchEventsChecklistRequestWithTaskId extends IPatchEventsChecklistRequest {
    taskId: number;
}

export const checklistApi = emptySplitApi
    .injectEndpoints({
        endpoints: build => ({

            addChecklistToTask: build.mutation<
                { id: number },
                { name: string, taskId: number, description: string, eventId: number }
            >({
                query: data => ({
                    url: 'checklist/add-checklist',
                    method: 'POST',
                    body: {
                        ...data,
                        assignee: null,
                    },
                }),
                invalidatesTags: (result, error, { eventId, taskId }) => [
                    { type: 'EventsChecklist', eventId, taskId },
                ],
            }),

            updateChecklistToTask: build.mutation<
                IPatchEventsChecklistResponse,
                IPatchEventsChecklistRequestWithTaskId
            >({
                query: data => ({
                    url: 'checklist',
                    method: 'PATCH',
                    body: data,
                }),
                invalidatesTags: (result, error, { eventId, taskId }) => [
                    { type: 'EventsChecklist', eventId, taskId },
                ],
            }),

        }),
    });

export const {
    useAddChecklistToTaskMutation,
    useUpdateChecklistToTaskMutation,
} = checklistApi;
