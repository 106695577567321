import {
    historyStatesTableId,
} from '../../../../../../../config/constants.js';
import deleteHistoryItemService from '../services/deleteHistoryItemService.js';

export default (ev, rowObj) => {
    const item = $$(historyStatesTableId).getItem(rowObj.row);

    webix.confirm({
        title: 'Вы действительно хотите удалить запись?',
        ok: 'Да',
        cancel: 'Нет',
    }).then(() => {
        deleteHistoryItemService(item.id)
            .then((res) => {
                if (!res) return webix.alert('Ошибка! Запись не была удалена.');

                $$(historyStatesTableId).remove(item.id);
            });
    });
};
