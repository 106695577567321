import { nodePath } from 'Config/path.js';

const updateTimeTrack = async (data) => {
    const result = await webix.ajax()
        .put(`${nodePath}/tasks/updateTimeTrack`, data)
        .then(res => res.json());

    return result;
};

export default updateTimeTrack;
