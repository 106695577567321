import React, { StrictMode } from 'react';
import { ThemeProvider } from '@mui/material';
import { Root, createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import tasksTabsSlice, { activeTab, resetTabs } from 'fsrc/Task/store/tasksTabsSlice';

import theme from 'Lib/Mui/theme';
import container from 'Services/container';
import StyledSnackbarProvider from 'Lib/Notistack/StyledSnackbarProvider';
import Action from 'Lib/Notistack/DefaultAction';
import { IIFieldsVisibleConfigReqeust } from 'CommonTypes/Forms/FormsFieldsConfig';
import { TTabs } from '../types/TasksTabsState';
import TabsWrapper from '../components/TabsWrapper';
import EmitFieldsVisibleConfig from './EmitFieldsVisibleConfig';

type TData = {
    taskId: number;
} & IIFieldsVisibleConfigReqeust;

let rootContainer: Root;

function initForTaskFormTabs({ taskId, taskTypeId, formTypeId }: TData) {
    const root = document.getElementById(`task__tasks_form-tab-container${taskId}`) as HTMLDivElement;
    rootContainer = createRoot(root);

    const { store, injectReducer } = container;

    injectReducer('tasksTabsSliceReducer', tasksTabsSlice);

    rootContainer.render(
        <StrictMode>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <StyledSnackbarProvider
                        action={snackbarId => Action(snackbarId)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        hideIconVariant
                    >
                        <EmitFieldsVisibleConfig formTypeId={formTypeId} taskTypeId={taskTypeId} />
                        <TabsWrapper
                            taskId={taskId}
                            formTypeId={formTypeId}
                            taskTypeId={taskTypeId}
                        />
                    </StyledSnackbarProvider>
                </ThemeProvider>
            </Provider>
        </StrictMode>,
    );
}

type TActiveTabData = {
    tabId: TTabs;
}

function activateForTaskFormTab({ tabId }: TActiveTabData) {
    const { store } = container;
    store.dispatch(activeTab(tabId));
}

function closeTaskForm() {
    rootContainer?.unmount();

    const { store } = container;
    store.dispatch(resetTabs());
}

export default () => {
    const { events } = container;

    events.on(
        'task__formTaskTabs:initTaskFormTabs',
        initForTaskFormTabs,
        false,
    );

    events.on(
        'task__formTaskTabs:activateForTaskFormTab',
        activateForTaskFormTab,
        false,
    );

    events.on(
        'task__formTaskTabs:closeTaskForm',
        closeTaskForm,
        false,
    );
    events.on(
        'router:before-route',
        closeTaskForm,
        false,
    );
};
