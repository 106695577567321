import { historyStatesTableId } from '../../../../../../../config/constants.js';
import updateStateInHistory from '../services/updateStateInHistory.js';

export default (state, editor) => {
    const data = { id: +editor.row };

    if (!editor.column.includes('Date')) {
        if (+state.value !== +state.old && +state.value >= 0) {
            data[editor.column] = Math.round(+state.value);
            updateStateInHistory(data);
        }
    }

    if (editor.column.includes('Date')) {
        if (state.value !== state.old) {
            const item = $$(historyStatesTableId).getItem(editor.row);
            data.startDate = item.startDate;
            data.endDate = item.endDate;

            data[editor.column] = state.value;
            updateStateInHistory(data)
                .then((res) => {
                    const [newDataHistoryItem] = res;

                    $$(historyStatesTableId).updateItem(editor.row, newDataHistoryItem);
                });
        }
    }
};
