import di from 'Services/di.js';

const { events } = di;

export default () => {
    document.body.classList.toggle('mini-navbar');
    $$('main-menu').toggle();

    if (document.body.classList.contains('mini-navbar')) {
        localStorage.setItem('mainMenuCollapse', '1');
    } else {
        localStorage.removeItem('mainMenuCollapse');
    }

    events.emit('mainmenu:toggle');
};
