import updateFinishedRequirementsService from '../services/updateFinishedRequirementsService.js';
import { requirementsView } from '../../../../../../../config/constants.js';

export default (ev, obj) => {
    const id = {
        taskId: obj.row,
    };
    updateFinishedRequirementsService(id);
    const elem = $$(requirementsView).getItemNode(obj.row);
    if (elem.classList.contains('complete-task')) {
        $$(requirementsView).removeCellCss(obj.row, 'name', 'complete-task');
    } else {
        $$(requirementsView).addCellCss(obj.row, 'name', 'complete-task');
    }
};
