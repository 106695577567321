function trigger(eventsObj, eventName, data) {
    const event = eventsObj[eventName];
    if (event) {
        event.forEach((fn) => {
            fn.call(null, data);
        });
    }
}

class EventEmitter {
    constructor() {
        this.events = {};
        this.eventsOnce = {};
        this.weakEvents = {};
        this.weakOnce = {};
    }

    emit(eventName, data) {
        trigger(this.events, eventName, data);
        trigger(this.eventsOnce, eventName, data);
        trigger(this.weakEvents, eventName, data);
        trigger(this.weakOnce, eventName, data);
        this.eventsOnce[eventName] = [];
        this.weakOnce[eventName] = [];

        if (eventName === 'router:before-route') {
            this.weakEvents = {};
            this.weakOnce = {};
        }
    }

    removeListener(eventsObj, eventName, fn) {
        if (!eventsObj[eventName]) {
            return this;
        }

        const index = eventsObj[eventName].indexOf(fn);
        if (!~index) {
            return this;
        }

        eventsObj[eventName].splice(index, 1);
        return this;
    }

    off(eventName, fn) {
        this.removeListener(this.events, eventName, fn);
        this.removeListener(this.eventsOnce, eventName, fn);
        this.removeListener(this.weakEvents, eventName, fn);
        return this.removeListener(this.weakOnce, eventName, fn);
    }

    addListener(eventsObj, eventName, fn) {
        if (!eventsObj[eventName]) {
            eventsObj[eventName] = [];
        }

        eventsObj[eventName].push(fn);
        return this.removeListener.bind(this, eventsObj, eventName, fn);
    }

    on(eventName, fn, weak = true) {
        return this.addListener(weak ? this.weakEvents : this.events, eventName, fn);
    }

    once(eventName, fn, weak = true) {
        return this.addListener(weak ? this.weakOnce : this.eventsOnce, eventName, fn);
    }
}

export default EventEmitter;
