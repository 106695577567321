import openOldTask from '../utils/openOldTask.js';
import deleteItem from './services/deleteFavorite.js';

export default {
    id: 'listFavorite',
    view: 'list',
    // url: 'ajax/task_favorite.php?action=select',
    data: [],
    select: true,
    type: {
        height: 'auto',
    },
    template(data) {
        return `<div class="list__favorite">
            <i class="fa fa-star deleteFavorite" title="Убрать из Избранных"></i>
            <span id="${data.id_task}" class="openTask">${data.name}</span>
        </div>`;
    },
    onClick: {
        openTask(item) {
            const { id } = item.path[0];
            openOldTask(id);
            $$('menu').hide();
        },
        deleteFavorite(e, item) {
            deleteItem(item);
            $$('listFavorite').remove(item);
            console.log(item);
        },
    },
};
