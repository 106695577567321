import React from 'react';
import { useSnackbar, SnackbarKey } from 'notistack';
import { IconButton, Icon } from '@mui/material';

function Action(snackbarId: SnackbarKey) {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton
            aria-label="close"
            onClick={() => { closeSnackbar(snackbarId); }}
        >
            <Icon><i className="fa fa-close" /></Icon>
        </IconButton>
    );
}

export default Action;
