import dayjs, { Dayjs } from 'dayjs';
import IServiceFunctionsFormResponse from 'CommonTypes/ITServices/IServiceFunctionsFormResponse';
import { ITagsWithProjectLinkByTaskResponse } from 'CommonTypes/Tags/TagsWithProjectLinkByTask';
import IState from '../types/IState';

interface IReducerInit {
    type: 'init';
    payload: IServiceFunctionsFormResponse;
}

interface IReducerSetDeadline {
    type: 'setDeadline';
    payload: Dayjs;
}

interface IReducerSetSubprojects {
    type: 'setSubprojects';
    payload: ITagsWithProjectLinkByTaskResponse[];
}

const reducer = (
    state: IState,
    action: IReducerInit | IReducerSetDeadline | IReducerSetSubprojects,
) => {
    const { payload, type } = action;
    const currentState = { ...state };

    switch (type) {
    case 'init': {
        const newData = {
            ...payload, taskDateCreate: dayjs(), deadline: dayjs(), startDate: dayjs(),
        };
        newData.taskDateCreate = dayjs(payload.dateCreate.dateCreate);
        newData.deadline = dayjs(payload.dateCreate.currentDeadline);
        newData.startDate = dayjs(payload.dateCreate.dateStart);
        return newData;
    }
    case 'setDeadline': {
        const data = { ...state, deadline: action.payload };
        return data;
    }
    case 'setSubprojects': {
        const data = { ...state, subprojects: payload };
        return data;
    }
    default:
        break;
    }

    return currentState;
};

export default reducer;
