export default (data) => {
    const stringOfParams = Object.entries(data).reduce((result, currentElement, index, array) => {
        const [key, value] = currentElement;
        const stringFromData = `date:\t${new Date().toString()}\r\n`;
        if (index !== array.length - 1) {
            return `${result}${key}:\t${value}\r\n`;
        }

        return `${result}${key}:\t${value}\r\n${stringFromData}`;
    }, '');

    return stringOfParams;
};
