import { Match } from 'navigo';
import { initModule } from 'Services/initModule.js';

export default {
    '/configs/roles-routestates/:taskTypeId': {
        as: 'configs__rolesRoutestates',
        uses(match: Match) {
            import(
                /* webpackChunkName: "configsrolesroutestates" */ '../../RolesToRoutestates/controllers/RolesConfigController'
            ).then(module => initModule(module.default, match));
        },
    },
    '/configs/bp/:tasksTypeId': {
        as: 'configs__bp',
        uses(match: Match) {
            import(
                /* webpackChunkName: "configsrolesroutestates" */ '../../Bp/controllers/BpConfig'
            ).then(module => initModule(module.default, match));
        },
    },
};
