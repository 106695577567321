import changeStateTask from '../../../models/changeStateTask.js';

export default (dataTask, fieldsConfig) => {
    const { states } = dataTask;
    const buttonsOfState = states.map((state) => {
        const { routestates } = state;
        const view = 'button';
        const width = 112;
        const style = `style="background-color: #${state.color_state}10"`;
        const template = `<button class = "toolbar__btn" ${style}>${state.name}</button>`;

        return {
            view,
            id: routestates,
            width,
            template,
            disabled: true,
            click() {
                changeStateTask(state, dataTask, true, fieldsConfig);
            },
        };
    });

    return buttonsOfState;
};
