import {
    formAddTaskId,
} from '../../../../../../../config/constants.js';
import changeTabSubtasks from '../../../../bottomTabbar/models/changeTabSubtasks.js';

export default (dataFiles) => {
    const parentTaskInfo = $$(formAddTaskId).getValues();

    const data = {
        contactId: parentTaskInfo.contact,
        projectId: parentTaskInfo.project,
    };

    const parentItem = {
        id: parentTaskInfo.taskId,
        name: parentTaskInfo.name,
    };

    window.events.emit('tasks__formAddTask:addNewTask', {
        type: 'subTask',
        params: { data, parentItem },
        dataFiles,
        callback: () => changeTabSubtasks(true),
    });
};
