import { nodePath } from 'Config/path.js';

const deleteHistoryItemService = async (itemId) => {
    const result = await webix.ajax()
        .del(`${nodePath}/tasks/deleteHistoryItem?id=${itemId}`)
        .then(res => res.json());

    return result;
};

export default deleteHistoryItemService;
