import postTaskPriceItemsReform from '../services/postTaskPriceItemsReform.js';
import taskPriceRecompute from './taskPriceRecompute';

import { IFormulas } from '../types/IFormulas';
import { ITaskPriceItemsReform } from '../types/ITaskPriceItemsReform';

function actionSetDefault(idTaskPrice: string) {
    postTaskPriceItemsReform(idTaskPrice)
        .then((res: ITaskPriceItemsReform) => {
            // errors?
            const errors = res.formulas.filter((el: IFormulas) => !el.formula_error);

            if (errors && errors.length && errors[0].formula_error) {
                webix.message({
                    text: `Ожидаются: ${errors[0].formula_error.vars.join(', ')}`,
                    type: 'error',
                });

                return false;
            }

            taskPriceRecompute(idTaskPrice, 1);
            taskPriceRecompute(idTaskPrice, 0);
        })
        .catch(() => {
            console.warn('Вам  необходимо установить контрагента'); // Точно ли контрагента? undefined
        });
}

export default actionSetDefault;
