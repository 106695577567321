import React from 'react';

export default function SpinnerThreeBounce() {
    return (
        <div className="sk-spinner sk-spinner-three-bounce">
            <div className="sk-bounce1" />
            <div className="sk-bounce2" />
            <div className="sk-bounce3" />
        </div>
    );
}
