import {
    formAddHistoryItemId,
} from '../../../../../../../../config/constants.js';

export default () => {
    const validateDate = $$(formAddHistoryItemId).validate();

    if (validateDate) {
        const formValues = $$(formAddHistoryItemId).getValues();

        if (!formValues.endDate) return;

        const secondsDiff = Math.floor((formValues.endDate - formValues.startDate) / 1000);
        const daysDiff = Math.round(secondsDiff / 60 / 60 / 24);

        $$(formAddHistoryItemId).setValues({ ...formValues, durationDays: daysDiff + 1 });
    }
};
