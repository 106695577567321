import { nodePath } from '../configModule/config.js';
import {
    bugReportId, nameIssueId, serviceClassesId, issueDiscriptionId,
    solutionIssueId, uploaderFilesId,
} from './config/constants.js';
import bugReportDestructor from './utils/bugReportDestructor.js';
import saveBugInfo from './models/saveBugInfo.js';
import defaultName from './models/defaultName.js';
import './assets/bugReport.original.scss';

export default (params = {}, callback) => {
    const addTaskHeader = `Создать подзадачу для задачи №${
        params?.parentItem?.wsId ?? params?.parentItem?.id
    } ${params?.parentItem?.name}`;

    const defaultHeader = 'Предложения и замечания';
    const headerCondition = params.parentItem ? addTaskHeader : defaultHeader;

    webix.ui({
        view: 'window',
        modal: true,
        move: true,
        resize: true,
        id: bugReportId,
        width: 1000,
        height: 600,
        position: 'center',
        head: {
            view: 'toolbar',
            cols: [
                { width: 20 },
                {
                    view: 'label',
                    align: 'center',
                    label: headerCondition,
                },
                {
                    view: 'icon',
                    icon: 'wxi-plus-square',
                    tooltip: 'Развернуть окно',
                    click() {
                        if ($$(bugReportId).config.fullscreen) {
                            webix.fullscreen.exit();
                            this.define({
                                icon: 'wxi-plus-square',
                                tooltip: 'Развернуть окно',
                            });
                        } else {
                            webix.fullscreen.set($$(bugReportId));
                            this.define({
                                icon: 'wxi-minus-square',
                                tooltip: 'Свернуть окно',
                            });
                        }
                        this.refresh();
                    },
                },
                {
                    view: 'icon',
                    icon: 'wxi-close',
                    tooltip: 'Закрыть окно',
                    click: bugReportDestructor,
                },
            ],
        },
        body: {
            rows: [
                {
                    cols: [
                        {
                            id: nameIssueId,
                            label: 'Тема',
                            view: 'text',
                            value: params?.parentItem?.name ?? defaultName(),
                            required: true,
                            invalidMessage: 'Поле должно быть заполнено',
                        },
                        {
                            id: serviceClassesId,
                            label: 'Класс обслуживания',
                            clear: true,
                            placeholder: 'Не выбран',
                            view: 'richselect',
                            options: `${nodePath}/bug-report/service-classes`,
                            required: true,
                            height: 38,
                            labelWidth: 170,
                            width: 400,
                        },
                    ],
                    paddingY: 10,
                },
                {
                    cols: [
                        {
                            rows: [
                                {
                                    view: 'accordion',
                                    css: 'bug-accordion',
                                    multi: true,
                                    collapsed: true,
                                    rows: [
                                        {
                                            header: 'Описание',
                                            body: 'Описывая свое предложение стараемся зафиксировать что произошло и при каких обстоятельствах. Если пишем своё предложение то стараемся формулировать его в терминах своей истории: Я как пользователь хочу [пишем что Вы хотите] для того чтобы [пишем свою ценность для чего Вам надо чтобы была это реализовано]',
                                        },
                                    ],
                                },
                                {
                                    id: issueDiscriptionId,
                                    view: 'ckeditor5',
                                },
                            ],
                        },
                        {
                            rows: [
                                {
                                    view: 'accordion',
                                    css: 'bug-accordion',
                                    multi: true,
                                    collapsed: true,
                                    rows: [
                                        {
                                            header: 'Критерии приемки',
                                            body: 'В критериях приемки описываем результат то что вы хотите получить. Критериев должно быть минимум 3, но не более.',
                                        },
                                    ],
                                },
                                {
                                    id: solutionIssueId,
                                    view: 'ckeditor5',
                                },
                            ],
                        },
                    ],
                },
                {
                    cols: [
                        {
                            id: uploaderFilesId,
                            view: 'uploader',
                            css: 'webix_secondary',
                            label: '<span class="fa fa-upload">',
                            tooltip: 'Добавить файл',
                            inputWidth: 45,
                            link: 'listOfFilies',
                            autosend: false,
                        },
                        {
                            label: 'Сохранить',
                            view: 'button',
                            width: 100,
                            click() {
                                saveBugInfo(params, callback);
                            },
                        },
                        {
                            view: 'button',
                            label: 'Отмена',
                            width: 100,
                            click: bugReportDestructor,
                        },
                    ],
                },
                {
                    view: 'list',
                    id: 'listOfFilies',
                    type: 'uploader',
                    autoheight: true,
                    borderless: true,
                },
            ],
        },
    });
    $$(bugReportId).show();
};
