import {
    windowEditTimeTrackId,
    timeTrackingTableId,
} from '../../../../../../../config/constants.js';
import windowEditTimeTrack from '../../windowEditTimeTrack/windowEditTimeTrack.js';

export default (ev, rowObj) => {
    const item = $$(timeTrackingTableId).getItem(rowObj.row);

    webix.ui(windowEditTimeTrack(item));
    $$(windowEditTimeTrackId).show();
};
