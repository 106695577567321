import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import di from 'Services/di.js';
import { startEditing } from 'fsrc/PlanDay/utils/planDaySlice';
import currentUserLoggedSelector from 'Domain/Users/store/currentUserLoggedSelector';
import useEnqueueSnackbar from 'Lib/Notistack/useEnqueueSnackbar';
import Suspend from 'Ui/Suspend';

import { useGetPlanDayUserQuery, useDeleteEventByIdMutation } from 'Endpoints/PlanDay/planDay.api';
import showTask from 'fsrc/Task/models/showTask';
import AddEventComponent from 'fsrc/PlanDay/components/EventEditing/AddEventComponent';
import TabPanelTitle from '../TabPanelTitle/TabPanelTitle';
import GroupTasks from '../GroupTasks';

function TabPlanDay() {
    const dispatch = useDispatch();
    const { error } = useEnqueueSnackbar();
    const [suspend, setSuspend] = useState(false);
    const currentDate = dayjs().format('YYYY-MM-DD');

    const userId = useSelector(currentUserLoggedSelector);

    const { isError, data, isLoading } = useGetPlanDayUserQuery(
        { startDate: currentDate, userId },
        { refetchOnMountOrArgChange: true },
    );
    const [eventDelete] = useDeleteEventByIdMutation();

    if (isError) error('Не удалось загрузить планы на день');

    const onClickCard = useCallback(async (taskId: number) => {
        setSuspend(true);
        const { events } = di;
        await showTask(taskId);
        events.emit('plans__drawer:closeDrawer');
        setSuspend(false);
    }, []);

    const deleteClickHandler = async (id: number) => {
        const deleteData = {
            id,
            userId,
            startDateEvent: currentDate,
        };
        await eventDelete(deleteData).unwrap();
    };
    const editClickHandler = (
        schedulerName: string,
        comment: string,
        schedulerId: number,
    ) => {
        const currentEvent = data?.find(value => value.schedulerId === schedulerId);
        const eventData = {
            schedulerName,
            schedulerId,
            comment,
            userId,
            eventDay: currentDate,
            startDateEvent: currentEvent?.startDateEvent,
            endDateEvent: currentEvent?.endDateEvent,
            allDay: !!currentEvent?.allDay,
            projectId: currentEvent?.projectId || 0,
            projectName: currentEvent?.projectName || '',
            participants: currentEvent?.participants || [],
            participantsSearchValue: currentEvent?.participantsSearchValue || [],
            eventContactsIds: currentEvent?.eventContactsIds || [],
            eventContacts: currentEvent?.eventContacts || [],
            taskId: currentEvent?.taskId || 0,
            finished: currentEvent?.finished || false,
        };
        dispatch(startEditing({ eventData, type: 'update' }));
    };

    return (
        <>
            {data && (
                <>
                    <TabPanelTitle icon="fa fa-cube" title="Планы на день">
                        {data?.length
                            ? `Общее количество задач на день: ${data.length}`
                            : 'Планов на день нет'}
                    </TabPanelTitle>
                    <AddEventComponent tabFlag />
                    <GroupTasks
                        plans={data}
                        onClickCard={onClickCard}
                        onClickDelete={deleteClickHandler}
                        onClickEdit={editClickHandler}
                    />
                    {(isLoading || suspend) && <Suspend suspend />}
                </>
            )}
        </>
    );
}

export default TabPlanDay;
