import getPlannedTask from 'fsrc/AddTaskWindow/service/getPlannedTask';
import { plannedTaskId } from '../../../config/constants.js';
import changeCurrentPlanIdByPlannedTaskId from './changeCurrentPlanIdByPlannedTaskId';

export default async (value: string) => {
    if (!value) {
        $$(plannedTaskId).define('validationFlag', true);
        return ($$(plannedTaskId) as webix.ui.text).validate();
    }
    const [result] = await getPlannedTask(value);
    if (!result) {
        $$(plannedTaskId).define('validationFlag', false);
        webix.message({
            text: `Задача №${value} не найдена. Откорректируйте номер задачи или очистите поле "Плановая задача`,
            type: 'error',
        });
        return ($$(plannedTaskId) as webix.ui.text).validate();
    }
    const { taskId, planTaskId } = result;

    if (taskId) {
        $$(plannedTaskId).define('validationFlag', true);
        ($$(plannedTaskId) as webix.ui.text).validate();
    }

    if (planTaskId) {
        changeCurrentPlanIdByPlannedTaskId(planTaskId);
    }

    return ($$(plannedTaskId) as webix.ui.text).validate();
};
