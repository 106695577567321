import React from 'react';

export default function SpinnerWanderingCubes() {
    return (
        <div className="sk-spinner sk-spinner-wandering-cubes">
            <div className="sk-cube1" />
            <div className="sk-cube2" />
        </div>
    );
}
