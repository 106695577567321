import { ITaskTypes } from 'CommonTypes/Tasks/QuickAddTaskForm/IResponse';

export default (
    taskTypes: ITaskTypes[],
    taskTypeId: number | null,
    defaultTaskTypeId: number | null,
) => {
    const defaultTaskType = taskTypes.find(taskType => (
        defaultTaskTypeId
            ? taskType.id === +defaultTaskTypeId
            : taskType.id === taskTypeId
    ));

    return defaultTaskType ?? null;
};
