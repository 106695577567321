import sendMessage from './sendMessage.js';
import createSubmissionsTask from './createSubmissionsTask.js';

import { IData } from '../types/IData';

type TEventName = string;

interface ICallback {
    [key: TEventName]: () => void,
}

export default (data: IData) => {
    const callbackAdapter: ICallback = {
        'tasks__comments:advancedCommentEditing': sendMessage,
        'tasks__submissions:createTask': createSubmissionsTask,
    };

    return callbackAdapter[data.eventName];
};
