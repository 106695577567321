import { formAddTaskId, btnSaveId } from '../../../config/constants.js';
import addNewTask from './addNewTask.js';
import updateTask from './updateTask.js';
import toggleReadonly from '../../../models/toggleReadonly.js';
import checkIsDirty from './checkIsDirty.js';

export default async (taskData, fieldsConfig) => {
    if ($$(formAddTaskId).validate()) {
        const newTask = $$(formAddTaskId).getValues().taskId;
        const isDirty = checkIsDirty(taskData);
        switch (true) {
        case !newTask:
            return addNewTask(fieldsConfig);
        case !isDirty:
            return toggleReadonly({ isReadOnly: true });
        default: {
            const result = await updateTask();
            if (result) {
                const changeData = $$(formAddTaskId).getValues();
                $$(formAddTaskId).setValues(changeData);
                webix.message({ text: `Задача №${changeData.taskId} обновлена`, type: 'success' });
            }
            webix.html.removeCss($$(btnSaveId).getNode(), 'webix_primary');
            return toggleReadonly({ isReadOnly: true });
        }
        }
    }
    return false;
};
