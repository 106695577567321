import { initModule, use } from '../services/initModule.js';
// TODO: Переделать подключение библиотеки pivot

export default {
    '/pivot': {
        as: 'pivot',
        uses: async () => {
            await import('../Lib/Webix/Pivot/pivot.min.orig.css');
            use(await import('../Lib/Webix/Pivot/pivot.min.orig.js'), 'pivot.min.orig.js');

            import(
                /* webpackChunkName: "pivotTest" */ '../../QuestModule/QuestPivot/PivotController.js'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
