import React from 'react';
import Checkbox from 'Ui/Form/Checkbox/Checkbox';

function EventCheckSection() {
    return (
        <Checkbox
            name="finished"
            label="Выполнено"
        />
    );
}

export default EventCheckSection;
