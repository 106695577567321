import toggletimer from '../../../models/toggletimer.js';
import { btnTimerId } from '../../../../../config/constants.js';
import './assets/styles.original.scss';

export default (dataTask) => {
    const timerTaskId = localStorage.getItem('running_task');
    const taskId = dataTask?.taskId || null;
    const icon = taskId === +timerTaskId ? 'fa-stop-circle-o' : 'fas fa-play-circle-o';
    return {
        id: btnTimerId,
        view: 'button',
        width: 45,
        label: `<span class="fa ${icon} task__btn-timer">`,
        hidden: true,
        click() {
            toggletimer();
        },
    };
};
