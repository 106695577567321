import saveColumnState from './models/saveColumnState';

export default (idTable: string) => (idTable ? {

    view: 'button',
    width: 32,
    tooltip: 'Сохранить настройки колонок',
    label: '<i class="fa fa-columns"></i>',
    css: 'bitbucket default',

    click() {
        saveColumnState(idTable);
    },
} : { hidden: true });
