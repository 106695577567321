import { Match } from 'navigo';
import { initModule } from '../services/initModule.js';

export default {
    '/test-task-panel': {
        as: 'test-task-panel',
        uses: () => {
            import(
                /* webpackChunkName: "user-story" */ '../../TaskPanel/TaskPanelController'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
    '/tasks-panel/:filterType': {
        as: 'tasksPanel',
        uses: (match: Match) => {
            import(
                /* webpackChunkName: "user-story" */ '../../TaskPanel/TaskPanelController'
            ).then((module) => {
                initModule(module.default, match);
            });
        },
    },
};
