import {
    formAddTaskId,
    historyStatesTableId,
} from '../../../../../../../config/constants.js';
import resetHistory from '../services/resetHistory.js';
import runLoaderBar from '../../../../../../../utils/runLoaderBar.js';
import stopLoaderBar from '../../../../../../../utils/stopLoaderBar.js';

export default () => {
    const values = $$(formAddTaskId).getValues();
    const { taskId } = values;

    if (!+taskId) return;

    webix.confirm({
        title: 'Вы действительно хотите обнулить историю?',
        ok: 'Да',
        cancel: 'Нет',
    }).then(() => {
        runLoaderBar(historyStatesTableId);
        resetHistory(taskId)
            .then((res) => {
                stopLoaderBar(historyStatesTableId);

                $$(historyStatesTableId).getColumnConfig('stateId').collection.parse(res.states, '', true);
                $$(historyStatesTableId).parse(res.data, '', true);
            });
    });
};
