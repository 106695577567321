import { nodePath, oldApi } from 'Config/path.js';
import { fetchPostFile, fetchPut } from 'Services/fetchService.js';
import IFormData from '../types/IFormData';

export default async (taskId: number, formData: IFormData) => {
    const data = {
        taskId,
        serviceId: formData.services?.id || null,
        functionsIds: (formData?.functions || []).map(func => func.id),
        subprojectsIds: (formData?.subprojects || []).map(subproject => subproject.id),
        serviceClass: formData?.serviceClass?.id || 1,
        planDuration: formData?.planDuration || 0,
    };

    const response = await fetchPut(`${nodePath}/it-services/service-functions-form`, data);

    const autocommentData = new FormData();
    autocommentData.append('task-id', taskId.toString());
    await fetchPostFile(`${oldApi}/setServiceFunctionsEvent.php`, autocommentData);

    return response;
};
