export default {
    issue: {
        project: 13,
        serviceClassId: 3,
        taskTypeId: 2,
        assignee: {
            id: 35,
            value: '',
            photo: '',
            projectsId: '',
        },
        bug: 1,
    },
    task: {
        serviceClassId: 3,
        taskTypeId: 1,
    },
    taskByInputMail: {
        serviceClassId: 3,
        taskTypeId: 1,
    },
    submissionTask: {
        serviceClassId: 3,
        taskTypeId: 1,
    },
    subTask: {
        serviceClassId: 3,
        taskTypeId: 1,
    },
    taskMessage: {
        serviceClassId: 3,
    },
};
