import { initModule, use } from '../services/initModule.js';

export default {
    '/report-manager': {
        as: 'reportManager',
        uses: async () => {
            await import('../Lib/Webix/Reportmanager/reports.min.orig.css');
            use(await import('../Lib/Webix/Reportmanager/reports.min.orig.js'), 'reports.min.orig.js');

            import(
                /* webpackChunkName: "reportManager" */ '../../ReportManager/ReportsController.js'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
