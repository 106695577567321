import { slimPath } from 'Config/path.js';
import { fetchPost } from 'Services/fetchService.js';

export async function saveOptionsService(target, optionValue) {
    const data = {
        target,
        optionValue,
    };
    const result = await fetchPost(`${slimPath}/options/saveoptions`, data);
    return result;
}

export async function loadOptions(target, subTarget) {
    const subTargetUrl = subTarget ? `&subTarget=${subTarget}` : '';
    const url = `${slimPath}/options/getoptions?user=forMe&target=${target}${subTargetUrl}`;

    const response = await fetch(url);
    const result = await response.json();

    if (result.success && result.options) {
        const options = result.options[0];
        const optionValue = JSON.parse(options.optionValue);

        return optionValue.length ? optionValue : null;
    }

    return null;
}

export function setOptions(component, optionValue) {
    if (optionValue.length) {
        optionValue.forEach((value) => {
            const option = { visible: value.visible };
            if (typeof value.visibleIndex !== 'undefined') {
                option.visibleIndex = value.visibleIndex;
            }
            if (typeof value.width !== 'undefined' && value.width !== 'auto') {
                option.width = value.width;
            }

            if ('groupIndex' in value) {
                option.groupIndex = value.groupIndex;
            }

            component.columnOption(value.dataField, option);
        });
    }
}

export async function loadAndSetOptions(component, target, subTarget) {
    const optionValue = await loadOptions(target, subTarget);

    if (optionValue) {
        setOptions(component, optionValue);
    }
}

export function getOptions(node) {
    const component = node;
    const optionsColumns = component.option();
    const columnsArray = optionsColumns.columns;

    const optionsArray = [];

    for (const column of columnsArray) {
        if (column.type && column.type == 'buttons') {
            continue;
        }

        const options = component.columnOption(column.dataField);
        let optionsValues = {
            dataField: column.dataField,
            width: options.width ? options.width : 'auto',
            visibleIndex: options.visibleIndex,
            visible: options.visible,
        };

        if ('groupIndex' in options) {
            optionsValues.groupIndex = options.groupIndex;
        }

        optionsArray.push(optionsValues);
    }

    return optionsArray;
}
