import deleteTaskPrice from '../services/deleteTaskPrice';

function actionDeleteTask(taskId: number, idTaskPrice: string, deleteRow: (idRow: string) => void) {
    deleteTaskPrice(taskId, idTaskPrice)
        .then((res) => {
            if (res) {
                deleteRow(idTaskPrice);
                return webix.message({
                    text: 'Успешно удалено',
                    type: 'success',
                });
            }
            return webix.message({
                text: 'Ошибка удаления',
                type: 'error',
            });
        });
}

export default actionDeleteTask;
