import { ITagSetWithRouteSort, ITagSimpleSet, ITagsWithRouteSortRequest } from 'CommonTypes/Tags/TagsSimpleSet';
import emptySplitApi from 'Endpoints/emptySplitApi';

export const tagsApi = emptySplitApi.injectEndpoints({
    endpoints: build => ({

        getTagsSimpleSet: build.query<
            ITagSimpleSet[],
            void
        >({
            query: () => ({
                url: 'tags',
            }),
        }),

        getTagsWithRouteSort: build.query<
            ITagSetWithRouteSort[],
            ITagsWithRouteSortRequest
        >({
            query: params => ({
                url: 'tags/route-sort',
                params,
            }),
        }),

    }),
});

export const {
    useGetTagsSimpleSetQuery,
    useGetTagsWithRouteSortQuery,
} = tagsApi;
