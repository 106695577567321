import React from 'react';

import { Confirm } from 'Ui/Modal';

interface IModalConfirmProps {
    onClickConfirmDelete: (result: boolean) => void;
    eventName: string;
}

function ConfirmDelete({ onClickConfirmDelete, eventName }: IModalConfirmProps) {
    const onCloseConfirm = (result: boolean) => {
        onClickConfirmDelete(result);
    };

    return (
        <Confirm
            open
            title="Вы уверены, что хотите удалить событие?"
            onClose={onCloseConfirm}
        >
            Событие
            &quot;
            {eventName}
            &quot;
            {' '}
            будет удален навсегда!
        </Confirm>
    );
}

export default ConfirmDelete;
