import React from 'react';

export default function SpinnerWave() {
    return (
        <div className="sk-spinner sk-spinner-wave">
            <div className="sk-rect1" />
            <div className="sk-rect2" />
            <div className="sk-rect3" />
            <div className="sk-rect4" />
            <div className="sk-rect5" />
        </div>
    );
}
