import React from 'react';
import dayjs from 'dayjs';

import {
    Avatar, Divider, Grid, Icon, Typography,
} from '@mui/material';

import container from 'Services/container';

import { IComment } from 'CommonTypes/Tasks/Comments/IResponse';
import getIconByFlagType from 'Ui/Comments/models/getIconByFlagType';

interface IProps {
    comment: IComment;
}

function Comment({ comment }: IProps) {
    const {
        userName, userPhoto, commentDate, commentText, flags,
    } = comment;
    const { root } = container.store.getState().reducerBase;

    return (
        <Grid
            item
            container
            direction="column"
            spacing={1}
        >
            <Grid
                item
                container
                wrap="nowrap"
                spacing={2}
            >
                <Grid
                    item
                    alignItems="center"
                >
                    <Avatar src={`${root}/${userPhoto}`} />
                </Grid>
                <Grid
                    item
                    container
                    direction="column"
                >
                    <Grid item>
                        <Typography
                            component="div"
                            sx={{
                                fontSize: '14px',
                                color: '#2f4050',
                            }}
                        >
                            {userName}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            component="div"
                            sx={{
                                fontSize: '9px !important',
                                color: '#888888',
                            }}
                        >
                            {dayjs(commentDate).locale('ru').format('dddd, D MMMM YYYY, HH:mm')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={1}
                    wrap="nowrap"
                    justifyContent="flex-end"
                >
                    {!!comment.recipients?.length && (
                        <Grid
                            item
                            container
                            direction="column"
                            alignItems="flex-end"
                        >
                            {comment.recipients.map(recipient => (
                                <Grid
                                    key={recipient}
                                    item
                                >
                                    <Typography
                                        component="div"
                                        sx={{
                                            fontSize: '10px!important',
                                            color: '#888888',
                                        }}
                                    >
                                        {recipient}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    <Grid item>
                        <Icon><i className={getIconByFlagType(flags)} /></Icon>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Typography
                    component="p"
                    sx={{
                        fontSize: '13px',
                        lineHeight: '18px',
                        color: '#676a6c',
                        marginBottom: '10px',
                    }}
                    dangerouslySetInnerHTML={{ __html: commentText }}
                />
            </Grid>
            <Grid item>
                <Divider />
            </Grid>
        </Grid>
    );
}

export default Comment;
