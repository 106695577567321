import { oldApi } from 'Config/path.js';
import { fetchPostFile as fetchPost } from 'Services/fetchService.js';

type TData = {
    taskId: number,
    contactId: number,
}

export default async ({ taskId, contactId }: TData) => {
    const form = new FormData();
    form.append('id', String(taskId));
    form.append('name', 'id_contact');
    form.append('value', String(contactId));

    const response: number = await fetchPost(`${oldApi}/task_save_a_param.php`, form);
    return Number.isFinite(response) && +response > 0;
};
