import changeTabHandler from 'fsrc/AddTaskWindow/models/changeTabHandler.js';
import {
    topTabbarId, descriptionTaskId, checkListId, requirementsId, servicesId,
} from '../../../../config/constants.js';

export default {
    id: topTabbarId,
    view: 'tabbar',
    value: descriptionTaskId,
    multiview: true,
    options: [
        { value: 'Описание', id: descriptionTaskId },
        { value: 'Чек-лист(0)', id: checkListId, disabled: true },
        { value: 'Требования', id: requirementsId, disabled: true },
        { value: 'Услуги', id: servicesId },
    ],
    height: 40,
    borderless: 1,
    on: {
        onChange: changeTabHandler,
    },
};
