import React from 'react';
import { Button } from '@mui/material';
import styles from '../assets/styles.scss';
import { TTypeNotice } from '../types/TTypeNotice';

interface IConfigAction {
    actionText?: string;
    callbacksStack?: (() => void) | null;
    typeNotice?: keyof TTypeNotice;
}

function CreateNoticeContent(
    message: string,
    {
        actionText,
        callbacksStack,
        typeNotice,
    }: IConfigAction = {},
) {
    const buttonColor = {
        success: '#0b2e13',
        error: '#491217',
        info: '#062c33',
        warning: '#533f03',
    };

    return (
        <div
            className={styles.wrapper}
        >
            <span>
                {message}
            </span>
            {(!!typeNotice && callbacksStack) && (
                <Button
                    color="inherit"
                    sx={{
                        color: buttonColor[typeNotice],
                        fontWeight: 600,
                        padding: 0,
                    }}
                    onClick={() => callbacksStack()}
                >
                    {actionText}
                </Button>
            )}
        </div>
    );
}

export default CreateNoticeContent;
