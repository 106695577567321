import { Match } from 'navigo';
import { initModule } from '../services/initModule.js';

export default {
    '/plan-day': {
        as: 'plan-day',
        uses: (data: Match) => {
            import(
                /* webpackChunkName: "calendar" */ '../../PlanDay/PlanDayController'
            ).then((module) => {
                initModule(module.default, data);
            });
        },
    },
};
