import { RootState } from 'Utils/store/store';
import { IRolesMatrixProjectsReducer } from '../types/RolesMatrixTypes';

type State = RootState & IRolesMatrixProjectsReducer;

export const getSelectedProject = (state: State) => state.rolesMatrixProjectsReducer.projectId;
export const getSelectedUser = (state: State) => state.rolesMatrixProjectsReducer.userId;

export const getConfirmDeleteWindowState = (state: State) => state.rolesMatrixProjectsReducer
    .confirmDeleteWindowOpen;

export const getProjectIdForDelete = (state: State) => state.rolesMatrixProjectsReducer
    .projectIdForDelete;
export const getUserIdForDelete = (state: State) => state.rolesMatrixProjectsReducer
    .userIdForDelete;
