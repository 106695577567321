/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ThemeProvider, Button, Icon } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import di from 'Services/di.js';
import theme from 'Lib/Mui/theme';
import { Modal } from 'Ui/Modal';
import Editor from 'Ui/Editor';

import { IResponse } from 'CommonTypes/Tasks/Contacts/IResponse';
import { IFormInput } from './types/IFormInput';
import getContacts from './services/getContacts';

interface IStateContacts {
    currentContacts: IResponse[] | [];
    allContacts: IResponse[] | [];
}

interface IEventData {
    taskId: number;
    textComment: string;
    submissionId?: number;
}

interface IProps {
    eventData: IEventData;
    callback: (eventData: IEventData, dataMessage: object) => void
}

function Footer({ close, send, handleSubmit }:
    { close: () => void, send: () => void, handleSubmit: () => void }) {
    return (
        <>
            <Button variant="contained" size="wm" onClick={send} startIcon={<Icon><i className="fa fa-paper-plane-o" /></Icon>}>
                Отправить
            </Button>
            <Button variant="contained" color="secondary" size="wm" onClick={close}>Отмена</Button>
            <Button variant="contained" size="wm" onClick={handleSubmit}>Сохранить</Button>
        </>
    );
}

export default function ModalComment({ eventData, callback }: IProps) {
    const {
        handleSubmit, setValue, getValues,
    } = useForm<IFormInput>({ defaultValues: { description: eventData.textComment } });

    const [open, setOpen] = useState(false);

    const [data, setContacts] = useState({
        currentContacts: [],
        allContacts: [],
    } as IStateContacts);

    const handlerOnClose = () => di.events.emit('modalComment:closeModal');

    const sendClick = () => {
        const dataMessage = getValues();

        callback(eventData, {
            isSend: true,
            dataMessage,
            currentContacts: data.currentContacts,
        });
        handlerOnClose();
    };

    useEffect(() => {
        getContacts(eventData.taskId).then((taskContacts: IResponse[]) => {
            const currentContacts = taskContacts.filter(contact => contact.def);
            setContacts({ currentContacts, allContacts: taskContacts });
            setOpen(true);
        });
    }, [eventData.taskId]);

    const onSubmit: SubmitHandler<IFormInput> = (dataMessage) => {
        callback(eventData, { isSend: false, dataMessage, currentContacts: data.currentContacts });

        handlerOnClose();
    };

    function onChangeHandler(value: IResponse[]) {
        setContacts({ ...data, currentContacts: value });
    }

    return (
        <ThemeProvider theme={theme}>
            (
            <Modal
                title="Комментарий"
                open={open}
                onClose={handlerOnClose}
                width={900}
                height={500}
                footer={(
                    <Footer
                        close={() => handlerOnClose()}
                        send={sendClick}
                        handleSubmit={handleSubmit(onSubmit)}
                    />
                )}
            >
                <form id="comment-form" onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Autocomplete
                        multiple
                        options={data.allContacts}
                        sx={{
                            marginBottom: '15px',
                            backgroundColor: '#fff',
                        }}
                        getOptionLabel={options => options.contactName}
                        value={data.currentContacts}
                        onChange={(event, value1) => onChangeHandler(value1)}
                        filterSelectedOptions
                        renderOption={(props, option) => (
                            <li {...props} key={option.contactId}>
                                {option.contactName}
                            </li>
                        )}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="Список контактов"
                            />
                        )}
                    />
                    <Editor
                        value={getValues('description')}
                        onChange={(e, editor) => setValue('description', editor.getData())}
                    />
                </form>
            </Modal>
            )
        </ThemeProvider>
    );
}
