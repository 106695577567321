export default (value, fields) => {
    const isValidate = true;

    if (value && (value < fields.startDate)) {
        return !isValidate;
    }

    return isValidate;
};

