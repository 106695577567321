import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material';

import StyledSnackbarProvider from 'Lib/Notistack/StyledSnackbarProvider';
import Action from 'Lib/Notistack/DefaultAction';

import theme from 'Lib/Mui/theme';

export default (di, reactUi, rootId, {
    fullHeight, flexColumn, container, animate, displayFlex,
}) => {
    const rootEl = document.getElementById(rootId);
    rootEl.empty();

    const root = document.createElement('div');

    if (fullHeight) {
        root.classList.add('flex-full-height');
    }

    if (flexColumn) {
        root.classList.add('root-flex-column');
    }

    if (displayFlex) {
        root.classList.add('display-flex');
    }

    if (animate && container === 'main') {
        const animateType = 'fadeInRight';
        root.classList.add('animated', animateType);

        const animateRemover = (htmlElement) => {
            if (htmlElement.animationName === animateType) {
                root.classList.remove('animated', animateType);
                root.removeEventListener('animationend', animateRemover);
            }
        };

        root.addEventListener('animationend', animateRemover);
    }

    rootEl.appendChild(root);
    const rootContainer = createRoot(root);

    rootContainer.render(
        <StrictMode>
            <Provider store={di.store}>
                <ThemeProvider theme={theme}>
                    <StyledSnackbarProvider
                        action={snackbarId => Action(snackbarId)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        hideIconVariant
                    >
                        { reactUi }
                    </StyledSnackbarProvider>
                </ThemeProvider>
            </Provider>
        </StrictMode>,
    );

    return rootContainer;
};
