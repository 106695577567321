import { initModule } from '../services/initModule.js';

export default {
    '/dataview': {
        as: 'dataview',
        uses: () => {
            import(
                /* webpackChunkName: "dataview" */ '../../DataViewPage/dataViewController.js'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
