import di from 'Services/di.js';

export default async (dataTask) => {
    const { store } = di;
    const { reducerBase } = store.getState();
    const { root } = reducerBase;

    const result = await app.fetch(`${root}/ajax/qick_add_task.php`, dataTask, 'POST');

    return result;
};
