import di from 'Services/di.js';
import getCurrentPage from '../../../../../../MainMenu/utils/getCurrentPage.js';
import { lamapiPath, wikiPath } from '../../../../../config/path.js';

const { store } = di;
const { reducerBase } = store.getState();
const { root: rootPath } = reducerBase;

const getWikiButton = () => {
    const { router } = di;
    const sidebarElements = $$('main-menu').serialize();
    const { url } = router.current[0];
    const currentPage = getCurrentPage(sidebarElements, url);

    const data = $$('main-menu').getItem(currentPage?.currentId);
    if (data?.wikiId) {
        return {
            view: 'button',
            width: 32,
            tooltip: 'Открыть wiki',
            label: '<span class="fa fa-info"></span>',
            css: 'bitbucket default',
            click() {
                window.open(`${rootPath}${lamapiPath}${wikiPath}/${data.wikiId}`, '_blank').focus();
            },
        };
    }

    return {
        hidden: true,
    };
};

export default getWikiButton;
