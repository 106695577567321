import addTaskFormDestructor from '../../../utils/addTaskFormDestructor.js';
import { formAddTaskId, btnTimerId } from '../../../config/constants.js';
import readonlyMode from '../../../models/readonlyMode.js';
import checkIsDirty from './checkIsDirty.js';
import getTaskById from '../../../service/getTaskById.js';
import updateRequestsTable from './updateRequestsTable.js';

export default async (taskData) => {
    const readonly = $$(btnTimerId).isVisible();
    const { taskId, flagStateUpdate } = $$(formAddTaskId).getValues();
    const newTask = !taskId;
    const isDirty = checkIsDirty(taskData);
    switch (true) {
    case newTask && !isDirty:
        return addTaskFormDestructor();
    case !newTask && readonly && isDirty: {
        const dataTask = await getTaskById(taskId);
        updateRequestsTable(dataTask, false);

        return addTaskFormDestructor();
    }
    case !newTask && readonly:
        return addTaskFormDestructor();
    case !newTask && !readonly && !isDirty:
        return readonlyMode();
    case !newTask && !readonly && isDirty && flagStateUpdate: {
        const dataTask = await getTaskById(taskId);
        updateRequestsTable(dataTask, false);
        return readonlyMode();
    }
    default:
        return webix.confirm({
            title: 'Несохраненные данные будут потеряны',
            ok: 'Ок',
            cancel: 'Отмена',
        }).then(() => {
            if (newTask) return addTaskFormDestructor();
            $$(formAddTaskId).setValues(taskData);
            return readonlyMode();
        });
    }
};
