import { fetchPostFile } from 'Services/fetchService.js';
// import addMessageFilesToTask from 'fsrc/AddTaskWindow/service/addMessageFilesToTask';
import { uploaderId } from '../../../../../../../config/constants.js';

export default async () => {
    const uploader = $$(uploaderId);
    if (uploader.files.data.order.length) {
        const newFileForm = new FormData();
        // const existingFilesByMessage = [];
        uploader.files.data.each((objectOfUploudedFiles, index) => {
            const { file } = objectOfUploudedFiles;
            if (file) {
                const description = objectOfUploudedFiles.uploader_fileDescription;
                newFileForm.append('path', '');
                newFileForm.append('file[]', file);
                if (description) newFileForm.append(`description${index}`, description);
            }
            // else {
            //     existingFilesByMessage.push(objectOfUploudedFiles);
            // }
        });

        // const addFilesToTask = await addMessageFilesToTask(existingFilesByMessage);
        const result = await fetchPostFile(`${app.root}files/upload_several.php`, newFileForm);
        const arrayOfFilesId = result.map(el => el.id);
        uploader.files.data.each((file, ind) => {
            $$(uploaderId).files.updateItem(file.id, result[ind]);
            $$(uploaderId).files.data.changeId(file.id, result[ind].id);
        });

        return arrayOfFilesId;
    }
    return [];
};
