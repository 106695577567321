import { relatedTasksTableId, formAddTaskId } from '../../../../../../../config/constants.js';
import addCreatedTaskToLinks from '../services/addCreatedTaskToLinks.js';
import getCreatedRelatedTask from '../services/getCreatedRelatedTask.js';

export default (data) => {
    const parentTaskInfo = $$(formAddTaskId).getValues();

    addCreatedTaskToLinks({ taskId: data.id, parentId: parentTaskInfo.taskId })
        .then(async () => {
            const taskData = await getCreatedRelatedTask(data.id);
            $$(relatedTasksTableId).add(taskData[0]);
        });
};
