import {
    typeTaskId,
    serviceClassId,
    departmentId,
    prioritiesId,
    projectsId,
    userId,
    roleId,
    serviceId,
    functionId,
    planId,
    sprintId,
    tagsId,
    formAddTaskId,
    topTabbarId,
    bottomTabbarId,
} from '../../../config/constants.js';
import getAllPropertyForTaskMenu from '../../../service/getAllPropertyForTaskMenu.js';
import getTaskById from '../../../service/getTaskById.js';
import filterTagsData from './filterTagsData.js';
import changeTabHandler from '../../../models/changeTabHandler.js';
import changeButtonsByCurrentState from './changeButtonsByCurrentState.js';

export default async (taskId) => {
    const allProperty = await getAllPropertyForTaskMenu();

    const elemsObj = {
        typeTaskId: $$(typeTaskId)?.getList(),
        serviceClass: $$(serviceClassId)?.getList(),
        departmentId: $$(departmentId)?.getList(),
        prioritiesId: $$(prioritiesId)?.getList(),
        projectsId: $$(projectsId)?.getList(),
        userId: $$(userId)?.getList(),
        roleId: $$(roleId)?.getList(),
        servicesId: $$(serviceId)?.getList(),
        functionId: $$(functionId)?.getList(),
        plansId: $$(planId)?.getList(),
        sprintsId: $$(sprintId)?.getList(),
        tagsId: $$(tagsId)?.getList(),
    };

    Object.values(elemsObj).forEach((el, ind) => {
        if (!el) return;

        el.parse(allProperty[elemsObj[ind]], '', true);

        if (elemsObj[ind] === tagsId) {
            el.parse(filterTagsData(allProperty[elemsObj[ind]]), '', true);
        }
    });

    if (!taskId) {
        return webix.message({
            text: 'Данные полей формы обновлены.',
            type: 'success',
        });
    }

    const taskData = await getTaskById(taskId);

    $$(formAddTaskId).setValues(taskData);
    changeTabHandler($$(topTabbarId).getValue());
    changeTabHandler($$(bottomTabbarId).getValue());

    changeButtonsByCurrentState(taskData);

    return webix.message({
        text: 'Данные задачи успешно обновлены.',
        type: 'success',
    });
};
