import { IRolesMatchingCanUserByTaskRequest, IRolesMatchingCanUserByTaskResponse } from 'CommonTypes/RolesMatching/RolesMatchingTypes';
import emptySplitApi from 'Endpoints/emptySplitApi';

export const rolesMatchingApi = emptySplitApi.injectEndpoints({
    endpoints: build => ({

        canUserByTaskIdAction: build.query<
            IRolesMatchingCanUserByTaskResponse,
            IRolesMatchingCanUserByTaskRequest
        >({
            query: ({ taskId, action }) => ({
                url: `roles/matching/can/actions?taskId=${taskId}&action=${action}`,
            }),
        }),

    }),
});

export const {
    useCanUserByTaskIdActionQuery,
} = rolesMatchingApi;
