export default (obj) => {
    const { sname } = obj;
    const target = sname ? 'target="_blank"' : '';
    const template = sname ? `title="${obj.name}"` : '';
    const dataHref = sname ? `href="${app.root}${obj.folder}${sname}"` : '';
    const dataUrl = sname ? `data-url="${obj.folder}${sname}"` : '';
    const dataId = sname ? `data-id="${obj.id}"` : '';
    const dataName = sname ? `data-name="${obj.name}"` : '';
    const dataDownloadUrl = sname ? `data-download-url="${app.root}/files/get_file.php?sname=${sname}&name=${obj.name}"` : '';
    const dataShowUrl = sname ? `data-show-url="http://docs.google.com/a/my-pm.ru/viewer?url=${app.root}${obj.folder}${sname}&embedded=false"` : '';
    const dataClass = sname ? 'class="fileAttach"' : '';

    return `<a ${dataHref} ${target} ${template} ${dataUrl} ${dataClass} ${dataId} ${dataName} ${dataDownloadUrl} ${dataShowUrl} onclick="return false;">${obj.name}</a>`;
};
