import { SnackbarProvider } from 'notistack';
import { styled } from '@mui/material/styles';

const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.notistack-MuiContent-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
  &.notistack-MuiContent-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  &.notistack-MuiContent-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }
  &.notistack-MuiContent-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
`;

export default StyledSnackbarProvider;
