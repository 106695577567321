/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useCallback } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import container from 'Services/container';

import { IContragentsOldStrucutreResponse } from 'CommonTypes/Contragents/ContragentsOldStrucutre';
import getContragentsOptionsService from 'fsrc/Sales/services/getContragentsOptionsService';
import setContragentToTaskService from 'fsrc/Sales/services/setContragentToTaskService';
import connectorOldContragentsAddModel from 'fsrc/Sales/models/connectorOldContragentsAddModel.js';

const filter = createFilterOptions<IContragentsOldStrucutreResponse>();

type TProps = {
    taskId: number,
    contragentId: number,
    onContragentChangeHandle: (contragentId: number | null) => void,
}

function ContragentSelect({ taskId, contragentId, onContragentChangeHandle }: TProps) {
    const { events } = container;
    const [options, setOptions] = useState<IContragentsOldStrucutreResponse[]>([]);
    const [value, setValue] = useState<IContragentsOldStrucutreResponse | null>(null);

    const onChangeHandle = (
        e: React.SyntheticEvent<Element, Event>,
        newValue: IContragentsOldStrucutreResponse | string | null,
    ) => {
        if (typeof newValue === 'string' || newValue?.id === 0) {
            const name = typeof newValue === 'string' ? newValue : newValue.name.replace('Добавить "', '').replace('"', '').trim();
            connectorOldContragentsAddModel((result: IContragentsOldStrucutreResponse) => {
                const newOptions = [...options, { ...result, id: +result.id }];
                setOptions(newOptions);
                const newContragent = newOptions.find(
                    contragent => contragent.id === +result.id,
                ) || null;
                setContragentToTaskService({ taskId, contragentId: +(newContragent?.id || 0) })
                    .then(() => {
                        events.emit('sales__formInOldSalesForm:changeContragent');
                    });
                onContragentChangeHandle(newContragent?.id || null);
                setTimeout(() => {
                    setValue(newContragent);
                }, 500);
            }, name, 1);
        } else {
            setContragentToTaskService({ taskId, contragentId: +(newValue?.id || 0) })
                .then(() => {
                    events.emit('sales__formInOldSalesForm:changeContragent');
                });
            setValue(newValue);
            onContragentChangeHandle(newValue?.id || null);
        }
    };

    const handleClickPlusButton = useCallback(() => {
        connectorOldContragentsAddModel((result: IContragentsOldStrucutreResponse) => {
            if (+result.id) {
                const newOptions = [...options, { ...result, id: +result.id }];
                setOptions(newOptions);
                const newContragent = newOptions.find(
                    contragent => contragent.id === +result.id,
                ) || null;
                setContragentToTaskService({ taskId, contragentId: +result.id })
                    .then(() => {
                        events.emit('sales__formInOldSalesForm:changeContragent');
                    });
                onContragentChangeHandle(+result.id);
                setTimeout(() => {
                    setValue(newContragent);
                }, 500);
            }
        }, '', 1);
    }, [events, onContragentChangeHandle, options, taskId]);

    const onClickLinkHandle = useCallback(() => {
        window.open(`//${window.location.host}/contragents/${value?.id || 0}`, '_blank', 'noreferrer');
    }, [value?.id]);

    useEffect(() => {
        getContragentsOptionsService().then(result => setOptions(result));
    }, []);

    useEffect(() => {
        const currentValue = options?.find(option => +option.id === contragentId) || null;
        if (currentValue) {
            setValue(currentValue);
        }
    }, [contragentId, options]);

    return (
        <Autocomplete
            size="small"
            options={options}
            value={value}
            blurOnSelect
            handleHomeEndKeys={false}
            freeSolo
            renderInput={params => (
                <TextField
                    {...params}
                    label="Контрагент"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {Boolean(value) && (
                                    <InputAdornment position="end">
                                        <Tooltip title="Открыть Контрагента">
                                            <IconButton onClick={onClickLinkHandle}>
                                                <i className="fa fa-external-link" />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )}
                                <InputAdornment position="end">
                                    <Tooltip title="Добавить Контрагента">
                                        <IconButton onClick={handleClickPlusButton}>
                                            <i className="fa fa-plus" />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            filterOptions={(option, params) => {
                const filtered = filter(options, params);

                if (params.inputValue !== '' && !filtered.length) {
                    filtered.push({
                        id: 0,
                        inn: '',
                        name: `Добавить "${params.inputValue}"`,
                    });
                }

                return filtered;
            }}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {`${option.name || ''} | ${option.inn || ''}`}
                </li>
            )}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option;
                }
                return `${option?.name || ''} | ${option?.inn || ''}`;
            }}
            onChange={onChangeHandle}
        />
    );
}

export default ContragentSelect;
