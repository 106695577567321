import { subTasksId, bottomTabbarId, bottomMultiviewId } from '../../../../config/constants.js';
import subtasksTable from './components/subtasksTable/subtasksTable.js';
import toolbar from './components/toolbar/toolbar.js';

export default dataFiles => ({
    id: subTasksId,
    tabbarParent: bottomTabbarId,
    multiviewParent: bottomMultiviewId,
    rows: [
        toolbar(dataFiles),
        subtasksTable,
    ],
});
