import { fetchGet } from 'Services/fetchService.js';
import { nodePath } from 'Config/path.js';
import { IResponse } from 'CommonTypes/Tasks/QuickAddTaskForm/IResponse';
import { IDataForm } from '../types/IDataForm';

export default async () => {
    const responce: IResponse = await fetchGet(`${nodePath}/tasks/all-property-for-mini-form`);

    const result: IDataForm = {
        ...responce,
        subdivisionIsRequired: 0,
        itserviceIsRequired: 0,
        functionsIsRequired: 0,
    };

    return result;
};
