import { initModule, use } from '../services/initModule.js';

export default {
    '/scheduler': {
        as: 'scheduler',
        uses: async () => {
            await import('../Lib/Webix/Scheduler/scheduler.min.orig.css');
            use(await import('../Lib/Webix/Scheduler/scheduler.min.orig.js'), 'scheduler.min.orig.js');

            import(
                /* webpackChunkName: "scheduler" */ '../../SchedulerModule/SchedulerController.js'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
    '/scheduler/units': {
        as: 'units',
        uses: async () => {
            import(
                /* webpackChunkName: "units" */ '../../SchedulerModule/UnitsTimelineModule/UnitsTimelineController.js'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
