import { assetsId, projectsId } from '../../../config/constants.js';

export default (data) => {
    const modules = data.modulesId;
    const currentProjectId = $$(projectsId).getValue();
    if (currentProjectId) {
        const currentDataProject = $$(projectsId).getList().getItem(currentProjectId);
        const { companyId } = currentDataProject;
        const resultAssets = modules.reduce((acc, el) => {
            if (el.companyId === companyId) {
                const {
                    id, parent, value, flag,
                } = el;
                const module = {
                    id, parent, value, flag,
                };
                acc.push(module);

                return acc;
            }

            return acc;
        }, []);
        const list = $$(assetsId).getPopup().getList();
        list.clearAll();
        list.parse(webix.copy(resultAssets));
        $$(assetsId).setValue('');
        $$(assetsId).enable();
    } else {
        const list = $$(assetsId).getPopup().getList();
        list.clearAll();
        $$(assetsId).setValue('');
        $$(assetsId).disable();
    }
};
