import { windowAddHistoryItemId } from '../../../../../../config/constants.js';
import form from './form/form.js';

export default data => ({
    id: windowAddHistoryItemId,
    view: 'window',
    width: 500,
    height: 600,
    position: 'center',
    animate: true,
    move: true,
    close: true,
    modal: true,
    resize: true,
    head: {
        view: 'toolbar',
        cols: [
            { width: 20 },
            {
                view: 'label',
                align: 'center',
                label: 'Добавление записи состояния задачи',
            },
            {
                view: 'icon',
                icon: 'wxi-close',
                click() {
                    $$(windowAddHistoryItemId).destructor();
                },
            },
        ],
    },
    body: {
        rows: [
            form(data),
        ],
    },
});
