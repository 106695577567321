import React from 'react';
import IContactSimpleSet from 'CommonTypes/Contacts/IContactSimpleSet';

import { AutocompleteLongList } from 'Ui/Form/Autocomplete';
import filterOptionsBySearchValue from '../models/filterOptionsBySearchValue';

function EventContactsSection({ options }: { options: IContactSimpleSet[] }) {
    return (
        <AutocompleteLongList
            name="contacts"
            label="Контакты"
            options={options}
            blurOnSelect
            multiple
            listValue="searchValue"
            limitTags={1}
            filterOptions={filterOptionsBySearchValue}
        />
    );
}

export default EventContactsSection;
