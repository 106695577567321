import { initModule } from '../services/initModule.js';

export default {
    '/layout-configuration': {
        as: 'layout-configuration',
        uses: () => {
            import(
                /* webpackChunkName: "assets" */ '../Domain/LayoutConfiguration/LayoutConfigurationController'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
