/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import { Autocomplete, TextField } from '@mui/material';

interface IValueOptions {
    value: number | null;
    label: string;
}

function AutocompleteSelectField(
    {
        colDef: { valueOptions = [] },
        value,
        id,
        field,
        api,
    }: GridRenderEditCellParams,
) {
    const currentValue = (valueOptions as IValueOptions[])
        .find(el => +(el?.value || 0) === +value) || undefined;
    return (
        <Autocomplete
            value={currentValue}
            options={valueOptions as IValueOptions[]}
            getOptionLabel={option => option.label}
            sx={{ width: '100%' }}
            openOnFocus
            noOptionsText="Нет опций"
            renderInput={params => <TextField {...params} autoFocus />}
            onChange={(_, newValue) => api.setEditCellValue({
                id, field, value: newValue?.value || null,
            })}
            isOptionEqualToValue={(option, eqValue) => option?.value === eqValue?.value}
        />
    );
}

export default AutocompleteSelectField;
