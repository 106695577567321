/* eslint-disable no-param-reassign */
import IButtonProps from 'Ui/Button/types/IButtonProps';
import style from '../assets/style.scss';

function ConstructorClass(config: IButtonProps) {
    const currentClass = Object.values(config).reduce((acc, prop) => {
        if (prop === 'large') {
            acc += ` ${style[`btn-${prop}-dim`]}`;
        } else {
            acc += ` ${style[`btn-${prop}`]}`;
        }
        return acc;
    }, '');
    return currentClass;
}

export default ConstructorClass;
