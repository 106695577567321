import {
    timeManagementId,
    bottomTabbarId,
    timeTrackingTableId,
    formAddTaskId,
} from '../../../../../config/constants.js';
import getTimeTracks from '../services/getTimeTracks.js';
import runLoaderBar from '../../../../../utils/runLoaderBar.js';
import stopLoaderBar from '../../../../../utils/stopLoaderBar.js';

export default () => {
    const values = $$(formAddTaskId).getValues();
    const { taskId } = values;

    if (!+taskId) return;

    runLoaderBar(timeTrackingTableId);
    getTimeTracks(taskId)
        .then((res) => {
            stopLoaderBar(timeTrackingTableId);

            $$(timeTrackingTableId).parse(res, '', true);
            $$(bottomTabbarId).getOption(timeManagementId).value = `Учёт времени (${res.length})`;
        });
};
