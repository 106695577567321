import getInfoStory from '../../../service/getInfoStory';

export default (taskId: number) => {
    getInfoStory(taskId)
        .then((res: { success: boolean, storyId: number }) => {
            if (!res.success) return;

            window.open(`/user-story/${res.storyId}`, '_blank')?.focus();
        });
};
