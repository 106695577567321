import di from 'Services/di.js';

import defaultTasksConfig from 'Config/default/defaultTasksConfig';

import { IResponse } from 'CommonTypes/Tasks/QuickAddTaskForm/IResponse';

import getDefaultContact from './getDefaultContact';
import getDefaultServiceClasse from './getDefaultServiceClasse';
import getDefaultTaskType from './getDefaultTaskType';
import getDefaultAssignee from './getDefaultAssignee';
import getDefaultProject from './getDefaultProject';

export default (
    data: IResponse,
    params: {
        data: { [key: string]: string | number; };
        parentItem?: { id: number; name: string; } | undefined;
    },
) => {
    const { store } = di;
    const { reducerUser } = store.getState();
    const { contactId, taskTypeId, id } = reducerUser;

    const contact = getDefaultContact(data.contacts, contactId);
    const bugInfo = params;

    const taskName = `Связанная задача для задачи №${params?.parentItem?.id} ${params?.parentItem?.name}`;

    const serviceClasse = getDefaultServiceClasse(
        data.serviceClasses,
        defaultTasksConfig.submissionTask.serviceClassId,
    );

    const assignee = getDefaultAssignee(
        data.users,
        id ?? 35,
    );

    const project = getDefaultProject(
        data,
        contact,
        +params.data.projectId ?? 13,
    );

    const taskTypes = getDefaultTaskType(
        data.taskTypes,
        defaultTasksConfig.submissionTask.taskTypeId,
        project?.taskTypeId || taskTypeId,
    );

    return {
        contact,
        serviceClasse,
        taskTypes,
        taskName,
        assignee, // assignee: params.parentItem ? 0 : 35,
        // FIXME: почему так было? почему 0?? а не тот кто задачу создает???
        project,
        bugInfo,
        bug: params.parentItem ? 0 : 1,
        planId: params?.data.planId ?? 0,
    };
};
