import { uploaderId, filesListId } from '../../../../../../../../../../config/constants.js';
import changeAmountOfFiles from '../../../../models/changeAmountOfFiles.js';
import checkUploadFile from '../../../../models/checkUploadFile.js';

export default () => ({
    id: uploaderId,
    view: 'uploader',
    width: 45,
    css: 'button',
    label: '<span class="fa fa-upload">',
    tooltip: 'Добавить файл',
    link: filesListId,
    autosend: false,
    on: {
        onAfterFileAdd(file) {
            changeAmountOfFiles();
            checkUploadFile(file);
        },
    },
});
