import {
    formAddTaskId,
    formAddHistoryItemId,
    historyStatesTableId,
    windowAddHistoryItemId,
} from '../../../../../../../../config/constants.js';
import addHistoryStatesItem from '../services/addHistoryStatesItem.js';

export default () => {
    if ($$(formAddHistoryItemId).validate()) {
        const formValues = $$(formAddHistoryItemId).getValues();
        const mainValues = $$(formAddTaskId).getValues();
        const { taskId } = mainValues;

        if (!+taskId) return;

        const data = {
            taskId: +taskId,
            stateId: +formValues.stateId,
            startDate: formValues.startDate,
            endDate: formValues.endDate,
            durationDays: Math.round(+formValues.durationDays),
        };

        addHistoryStatesItem(data)
            .then((res) => {
                $$(historyStatesTableId).add(res);
                $$(windowAddHistoryItemId).destructor();
            });
    }
};
