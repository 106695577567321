function getHandler(router) {
    return (id) => {
        const data = $$('main-menu').getItem(id);
        if (data.hash) {
            $$('main-menu').clearCss('webix_selected');
            $$('main-menu').addCss(id, 'webix_selected');
            router.navigate(data.hash);
            if (data.parent_id) {
                $$('main-menu').addCss(data.parent_id, 'webix_selected');
            }
        }
    };
}

export default getHandler;
