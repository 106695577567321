import di from 'Services/di.js';
import { commentsId, formAddTaskId } from 'fsrc/AddTaskWindow/config/constants.js';
import getCommentsView from './commentsView.js';
import setCountToTabModel from './models/setCountToTabModel.js';
import getUsersForComments from './services/getUsersForComments.js';

export default () => {
    const values = $$(formAddTaskId).getValues();
    const { taskId } = values;

    if (+taskId && !$$('comments__view')) {
        $$(commentsId).removeView('comments__placeholder');

        getUsersForComments(taskId).then((usersData) => {
            const { store } = di;
            const { reducerUser: { id: currentUserId } } = store.getState();

            $$(commentsId).addView(
                webix.copy(getCommentsView(+taskId, currentUserId, usersData, setCountToTabModel)),
            );
        });
    }
};
