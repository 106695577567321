import { oldApi } from 'Config/path.js';
import { fetchPostFile } from 'Services/fetchService.js';
import { Dayjs } from 'dayjs';

type TResponse = {
    updates: {
        deadline: {
            success: boolean,
        }
    }
}

export default async (taskId: number, deadline: Dayjs, startDate: Dayjs) => {
    const form = new FormData();
    form.append('id_task', taskId.toString());
    form.append('date', startDate.unix().toString());
    form.append('deadline', deadline.unix().toString());

    const response: TResponse = await fetchPostFile(`${oldApi}/task_dates.php`, form);

    return {
        success: !!response?.updates?.deadline?.success,
    };
};
