import React from 'react';
import Button from '@mui/material/Button';

import Modal from './Modal';

import { IConfirm } from './types/IConfirm';

function Footer({ onClick, yes, no }: {
    onClick: (result: boolean) => void, yes: string, no: string
}) {
    return (
        <>
            <Button variant="contained" color="secondary" size="wm" onClick={() => onClick(false)}>{no}</Button>
            <Button variant="contained" size="wm" onClick={() => onClick(true)}>{yes}</Button>
        </>
    );
}

function Confirm({
    open = false,
    maxWidth = 'sm',
    title,
    yes = 'Да',
    no = 'Нет',
    children,
    onClose,
}: IConfirm) {
    const onCloseHandler = () => onClose(false);

    return (
        <Modal
            open={open}
            title={title}
            maxWidth={maxWidth}
            resizable={false}
            draggableWindow={false}
            close={false}
            fullScreen={false}
            onClose={onCloseHandler}
            footer={<Footer onClick={result => onClose(result)} yes={yes} no={no} />}
        >
            { children }
        </Modal>
    );
}

export default Confirm;
