import { planId } from 'fsrc/AddTaskWindow/config/constants.js';

interface IPlan {
    id: number;
    projectId: number;
    value: string;
}

export default (planTaskId: number) => {
    const currentPlanId = ($$(planId) as webix.ui.combo).getValue();

    if (currentPlanId) return true;
    const listOfPlans = ($$(planId) as webix.ui.combo).getList() as webix.ui.list;
    const allowedPlans: IPlan[] | [] = listOfPlans.serialize();

    if (!allowedPlans.length) return true;
    const isPlanByTaskId = allowedPlans.reduce((acc: boolean, plan: IPlan) => {
        const { id } = plan;
        const isAllowedPlan = id === planTaskId;

        return isAllowedPlan || acc;
    }, false);

    if (isPlanByTaskId) return ($$(planId) as webix.ui.text).setValue(`${planTaskId}`);

    return true;
};
