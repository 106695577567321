import { assetsId, modulesId, projectsId } from '../../../config/constants.js';

export default (data) => {
    const assetsValue = $$(assetsId).getValue();
    const currentProjectId = $$(projectsId).getValue();
    const modules = data.modulesId;

    if (assetsValue && currentProjectId) {
        const currentAssets = $$(assetsId).getValue().split(',');
        const assets = currentAssets.map(el => $$(assetsId).getList().getItem(el).flag);
        const currentDataProject = $$(projectsId).getList().getItem(currentProjectId);
        const { companyId } = currentDataProject;
        const resultModules = modules.filter((module) => {
            const result = module.companyId === companyId && assets.includes(module.flag);

            return result;
        });

        const list = $$(modulesId).getPopup().getList();
        list.clearAll();
        list.parse(webix.copy(resultModules));

        const currentModules = $$(modulesId).getValue().split(',');
        const modulesFilter = currentModules.filter((el) => {
            const result = $$(modulesId).getList()?.getItem(el)?.flag;

            return result;
        });

        $$(modulesId).setValue(modulesFilter);
    } else {
        const currentDataProject = $$(projectsId).getList().getItem(currentProjectId);
        const { companyId } = currentDataProject;
        const resultModules = modules.filter(module => module.companyId === companyId);
        const list = $$(modulesId).getPopup().getList();
        list.clearAll();
        list.parse(webix.copy(resultModules));
    }
};
