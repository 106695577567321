import React from 'react';
import { Confirm } from 'Ui/Modal';

export interface IProps {
    isOpen: boolean;
    title: string;
    describe: string;
    closeHandle: (result: boolean) => void;
}

function ModalConfirmSimpleWindow({
    isOpen, title, closeHandle, describe,
}: IProps) {
    return (
        <Confirm
            open={isOpen}
            title={title}
            onClose={closeHandle}
        >
            <p>
                { describe }
            </p>
        </Confirm>
    );
}

export default ModalConfirmSimpleWindow;
