import { IEventsChecklist } from 'CommonTypes/Scheduler/IEventsChecklist';

function getCheckedListItems(items: IEventsChecklist[]) {
    const result = items.reduce((acc, value) => {
        if (value.checked) {
            acc.push(value.checkId);
        }

        return acc;
    }, <number[]>[]);

    return result;
}

export default getCheckedListItems;
