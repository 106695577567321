import React, { memo } from 'react';
import Grid from '@mui/material/Grid';

import { Button } from '@mui/material';

import EventCheckSection from './components/EventCheckSection';
import CreateChecklistButton from './components/CreateChecklistButton';
import EventsCopyDatesSection from './components/EventsCopyDatesSection';

function FooterEventEditing({ handleSubmit, linkedToChecklist }:
    { handleSubmit: () => void, linkedToChecklist: boolean }) {
    return (
        <Grid container>
            <Grid item style={{ maxWidth: '125px' }}>
                <EventCheckSection />
            </Grid>
            <Grid item style={{ width: '595px' }}>
                <EventsCopyDatesSection />
            </Grid>
            <Grid container item xs justifyContent="flex-end" sx={{ gap: '15px', alignItems: 'center' }}>
                <CreateChecklistButton linkedToChecklist={linkedToChecklist} />
                <Button
                    id="create"
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Сохранить
                </Button>
            </Grid>
        </Grid>
    );
}

export default memo(FooterEventEditing);
