window.webix.protoUI({
    name: 'reactWrapper',
    $init(config) {
        const html = '<div class="flex-full-height"></div>';

        this.$view.innerHTML = html;
        this.$ready.push(this._render_react);
    },
    _render_react() {
        const createRoot = this.config.createRoot;
        const rootContainer = createRoot(this.$view.firstChild);
        rootContainer.render(this.config.reactUi);

        this.attachEvent('onDestruct', function() {
            rootContainer?.unmount();
        });
    }
}, window.webix.ui.view, window.webix.EventSystem);
