import { fetchGet } from 'Services/fetchService.js';
import { nodePath } from 'Config/path.js';

import { IResponse } from 'CommonTypes/Tasks/Submissions/IResponse';

interface IArguments {
    taskId: number;
    submissionId: number;
}

export default async ({ taskId, submissionId }: IArguments) => {
    const result: IResponse = await fetchGet(`${nodePath}/tasks/submissions/?taskId=${taskId}&submissionId=${submissionId}`);

    return result;
};
