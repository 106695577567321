import React, { useCallback, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Button, Icon } from '@mui/material';

interface IProps {
    callbackChange: (taskNumber: string) => void;
}

function LinksInputTask({ callbackChange }: IProps) {
    const [taskNumber, setTaskNumber] = useState<string>('');

    const changeHandle = useCallback((
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const { value } = event.target;
        const newValue = !value?.length ? '' : (Number(value.replace(/\s/g, '')) || taskNumber);
        setTaskNumber(String(newValue));
    }, [taskNumber]);

    const handleClickButton = useCallback(() => {
        if (taskNumber.length) {
            callbackChange(taskNumber);
        }
        setTaskNumber('');
    }, [callbackChange, taskNumber]);

    return (
        <>
            <TextField
                size="small"
                value={taskNumber}
                label="Номер задачи"
                onChange={changeHandle}
                sx={{
                    width: '150px',
                }}
            />
            <Button variant="contained" onClick={handleClickButton} startIcon={<Icon><i className="fa fa-link" /></Icon>}>
                Связать задачу
            </Button>
        </>
    );
}

export default LinksInputTask;
