/* eslint-disable camelcase */
import defaultTasksConfig from 'Config/default/defaultTasksConfig';

import { IResponse } from 'CommonTypes/Tasks/QuickAddTaskForm/IResponse';

import getDataTaskBySubmissionId from 'Domain/Tasks/TaskMessageModal/services/getDataTaskBySubmissionId';
import getDefaultContact from './getDefaultContact';
import getDefaultProject from './getDefaultProject';
import getDefaultTaskType from './getDefaultTaskType';
import getDefaultAssignee from './getDefaultAssignee';
import getDefaultServiceClasse from './getDefaultServiceClasse';
import getDefaultObservers from './getDefaultObservers';
import { IFiles } from '../../types/IFiles';

export default async (
    data: IResponse,
    params: {
        data: { [key: string]: string | number; };
        parentItem?: { id: number; name: string; } | undefined;
    },
    dataFiles: [] | IFiles[],
) => {
    const {
        taskId, submissionId,
    } = params.data;

    const dataTask = await getDataTaskBySubmissionId({
        taskId: +taskId, submissionId: +submissionId,
    });

    const {
        owner, routeId, stateName, submissionType, taskTypeId, desc, name,
        department, it_service,
    } = dataTask;

    const contact = getDefaultContact(data.contacts, dataTask.contact);

    const serviceClasse = getDefaultServiceClasse(
        data.serviceClasses,
        defaultTasksConfig.taskMessage.serviceClassId,
    );

    const project = getDefaultProject(
        data,
        contact,
        dataTask.project,
    );

    const taskTypes = taskTypeId ? getDefaultTaskType(
        data.taskTypes,
        defaultTasksConfig.taskByInputMail.taskTypeId,
        project?.taskTypeId || taskTypeId,
    ) : null;

    const assignee = dataTask.assignee ? getDefaultAssignee(
        data.users,
        dataTask.assignee,
    ) : null;

    const observersId = dataTask?.observers?.map((el: string) => +el);
    const observers = observersId ? getDefaultObservers(data.contacts, observersId) : [];

    const filesUploader = dataFiles?.length ? dataFiles?.map(el => ({
        fileName: el.name,
        fileDescription: el.uploader_fileDescription ?? '',
        id: el.id.toString(),
        folder: el.folder,
        mime: el.mime,
        sname: el.sname,
    })) : [];

    return {
        taskName: name,
        contact,
        project,
        assignee,
        owner,
        routeId,
        stateName,
        submissionType,
        taskTypes,
        serviceClasse,
        observers,
        parentId: +taskId,
        filesUploader,
        desc,
        department,
        it_service,
    };
};
