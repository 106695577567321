import { filesSearchId, filesListId } from '../../../../../../../../../config/constants.js';

export default () => {
    const value = $$(filesSearchId).getValue().toLowerCase();
    if (value) {
        $$(filesListId).filter((data) => {
            const name = data.name.toLowerCase().includes(value);

            return name;
        });
    } else {
        $$(filesListId).filter();
    }
};
