import React from 'react';
import { Avatar } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import di from 'Services/di.js';

interface IOptions {
    id: number;
    value: string;
    projectsId: string;
    photo: string;
}

function CustomAvatar({ options }: {options: IOptions}) {
    const { value, photo } = options;
    const state = di.store.getState();
    const { root } = state.reducerBase;

    return (
        photo
            ? (
                <Avatar
                    alt={value}
                    src={`${root}/${photo}`}
                    sx={{
                        width: 25,
                        height: 25,
                    }}
                />
            )
            : (
                <Avatar
                    sx={{
                        width: 25,
                        height: 25,
                    }}
                >
                    <AccountCircle
                        sx={{
                            width: 25,
                            height: 25,
                        }}
                    />
                </Avatar>
            )
    );
}

export default CustomAvatar;
