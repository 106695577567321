import { filesListId } from '../../../../../../../../../config/constants.js';

export default () => {
    const file = $$(filesListId).getFirstId();
    if (!file) return false;

    document.querySelector('.attachviewer')?.remove();

    const thisNode = $$(filesListId).getItemNode(file);
    app.modules.use('attachviewer').then(() => {
        const arr = document.querySelectorAll('.fileAttach');
        thisNode.av = new AttachViewer(arr, {
            domain: 'my-pm.ru',
            close: new MIcon('close'),
            download: new MIcon('get_app'),
            show: new MIcon('launch'),
            next: new MIcon('keyboard_arrow_right'),
            prev: new MIcon('keyboard_arrow_left'),
            closable: true,
        });
    });

    return false;
};
