import { servicesId, formAddTaskId, servicesView } from 'fsrc/AddTaskWindow/config/constants.js';
import servicesLayout from './component/servicesPlaceholder/servicesLayout.js';

export default () => {
    const values = $$(formAddTaskId).getValues();
    const { taskId, project } = values;

    if (+taskId && project && !$$(servicesView)) {
        $$(servicesId).removeView('services__placeholder');
        $$(servicesId).addView(webix.copy(servicesLayout(+taskId, +project)));
    }
};