import { timeTrackingTableId } from '../../../../../../config/constants.js';
import editTimeTrack from './models/editTimeTrack.js';
import deleteTimeTrack from './models/deleteTimeTrack.js';

export default {
    id: timeTrackingTableId,
    view: 'datatable',
    select: 'row',
    resizeColumn: { size: 6 },
    footer: true,
    borderless: false,
    data: [],
    scheme: {
        $init: (obj) => {
            const parser = webix.Date.strToDate('%Y-%m-%d %H:%i:%s');
            obj.startDate = parser(obj.startDate);
            obj.endDate = parser(obj.endDate);

            return obj;
        },
    },
    columns: [
        { id: 'id', hidden: true },
        {
            id: 'userName',
            header: 'Пользователь',
            sort: 'string',
            fillspace: true,
            footer: {
                text: 'Итого:',
                colspan: 4,
                css: { 'text-align': 'right' },
            },
        },
        {
            id: 'comment',
            header: 'Комментарий',
            sort: 'string',
            fillspace: true,
        },
        {
            id: 'startDate',
            header: 'Начало',
            sort: 'date',
            format: webix.Date.dateToStr('%d.%m.%Y %H:%i:%s'),
            fillspace: true,
        },
        {
            id: 'endDate',
            header: 'Завершение',
            sort: 'date',
            format: webix.Date.dateToStr('%d.%m.%Y %H:%i:%s'),
            fillspace: true,
        },
        {
            id: 'duration',
            header: 'Продолжительность',
            sort: 'int',
            format: webix.i18n.numberFormat,
            template(obj) { return `${(obj.duration / 60).toFixed(2)} ч.`; },
            fillspace: true,
            footer: {
                content: 'summColumn',
                template(obj) {
                    return `${(+(obj.value.replace(',', '.').replace(' ', '')) / 60).toFixed(2)} ч.`;
                },
            },
        },
        {
            id: '',
            header: '',
            template: `<button class="btn btn-white btn-bitbucket editTimeTrack"><i class="webix_icon wxi-pencil"></i></button>
                <button class="btn btn-white btn-bitbucket deleteTimeTrack"><i class="webix_icon wxi-trash"></i></button>`,
            adjust: true,
        },
    ],
    onClick: {
        editTimeTrack,
        deleteTimeTrack,
    },
};
