import {
    commentsId,
    filesId,
    subTasksId,
    checkListId,
    requirementsId,
    timeManagementId,
    relatedTasksId,
    storyId,
    descriptionTaskId,
    servicesId,
} from 'fsrc/AddTaskWindow/config/constants.js';

import taskCommentsController from '../components/formAddTask/components/cellsBottomTabbar/components/taskComments/taskCommentsController.js';
import checklistController from '../components/formAddTask/components/cellsTopTabbar/checklistController.js';
import changeTabSubtasks from '../components/formAddTask/components/bottomTabbar/models/changeTabSubtasks.js';
import changeTabHistoryStates from '../components/formAddTask/components/bottomTabbar/models/changeTabHistoryStates.js';
import changeTabTimeTracks from '../components/formAddTask/components/bottomTabbar/models/changeTabTimeTracks.js';
import changeTabRelatedTasks from '../components/formAddTask/components/bottomTabbar/models/changeTabRelatedTasks.js';
import requirementsController from '../components/formAddTask/components/cellsTopTabbar/requirementsController.js';
import servicesController from '../components/formAddTask/components/cellsTopTabbar/servicesController.js';

export default {
    [checkListId]: checklistController,
    [requirementsId]: requirementsController,
    [servicesId]: servicesController,
    [commentsId]: taskCommentsController,
    [filesId]() {
        console.log('Стрельнула таба files'); // FIXME: Удалить этот console log
    },
    [subTasksId]: changeTabSubtasks,
    [timeManagementId]: changeTabTimeTracks,
    [relatedTasksId]: changeTabRelatedTasks,
    [storyId]: changeTabHistoryStates,
    [descriptionTaskId]() {},
};
