import {
    relatedTasksTableId,
    relatedTasksFilterStateBtnsId,
    relatedTasksFilterInworkId,
    relatedTasksFilterDraftId,
    relatedTasksFilterFinishId,
} from '../../../../../../../config/constants.js';

const cache = {
    historyClicked: '',
};

export default (id, ev) => {
    if (id === relatedTasksFilterInworkId) {
        $$(relatedTasksTableId).filter(obj => obj.stateStart !== 1 && obj.stateFinish !== 1);
    }

    if (id === relatedTasksFilterDraftId) {
        $$(relatedTasksTableId).filter(obj => obj.stateStart === 1);
    }

    if (id === relatedTasksFilterFinishId) {
        $$(relatedTasksTableId).filter(obj => obj.stateFinish === 1);
    }

    if (id === cache.historyClicked) {
        $$(relatedTasksFilterStateBtnsId).setValue('');
        $$(relatedTasksTableId).filter('stateId', '');
    }

    cache.historyClicked = id !== cache.historyClicked ? id : '';
};
