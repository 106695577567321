function progressStyleController(progress: number) {
    switch (true) {
    case progress >= 90: {
        return 'progress-bar-green';
    }
    case progress >= 50: {
        return 'progress-bar-info';
    }
    case progress >= 30: {
        return 'progress-bar-warning';
    }
    case progress < 30: {
        return 'progress-bar-red';
    }
    default: return '';
    }
}

export default progressStyleController;
