import { oldApi } from 'Config/path.js';
import { fetchGet } from 'Services/fetchService.js';

type TData = {
    contragentId: number | null,
    firmId: number | null,
}

type TResponse = {
    id: number;
    subject: string;
}

export default async ({ contragentId, firmId }: TData) => {
    const paramsStr: string[] = [];
    if (contragentId) {
        paramsStr.push(`id_contragent=${contragentId}`);
    }
    if (firmId) {
        paramsStr.push(`id_firm=${firmId}`);
    }

    const params = paramsStr.join('&');
    const response: TResponse[] = await fetchGet(`${oldApi}/outbox_load_by_project.php${params.length > 0 ? `?${params}` : ''}`);

    return response;
};
