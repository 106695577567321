import React, { StrictMode } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';

import theme from 'Lib/Mui/theme';
import StyledSnackbarProvider from 'Lib/Notistack/StyledSnackbarProvider';
import Action from 'Lib/Notistack/DefaultAction';
import di from 'Services/di.js';
import { injectReducer } from 'Utils/store/store';
import taskViewFormReducer from '../utils/taskViewFormReducer';
import ModalWrapper from '../ModalWrapper';

export interface IProps {
    taskId: number;
}

let rootContainer: Root;
let root: HTMLDivElement | undefined | null;

const openTask = async ({ taskId }: IProps) => {
    if (root) return;

    root = document.createElement('div');
    root.className = 'flex-full-height root-flex-column';
    rootContainer = createRoot(root);
    document.body.append(root);

    const { store } = di;
    injectReducer('taskViewFormReducer', taskViewFormReducer);

    rootContainer.render(
        <StrictMode>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <StyledSnackbarProvider
                        action={snackbarId => Action(snackbarId)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        hideIconVariant
                    >
                        <ModalWrapper taskId={taskId} />
                    </StyledSnackbarProvider>
                </Provider>
            </ThemeProvider>
        </StrictMode>,
    );
};
const closeTask = () => {
    rootContainer.unmount();
    if (root) root.remove();
    root = null;
};

export default () => {
    const { events } = di;
    events.on(
        'tasks__taskViewForm:openTask',
        openTask,
        false,
    );
    events.on(
        'tasks__taskViewForm:closeTask',
        closeTask,
        false,
    );
};
