export default (dataSend) => {
    app.fetch(`${root}/ajax/gmail/v3_send_for_task.php`, dataSend, 'POST')
        .then(((res) => {
            if (res && res.success) {
                app.fetch('ajax/gmail/outbox_queue.php?send')
                    .then((result) => {
                        webix.message({
                            text: result?.length
                                ? `Отправлено писем - ${result.length}`
                                : 'Не удалось отправить письма',
                            type: result?.length
                                ? 'success'
                                : 'error',
                        });
                    });
            }
        }));
};
