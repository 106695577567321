import emptySplitApi from 'Endpoints/emptySplitApi';
import IProjectSimpleSet from 'CommonTypes/Projects/IProjectSimpleSet';

export const projectsApi = emptySplitApi
    .injectEndpoints({
        endpoints: build => ({

            getProjectsSimpleSet: build.query<IProjectSimpleSet[], void>({
                query: () => ({
                    url: 'projects',
                }),
            }),

            getSaleProjectsSimpleSet: build.query<IProjectSimpleSet[], void>({
                query: () => ({
                    url: 'projects?filter=sale',
                }),
            }),

        }),
    });

export const {
    useGetProjectsSimpleSetQuery,
    useGetSaleProjectsSimpleSetQuery,
} = projectsApi;
