import {
    relatedTasksId,
    bottomTabbarId,
    relatedTasksTableId,
    formAddTaskId,
} from '../../../../../config/constants.js';
import getRelatedTasks from '../services/getRelatedTasks.js';
import runLoaderBar from '../../../../../utils/runLoaderBar.js';
import stopLoaderBar from '../../../../../utils/stopLoaderBar.js';

export default () => {
    const values = $$(formAddTaskId).getValues();
    const { taskId } = values;

    if (!+taskId) return;

    runLoaderBar(relatedTasksTableId);
    getRelatedTasks(+taskId)
        .then((res) => {
            stopLoaderBar(relatedTasksTableId);

            $$(relatedTasksTableId).parse(res, '', true);
            $$(bottomTabbarId).getOption(relatedTasksId).value = `Связанные задачи (${res.length})`;
        });
};
