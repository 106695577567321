import { createSlice } from '@reduxjs/toolkit';
import { IRolesMatrixProjectsSlice } from '../types/RolesMatrixTypes';

const initialState: IRolesMatrixProjectsSlice = {
    projectId: 0,
    userId: 0,
    confirmDeleteWindowOpen: false,
    projectIdForDelete: 0,
    userIdForDelete: 0,
};

export const rolesMatrixProjectsSlice = createSlice({
    name: 'ROLES_MATRIX_PROJECTS',
    initialState,
    reducers: {
        setProject(state, action) {
            state.projectId = action.payload;
        },
        setUser(state, action) {
            state.userId = action.payload;
        },
        openConfirmDeleteWindow(state, action) {
            state.confirmDeleteWindowOpen = true;
            state.projectIdForDelete = action.payload.projectId;
            state.userIdForDelete = action.payload.userId;
        },
        closeConfirmDeleteWindow(state) {
            state.confirmDeleteWindowOpen = false;
            state.projectIdForDelete = 0;
            state.userIdForDelete = 0;
        },
    },
});

export const {
    setProject,
    setUser,
    openConfirmDeleteWindow,
    closeConfirmDeleteWindow,
} = rolesMatrixProjectsSlice.actions;

export default rolesMatrixProjectsSlice.reducer;
