import React from 'react';
import Box from '@mui/material/Box';
import { Checkbox } from 'Ui/Form/Checkbox';
import { DatePicker } from 'Ui/Form/DatePicker';

function EventsCopyDatesSection() {
    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', minWidth: '420px' }}>
                <Checkbox
                    name="dayMon"
                    label="Пн"
                />
                <Checkbox
                    name="dayTue"
                    label="Вт"
                />
                <Checkbox
                    name="dayWed"
                    label="Ср"
                />
                <Checkbox
                    name="dayThu"
                    label="Чт"
                />
                <Checkbox
                    name="dayFri"
                    label="Пт"
                />
                <Checkbox
                    name="daySat"
                    label="Сб"
                />
                <Checkbox
                    name="daySun"
                    label="Вс"
                />
            </Box>
            <DatePicker
                name="copyDateTo"
                label="Дата до"
            />
        </Box>
    );
}

export default EventsCopyDatesSection;
