import React, {
    useRef,
    useEffect,
    useCallback,
} from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import useEnqueueSnackbar from 'Lib/Notistack/useEnqueueSnackbar';
import { IClassicEditor } from 'Ui/Editor/types/ICKEditorClassic';
import Editor from 'Ui/Editor';
import getDescription from './services/getDescription';
import updateDescription from './services/updateDescription';

type TProps = {
    outboxId: number,
}

function DescriptionFieldComponent({ outboxId }: TProps) {
    const { success, error } = useEnqueueSnackbar();
    const editorRef = useRef<IClassicEditor | null>(null);

    const handleButtonSaveClick = useCallback(() => {
        const newDescription = editorRef.current?.getData() || '';
        updateDescription({ outboxId, description: newDescription }).then((response) => {
            if (response?.success) {
                success('Описание успешно обновлено!');
            } else {
                error('Во время обновления описания произошла ошибка!');
            }
        });
    }, [error, outboxId, success]);

    useEffect(() => {
        getDescription({ outboxId }).then((result) => {
            if (result && result?.success) {
                editorRef.current?.setData(result.description || '');
            }
        });
    }, [outboxId]);

    return (
        <>
            <Grid container>
                <Grid item xs><h2>Реализация требований</h2></Grid>
                <Grid item xs>
                    <Button
                        variant="contained"
                        size="wm"
                        onClick={handleButtonSaveClick}
                    >
                        Сохранить
                    </Button>
                </Grid>
            </Grid>
            <div style={{ display: 'flex', marginTop: '15px' }}>
                <Editor
                    value={String(outboxId)}
                    onReady={(editor) => {
                        editorRef.current = editor;
                    }}
                />
            </div>
        </>
    );
}

export default DescriptionFieldComponent;
