import { TTypeTask } from '../types/TTypeTask';

type TSection = 'mainSection' | 'descriptionSection' | 'criterionSection' | 'filesSection';
type TPropertyType = 'hidden' | 'expanded';

export default (typeTask: TTypeTask, section: TSection, propertyType: TPropertyType) => {
    const configuration = {
        task: {
            mainSection: {
                hidden: false,
                expanded: true,
            },
            descriptionSection: {
                hidden: false,
                expanded: false,
            },
            criterionSection: {
                hidden: false,
                expanded: false,
            },
            filesSection: {
                hidden: false,
                expanded: false,
            },
        },
        issue: {
            mainSection: {
                hidden: true,
                expanded: false,
            },
            descriptionSection: {
                hidden: false,
                expanded: true,
            },
            criterionSection: {
                hidden: false,
                expanded: false,
            },
            filesSection: {
                hidden: false,
                expanded: false,
            },
        },
        taskByInputMail: {
            mainSection: {
                hidden: false,
                expanded: true,
            },
            descriptionSection: {
                hidden: false,
                expanded: false,
            },
            criterionSection: {
                hidden: false,
                expanded: false,
            },
            filesSection: {
                hidden: false,
                expanded: false,
            },
        },
        submissionTask: {
            mainSection: {
                hidden: false,
                expanded: true,
            },
            descriptionSection: {
                hidden: false,
                expanded: false,
            },
            criterionSection: {
                hidden: false,
                expanded: false,
            },
            filesSection: {
                hidden: false,
                expanded: false,
            },
        },
        subTask: {
            mainSection: {
                hidden: false,
                expanded: true,
            },
            descriptionSection: {
                hidden: false,
                expanded: false,
            },
            criterionSection: {
                hidden: false,
                expanded: false,
            },
            filesSection: {
                hidden: false,
                expanded: false,
            },
        },
        taskMessage: {
            mainSection: {
                hidden: false,
                expanded: true,
            },
            descriptionSection: {
                hidden: false,
                expanded: false,
            },
            criterionSection: {
                hidden: false,
                expanded: false,
            },
            filesSection: {
                hidden: false,
                expanded: false,
            },
        },
    };
    const config = configuration[typeTask][section];

    return config[propertyType];
};
