import { subtasksTableId } from '../config/constants';

const showTaskInfo = async (id, e, node) => {
    $$(subtasksTableId).isModalOpen = true;
    await app.modules.use('app.tasks.quickView');
    app.tasks.quickView(+id, () => {
        loader.killReact('reactliveel');
    });
};

export default showTaskInfo;
