import { ISubmissionsSimpleSet, ISubmissionsSimpleSetRequest } from 'CommonTypes/Tasks/Submissions/SubmissionsTask';
import emptySplitApi from 'Endpoints/emptySplitApi';

export const tasksSubmissionsApi = emptySplitApi.injectEndpoints({
    endpoints: build => ({

        getSubmissionsTaskTemplatesSimpleSet: build.query<
            ISubmissionsSimpleSet[],
            ISubmissionsSimpleSetRequest
        >({
            query: params => ({
                url: 'tasks/submissions/tasks-template',
                params,
            }),
        }),

    }),
});

export const {
    useGetSubmissionsTaskTemplatesSimpleSetQuery,
} = tasksSubmissionsApi;
