import { toStrDateTime } from 'Domain/Common/dateFormat.js';

export default (dataTask) => {
    const { taskId } = dataTask;
    const dateCreate = toStrDateTime(dataTask.dateCreate);
    const dateUpdate = toStrDateTime(dataTask.dateUpdateTaskId);
    const stateTask = dataTask.taskStatusId;
    const taskInfoId = {};
    taskInfoId.taskId = taskId;
    taskInfoId.dateCreate = dateCreate;
    taskInfoId.dateUpdate = dateUpdate;
    taskInfoId.stateTask = stateTask;
    const data = dataTask;
    data.taskInfoId = taskInfoId;
    data.currentContact = { id: dataTask.contact, value: dataTask.contactValue };
    data.currentObservers = data.observersValues.split(',').reduce((acc, el) => {
        const separator = el.indexOf('~');
        const id = el.slice(0, separator);
        const value = el.slice(separator + 1);
        acc.push({ id, value });

        return acc;
    }, []);

    return data;
};
