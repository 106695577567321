import { nodePath } from 'Config/path.js';
import { checklistView } from '../../../../../config/constants.js';
import completeChecklist from './utils/completeChecklist.js';
import checklistButtons from './template/checklistButtons.hbs';
import deleteChecklist from './utils/deleteChecklist.js';
import updateChecklistService from './services/updateChecklistService.js';
import updateOrderService from './services/updateOrderService.js';
import getToolbarView from './toolbar/toolbarView.js';
import './assets/checklistTable.original.scss';

export default (taskId, project) => ({
    rows: [
        getToolbarView(taskId),
        {
            css: 'webix_header_border',
            id: checklistView,
            view: 'datatable',
            select: 'row',
            headermenu: { width: 170 },
            drag: true,
            dragColumn: 'order',
            resizeColumn: { size: 6 },
            url: `${nodePath}/checklist/all-checklist?taskId=${taskId}`,
            scheme: {
                $init: (obj) => {
                    const parser = webix.Date.strToDate('%Y-%m-%d');
                    obj.date = parser(obj.date);

                    return obj;
                },
            },
            editable: true,
            editaction: 'dblclick',
            hover: 'myhover',
            columns: [
                // {
                //     id: 'idSort',
                //     header: [
                //         { text: '№', content: 'excelFilter', mode: 'text' },
                //     ],
                //     adjust: true,
                // },
                {
                    id: 'name',
                    header: [
                        {
                            text: 'Наименование',
                            content: 'excelFilter',
                            mode: 'text',
                        },
                    ],
                    fillspace: true,
                    editor: 'text',
                },
                {
                    id: 'date',
                    header: [
                        { text: 'Срок', content: 'excelFilter', mode: 'date' },
                    ],
                    sort: 'date',
                    format: webix.Date.dateToStr('%d.%m.%Y'),
                    adjust: true,
                    editor: 'date',
                },
                {
                    id: 'assignee',
                    header: [
                        {
                            text: 'Исполнитель',
                            content: 'excelFilter',
                            mode: 'text',
                        },
                    ],
                    template(obj, common, value, config) {
                        if (!config.collection.getItem(obj.assignee)) {
                            return '';
                        }

                        return config.collection.getItem(obj.assignee).name;
                    },
                    collection: `${nodePath}/user/all-user-by-project-id?projectId=${project}`,
                    editor: 'combo',
                    suggest: {
                        template: '#name#',
                        filter(item, value) {
                            return item.name.toString().toLowerCase().includes(value.toLowerCase());
                        },
                        body: {
                            template: '#name#',
                        },
                    },
                },
                {
                    id: 'button',
                    header: '',
                    width: 75,
                    tooltip: true,
                    template: checklistButtons,
                },
            ],
            on: {
                onAfterLoad() {
                    const data = $$(checklistView).serialize();
                    data.forEach((item) => {
                        if (item.finished === 1) {
                            $$(checklistView).addCellCss(item.id, 'name', 'complete-task');
                        }
                    });
                },
                onAfterEditStop(state, editor) {
                    const rowId = editor.row;
                    const data = $$(checklistView).getItem(rowId);
                    const format = webix.Date.dateToStr('%Y-%m-%d');
                    const date = format(data.date);
                    const userId = data.assignee ? data.assignee : null;

                    const obj = {
                        id: data.id,
                        name: data.name,
                        date,
                        userId,
                    };
                    updateChecklistService(obj);
                },
                onAfterDrop() {
                    const arr = $$(checklistView).serialize();
                    const data = arr.map(item => item.id);
                    const newData = { data };
                    updateOrderService(newData);
                },
            },
            onClick: {
                'complete-checklist': completeChecklist,
                'remove-checklist': deleteChecklist,
            },
        },
    ],
});
