import { toolbarUploaderId, filesSearchId, filesListId } from '../../../../../../../../config/constants.js';
import uploaderView from './components/uploader/uploaderView.js';
import showSearchMenu from './models/showSearchMenu.js';
import findFile from './models/findFile.js';
import returnFullFilesList from './models/returnFullFilesList.js';

export default () => ({
    id: toolbarUploaderId,
    view: 'toolbar',
    elements: [
        {
            cols: [
                uploaderView(),
                {},
                {
                    id: filesSearchId,
                    view: 'text',
                    placeholder: 'Искать...',
                    width: 400,
                    hidden: true,
                    clear: true,
                    keyPressTimeout: 500,
                    on: {
                        onTimedKeyPress: findFile,
                        onChange() {
                            returnFullFilesList();
                        },
                    },
                },
                {
                    view: 'button',
                    width: 45,
                    tooltip: 'Найти файл',
                    label: '<span class="fa fa-search">',
                    click: showSearchMenu,
                },
                {
                    view: 'button',
                    width: 45,
                    tooltip: 'Экспорт в Excel',
                    label: '<span class="fa fa-file-excel-o">',
                    click: () => webix.toExcel($$(filesListId)),
                },
            ],
        },
    ],
});
