import toolbar from './toolbar/toolbar.js';
import listTasks from './listTasks/listTasks.js';
import listFavorites from './listFavorites/listFavorites.js';
import handlerItemClick from './tabbar/utils/handlerTabClick.js';
import './assets/scss/sidemenu.orig.css';

export default function init() {
    webix.ui({
        view: 'sidemenu',
        id: 'menu',
        width: 325,
        zIndex: 102,
        position: 'right',
        css: 'my_menu',
        state(state) {
            state.top = 60;
            state.height -= 60;
        },
        body: {
            rows: [
                {
                    view: 'template',
                    css: 'accordeon__header',
                    id: 'accordeon',
                    height: 25,
                    template: '<span class="fa fa-close closeWindow"></span>',
                    onClick: {
                        closeWindow() {
                            $$('menu').hide();
                        },
                    },
                },
                {
                    id: 'accordion',
                    css: 'sidemenu__accordion',
                    view: 'accordion',
                    multi: true,
                    rows: [
                        {
                            id: 'tasks',
                            view: 'accordionitem',
                            header: 'Шаблоны',
                            headerHeight: 50,
                            body: listTasks,
                        },
                        {
                            id: 'favorites',
                            view: 'accordionitem',
                            header: 'Избранные',
                            body: listFavorites,
                        },
                    ],
                },
                toolbar,
            ],
        },
        on: {
            onBeforeShow: handlerItemClick,
        },
    });
}
