import { nodePath } from 'Config/path.js';

const resetHistory = async (taskId) => {
    const data = await webix.ajax()
        .del(`${nodePath}/tasks/resetHistoryStates?taskId=${taskId}`)
        .then(res => res.json());

    return data;
};

export default resetHistory;
