interface IState {
    height: number;
    left: number;
    maxHeight: number;
    maxWidth: number;
    top: number;
    width: number;
}

export default (...currentState: [IState]) => {
    const [state] = currentState;
    const pageContent = document.getElementById('pageContent');
    if (pageContent) {
        const parentPageContent = pageContent.offsetParent as HTMLElement;
        const parentPageContentOffsetTop = parentPageContent.offsetTop;
        const PageContentOffsetTop = pageContent.offsetTop;
        const offsetTop = parentPageContentOffsetTop + PageContentOffsetTop;
        state.top = offsetTop;
        return state;
    }
    return 'top';
};
