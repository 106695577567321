import { fetchGet } from 'Services/fetchService.js';
import { nodePath } from 'Config/path.js';
import { ICurrentFormConfig } from '../types/ICurrentFormConfig';
import { IFormConroller } from '../types/IFormConroller';

export default async (
    formId: keyof IFormConroller,
    isUser: boolean,
    typeTaskId: number,
): Promise<ICurrentFormConfig | null> => {
    const response: ICurrentFormConfig | null = await fetchGet(`${nodePath}/form/get-current-config?formId=${formId}&user=${+isUser}&typeTaskId=${typeTaskId}`);

    return response;
};
