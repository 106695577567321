import { windowAddHistoryItemId, historyStatesTableId } from '../../../../../../../config/constants.js';
import windowAddHistoryItem from '../../windowAddHistoryItem/windowAddHistoryItem.js';

export default () => {
    const statesList = $$(historyStatesTableId).getColumnConfig('stateId')
        .collection.serialize();
    const data = {
        states: statesList,
    };

    webix.ui(windowAddHistoryItem(data));
    $$(windowAddHistoryItemId).show();
};
