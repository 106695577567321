import React from 'react';
import IconButton from '@mui/material/IconButton';

import classes from '../../assets/style.scss';
import SettingsButtons from '../SettingsButtons/SettingsButtons';

interface IHeader {
    open: boolean;
    callback: () => void;
    data: any;
}

function Header({ open, callback, data }: IHeader) {
    return (
        <div className={classes.header}>
            <IconButton size="small" sx={{ fontSize: '30px' }}>
                <i className="fa fa-play-circle-o" />
            </IconButton>
            <div className={classes.header_btns}>
                <div className={classes.header_float}>
                    <SettingsButtons open={open} callback={callback} />
                </div>
                <h2 className={classes.header_title}>
                    #
                    {data.id}
                    {' | '}
                    {data.wsId}
                    {' | '}
                    {data.name}
                </h2>
            </div>
        </div>
    );
}

export default Header;
