import React from 'react';
import { Confirm } from 'Ui/Modal';

interface IProps {
    title: string;
    describe: string;
    closeHandle: (result: boolean) => void;
}

function ModalConfirm({ closeHandle, title, describe }: IProps) {
    return (
        <Confirm
            open
            title={title}
            onClose={closeHandle}
        >
            <p>
                <strong>Предупреждение!</strong>
                <br />
                { describe }
            </p>
        </Confirm>
    );
}

export default ModalConfirm;
