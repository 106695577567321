import { nodePath } from 'Config/path.js';
import { fetchPost } from 'Services/fetchService.js';
import { ITagAddRequest, ITagAddResponse } from 'CommonTypes/Tags/TagsSimpleSet';

export default async ({ tagsName }: Omit<ITagAddRequest, 'moduleId'>) => {
    const params: ITagAddRequest = { tagsName, moduleId: 3 };

    const response = await fetchPost(`${nodePath}/tags`, params) as ITagAddResponse;
    return response;
};
