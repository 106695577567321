import React, {
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import container from 'Services/container';
import { IClassicEditor } from 'Ui/Editor/types/ICKEditorClassic';
import Editor from 'Ui/Editor';
import { Button } from 'Ui/Button';
import { Confirm } from 'Ui/Modal';
import useEnqueueSnackbar from 'Lib/Notistack/useEnqueueSnackbar';

import { TTemplates } from 'CommonTypes/DocsTemplates/DocsTemplate';
import printElement from 'Domain/Documents/models/printElement';
import updateDocument from './services/updateDocument';
import getStartData from './services/getStartData';
import fillTemplate from './services/fillTemplate';
import createPdfFile from './services/createPdfFile';

type TProps = {
    outboxId: number,
}

function DocEditForm(props: TProps) {
    const { outboxId } = props;
    const { events } = container;
    const { success, error } = useEnqueueSnackbar();
    const [templateId, setTemplateId] = useState<string>('');
    const [templates, setTemplates] = useState<TTemplates>([]);
    const [dateUpdate, setDateUpdate] = useState<boolean>(false);
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
    const editorRef = useRef<IClassicEditor | null>(null);

    const handleSelectChange = (event: SelectChangeEvent) => {
        setTemplateId(event.target.value as string);
    };

    const handleSubmit = () => {
        const currentDocument = editorRef.current?.getData();
        updateDocument({
            outboxId, templateId: +templateId, document: currentDocument || '',
        }).then((response) => {
            if (response.success) {
                setDateUpdate(true);
                success('Документ успешно изменён');
            }
        });
    };

    const fillTemplateFunc = () => {
        fillTemplate({ outboxId: +outboxId, templateId: +templateId }).then((response) => {
            if (response?.success) {
                editorRef.current?.setData(response?.doc || '');
                success('Документ успешно сформирован');
                setDateUpdate(false);
            }
        });
    };

    const handleFillClick = () => {
        if (!dateUpdate) {
            fillTemplateFunc();
        } else {
            setConfirmOpen(true);
        }
    };

    const handlePrintDocument = () => {
        const html = editorRef.current?.getData() || '';
        printElement(html);
    };

    const handleCreatePdfClick = () => {
        createPdfFile({ outboxId: +outboxId, templateId: +templateId })
            .then((pdfResponse) => {
                if (pdfResponse?.success) {
                    success('Файл успешно сформирован');
                    events.emit('docsTemplates__docInOutboxEdit:pdfIsCreated', { ...pdfResponse });
                } else {
                    error('Возникла ошибка во время формирования файла');
                }
            });
    };

    const handleConfirmClose = (result: boolean) => {
        if (result) {
            fillTemplateFunc();
        }

        setConfirmOpen(false);
    };

    const handleLinkButtonClick = useCallback(() => {
        window.open(`//${window.location.host}/templates/edit/${templateId}`, '_blank', 'noreferrer');
    }, [templateId]);

    useEffect(() => {
        getStartData(outboxId).then((result) => {
            if (result?.templatesList) {
                setTemplates(result.templatesList);
            }
            if (result?.document?.templateId > 0) {
                setTemplateId(result.document.templateId.toString());
            }
            if (result?.document?.dateUpdate) {
                setDateUpdate(true);
            }
            editorRef.current?.setData(result?.document?.document || '');
        });
    }, [outboxId]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item sx={{ flex: 1 }}>
                    <FormControl fullWidth>
                        <InputLabel id="docs-edit-select-label">Шаблон документа</InputLabel>
                        <Select
                            size="small"
                            labelId="docs-edit-select-label"
                            id="docs-edit-templates-select"
                            value={templateId}
                            label="Шаблон документа"
                            onChange={handleSelectChange}
                        >
                            {
                                templates.map(
                                    tmpl => (
                                        <MenuItem key={tmpl.id} value={tmpl.id}>
                                            {tmpl.name}
                                        </MenuItem>
                                    ),
                                )
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid
                    item
                    sx={{
                        display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'flex-end',
                    }}
                >
                    <Tooltip title="Открыть Шаблон">
                        <IconButton onClick={handleLinkButtonClick}>
                            <i className="fa fa-external-link" />
                        </IconButton>
                    </Tooltip>
                    <Button onClick={handleFillClick} color="warning" size="w-m">Сформировать</Button>
                    <Button onClick={handleSubmit} color="primary" size="w-m">Сохранить</Button>
                    <Button onClick={handleCreatePdfClick} color="info" size="w-m">Сохранить в PDF</Button>
                    <Button onClick={handlePrintDocument} color="info" size="w-m">Печать</Button>
                </Grid>
            </Grid>
            <div style={{ display: 'flex', marginTop: '15px' }}>
                <Editor
                    value=""
                    onReady={(editor) => {
                        editorRef.current = editor;
                    }}
                />
            </div>
            <Confirm
                open={confirmOpen}
                title="Сформировать заново?"
                onClose={handleConfirmClose}
            >
                <p>
                    <strong>Предупреждение!</strong>
                    <br />
                    Документ был изменён, Сформировать заново?
                </p>
            </Confirm>
        </>
    );
}

export default DocEditForm;
