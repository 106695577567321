/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useCallback } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import container from 'Services/container';

import { IFirmsResponse } from 'CommonTypes/Firms/Firms';
import getFirmsOptionsService from 'fsrc/Sales/services/getFirmsOptionsService';
import setFirmToTaskService from 'fsrc/Sales/services/setFirmToTaskService';

type TProps = {
    taskId: number,
    firmId: number,
    onFirmChangeHandle: (firmId: number | null) => void,
}

function FirmSelect({ taskId, firmId, onFirmChangeHandle }: TProps) {
    const { events } = container;
    const [options, setOptions] = useState<IFirmsResponse[]>([]);
    const [value, setValue] = useState<IFirmsResponse | null>(null);

    const onChangeHandle = (
        e: React.SyntheticEvent<Element, Event>,
        newValue: IFirmsResponse | null,
    ) => {
        setValue(newValue);
        setFirmToTaskService({ taskId, contragentId: newValue?.id || 0 }).then(() => {
            events.emit('sales__formInOldSalesForm:changeFirm');
        });
        onFirmChangeHandle(newValue?.id || null);
    };

    const onClickLinkHandle = useCallback(() => {
        window.open(`//${window.location.host}/contragents/${value?.id || 0}`, '_blank', 'noreferrer');
    }, [value?.id]);

    useEffect(() => {
        getFirmsOptionsService().then(result => setOptions(result));
    }, []);

    useEffect(() => {
        const currentValue = options.find(firm => +firm.id === firmId) || null;
        if (currentValue) {
            setValue(currentValue);
        }
    }, [firmId, options]);

    return (
        <Autocomplete
            size="small"
            options={options}
            value={value}
            onChange={onChangeHandle}
            blurOnSelect
            handleHomeEndKeys={false}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Фирма"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {Boolean(value) && (
                                    <InputAdornment position="end">
                                        <Tooltip title="Открыть Фирму">
                                            <IconButton onClick={onClickLinkHandle}>
                                                <i className="fa fa-external-link" />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            getOptionLabel={option => option.fullname}
        />
    );
}

export default FirmSelect;
