import IServiceFunctionsFormResponse from 'CommonTypes/ITServices/IServiceFunctionsFormResponse';

export default (data: IServiceFunctionsFormResponse) => {
    const {
        serviceId,
        services: servicesArray,
        functions: functionsArray,
        functionsIds,
        subprojects,
        subprojectsIds,
        serviceClass,
        serviceClasses,
        planDuration,
    } = data;
    const services = servicesArray.find(value => serviceId === value.id);
    const functions = functionsArray.filter(value => functionsIds.includes(value.id));
    const currentSubprojects = subprojects.filter(value => subprojectsIds.includes(value.id));
    const currentServiceClass = serviceClasses.find(
        value => +serviceClass.serviceClassId === +value.id,
    );

    return {
        services,
        functions,
        subprojects: currentSubprojects,
        serviceClass: currentServiceClass,
        planDuration,
    };
};
