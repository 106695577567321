import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import di from 'Services/di.js';

import defaultTasksConfig from 'Config/default/defaultTasksConfig';

import { IResponse } from 'CommonTypes/Tasks/QuickAddTaskForm/IResponse';

import getDefaultContact from './getDefaultContact';
import getDefaultServiceClasse from './getDefaultServiceClasse';
import getDefaultTaskType from './getDefaultTaskType';
import getDefaultAssignee from './getDefaultAssignee';
import getDefaultProject from './getDefaultProject';

export default (
    data: IResponse,
    params: {
        data: { [key: string]: string | number; };
        parentItem?: { id: number; name: string; } | undefined;
    } | undefined,
) => {
    const { store } = di;
    const { reducerUser } = store.getState();
    const { contactId, taskTypeId, id } = reducerUser;

    const contact = getDefaultContact(data.contacts, contactId);

    const taskName = `Предложение или замечание ${(new Date().toLocaleString())}`;
    const bugInfo = params;

    const serviceClasse = getDefaultServiceClasse(
        data.serviceClasses,
        defaultTasksConfig.issue.serviceClassId,
    );

    const assignee = getDefaultAssignee(
        data.users,
        id,
        defaultTasksConfig.issue.assignee,
    );

    const project = getDefaultProject(
        data,
        contact,
        defaultTasksConfig.issue.project,
    );

    const taskTypes = getDefaultTaskType(
        data.taskTypes,
        taskTypeId,
        project?.taskTypeId || defaultTasksConfig.issue.taskTypeId,
    );

    const { bug } = defaultTasksConfig.issue;

    const deadline = dayjs().endOf('day');

    return {
        contact,
        serviceClasse,
        taskTypes,
        assignee,
        project,
        taskName,
        bugInfo,
        bug,
        deadline,
    };
};
