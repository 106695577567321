import bugButton from './buttons/bugButton.js';
import wikiButton from './buttons/wikiButton/wikiButton.js';
import downloadMenu from './buttons/downloadMenu/downloadMenu.js';
import './buttons/assets/btnStyles.orig.scss';
import saveConfig from './buttons/saveColumnConfig/saveColumnConfig';

export default ({
    bugInfoSet, downloadSet, saveColumnConfig,
} = { bugInfoSet: {}, downloadSet: undefined, saveColumnConfig: null }) => ([
    {
        css: { width: 'auto !important' },
        margin: 5,
        cols: [
            saveConfig(saveColumnConfig),
            downloadMenu(downloadSet),
            bugButton(bugInfoSet),
            wikiButton(),
        ],
    },
]);
