import React, { ChangeEvent, HTMLInputTypeAttribute } from 'react';
import { SxProps } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import {
    useController, UseControllerReturn, useFormContext, RegisterOptions, FieldValues,
} from 'react-hook-form';
import { InputBaseComponentProps } from '@mui/material';

interface IProps {
    name: string;
    type?: HTMLInputTypeAttribute;
    sx?: SxProps,
    callback?: (newValue: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onKeyUp?: (e: React.KeyboardEvent) => void;
    label?: string;
    placeholder?: string;
    multiline?: boolean;
    rows?: number;
    autoFocus?: boolean;
    size?: 'small' | 'medium' | undefined;
    fullWidth?: boolean;
    required?: Omit<RegisterOptions<FieldValues>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    inputProps?: InputBaseComponentProps;
}

function TextFieldRHF(props: IProps) {
    const {
        name, sx = {}, callback, onKeyUp, label, placeholder, required, multiline = false, rows = 1,
        autoFocus = false, size = 'small', fullWidth = false, inputProps, type,
    } = props;
    const { control } = useFormContext();
    const controller: UseControllerReturn = useController({
        name,
        control,
        rules: required,
    });

    return (
        <TextField
            variant="outlined"
            type={type}
            sx={sx}
            size={size}
            fullWidth={fullWidth}
            placeholder={placeholder}
            label={label}
            rows={rows}
            autoComplete="off"
            multiline={multiline}
            name={controller.field.name}
            onChange={e => (callback
                ? callback(e)
                : controller.field.onChange(e.target.value))}
            onBlur={controller.field.onBlur}
            ref={controller.field.ref}
            value={controller.field.value}
            error={!!controller.fieldState.error}
            helperText={controller.fieldState.error?.message}
            autoFocus={autoFocus}
            onKeyUp={(e) => {
                if (onKeyUp) {
                    onKeyUp(e);
                }
            }}
            inputProps={inputProps}
        />
    );
}

export default TextFieldRHF;
