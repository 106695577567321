import invalidMessage from './invalidMessage.js';

export default (newValue, oldValue, config, id) => {
    if (config) {
        const currentNewValueId = Array.isArray(newValue) ? newValue.pop() : newValue;
        if (currentNewValueId?.includes('c')) {
            $$(id).setValue(oldValue);
            invalidMessage(currentNewValueId, id);
        }
    }
};
