import { fetchPost } from 'Services/fetchService.js';
import { nodePath } from 'Config/path.js';

interface IData {
    taskId: number;
    companyId: number;
}

export default async (data: IData) => {
    const response: boolean = await fetchPost(`${nodePath}/tasks/companies/`, data);

    return response;
};
