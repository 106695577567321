import { RootState } from 'Utils/store/store';
import { createSelector } from '@reduxjs/toolkit';

import { planDayApi } from 'Endpoints/PlanDay/planDay.api';

import { IState } from '../types/IState';

type State = RootState & IState;

export const getDate = (state: State) => state.planDayReducer.date;

export const getCurrentSubordinate = (state: State) => state.planDayReducer.currentSubordinate;

export const getCurrentUserId = (state: State) => state.reducerUser.id;
export const getCurrentProjectId = (state: State) => state.planDayReducer.projectId;

export const amI = createSelector(
    [getCurrentUserId, getCurrentSubordinate],
    (currentUserId, currentChangeValue) => currentUserId === currentChangeValue.subordinateId,
);

export const isEditing = (state: State) => state.planDayReducer.eventEditing.editing;
export const getEditingData = (state: State) => state.planDayReducer.eventEditing;
export const getEventId = (state: State) => state
    .planDayReducer.eventEditing.eventData?.schedulerId || 0;

export const getTasks = createSelector(
    [
        getCurrentSubordinate,
        state => state,
    ],
    ({ subordinateId }, state) => planDayApi.endpoints.getTasksByAssigneeId
        .select(subordinateId)(state).data,
);
