import { IDataForm } from '../types/IDataForm';

export interface IRequiredFormFields {
    subdivisionIsRequired: 0 | 1;
    itserviceIsRequired: 0 | 1;
    functionsIsRequired: 0 | 1;
}

export interface IReducer {
    type: 'open' | 'updateDataForm' | 'updateRuqired' | 'suspend' | 'openConfirm' | 'changeViewMiniForm' | 'changeWidthLeftSide';
    payload: IDataForm | IRequiredFormFields | boolean | number;
}

export interface IState {
    open: boolean;
    dataForm: IDataForm;
    suspend: boolean;
    openConfirm: boolean;
    newViewMiniForm: boolean;
    widthLeftSide: number | undefined;
}

const reducer = (state: IState, action: IReducer) => {
    const { payload } = action;
    const currentState = { ...state };

    switch (action.type) {
    case 'updateDataForm':
        currentState.dataForm = payload as IDataForm;
        break;

    case 'updateRuqired':
        currentState.dataForm = { ...currentState.dataForm, ...payload as IRequiredFormFields };
        break;

    case 'open':
        currentState.open = payload as boolean;
        break;

    case 'suspend':
        currentState.suspend = payload as boolean;
        break;

    case 'openConfirm':
        currentState.openConfirm = payload as boolean;
        break;

    case 'changeViewMiniForm':
        currentState.newViewMiniForm = payload as boolean;
        break;

    case 'changeWidthLeftSide':
        currentState.widthLeftSide = payload as number;
        break;

    default:
        break;
    }

    return currentState;
};

export default reducer;
