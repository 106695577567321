export async function fetchPost(url, data) {
    try {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const result = await response.json();
        return result;
    } catch (error) {
        alert('На сервере возникла ошибка: ' + error);
    }
}

export async function fetchPut(url, data) {
    try {
        const response = await fetch(url, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const result = await response.json();
        return result;
    } catch (error) {
        alert('На сервере возникла ошибка: ' + error);
    }

    return null;
}

export async function fetchGet(url) {
    try {
        const response = await fetch(url);
        const result = await response.json();
        return result;
    } catch (error) {
        alert('На сервере возникла ошибка: ' + error);
    }
}

export async function fetchDelete(url) {
    try {
        const response = await fetch(url, {
            method: 'DELETE',
        });
        const result = await response.json();
        return result;
    } catch (error) {
        alert('На сервере возникла ошибка: ' + error);
    }

    return null;
}

export async function fetchPatch(url, data) {
    try {
        const response = await fetch(url, {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const result = await response.json();
        return result;
    } catch (error) {
        alert('На сервере возникла ошибка: ' + error);
    }

    return null;
}

export function parseParamsArray(paramName, params) {
    const result = params.reduce((acc, value) => `${acc}${paramName}[]=${value}&`, '');
    return result.slice(0, -1);
}

export async function fetchPostFile(url, data) {
    try {
        const response = await fetch(url, {
            method: 'POST',
            body: data,
        });
        const result = await response.json();
        return result;
    } catch (error) {
        alert('На сервере возникла ошибка: ' + error);
    }
}
