/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import IContactSimpleSet from 'CommonTypes/Contacts/IContactSimpleSet';

import { useGetContactsSimpleSetQuery, contactsApi } from 'Endpoints/Contacts/contacts.api';
import setContactToTaskService from 'fsrc/Sales/services/setContactToTaskService';
import connectorOldContactAddModel from 'fsrc/Sales/models/connectorOldContactAddModel.js';

const filter = createFilterOptions<IContactSimpleSet>();

type TContactAdd = {
    id: number,
    name: string,
    email: string | null,
    phone: string | null,
}

type TProps = {
    taskId: number;
    contactId: number;
    setNewContact: (newContactId: number) => void;
}

function ContactSelect({ taskId, contactId, setNewContact }: TProps) {
    const dispatch = useDispatch();
    const { data: contactsOptions = [] } = useGetContactsSimpleSetQuery();
    const [value, setValue] = useState<IContactSimpleSet | null>(null);

    const onChangeHandle = (
        e: React.SyntheticEvent<Element, Event>,
        newValue: IContactSimpleSet | string | null,
    ) => {
        if (typeof newValue === 'string' || newValue?.id === 0) {
            const name = typeof newValue === 'string' ? newValue : newValue.searchValue.replace('Добавить "', '').replace('"', '').trim();
            connectorOldContactAddModel((result: TContactAdd) => {
                if (+result.id) {
                    setContactToTaskService({ taskId, contactId: +result.id });
                    dispatch(contactsApi.util.invalidateTags(['ContactsSimpleSet']));
                    setNewContact(+result.id);
                }
            }, name);
        } else {
            setValue(newValue);
            if (newValue?.id) {
                setContactToTaskService({ taskId, contactId: newValue.id });
            }
        }
    };

    const onClickLinkHandle = useCallback(() => {
        window.open(`//${window.location.host}/contacts/${value?.id || 0}`, '_blank', 'noreferrer');
    }, [value?.id]);

    const handleClickPlusButton = useCallback(() => {
        connectorOldContactAddModel((result: TContactAdd) => {
            if (+result.id) {
                setContactToTaskService({ taskId, contactId: +result.id });
                dispatch(contactsApi.util.invalidateTags(['ContactsSimpleSet']));
                setNewContact(+result.id);
            }
        }, '');
    }, [dispatch, setNewContact, taskId]);

    useEffect(() => {
        const currentValue = contactsOptions.find(contact => +contact.id === contactId) || null;
        if (currentValue) {
            setValue(currentValue);
        }
    }, [contactId, contactsOptions]);

    return (
        <Autocomplete
            size="small"
            options={contactsOptions}
            value={value}
            blurOnSelect
            freeSolo
            disableClearable={Boolean(value)}
            handleHomeEndKeys={false}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Контакт"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {Boolean(value) && (
                                    <InputAdornment position="end">
                                        <Tooltip title="Открыть Контакт">
                                            <IconButton onClick={onClickLinkHandle}>
                                                <i className="fa fa-external-link" />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )}
                                <InputAdornment position="end">
                                    <Tooltip title="Добавить Контакт">
                                        <IconButton onClick={handleClickPlusButton}>
                                            <i className="fa fa-plus" />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            filterOptions={(option, params) => {
                const filtered = filter(contactsOptions, params);

                if (params.inputValue !== '' && !filtered.length) {
                    filtered.push({
                        id: 0,
                        value: '',
                        searchValue: `Добавить "${params.inputValue}"`,
                    });
                }

                return filtered;
            }}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.searchValue}
                </li>
            )}
            onChange={onChangeHandle}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option;
                }
                return option.searchValue;
            }}
        />
    );
}

export default ContactSelect;
