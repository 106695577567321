import defaultToolbarButtons from 'Domain/Common/ToolbarsBtnPlace/defaultToolbarButtons.js';
import { requirementsId } from 'fsrc/AddTaskWindow/config/constants.js';
import addChecklistService from './service/addChecklistService.js';
import addChecklist from './model/addChecklist.js';

export default taskId => ({
    view: 'toolbar',
    elements: [
        {},
        {
            view: 'button',
            width: 45,
            tooltip: 'Добавить чек-лист',
            label: '<i class="fa fa-plus"></i>',
            click: async () => {
                const newId = await addChecklistService({
                    name: '', taskId, assignee: null, date: '',
                });
                addChecklist(newId);
            },
        },
        ...defaultToolbarButtons({ downloadSet: requirementsId }),
    ],
});
