import React from 'react';

import configuratorHyperlinks from '../../utils/configuratorHuperlinks';
import Hyperlink from '../Hyperlink/Hyperlink';

import IHyperlink from '../../types/IHyperlink';

function HyperlinksWrapper() {
    return (
        <div>
            {configuratorHyperlinks.map(
                (hyperlink: IHyperlink) => <Hyperlink key={hyperlink.id} link={hyperlink} />,
            )}
        </div>
    );
}

export default HyperlinksWrapper;
