import {
    timeTrackingTableId,
} from '../../../../../../../config/constants.js';
import deleteTimeTrackService from '../services/deleteTimeTrackService.js';

export default (ev, rowObj) => {
    const item = $$(timeTrackingTableId).getItem(rowObj.row);

    webix.confirm({
        title: 'Вы действительно хотите удалить запись?',
        ok: 'Да',
        cancel: 'Нет',
    }).then(() => {
        deleteTimeTrackService(item.id)
            .then((res) => {
                if (!res) return webix.alert('Ошибка! Запись не была удалена.');

                $$(timeTrackingTableId).remove(item.id);
            });
    });
};
