import {
    ITimesheetAddBlankRecordRequest,
    ITimesheetAddBlankRecordResponse,
    ITimesheetDeleteRecordRequest,
    ITimesheetDeleteRecordResponse,
    ITimesheetRecordUpdateDatesRequest,
    ITimesheetRecordUpdateDatesResponse,
    ITimesheetTableByTaskCountRequest,
    ITimesheetTableByTaskCountResponse,
    ITimesheetTableRecordRequest,
    ITimesheetTableRecordResponse,
    ITimesheetUpdateRecordRequest,
    ITimesheetUpdateRecordResponse,
} from 'CommonTypes/Timesheets/TimesheetTableEdit';
import emptySplitApi from 'Endpoints/emptySplitApi';

export const timesheetsApi = emptySplitApi.injectEndpoints({
    endpoints: build => ({

        getTimesheetsCountByTaskId: build.query<
            ITimesheetTableByTaskCountResponse,
            ITimesheetTableByTaskCountRequest
        >({
            query: ({ taskId }) => ({
                url: `timesheets/${taskId}/count`,
            }),
        }),

        getTimesheetsRecordsByTaskId: build.query<
            ITimesheetTableRecordResponse[],
            ITimesheetTableRecordRequest
        >({
            query: ({ taskId }) => ({
                url: `timesheets/${taskId}`,
            }),
        }),

        getTimesheetRecordAccess: build.query<
            ITimesheetDeleteRecordResponse,
            ITimesheetDeleteRecordRequest
        >({
            query: ({ recordId, ...rest }) => ({
                url: `timesheets/${recordId}/access`,
                params: rest,
            }),
        }),

        deleteTimesheetRecordByRecordId: build.mutation<
            ITimesheetDeleteRecordResponse,
            ITimesheetDeleteRecordRequest
        >({
            query: ({ recordId, ...rest }) => ({
                url: `timesheets/records/${recordId}`,
                method: 'DELETE',
                params: rest,
            }),
            async onQueryStarted(
                { recordId, taskId },
                { dispatch, queryFulfilled },
            ) {
                const { data: response } = await queryFulfilled;
                if (response?.success) {
                    dispatch(
                        timesheetsApi.util.updateQueryData(
                            'getTimesheetsRecordsByTaskId',
                            { taskId },
                            records => records.filter(record => +record.id !== +recordId),
                        ),
                    );
                    dispatch(
                        timesheetsApi.util.updateQueryData(
                            'getTimesheetsCountByTaskId',
                            { taskId },
                            draft => ({ count: draft.count - 1 }),
                        ),
                    );
                }
            },
        }),

        updateTimesheetRecordDate: build.mutation<
            ITimesheetRecordUpdateDatesResponse,
            ITimesheetRecordUpdateDatesRequest
        >({
            query: ({ recordId, ...rest }) => ({
                url: `timesheets/records/${recordId}/dates`,
                method: 'PATCH',
                body: rest,
            }),
            async onQueryStarted(
                {
                    taskId, recordId, dateType, newDate,
                },
                { dispatch, queryFulfilled },
            ) {
                const { data: response } = await queryFulfilled;
                if (response?.success) {
                    dispatch(
                        timesheetsApi.util.updateQueryData(
                            'getTimesheetsRecordsByTaskId',
                            { taskId },
                            records => records.map(
                                record => ((+record.id === +recordId)
                                    ? {
                                        ...record,
                                        [dateType]: newDate,
                                        duration: response?.duration || 0,
                                    }
                                    : record),
                            ),
                        ),
                    );
                }
            },
        }),

        updateTimesheetRecord: build.mutation<
            ITimesheetUpdateRecordResponse,
            ITimesheetUpdateRecordRequest
        >({
            query: ({ recordId, ...rest }) => ({
                url: `timesheets/records/${recordId}`,
                method: 'PATCH',
                body: rest,
            }),
            async onQueryStarted(
                {
                    taskId, recordId, startDate, finishDate, comment,
                },
                { dispatch, queryFulfilled },
            ) {
                const { data: response } = await queryFulfilled;
                if (response?.success) {
                    dispatch(
                        timesheetsApi.util.updateQueryData(
                            'getTimesheetsRecordsByTaskId',
                            { taskId },
                            records => records.map(
                                record => ((+record.id === +recordId)
                                    ? {
                                        ...record,
                                        start: startDate,
                                        finish: finishDate,
                                        comment,
                                        duration: response?.duration || 0,
                                    }
                                    : record),
                            ),
                        ),
                    );
                }
            },
        }),

        addNewBlankTimesheetRecord: build.mutation<
            ITimesheetAddBlankRecordResponse,
            ITimesheetAddBlankRecordRequest
        >({
            query: data => ({
                url: 'timesheets/records/simpleset',
                method: 'POST',
                body: data,
            }),
            async onQueryStarted(
                { taskId },
                { dispatch, queryFulfilled },
            ) {
                const { data: response } = await queryFulfilled;
                if (response?.success && response?.insertedRecord) {
                    dispatch(
                        timesheetsApi.util.updateQueryData(
                            'getTimesheetsRecordsByTaskId',
                            { taskId },
                            (draft) => {
                                draft.push(response.insertedRecord!);
                            },
                        ),
                    );
                    dispatch(
                        timesheetsApi.util.updateQueryData(
                            'getTimesheetsCountByTaskId',
                            { taskId },
                            draft => ({ count: draft.count + 1 }),
                        ),
                    );
                }
            },
        }),

    }),
});

export const {
    useGetTimesheetsCountByTaskIdQuery,
    useGetTimesheetsRecordsByTaskIdQuery,
    useDeleteTimesheetRecordByRecordIdMutation,
    useUpdateTimesheetRecordDateMutation,
    useAddNewBlankTimesheetRecordMutation,
    useUpdateTimesheetRecordMutation,
    useGetTimesheetRecordAccessQuery,
} = timesheetsApi;
