import changeTabHandler from 'fsrc/AddTaskWindow/models/changeTabHandler.js';

export default (
    tabbarId: string,
    multiviewId: string,
    fieldId: string,
    isVisible: boolean,
): string => {
    const setTabs = ($$(tabbarId) as webix.ui.tabbar);
    if (isVisible) {
        const isHiddenTabbar = setTabs.config.options?.every(tab => tab?.hidden);
        if (isHiddenTabbar) {
            $$(tabbarId).show();
            $$(multiviewId).show();
            $$(fieldId).show();
            changeTabHandler(fieldId);
        }
        ($$(tabbarId) as webix.ui.tabbar).showOption(fieldId);

        return 'show';
    }
    ($$(tabbarId) as webix.ui.tabbar).hideOption(fieldId);
    const isHiddenTabbar = setTabs.config.options?.every(tab => tab?.hidden);
    if (isHiddenTabbar) {
        $$(tabbarId).hide();
        $$(multiviewId).hide();
    }
    return 'hide';
};
