import React from 'react';
import dayjs from 'dayjs';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

import { useEditEventByIdMutation } from 'Endpoints/PlanDay/planDay.api';

import { IPlanUser } from 'CommonTypes/Scheduler/IResponse';

import classes from '../../../assets/style.scss';
import TimerComponent from './TimerComponent';

function CardHeader({ plan }: {plan: IPlanUser}) {
    const {
        schedulerName, finished, comment, schedulerId, startDateEvent, userId,
    } = plan;
    const [updateEvent] = useEditEventByIdMutation();

    const handleChangeEventState = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateEvent({
            comment,
            finished: +event.target.checked ? 1 : 0,
            schedulerId,
            schedulerName,
            eventDay: dayjs(startDateEvent).format('YYYY-MM-DD'),
            startDateEvent: plan.startDateEvent,
            endDateEvent: plan.endDateEvent || dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            userId,
            allDay: plan.allDay,
            projectId: plan.projectId || 0,
            participants: plan.participants,
            contacts: plan.eventContactsIds,
            taskId: plan.taskId || 0,
        });
    };

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography
                variant={finished ? 'planTitleS' : 'planTitle'}
                className={classes.card_name}
            >
                <TimerComponent eventId={plan.schedulerId} />
                <Checkbox
                    checked={!!finished}
                    onChange={handleChangeEventState}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                {schedulerName}
            </Typography>
        </Stack>
    );
}

export default CardHeader;
