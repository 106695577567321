import { nodePath } from 'Config/path.js';
import { fetchPost } from 'Services/fetchService.js';

export default async (taskId, comment) => {
    const data = { taskId, comment };

    const response = await fetchPost(`${nodePath}/comments/lite-form`, data);

    return response?.id || 0;
};
