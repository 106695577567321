import createDataTaskModel from '../../models/createDataTaskModel';
import createTaskByEvent from '../../models/createTaskByEvent';
import IDataFromEvent from '../../types/IDataFromEvent';
import defaultDataController from './defaultDataController';

async function createTaskInBackground({
    type, params, messageId, dataFiles, callback,
}: IDataFromEvent) {
    const data = await defaultDataController(type, params, messageId, dataFiles);
    const baseDataTask = await createDataTaskModel(
        type,
        { ...data.defaultProperty, doctype: data.defaultProperty.doctype || null },
    );

    const result = await createTaskByEvent(baseDataTask!);
    if (result.success) {
        if (callback) callback(result, baseDataTask);
        webix.message({ text: `Создана задача №${result.id}`, type: 'success' });
    }
}

export default createTaskInBackground;
