import { IContacts } from 'CommonTypes/Tasks/QuickAddTaskForm/IResponse';

export default (contacts: IContacts[], observersId: number[] | string[]) => {
    if (!observersId || !observersId.length) return [];
    const observers = contacts.filter(
        value => observersId.some(observerId => +observerId === value.id),
    );

    return (observers || []) as IContacts[];
};
