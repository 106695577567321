import IProjectSimpleSet from 'CommonTypes/Projects/IProjectSimpleSet';
import findProjectById from './findProjectById';
import findProjectByName from './findProjectByName';

export default (projectsList: IProjectSimpleSet[], eventName: string, projectId: number = 0) => {
    if (projectId > 0) {
        const projectSet = findProjectById(projectsList, projectId);

        if (projectSet) {
            return projectSet;
        }
    }

    const index = eventName.indexOf(':');
    if (index === -1) {
        return null;
    }

    const projectNameFromEvent = eventName.substring(0, index);
    const result = findProjectByName(projectsList, projectNameFromEvent);

    return result;
};
