import { nodePath } from 'Config/path.js';
import { fetchPatch } from 'Services/fetchService.js';

interface IData {
    taskId: number;
    parentId: number;
    submissionType: number;
}

export default async (data: IData) => {
    const response: boolean = await fetchPatch(`${nodePath}/tasks/submissions/`, data);

    return response;
};
