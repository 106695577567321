import { nodePath } from 'Config/path.js';

const getHistoryStates = async (taskId) => {
    const data = await webix
        .ajax(`${nodePath}/tasks/getHistoryStates?taskId=${taskId}`)
        .then(res => res.json());

    return data;
};

export default getHistoryStates;
