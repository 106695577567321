import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Icon from '@mui/material/Icon';

import ModalConfirm from './ModalConfirm';
import actionSetDefault from '../utils/actionSetDefault';
import actionRecalculate from '../utils/actionRecalculate';

function MenuAction({
    taskId, idTaskPrice, name, deleteRow,
}:
    { taskId: number, idTaskPrice: string, name: string, deleteRow: (idRow: string) => void }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickConfirm = () => { setOpenConfirm(!openConfirm); };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <Icon><i className="fa fa-ellipsis-v" /></Icon>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    actionSetDefault(idTaskPrice);
                    handleClose();
                }}
                >
                    <Icon><i className="fa fa-rotate-left" /></Icon>
                    Установить по умолчанию
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        actionRecalculate(idTaskPrice);
                        handleClose();
                    }}
                >
                    <Icon><i className="fa fa-refresh" /></Icon>
                    Пересчитать
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClickConfirm();
                        handleClose();
                    }}
                >
                    <Icon><i className="fa fa-trash" /></Icon>
                    Удалить
                </MenuItem>
            </Menu>
            {openConfirm && (
                <ModalConfirm
                    name={name}
                    taskId={taskId}
                    idTaskPrice={idTaskPrice}
                    modalClose={handleClickConfirm}
                    deleteRow={deleteRow}
                />
            )}
        </div>
    );
}

export default MenuAction;
