import React from 'react';
import { DataGridPremium as DataGrid, useGridApiRef } from '@mui/x-data-grid-premium';
import { useGetTasksRoleUserStateConfigQuery, useUpdateTasksroleUserStateConfigMutation } from 'Endpoints/Roles/roleUserConfigByTask.api';
import { useSelector } from 'react-redux';
import { isRoleUserTabActivated } from 'fsrc/Task/store/activeTabsFromTasksFormSelector';
import { ITasksRoleUserStateConfigUpdateRequest } from 'CommonTypes/Roles/TasksRoleUserStateConfig';
import useEnqueueSnackbar from 'Lib/Notistack/useEnqueueSnackbar';
import useColumnsRolesConfigTasks from './models/useColumnsRolesConfigTasks';

interface IProps {
    taskId: number;
}

function RolesConfigTasksDataGrid({ taskId }: IProps) {
    const apiRef = useGridApiRef();
    const { error, success, warning } = useEnqueueSnackbar();

    const tabIsActivated = useSelector(isRoleUserTabActivated);

    const columns = useColumnsRolesConfigTasks({ taskId });

    const { data: options = [] } = useGetTasksRoleUserStateConfigQuery(
        { taskId },
        {
            skip: !tabIsActivated,
        },
    );

    const [updateRecord] = useUpdateTasksroleUserStateConfigMutation();

    return (
        <DataGrid
            apiRef={apiRef}
            columns={columns}
            rows={options}
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                        stateId: false,
                    },
                },
            }}
            onCellEditCommit={(params) => {
                const row = apiRef.current.getRow(params.id);
                const newRow: ITasksRoleUserStateConfigUpdateRequest = {
                    taskId,
                    recordId: +(params?.id || 0),
                    roleId: row.roleId,
                    userId: row.userId,
                    planDuration: row.planDuration,
                };

                const field = params.field as keyof ITasksRoleUserStateConfigUpdateRequest;
                newRow[field] = params.value;
                if (field === 'roleId' && newRow[field]) {
                    newRow.userId = null;
                }

                if (field === 'userId' && newRow[field]) {
                    newRow.roleId = null;
                }

                updateRecord(newRow).unwrap().then((response) => {
                    if (response?.success) {
                        return success('Запись успешно обновлена');
                    }

                    if (
                        response?.permissions
                            && (!response.permissions?.role || !response.permissions?.rolesMatching)
                    ) {
                        return warning('У вас нет прав на редактирование этой записи');
                    }

                    return error('Во время обновления возникла ошибка');
                });
            }}
        />
    );
}

export default RolesConfigTasksDataGrid;
