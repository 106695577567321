import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material';

import di from 'Services/di.js';
import { injectReducer } from 'Utils/store/store';
import theme from 'Lib/Mui/theme';
import StyledSnackbarProvider from 'Lib/Notistack/StyledSnackbarProvider';
import Action from 'Lib/Notistack/DefaultAction';

import planDaySlice, { changeSubordinate } from 'fsrc/PlanDay/utils/planDaySlice';
import { getEditingData } from 'fsrc/PlanDay/utils/selectors';
import {
    useAddEventToPlanDayMutation,
    useEditEventByIdMutation,
    useGetPlanDayUserAvailableTasksQuery,
    useGetEventCheckListQuery,
} from 'Endpoints/PlanDay/planDay.api';
import { useGetProjectsSimpleSetQuery } from 'Endpoints/Projects/projects.api';
import { useGetContactsSimpleSetQuery } from 'Endpoints/Contacts/contacts.api';
import { useGetUsersActiveSimpleSetQuery } from 'Endpoints/Users/users.api';
import EventEditing from 'fsrc/PlanDay/components/EventEditing/EventEditing';
import { ICurrentSubordinate } from 'fsrc/PlanDay/types/IState';

function RootEventComponent() {
    const { editing, eventData, type } = useSelector(getEditingData);
    const {
        data: checklist,
    } = useGetEventCheckListQuery({
        taskId: eventData?.taskId || 0, eventId: eventData?.schedulerId || 0,
    }, {
        skip: !editing || !eventData?.taskId,
    });
    const {
        data: tasks = [],
        isFetching: tasksIsFetching,
        isLoading: tasksIsLoading,
    } = useGetPlanDayUserAvailableTasksQuery(undefined, {
        skip: !editing,
        selectFromResult: ({ data, isLoading, isFetching }) => {
            if (!data) {
                return {
                    data: [],
                    isLoading,
                    isFetching,
                };
            }
            const result = data.map(value => ({ id: value.id, value: `#${value.id} ${value.name}`, projectId: value.projectId || 0 }));
            return {
                data: result,
                isLoading,
                isFetching,
            };
        },
    });

    const { data: dataProjectsList = [], isFetching, isLoading } = useGetProjectsSimpleSetQuery(
        undefined,
        {
            skip: !editing,
        },
    );
    const {
        data: usersActiveSimpleSet = [],
        isFetching: usersIsFetching,
        isLoading: usersIsLoading,
    } = useGetUsersActiveSimpleSetQuery(
        undefined,
        {
            skip: !editing,
        },
    );
    const {
        data: contactsSimpleSet = [],
        isFetching: contactsIsFetching,
        isLoading: contactsIsLoading,
    } = useGetContactsSimpleSetQuery(
        undefined,
        {
            skip: !editing,
        },
    );

    const [addNewEvent] = useAddEventToPlanDayMutation();
    const [updateEvent] = useEditEventByIdMutation();

    return (
        <>
            {(editing
            && !isFetching
            && !isLoading
            && !usersIsFetching
            && !usersIsLoading
            && !contactsIsFetching
            && !contactsIsLoading
            && !tasksIsFetching
            && !tasksIsLoading) && (
                <EventEditing
                    open={editing}
                    dataProjectsList={dataProjectsList}
                    dataUsersActiveSimpleSet={usersActiveSimpleSet}
                    dataContactsSimpleSet={contactsSimpleSet}
                    tasks={tasks}
                    checklist={checklist || []}
                    eventData={eventData!}
                    callback={type === 'create' ? addNewEvent : updateEvent}
                    type={type}
                />
            )}
        </>
    );
}

const initRootEventComponent = () => {
    const { store } = di;

    const { reducerUser } = di.store.getState();
    const userId = reducerUser.id;

    injectReducer('planDayReducer', planDaySlice);

    const currentSubordinate: ICurrentSubordinate = {
        subordinateId: userId,
        subordinateName: 'Мой календарь',
        sort: 1,
        rolesIds: null,
    };
    store.dispatch(changeSubordinate(currentSubordinate));

    const root = document.createElement('div');
    const rootContainer = createRoot(root);
    document.body.append(root);

    rootContainer.render(
        <StrictMode>
            <ThemeProvider theme={theme}>
                <StyledSnackbarProvider
                    action={snackbarId => Action(snackbarId)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    hideIconVariant
                >
                    <Provider store={store}>
                        <RootEventComponent />
                    </Provider>
                </StyledSnackbarProvider>
            </ThemeProvider>
        </StrictMode>,
    );
};

export default initRootEventComponent;
