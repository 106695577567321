import { fetchGet } from 'Services/fetchService.js';

export default async (data) => {
    const { state } = data;
    const allButtons = data.states.map(el => el.routestates);
    const { states } = await fetchGet(`${app.root}ajax/states.next.load.php?cur=${state}`);
    const nextButtons = states?.map(el => +el.id);
    allButtons.forEach((el) => {
        const currentProp = data.states.reduce((acc, ce) => {
            if (ce.routestates === el) {
                acc.push(ce.name);
                acc.push(ce.color_state);
            }

            return acc;
        }, []);

        const [name, backgroundColor] = currentProp;

        switch (true) {
        case nextButtons?.includes(el): {
            $$(el).enable();
            const style = `style="background-color: #${backgroundColor}88;"`;
            const template = `<button class = "toolbar__btn" ${style}>${name}</button>`;
            $$(el).define('template', template);
            $$(el).refresh();
            break;
        }
        case el === state && !nextButtons: {
            const style = `
                style="background-color: #${backgroundColor}10;
                font-weight: bold;
                border: 5px solid #${backgroundColor}10;
                line-height: 1;
                padding: 0;"
            `;
            const template = `<button class = "toolbar__btn" ${style}>${name}</button>`;
            $$(el).define('template', template);
            $$(el).refresh();
            $$(el).disable();
            break;
        }
        case el === state: {
            const style = `
                style="background-color: #${backgroundColor}88;
                font-weight: bold;
                border: 5px solid #${backgroundColor};
                line-height: 1;
                padding: 0;"
            `;
            const template = `<button class = "toolbar__btn" ${style}>${name}</button>`;
            $$(el).define('template', template);
            $$(el).refresh();
            $$(el).disable();
            break;
        }
        default: {
            const style = `style="background-color: #${backgroundColor}10"`;
            const template = `<button class = "toolbar__btn" ${style}>${name}</button>`;
            $$(el).define('template', template);
            $$(el).refresh();
            $$(el).disable();
            break;
        }
        }
    });
};
