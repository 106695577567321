import React from 'react';
import Grid from '@mui/material/Grid';

import ProgressBar from 'Ui/ProgressBar/ProgressBar';
import Item from '../Item/Item';

import classes from '../../assets/style.scss';

function MainInformation({ data }: { data: any }) {
    const {
        progress, serviceClass, deadline, project, contact,
    } = data;

    const onChangeProgress = (newProgress: number | number[]) => {
        console.log(newProgress);
    };

    return (
        <div className={classes.main}>
            <div className={classes.main_progress}>
                <strong>Прогресс:</strong>
                <ProgressBar progress={progress} callback={onChangeProgress} />
            </div>
            <Grid container spacing={1} wrap="wrap" justifyContent="center" columnGap={1}>
                <Grid item xs="auto" flexDirection="column" className={classes.main_info}>
                    <Item item="Класс обслуживания" value={serviceClass} />
                    <Item item="Срок" value={deadline} />
                </Grid>
                <Grid item xs="auto" flexDirection="column" className={classes.main_info}>
                    <Item item="Проект" value={project} />
                    <Item item="Контакт" value={contact} />
                </Grid>
            </Grid>
        </div>
    );
}

export default MainInformation;
