import { fetchPatch } from 'Services/fetchService.js';
import { nodePath } from 'Config/path.js';

export default async (data) => {
    const response = await fetchPatch(`${nodePath}/checklist/finished-checklist`, data);

    if (response.data) {
        return response.data;
    }

    return Boolean(response.success);
};
