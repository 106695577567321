import createBody from 'Domain/LayoutConfiguration/models/createBody';
import { btnSaveId } from 'Domain/LayoutConfiguration/config/constans';
import closeSettingsForm from 'Domain/LayoutConfiguration/models/closeSettingsForm';
import saveSettingsForm from 'Domain/LayoutConfiguration/models/saveSettingsFrom';
import { IFormFieldsConfigController } from '../types/IFormFieldsConfigController';
import calculateOffsetTop from '../utils/calculateOffsetTop';

interface IWindowConfig extends Omit<webix.ui.windowConfig, 'body'> {
    body: webix.ui.formConfig;
}

export default (fieldsConfig: IFormFieldsConfigController): IWindowConfig => {
    const window = {
        view: 'window',
        id: fieldsConfig.formConfig.layout.id,
        modal: true,
        head: {
            view: 'toolbar',
            cols: [
                { width: 4 },
                {
                    view: 'label',
                    label: fieldsConfig.isUser
                        ? `Настройки по умолчанию для формы "${fieldsConfig.formConfig.layout.name}"`
                        : `Пользовательские настройки формы "${fieldsConfig.formConfig.layout.name}"`,
                },
                {
                    id: btnSaveId,
                    view: 'button',
                    width: 45,
                    disabled: true,
                    tooltip: 'Сохранить настройки',
                    label: '<span class="fa fa-floppy-o">',
                    click() {
                        saveSettingsForm(fieldsConfig);
                    },
                },
                {
                    view: 'button',
                    width: 45,
                    tooltip: 'Закрыть настройки',
                    label: '<span class="fa fa-times">',
                    align: 'right',
                    click() {
                        closeSettingsForm(fieldsConfig);
                    },
                },
            ],
        },
        height: 600,
        width: 600,
        position: calculateOffsetTop,
        body: createBody(fieldsConfig),
    };

    return window;
};
