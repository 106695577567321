import React, { ReactNode } from 'react';
import Typography from '@mui/material/Typography';

import classes from '../../assets/style.scss';

function TabPanelTitle(
    { icon, title, children }:
    { icon: string, title: string, children: ReactNode },
) {
    return (
        <div className={`${classes.drawer__item} ${classes.drawer__item_title}`}>
            <Typography variant="h6">
                <i className={icon} />
                {'   '}
                {title}
            </Typography>
            <Typography variant="planSubtitle">
                {children}
            </Typography>
        </div>
    );
}

export default TabPanelTitle;
