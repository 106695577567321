import { Match } from 'navigo';
import { initModule } from 'Services/initModule.js';

export default {
    '/templates': {
        as: 'docs-templates',
        uses: () => {
            import(
                /* webpackChunkName: "calendar" */ '../../DocsTemplates/DocsTemplatesController'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
    '/templates/edit/:templateId': {
        as: 'templateEdit',
        uses: (match: Match) => {
            import(
                /* webpackChunkName: "template-edit" */ '../../DocsTemplates/TemplatesEditPageController'
            ).then((module) => {
                initModule(module.default, match);
            });
        },
    },
};
