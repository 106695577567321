import React from 'react';
import Grid from '@mui/material/Grid';
import { useFormContext, useWatch } from 'react-hook-form';

import { TimePicker, DatePicker } from 'Ui/Form/DatePicker';
import { Checkbox } from 'Ui/Form/Checkbox';

function EventsDateSection() {
    const { control } = useFormContext();
    const allDay = useWatch({
        control,
        name: 'allDay',
    });

    return (
        <Grid container spacing={2}>
            <Grid item sx={{ width: '150px' }}>
                <Checkbox
                    name="allDay"
                    label="Весь день"
                />
            </Grid>
            { allDay
                ? (
                    <Grid item xs>
                        <DatePicker
                            name="startDate"
                            label="Дата"
                        />
                    </Grid>
                )
                : (
                    <>
                        <Grid item xs>
                            <TimePicker
                                name="startDate"
                                label="Начало"
                            />
                        </Grid>
                        <Grid item xs>
                            <TimePicker
                                name="endDate"
                                label="Окончание"
                            />
                        </Grid>
                    </>
                )}
        </Grid>
    );
}

export default EventsDateSection;
