import { nodePath } from 'Config/path.js';
import insertComment from './services/insertComment.js';
import './assets/commentsStyles.orig.scss';
import templateIcons from './assets/templateIcons.js';

export default (taskId, currentUser, usersData, afterLoadHandler) => (
    {
        id: 'comments__view',
        view: 'commentsCkeditor',
        currentUser,
        taskId,
        url: `${nodePath}/comments/by-task`,
        users: usersData,
        mode: 'chat',
        moreButton: webix.template('Загрузить ещё (#value#)'),
        listItem: {
            templateDate: templateIcons,
        },
        on: {
            onSaveComment(newRow) {
                insertComment(taskId, newRow.text);
                afterLoadHandler(this);
            },
            onAfterLoad() {
                afterLoadHandler(this);
            },
        },
    }
);
