export default (obj) => {
    const format = webix.Date.dateToStr('%d %M, %H:%i');
    const iconsObj = {
        fBug: obj.fBug ? '<li style="padding: 2px 0;" class="fa fa-bug"></li>' : '',
        fDeadline: obj.fDeadline ? '<li style="padding: 2px 0;" class="fa fa-calendar-check-o"></li>' : '',
        fEmail: obj.fEmail ? '<li style="padding: 2px 0;" class="fa fa-envelope-o"></li>' : '',
        fState: obj.fState ? '<li style="padding: 2px 0;" class="fa fa-code-fork"></li>' : '',
    };

    if (obj.recipients) {
        const emailsArr = obj.recipients.split(',');
        const emails = emailsArr.reduce((acc, curr) => {
            acc.str += `<li style="margin: -15px 0 0 0;">${curr}</li>`;

            return acc;
        }, { str: '' });
        obj.recipients = emails.str;
    }

    const result = `<div class="webix_comments_date" style="display: flex;">
        <div style="padding: 0 5px 0 0; display: flex; flex-direction: column;">
            <div style="align-self: flex-end;">${format(obj.date)}</div>
            <ul style="padding: 0; margin: 0; text-align: right; list-style-type: none;">
                ${obj.recipients || ''}
            </ul>
        </div>
        <ul style="padding: 0; margin: 0; list-style-type: none; margin-top: 6px; display: flex; flex-direction: column;">
            ${Object.values(iconsObj).join('')}
        </ul>
    </div>`;

    return result;
};
