/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { useGetProjectsSimpleSetQuery } from 'Endpoints/Projects/projects.api';
import { getSelectedProject } from '../utils/rolesMatrixProjectsSelectors';
import { setProject } from '../utils/rolesMatrixProjectsSlice';

function ProjectSelect() {
    const dispatch = useDispatch();
    const selectedProjectId = useSelector(getSelectedProject);
    const { data = [] } = useGetProjectsSimpleSetQuery();

    const selectedValue = useMemo(() => {
        if (!selectedProjectId) return null;

        const result = data.find(project => project.id === selectedProjectId);
        return result;
    }, [data, selectedProjectId]);

    return (
        <Autocomplete
            sx={{ maxWidth: '372px' }}
            size="small"
            options={data}
            openOnFocus
            getOptionLabel={option => option.value}
            renderOption={(prop, option) => (
                <li {...prop} key={option.id}>{option.value}</li>
            )}
            value={selectedValue}
            renderInput={params => <TextField {...params} label="Проект" />}
            onChange={(e, newValue) => {
                if (!newValue) {
                    dispatch(setProject(0));
                } else {
                    dispatch(setProject(newValue.id));
                }
            }}
            handleHomeEndKeys={false}
        />
    );
}

export default ProjectSelect;
