import { fetchPost } from 'Services/fetchService.js';
import { nodePath } from 'Config/path.js';

export interface IFileByMessage {
    folder: string;
    name: string;
    sname: string;
    mime: string;
    description: string;
}

export default async (data: IFileByMessage[]): Promise<any> => {
    const response = await fetchPost(`${nodePath}/tasks/data/files-by-message`, { data });

    return response;
};
