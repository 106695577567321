import React from 'react';

import CommentSection from './components/CommentSection/CommentSection';
import ControlSection from './components/ControlSection/ControlSection';

interface IProps {
    taskId: number;
}

function App({ taskId = 900 }: IProps) {
    return (
        <>
            <CommentSection taskId={taskId} />
            <ControlSection taskId={taskId} />
        </>
    );
}

export default App;
