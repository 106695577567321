import {
    formEditTimeTrackId,
    timeTrackingTableId,
    formAddTaskId,
    windowEditTimeTrackId,
} from '../../../../../../../../config/constants.js';
import updateTimeTrack from '../services/updateTimeTrack.js';
import addTimeTrack from '../services/addTimeTrack.js';

export default (data) => {
    const formValues = $$(formEditTimeTrackId).getValues();
    const values = $$(formAddTaskId).getValues();
    const { taskId } = values;

    if (data.id) {
        updateTimeTrack({ id: data.id, taskId, ...formValues })
            .then((res) => {
                const newData = {
                    ...formValues,
                    duration: (Date.parse(formValues.endDate) - Date
                        .parse(formValues.startDate)) / (1000 * 60),
                };
                $$(timeTrackingTableId)
                    .updateItem(data.id, { ...res, ...newData });
                $$(windowEditTimeTrackId).destructor();
            });
    } else {
        addTimeTrack({ taskId, ...formValues })
            .then((res) => {
                $$(timeTrackingTableId).add(res);
                $$(windowEditTimeTrackId).destructor();
            });
    }
};
