import { FilterOptionsState } from '@mui/material';

type T = {
    searchValue: string,
}

function filterOptionsBySearchValue(options: T[], state: FilterOptionsState<T[]>) {
    const searchValue = state.inputValue.toLowerCase().trim();
    const displayOptions = options.filter(
        option => option.searchValue.toLocaleLowerCase().trim().includes(searchValue),
    );

    return displayOptions;
}

export default filterOptionsBySearchValue;
