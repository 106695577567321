export default (viewId) => {
    const popup = $$(viewId).getPopup();
    popup.define('width', 250);
    const main = $$(viewId).getPopup().getBody().getChildViews();
    const menuTitle = main[0];
    const menu = main[1];
    menuTitle.define({
        template: obj => (obj.hash ? `<a href="${obj.hash}">${obj.value}</a>` : `<div class="webix_template">${obj.value}</div>`),
    });
    menu.define({
        type: {
            templateStart(row, common, markers) {
                const selectedId = +$$(viewId).getSelectedId();
                const selectedStr = (+row.id === selectedId) ? 'aria-selected="true" tabindex="0"' : 'tabindex="-1"';

                return `<a href="${row.hash}" onclick="return false;" webix_l_id="${row.id}" class="${common.classname(row, common, markers)}" style="width:auto; height:${common.heightSize(row, common)}; overflow:hidden;" role="menuitem" ${selectedStr}>`;
            },
            templateEnd: '</a>',
        },
    });
};
