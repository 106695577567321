import { initModule } from 'Services/initModule.js';

export default {
    '/roles-matrix': {
        as: 'roles-matrix',
        uses: () => {
            import(
                /* webpackChunkName: "rolesmatrix" */ '../../RolesMatrix/controllers/RolesMatrixPageController'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
    '/roles-matrix-by-user': {
        as: 'roles-matrix-by-user',
        uses: () => {
            import(
                /* webpackChunkName: "rolesmatrixbyuser" */ '../../RolesMatrix/controllers/RolesMatrixByUserPageController'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
