import React, { StrictMode } from 'react';
import { Root, createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import container from 'Services/container';
import { injectReducer } from 'Utils/store/store';

import { ThemeProvider } from '@mui/material';

import StyledSnackbarProvider from 'Lib/Notistack/StyledSnackbarProvider';
import Action from 'Lib/Notistack/DefaultAction';
import theme from 'Lib/Mui/theme';
import RolesByProjectsGrid from '../components/RolesByProjectsGrid';
import rolesMatrixProjectsSlice, { setProject } from './rolesMatrixProjectsSlice';

type TData = {
    projectId: number;
}

let rootContainer: Root | null = null;

function initMatrix({ projectId }: TData) {
    if (rootContainer) return;

    injectReducer('rolesMatrixProjectsReducer', rolesMatrixProjectsSlice);
    const { store } = container;
    store.dispatch(setProject(projectId));
    const root = document.getElementById(`rolesMatrix_${projectId}`) as HTMLDivElement;
    rootContainer = createRoot(root);

    rootContainer.render(
        <StrictMode>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <StyledSnackbarProvider
                        action={snackbarId => Action(snackbarId)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        hideIconVariant
                    >
                        <RolesByProjectsGrid />
                    </StyledSnackbarProvider>
                </ThemeProvider>
            </Provider>
        </StrictMode>,
    );
}

function unmountMatrix() {
    rootContainer?.unmount();
    rootContainer = null;
}

export default () => {
    const { events } = container;
    events.on(
        'rolesMatrix__projects:initGrid',
        initMatrix,
        false,
    );
    events.on(
        'router:before-route',
        unmountMatrix,
        false,
    );
};
