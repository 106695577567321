import di from 'Services/di.js';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import defaultTasksConfig from 'Config/default/defaultTasksConfig';

import { IResponse } from 'CommonTypes/Tasks/QuickAddTaskForm/IResponse';

import getDefaultContact from './getDefaultContact';
import getDefaultServiceClasse from './getDefaultServiceClasse';
import getDefaultTaskType from './getDefaultTaskType';
import getDefaultAssignee from './getDefaultAssignee';
import { IFiles } from '../../types/IFiles';

export default (
    data: IResponse,
    params: {
        data: { [key: string]: string | number; };
        parentItem?: { id: number; name: string; } | undefined;
    },
    dataFiles: [] | IFiles[],
) => {
    const { store } = di;
    const { reducerUser } = store.getState();
    const { id, taskTypeId } = reducerUser;
    const bugInfo = params;
    const taskName = params?.data?.name ? params.data.name as string : `Подзадача для задачи №${params?.parentItem?.id} ${params?.parentItem?.name}`;

    const contact = getDefaultContact(data.contacts, +params.data.contactId);

    const assignee = getDefaultAssignee(
        data.users,
        id,
    );

    const project = data.projects.find(
        projectItem => projectItem.id === +params.data.projectId,
    ) ?? null;

    const serviceClasse = getDefaultServiceClasse(
        data.serviceClasses,
        defaultTasksConfig.task.serviceClassId,
    );

    const taskTypes = getDefaultTaskType(
        data.taskTypes,
        defaultTasksConfig.task.taskTypeId,
        project?.taskTypeId || taskTypeId,
    );

    const filesUploader = dataFiles?.length ? dataFiles?.map(el => ({
        fileName: el.name,
        fileDescription: el.uploader_fileDescription ?? '',
        id: el.id.toString(),
        folder: el.folder,
        mime: el.mime,
        sname: el.sname,
    })) : [];

    const result = {
        contact,
        assignee,
        project,
        taskTypes,
        serviceClasse,
        taskName,
        filesUploader,
        bugInfo,
        parentId: params?.parentItem?.id,
        desc: params?.data?.desc as string || '',
        deadline: params?.data?.deadline ? dayjs(params.data.deadline).endOf('day') : dayjs().endOf('day'),
        id_checklist: params?.data?.id_checklist as number || 0,
    };

    return result;
};
