import addTaskFormDestructor from 'fsrc/AddTaskWindow/utils/addTaskFormDestructor.js';
import { fetchGet } from 'Services/fetchService.js';

export default async (taskId) => {
    try {
        await webix.confirm({
            title: 'Вы действительно хотите удалить задачу',
            ok: 'Да',
            cancel: 'Нет',
        });
    } catch (error) {
        return false;
    }

    function delTask(idTask, confirmation = 0) {
        app.fetch(`${app.root}ajax/task.delete.php`, { id_task: idTask, confirmation }, 'POST')
            .then(async (res) => {
                if (!res || res.error) {
                    return webix.message({
                        text: 'Неизвестная ошибка',
                        type: 'error',
                    });
                }

                if (res.confMe) {
                    await webix.confirm({
                        title: `Задача имеет подзадачи (${res.confMe}). Хотите ли удалить подчинённые задачи?`,
                        ok: 'Да',
                        cancel: 'Нет',
                    }).then(() => {
                        delTask(idTask, 1);
                    })
                        .fail(() => {
                            delTask(idTask, 2);
                        });
                }

                webix.message({
                    text: 'Задача успешно удалена',
                    type: 'success',
                });

                if ($$('addTaskMenuId')) addTaskFormDestructor();
            });
    }

    fetchGet(`/ajax/task_del_prepare.php?id_task=${taskId}`)
        .then((res) => {
            if (!res) {
                return webix.message({
                    text: 'Ошибка выполнения запроса',
                    type: 'error',
                });
            }

            if (res && !res.length) {
                return delTask(taskId);
            }

            return webix.confirm({
                title: 'Объект, который вы пытаетесь удалить связан с исходящими документами, вы действительно хотите удалить?',
                ok: 'Да',
                cancel: 'Нет',
            }).then((result) => {
                if (result) {
                    delTask(taskId);
                }
            });
        });

    return false;
};
