// export const service = {
//     templatesData,
//     contactData,
//     userData,
//     roleData,
//     projectData,
//     deleteTemplate
// }

export function handleResponse(response) {
    return response.text().then((text) => {
        const data = JSON.parse(text);
        if (!response.ok) {
            return Promise.reject(data);
        }
        return data;
    });
}

export function templatesData() {
    const requestOptions = {
        method: 'GET',
    };
    return new Promise((resolve, reject) => {
        fetch('./ajax/task_templates.php', requestOptions)
            .then(handleResponse)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function contactData(id_user) {
    const requestOptions = {
        method: 'GET',
    };
    return new Promise((resolve, reject) => {
        fetch(`./ajax/contact_info.php?id=${id_user}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export function roleData(id_role) {
    const requestOptions = {
        method: 'GET',
    };
    return new Promise((resolve, reject) => {
        fetch(`./ajax/autocomplete.roles.php?id_role=${id_role}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export function userData(id_user) {
    const requestOptions = {
        method: 'GET',
    };
    return new Promise((resolve, reject) => {
        fetch(`./ajax/user.php?id=${id_user}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function projectData(id_project) {
    const requestOptions = {
        method: 'GET',
    };
    return new Promise((resolve, reject) => {
        fetch(`./ajax/project_one.php?id=${id_project}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function deleteTemplate(template_id) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ template_id, delete: '1' }),
    };
    return new Promise((resolve, reject) => {
        fetch('./ajax/task_templates.php', requestOptions)
            .then(handleResponse)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
