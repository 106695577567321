import di from 'Services/di.js';
import {
    addTaskMenutId, uploaderId, formAddTaskId, topLayoutId,
} from './config/constants.js';
import formAddTask from './components/formAddTask/formAddTask.js';
import getAllPropertyForTaskMenu from './service/getAllPropertyForTaskMenu.js';
import readonlyMode from './models/readonlyMode.js';
import getTaskById from './service/getTaskById.js';
import changeButtonsByCurrentState from './components/formAddTask/models/changeButtonsByCurrentState.js';
import changeAmountOfFiles from './components/formAddTask/components/cellsBottomTabbar/components/taskFilesUploader/models/changeAmountOfFiles.js';
import getAllFilesByTask from './service/getAllFilesByTask.js';
import changeFormState from '../Application/Domain/LayoutConfiguration/models/changeFormState';
import buttonsToolbar from './components/formAddTask/components/buttonsToolbar/buttonsToolbar.js';
import getFormConfig from './service/getFormConfig';

const { events } = di;

export default async (taskId, fieldsConfig) => {
    const { typeTaskValue, standalone } = fieldsConfig;
    const widthPageContent = document.getElementById('pageContent').clientWidth;
    const heightPageContent = document.documentElement.clientHeight - 90;
    const dataTask = taskId ? await getTaskById(taskId, formAddTaskId) : null;

    if (!(dataTask?.permissions?.role ?? true) || !(dataTask?.permissions?.taskAccess ?? true)) {
        return webix.message({
            text: 'У вас нет прав на редактирование данной задачи',
            type: 'error',
        });
    }

    const allProperty = await getAllPropertyForTaskMenu();
    const dataFiles = taskId ? await getAllFilesByTask(taskId) : null;
    const formConfig = await getFormConfig(
        typeTaskValue || 0,
        taskId || 0,
        formAddTaskId,
        true,
        );

    if (taskId) {
        const projectTask = allProperty.projectsId
            .find(el => el.id === dataTask.projectInfo.id);

        if (!projectTask) {
            allProperty.projectsId.push(dataTask.projectInfo);
        }
    }

    const viewWindow = {
        view: 'window',
        id: addTaskMenutId,
        head: false,
        escHide: false,
        width: widthPageContent,
        height: heightPageContent,
        body: formAddTask(allProperty, dataTask, fieldsConfig, dataFiles),
        on: {
            onHide() {
                events.emit('taskWindow:onClose', taskId || 0);
            },
        },
    };

    if (standalone) {
        viewWindow.container = 'pageContent';
    } else {
        viewWindow.zIndex = 103;
        viewWindow.position = 'center';
        viewWindow.modal = true;
    }

    webix.ui(webix.copy(viewWindow));

    if (taskId) {
        $$(topLayoutId).addView(buttonsToolbar(dataTask, fieldsConfig), 1);
        await changeButtonsByCurrentState(dataTask);
        readonlyMode(dataTask);
        $$(uploaderId).files.clearAll();
        $$(uploaderId).files.parse(dataFiles);
        changeAmountOfFiles();
    }

    if (formConfig) changeFormState(JSON.stringify(formConfig));
    $$(addTaskMenutId).show();

    if (standalone) {
        $$(formAddTaskId).setValues({ standalone }, true);
    }
};
