import { IResponse, IContacts } from 'CommonTypes/Tasks/QuickAddTaskForm/IResponse';

export default (data: IResponse, defaultContact: IContacts | null, defaultProjectId?: number) => {
    if (defaultProjectId) {
        const defaultProfect = data.projects.find(project => project.id === +defaultProjectId);

        return defaultProfect ?? null;
    }

    if (!defaultContact || !defaultContact.projectId) return null;

    const defaultProject = data.projects.find(project => project.id === defaultContact.projectId);

    return defaultProject ?? null;
};
