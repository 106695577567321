import {
    timeTrackingToolbarId,
} from '../../../../../../config/constants.js';
import addTimeTrack from './models/addTimeTrack.js';

export default {
    id: timeTrackingToolbarId,
    view: 'toolbar',
    height: 40,
    elements: [
        {
            cols: [
                {
                    view: 'button',
                    width: 210,
                    css: 'webix_primary',
                    label: 'Добавить',
                    on: {
                        onItemClick: addTimeTrack,
                    },
                },
                {},
            ],
        },
    ],
};
