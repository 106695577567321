import insertComment from 'fsrc/AddTaskWindow/components/formAddTask/components/cellsBottomTabbar/components/taskComments/services/insertComment.js';
import di from 'Services/di.js';

export default async (eventData, messageData) => {
    const store = di.store.getState();
    const { root } = store.reducerBase;
    const { postId } = store.reducerUser;
    const { taskId } = eventData;
    const { isSend, dataMessage, currentContacts } = messageData;

    if (isSend) {
        const contacts = currentContacts.map(contact => contact.contactId);
        const dataSend = {
            id_task: taskId,
            text: dataMessage.description,
            post_id: postId,
            contacts,
        };

        webix.message({
            text: 'Отправка писем...',
            type: 'debug',
        });

        app.fetch(`${root}/ajax/gmail/v3_send_for_task.php`, dataSend, 'POST')
            .then(((res) => {
                if (!res?.success) {
                    webix.message({
                        text: 'Письма не были отправлены.',
                        type: 'error',
                    });
                }

                if (res?.success) {
                    app.fetch('ajax/gmail/outbox_queue.php?send')
                        .then((result) => {
                            webix.message({
                                text: `Отправлено писем - ${result.length ?? '0'}`,
                                type: 'success',
                            });
                        });
                }
            }));
    }

    if (!isSend) {
        const newComment = await insertComment(taskId, dataMessage.description);
        if (!newComment) {
            return webix.message({
                text: 'Не удалось отправить комментарий',
                type: 'error',
            });
        }

        if (newComment) {
            webix.message({
                text: 'Комментарий успешно добавлен',
                type: 'success',
            });
        }
    }
};
