import { initModule } from '../services/initModule.js';

export default {
    '/assets-module': {
        as: 'assets-module',
        uses: () => {
            import(
                /* webpackChunkName: "assets-module" */ '../../AssetsModules/AssetsController.js'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
