import di from 'Services/di.js';

export default async (data) => {
    const { store } = di;
    const { reducerBase } = store.getState();
    const { root } = reducerBase;

    const response = await app.fetch(`${root}/ajax/inbox_document_add.php`, data, 'POST');

    return response;
};
