export const addTaskMenutId = 'addTaskMenuId';
export const bottomTabbarId = 'bottomTabbarId';
export const formAddTaskId = 'formAddTaskId';
export const topTabbarId = 'topTabbarId';
export const descriptionTaskId = 'desc';
export const checkListId = 'checkListId';
export const requirementsId = 'requirementsId';
export const servicesId = 'servicesId';
export const subTasksId = 'subTasksId';
export const relatedTasksId = 'relatedTasksId';
export const timeManagementId = 'timeManagementId';
export const storyId = 'storyId';
export const progressTaskId = 'progress';
export const taskNameId = 'name';
export const typeTaskId = 'taskTypeId';
export const contactId = 'contact';
export const departmentId = 'department';
export const prioritiesId = 'priority';
export const projectsId = 'project';
export const userId = 'assignee';
export const roleId = 'role';
export const serviceId = 'it_service';
export const functionId = 'functionId';
export const startTaskId = 'date';
export const endTaskId = 'deadline';
export const dateTaskComplitedId = 'date_complete';
export const observersId = 'observers';
export const subprojectId = 'subprojectId';
export const planId = 'planId';
export const sprintId = 'sprint';
export const authorId = 'owner';
export const mainFormTask = 'mainFormTask';
export const formRightSidebar = 'formRightSidebar';
export const taskNumberId = 'wsId';
export const modulesId = 'modules';
export const dateTaskCreateId = 'dateCreate';
export const btnSaveId = 'btnSaveId';
export const btnClose = 'btnClose';
export const btnEdit = 'btnEdit';
export const btnSubmissionTask = 'btnSubmissionTask';
export const menuId = 'menuId';
export const checklistView = 'checklistView';
export const requirementsView = 'requirementsView';
export const servicesView = 'servicesView';
export const assetsId = 'assetsId';
export const taskInfoId = 'taskInfoId';
export const tagsId = 'tagsId';
export const btnTimerId = 'btnTimerId';
export const topMultiviewId = 'topMultiviewId';
export const bottomMultiviewId = 'bottomMultiviewId';
export const topLayoutId = 'topLayoutId';
export const serviceClassId = 'serviceClassId';
export const plannedTaskId = 'plannedTaskId';

// === Comments section ===
export const commentsId = 'commentsId';
export const commentsList = 'comments__list';
// === / Comments section ===

// =====================Подзадачи=====================
export const subtasksTableId = 'subtasks__table';
export const subtasksToolbarId = 'subtasks__toolbar';
export const subtasksFilterStateBtnsId = 'subtasks__filterState';
export const subtasksFilterInworkId = 'subtasks__inWork';
export const subtasksFilterDraftId = 'subtasks__draft';
export const subtasksFilterFinishId = 'subtasks__finish';
// ===================================================

// =====================Учет времени==================
export const timeTrackingTableId = 'timeTracking__table';
export const timeTrackingToolbarId = 'timeTracking__toolbar';
export const windowEditTimeTrackId = 'timeTracking__windowEdit';
export const formEditTimeTrackId = 'timeTracking__formEdit';
// ===================================================

// =====================Files Uploader================
export const toolbarUploaderId = 'uploader_toolbarUploaderId';
export const filesSearchId = 'uploader_filesSearchId';
export const fileDescriptionId = 'uploader_fileDescription';
export const uploaderId = 'uploader_uploaderId';
export const filesListId = 'uploader_filesListId';
export const filesId = 'uploader_filesId';
export const popupId = 'uploader_popupId';

// ==================Связанные задачи=================
export const relatedTasksTableId = 'relatedTasks__table';
export const relatedTasksToolbarId = 'relatedTasks__toolbar';
export const relatedTasksFilterStateBtnsId = 'relatedTasks__filterState';
export const relatedTasksFilterInworkId = 'relatedTasks__inWork';
export const relatedTasksFilterDraftId = 'relatedTasks__draft';
export const relatedTasksFilterFinishId = 'relatedTasks__finish';
// ===================================================

// =================История состояний=================
export const historyStatesTableId = 'historyStates__table';
export const historyStatesToolbarId = 'historyStates__toolbar';
export const windowAddHistoryItemId = 'historyStates__windowAdd';
export const formAddHistoryItemId = 'historyStates__formAdd';
// ===================================================
