import di from 'Services/di.js';

const { events } = di;

export default (dataTask, flagNewTask) => {
    const data = {
        contactId: dataTask.contact,
        completeDate: dataTask.date_complete,
        createDate: dataTask.dateCreate,
        departmentName: dataTask.department,
        id: dataTask.taskId,
        planId: dataTask.planId,
        projectId: dataTask.project,
        stateId: dataTask.currentState,
        wsId: dataTask.wsId,
        taskName: dataTask.name,
        authorName: dataTask.authorName,
        contactName: dataTask.contactName,
        moduleName: dataTask.moduleName,
        organizationName: dataTask.organizationName,
        serviceName: dataTask.serviceName,
        planName: dataTask.planName,
        stateTypeName: dataTask.stateTypeName,
        tagsName: dataTask.tagsName,
    };
    dataTask.states.forEach((el) => {
        data[`state${el.id}Data`] = dataTask[`state${el.id}Data`];
        if (el.id === dataTask.currentState) data.stateName = el.name;
    });

    if (flagNewTask) return events.emit('formAddTaskId:addNewTask', data);

    return events.emit('formAddTaskId:updateTask', data);
};
