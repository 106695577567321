import { IFormConroller } from '../types/IFormConroller';
import { IFormFieldsConfigController } from '../types/IFormFieldsConfigController';
import getCurrentFormConfig from '../service/getCurrentFormConfig';
import fieldsConfig from '../../../../AddTaskWindow/config/fieldsConfig';

export default async (
    formId: keyof IFormConroller,
    isUser: boolean,
    typeTaskId: number,
): Promise<IFormFieldsConfigController> => {
    const settings = await getCurrentFormConfig(formId, isUser, typeTaskId);
    const formConfig = fieldsConfig;

    return {
        formConfig,
        settings,
        typeTaskId,
        isUser,
    };
};
