/* eslint-disable no-underscore-dangle */
webix.protoUI({
    name: 'nic-editor',
    defaults: {
        config: {},
        value: '',
    },
    $init(config) {
        this.$view.innerHTML = "<div style='padding:10px'>Loading...</div>";
        this.$view.className += ' webix_selectable';

        this._waitEditor = webix.promise.defer();
        this.$ready.push(this._init_nic_editor);
    },
    _init_nic_editor() {
        if (this.config.cdn === false) {
            this._render_nic_editor();
            return;
        }

        const sources = [];
        const cdn = this.config.cdn ? this.config.cdn : `${window.location.origin}/js/src/Application/Lib/nicedit`;
        sources.push(`${cdn}/nicEdit.js`);

        if (!this.config.config.iconsPath) this.config.config.iconsPath = `${cdn}/nicEditorIcons.gif`;

        webix.require(sources)
            .then(webix.bind(this._render_nic_editor, this))
            .catch((e) => {
                console.log(e);
            });
    },
    _render_nic_editor() {
        if (!this._editorCss) {
            let style = '.nicEdit-panel{height:auto}\n';
            style += '.nicEdit-panelContain{border-top-width:0px !important;}\n';
            style += '.webix_selectable>div:nth-child(2){overflow-y:auto;}';
            webix.html.addStyle(style);
            this._editorCss = true;
        }

        const nic = new nicEditor(this.config.config).panelInstance(this.$view.firstChild);
        this._editor = nic.nicInstances[nic.nicInstances.length - 1];
        this._set_inner_size();

        this._waitEditor.resolve(this._editor);

        if (this._editor) this._editor.setContent(this.config.value);

        if (this._focus_await) this.focus();

        this._editor.e.addEvent('blur', webix.bind(this._onBlur, this));
    },
    _set_inner_size() {
        if (!this._editor || !this.$width) return;

        const editorView = this.$view;
        const toolbar = editorView.firstChild;

        toolbar.style.width = `${this.$width}px`;

        const toolbarHeight = toolbar.clientHeight || 0;
        const editor = toolbar.nextSibling;

        editor.style.width = `${this.$width - 20}px`; // 2x10 - padding
        editor.style.height = `${this.$height - 20 - toolbarHeight}px`; // 2x10 padding + header with borders
    },
    $setSize(x, y) {
        if (webix.ui.view.prototype.$setSize.call(this, x, y)) {
            this._set_inner_size();
        }
    },
    setValue(value, config) {
        const oldValue = this.getValue();
        this.config.value = value;
        if (this._editor) this._editor.setContent(value);
        this.callEvent('onChange', [value, oldValue, config]);
    },
    getValue() {
        return this._editor ? this._editor.getContent() : this.config.value;
    },
    focus() {
        this._focus_await = true;
        if (this._editor) this._editor.elm.focus();
    },
    getEditor(waitEditor) {
        return waitEditor ? this._waitEditor : this._editor;
    },
    _onBlur() {
        const oldValue = this.config.value;
        this.config.value = this.getValue();
        this.callEvent('onChange', [this.config.value, oldValue, 'user']);
    },
}, webix.ui.view, webix.EventSystem);
