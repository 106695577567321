import { nodePath } from 'Config/path.js';

const updateStateInHistory = async (data) => {
    const result = await webix.ajax()
        .put(`${nodePath}/tasks/updateHistoryItem`, data)
        .then(res => res.json());

    return result;
};

export default updateStateInHistory;
