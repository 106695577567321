import { uploaderFilesId } from '../config/constants.js';
import { fetchPostFile } from '../../ServicesModule/fetchService.js';

export default async () => {
    const uploader = $$(uploaderFilesId);
    if (uploader.files.data.order.length) {
        const form = new FormData();
        uploader.files.data.each((objectOfUploudedFiles) => {
            const { file } = objectOfUploudedFiles;
            form.append('path', ''); // корневой путь.
            form.append('file[]', file);
        });
        const result = await fetchPostFile(`${app.root}files/upload_several.php`, form);
        const arrayOfFilesId = result.map(el => el.id);

        return arrayOfFilesId;
    }
    return [];
};
