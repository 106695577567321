import { requirementsId, topTabbarId, topMultiviewId } from 'fsrc/AddTaskWindow/config/constants.js';

import requirementsPlaceholder from './requirementsPlaceholder.js';

export default {
    id: requirementsId,
    borderless: true,
    tabbarParent: topTabbarId,
    multiviewParent: topMultiviewId,
    rows: [
        requirementsPlaceholder,
    ],
};
