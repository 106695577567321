import { historyStatesTableId } from '../../../../../../config/constants.js';
import updateItemHistory from './models/updateItemHistory.js';
import deleteHistoryItem from './models/deleteHistoryItem.js';

export default {
    id: historyStatesTableId,
    view: 'datatable',
    select: 'row',
    resizeColumn: { size: 6 },
    editable: true,
    data: [],
    scheme: {
        $init: (obj) => {
            const parser = webix.Date.strToDate('%Y-%m-%d %H:%i:%s');
            obj.startDate = parser(obj.startDate);
            obj.endDate = parser(obj.endDate);

            return obj;
        },
    },
    columns: [
        {
            id: 'stateId',
            header: [
                { text: 'Наименование', content: 'excelFilter', mode: 'text' },
            ],
            sort: 'string',
            editor: 'select',
            fillspace: true,
            collection: [],
        },
        {
            id: 'startDate',
            header: [
                { text: 'Дата начала', content: 'excelFilter', mode: 'date' },
            ],
            sort: 'date',
            editor: 'date',
            format: webix.Date.dateToStr('%d.%m.%Y %H:%i:%s'),
            fillspace: true,
            value: new Date(),
            suggest: {
                type: 'calendar',
                body: {
                    timepicker: true,
                    icons: true,
                },
            },
        },
        {
            id: 'endDate',
            header: [
                { text: 'Дата окончания', content: 'excelFilter', mode: 'date' },
            ],
            sort: 'date',
            editor: 'date',
            format: webix.Date.dateToStr('%d.%m.%Y %H:%i:%s'),
            fillspace: true,
            suggest: {
                type: 'calendar',
                body: {
                    timepicker: true,
                    icons: true,
                },
            },
        },
        {
            id: 'durationDays',
            header: [
                { text: 'Длительность (дней)', content: 'excelFilter', mode: 'number' },
            ],
            sort: 'int',
            editor: 'text',
            validate: webix.rules.isNumber,
            fillspace: true,
        },
        {
            id: 'durationHours',
            header: [
                { text: 'Длительность (ч.)', content: 'excelFilter', mode: 'number' },
            ],
            sort: 'int',
            fillspace: true,
        },
        {
            id: 'authorName',
            header: [
                { text: 'Автор', content: 'excelFilter', mode: 'text' },
            ],
            sort: 'string',
            fillspace: true,
        },
        {
            id: '',
            header: '',
            template: '<button class="btn btn-white btn-bitbucket deleteHistoryItem"><i class="webix_icon wxi-trash"></i></button>',
            adjust: true,
        },
    ],
    onClick: {
        deleteHistoryItem,
    },
    on: {
        onBeforeEditStop: updateItemHistory,
    },
};
