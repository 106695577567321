/* eslint-disable no-underscore-dangle */
webix.protoUI({
    name: 'ckeditor5',
    $init(config) {
        let html = "<div class='webix_ck_toolbar'></div>";
        const editor = "<div class='webix_ck_editor'></div>";
        html += config.mode == 'document' ? (`<div class='webix_ck_body'>${editor}</div>`) : editor;

        this.$view.innerHTML = html;
        this._waitEditor = webix.promise.defer();
        this.$ready.push(this._require_ckeditor);
    },
    defaults: {
        config: {},
        css: { overflow: 'auto' },
    },
    _require_ckeditor() {
        if (this.config.cdn === false) {
            this._render_ckeditor();
            return;
        }

        this._render_ckeditor();
    },
    _render_ckeditor() {
        const config = webix.extend({
            toolbar: {
                shouldNotGroupWhenFull: true,
            },
            language: 'ru',
        }, this.config.config, true);

        const editor = window.ClassicEditor;

        editor.create(this.$view.querySelector('.webix_ck_editor'), config)
            .then(webix.bind(this._finalize_init, this))
            .catch((e) => {
                console.error(e);
            });
    },
    _finalize_init(editor) {
        this._tools_container = this.$view.querySelector('.webix_ck_toolbar');
        this._tools_container.appendChild(editor.ui.view.toolbar.element);
        this._body_container = this.$view.childNodes[1];
        this._editableArea = this.$view.querySelector('.ck-editor__editable');

        this._editor = editor;
        this._waitEditor.resolve(this._editor);

        // correct height on focus/blur
        editor.ui.focusTracker.on('change:isFocused', webix.bind(this._onIsFocused, this));

        this._set_height(this.$height);
        this.setValue(this.config.value);

        editor.model.document.on('change:data', webix.bind(this._onChange, this));
    },
    $setSize(x, y) {
        if (webix.ui.view.prototype.$setSize.call(this, x, y) && this._body_container) this._set_height(y);
    },
    _set_height(y) {
        const toolbar = this._tools_container;
        const toolH = toolbar ? toolbar.clientHeight + 2 : 2; // 2px for borders
        const height = `${y - toolH}px`;
        this._body_container.style.height = height;
        this._editableArea.style.height = height;
    },
    getEditor(wait) {
        return wait ? this._waitEditor : this._editor;
    },
    focus() {
        if (this._editor) {
            this._editor.focus();
        }
    },
    setValue(value, config) {
        const oldValue = this.getValue();
        this.config.value = value;
        this.getEditor(true).then((editor) => {
            if (oldValue !== value) {
                editor.setData(value);
                this.callEvent('onChange', [this.config.value, oldValue, config]);
            }
        });
    },
    getValue() {
        return this._editor ? this._editor.getData() : this.config.value;
    },
    _eventChangedFired: false,
    _onChange() {
        this._eventChangedFired = true;
    },
    _onIsFocused(ev, name, isFocused) {
        this._set_height(this.$height);

        if (!isFocused && this._eventChangedFired) {
            this._eventChangedFired = false;

            const oldValue = this.config.value;
            this.config.value = this.getValue();
            if (oldValue !== this.config.value) {
                this.callEvent('onChange', [this.config.value, oldValue, 'user']);
            }
        }
    },
}, webix.ui.view, webix.EventSystem);
