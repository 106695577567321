import createButtonsOfState from '../../models/createButtonsOfState.js';
import './assets/styles.original.scss';

export default (dataTask, fieldsConfig) => {
    const buttonsToolbar = {
        height: 40,
        cols: [
            {
                view: 'scrollview',
                id: 'scrollview',
                scroll: 'x',
                body: {
                    margin: 5,
                    cols: createButtonsOfState(dataTask, fieldsConfig),
                },
            },
        ],
        borderless: true,
    };

    return buttonsToolbar;
};
