import { initModule } from '../services/initModule.js';

export default {
    '/buttons': {
        as: 'buttons',
        uses: () => {
            import(
                /* webpackChunkName: "buttons" */ '../../ButtonsModule/ButtonsController'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
