import subtasks from '../subtasks/subtasks.js';
import timeTracking from '../timeTracking/timeTracking.js';
import relatedTasks from '../relatedTasks/relatedTasks.js';
import historyStates from '../historyStates/historyStates.js';
import { bottomMultiviewId } from '../../../../config/constants.js';
import taskCommentsTabView from './components/taskComments/taskCommentsTabVeiw.js';
import uploaderFilesTabberView from './components/taskFilesUploader/uploaderFilesTabberView.js';

export default (dataFiles) => ({
    animate: false,
    id: bottomMultiviewId,
    cells: [
        uploaderFilesTabberView(),
        taskCommentsTabView,
        subtasks(dataFiles),
        relatedTasks,
        timeTracking,
        historyStates,
    ],
});
