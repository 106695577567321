import { fetchPostFile, fetchGet } from 'Services/fetchService.js';
import { uploaderId } from '../../../../../../../config/constants.js';
import createAttachViewer from '../components/listFiles/models/createAttachViewer.js';

export default async (setfiles, currentTaskId) => {
    const form = new FormData();
    const { file } = setfiles;
    form.append('path', ''); // корневой путь.
    form.append('file[]', file);
    form.append('id_task', currentTaskId);
    await fetchPostFile(`${app.root}files/upload_several.php`, form);

    const dataFiles = await fetchGet(`${app.root}ajax/task_load_related_files_info.php?id=${currentTaskId}`);
    $$(uploaderId).files.clearAll();
    $$(uploaderId).files.parse(dataFiles);
    createAttachViewer();
};
