import React from 'react';

function DetailsAccordion({ hyperlink }: { hyperlink: string }) {
    return (
        <>
            {
                {
                    checkList: <p>checkList</p>,
                    subtask: <p>subtask</p>,
                    relatedTasks: <p>relatedTasks</p>,
                    access: <p>access</p>,
                    services: <p>services</p>,
                    timeTracking: <p>timeTracking</p>,
                    story: <p>story</p>,
                }[hyperlink]
            }
        </>
    );
}

export default DetailsAccordion;
