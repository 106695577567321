import { service } from '../../TemplatesBlock/services/user.service.js';
import getInfo from '../utils/getInfo.js';

export default {
    id: 'listTasks',
    view: 'list',
    url: 'ajax/task_templates.php',
    select: true,
    type: {
        height: 'auto',
    },
    template(data) {
        const templateData = JSON.parse(data.template);
        return `<div class="task__template">
            <span>${templateData.name}</span>
            <i id="${data.id}" class="fa fa-trash clickDelete" title="Удалить шаблон"></i>
        </div>`;
    },
    on: {
        onItemClick(id) {
            const item = this.getItem(id);
            const templateData = JSON.parse(item.template);
            getInfo(id, templateData);
            $$('menu').hide();
        },
    },
    onClick: {
        clickDelete(event, item) {
            service.deleteTemplate(item);
            $$('listTasks').remove(item);
            $$('menu').hide();
        },
    },
};
