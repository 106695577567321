import { initModule } from 'Services/initModule.js';

export default {
    '/yandex-account': {
        as: 'yandexAccount',
        uses: () => {
            import(
                /* webpackChunkName: "rolesmatrix" */ '../../YandexCalendar/controllers/AccountPageController'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
