import di from 'Services/di.js';
import {
    nameIssueId, serviceClassesId, issueDiscriptionId, solutionIssueId,
} from '../config/constants.js';
import uploudedFiles from './uploudedFiles.js';
import bugReportDestructor from '../utils/bugReportDestructor.js';
import currentData from './currentData.js';

export default async (params, callback) => {
    const { store } = di;
    const { reducerUser } = store.getState();
    const nameIssue = $$(nameIssueId).getValue();
    const issueDiscription = $$(issueDiscriptionId).getValue();
    const solutionIssue = $$(solutionIssueId).getValue();
    const solutionRow = solutionIssue ? `<hr><h2>Критерии приемки:</h2>${solutionIssue}` : '';
    const serviceClasses = $$(serviceClassesId).getValue();
    const data = {
        contact: reducerUser.contactId,
        project: params.projectId ?? 13, // TODO: УБРАТЬ НА НОВОГО BACKEND
        assignee: params.parentItem ? 0 : 35, // TODO: УБРАТЬ ПОСЛЕ НОВОГО BACKEND
        attach: await uploudedFiles(),
        name: nameIssue,
        priority: 2, // значение по умолчанию "Срочные, но не важные" (id = 2)
        bug: params.parentItem ? 0 : 1, // TODO: УБРАТЬ ПОСЛЕ НОВОГО BACKEND
        desc: currentData(issueDiscription, solutionRow, params),
        planId: params.planId ?? 0,
        serviceClassesId: serviceClasses,
    };

    if (params?.parentId) {
        data.id_parent = +params.parentId;
        data.sprintTask = params.sprintTask;
    }

    app.fetch(`${app.root}ajax/qick_add_task.php`, data, 'POST').then((res) => {
        if (res && res.success) {
            if (typeof (callback) === 'function') {
                callback({ ...data, ...res });
            } else {
                app.msg(`Создана задача №${res.id}`).then((noty) => {
                    noty.addAction('Открыть задачу', () => {
                        app.navigate(`tasks/${res.id}`);
                    });
                });
            }
        }
    });
    bugReportDestructor();
};
