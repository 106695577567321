/* eslint-disable no-undef */
import React from 'react';
import { TContainer } from 'Services/container';
import initReact from './initReact.js';

import { TUIBlock, TBlockName } from './types';
import type { createInstance, IRenderProps } from './types';

const uiBlock: TUIBlock = {
    header: { id: 'pageHeader', ui: null },
    main: { id: 'pageContent', ui: null },
};

export const getBlockId = (idName: TBlockName) => uiBlock?.[idName]?.id;

export function killWebix() {
    const uiBlockKeys = Object.keys(uiBlock) as TBlockName[];
    uiBlockKeys.forEach((keys) => {
        const value = uiBlock[keys];
        if (value.ui) {
            if ('destructor' in value.ui) value.ui.destructor();
            if ('unmount' in value.ui) value.ui.unmount();
            value.ui = null;
        }

        const element = document.getElementById(value.id);
        element?.replaceChildren();
    });

    const pageContent = document.getElementById('pageContent');
    pageContent?.classList.remove('root-flex-column', 'animated', 'fadeInRight');
}
export function getRender(di: TContainer) {
    function getView(
        config: webix.ui.baseview | typeof createInstance | JSX.Element,
    ): webix.ui.baseviewConfig {
        let view = config;
        if (typeof config === 'function') {
        // eslint-disable-next-line new-cap
            view = new (config as any)(di);

            if ('render' in view) {
                view = view.render();
            }
        }

        const newConfig = {
            ...view,
        };

        return newConfig;
    }

    function render(
        config: webix.ui.baseview | JSX.Element | typeof createInstance,
        {
            container = 'main',
            fullHeight = false,
            flexColumn = false,
            animate = true,
            displayFlex = false,
        }: IRenderProps | Partial<IRenderProps> = {},
    ) {
        if (React.isValidElement(config)) {
            const rootId = getBlockId(container);

            const rootEl = initReact(di, config, rootId, {
                fullHeight, flexColumn, container, animate, displayFlex,
            });

            uiBlock[container].ui = rootEl;
            return rootEl;
        }

        const newConfig = getView(config);
        const blockName = (newConfig.container as TBlockName | undefined) || 'main';

        newConfig.container = getBlockId(blockName);
        const ui = webix.ui(webix.copy(newConfig));
        uiBlock[blockName].ui = ui;

        if (blockName === 'main') {
            const root = document.getElementById(newConfig.container);
            if (root) {
                root.classList.add('animated', 'fadeInRight');
            }
        }

        return ui;
    }

    return {
        getView,
        render,
    };
}

export function setTitle(title: string) {
    document.title = title;
}

export default uiBlock;
