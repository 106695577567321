export default (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: {
        color: 'white',
        textTransform: 'capitalize',
        '&.Mui-selected': {
            backgroundColor: 'white',
            color: '#2f4050',
        },
    },
});
