import { IFormData } from 'Domain/LayoutConfiguration/types/IFormData';
import { formId } from 'Domain/LayoutConfiguration/config/constans';
import updateFormConfig from '../service/updateFormConfig';
import dataCreate from './dataCreate';
import changeFormState from './changeFormState';
import toggleStylesBtnSave from './toggleStylesBtnSave';
import { IFormFieldsConfigController } from '../types/IFormFieldsConfigController';

export default async (fieldsConfig: IFormFieldsConfigController): Promise<void> => {
    const formData: IFormData = ($$(formId) as webix.ui.form).getValues();
    const data = dataCreate(formData, fieldsConfig);
    const result = await updateFormConfig(data);
    if (result) {
        webix.message({
            text: 'Настройки успешно сохранены',
            type: 'success',
        });
        ($$(formId) as webix.ui.form).setDirty();
        toggleStylesBtnSave();
        if (fieldsConfig.isUser) {
            changeFormState(data.settings);
        }
    }
};
