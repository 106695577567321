/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import dayjs from 'dayjs';
import {
    useForm, UseFormReturn, UseFormProps, FormProvider,
} from 'react-hook-form';

import Grid from '@mui/material/Grid';
import { TextField } from 'Ui/Form/TextField';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';

import { IFormState } from 'fsrc/PlanDay/types/IFormState';
import { useAddEventSimpleMutation } from 'Endpoints/PlanDay/planDay.api';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentProjectId, getCurrentSubordinate, getDate } from 'fsrc/PlanDay/utils/selectors';
import { startEditing } from 'fsrc/PlanDay/utils/planDaySlice';

interface IProps {
    tabFlag?: boolean;
}

function AddEventComponent({ tabFlag = false } : IProps) {
    const dispatch = useDispatch();
    const { subordinateId: userId } = useSelector(getCurrentSubordinate);
    const startDate = useSelector(getDate);
    const projectId = useSelector(getCurrentProjectId);
    const form: UseFormReturn<IFormState, UseFormProps> = useForm<IFormState>({
        defaultValues: {
            schedulerName: '',
        },
    });

    const [addNewEvent] = useAddEventSimpleMutation();

    const onSubmit = async (formData: IFormState) => {
        const data = {
            eventName: formData.schedulerName,
            comment: '',
            userId,
            projectId: tabFlag ? null : projectId,
            eventDay: tabFlag ? dayjs().format('YYYY-MM-DD HH:mm:ss') : dayjs(startDate).format('YYYY-MM-DD HH:mm:ss'),
        };
        const result = await addNewEvent(data).unwrap();
        if (result.success) {
            form.reset({ schedulerName: '' });
        }
    };

    const handleClickCreateButton = () => {
        const value = form.getValues('schedulerName');
        const data = {
            type: 'create',
            eventData: {
                allDay: 1,
                userId,
                projectId: tabFlag ? null : projectId,
                schedulerName: value,
                startDate: dayjs(startDate).format('YYYY-MM-DD'),
                startDateEvent: tabFlag ? dayjs().format('YYYY-MM-DD') : dayjs(startDate).format('YYYY-MM-DD'),
                eventDay: dayjs(startDate).format('YYYY-MM-DD'),
                deadline: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
                stateId: 0,
            },
        };
        dispatch(startEditing(data));
        form.reset({ schedulerName: '' });
    };

    return (
        <FormProvider {...form}>
            <form
                onSubmit={form.handleSubmit(onSubmit)}
                style={{ display: 'flex', alignItems: 'center', flex: 1 }}
            >
                <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                    <Grid item sx={{ flex: 1 }}>
                        <TextField
                            name="schedulerName"
                            label="Событие"
                            fullWidth
                            required={{
                                required: {
                                    value: true,
                                    message: 'Поле не может быть пустым',
                                },
                                maxLength: {
                                    value: 256,
                                    message: 'Превышено максимальное количество символов',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Tooltip title="Создать событие">
                            <Button variant="contained" size="square" onClick={handleClickCreateButton}>
                                <Icon><i className="fa fa-tasks" /></Icon>
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
}

export default AddEventComponent;
