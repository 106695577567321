import { initModule, use } from '../services/initModule.js';

export default {
    '/quests/qst-datagrid': {
        as: 'questDatagrid',
        uses: async () => {
            await import('../Lib/Webix/Query/query.min.orig.css');
            use(await import('../Lib/Webix/Query/query.min.orig.js'), 'query.min.orig.js');

            import(
                /* webpackChunkName: "questDatagrid" */ '../../QuestModule/QuestTable/QuestController.js'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
    '/quests/questionnaire': {
        as: 'questionnaire',
        uses: () => {
            import(
                /* webpackChunkName: "questionnaire" */ '../../QuestModule/Questionnaire/QuestionnaireController.js'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
