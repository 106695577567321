import { fetchPostFile } from 'Services/fetchService.js';
import addMessageFilesToTask, { IFileByMessage } from 'fsrc/AddTaskWindow/service/addMessageFilesToTask';
import di from 'Services/di.js';
import { TFile } from '../types/TFile';

interface IFile {
    folder: string;
    id: number;
    mime: string;
    name: string;
    replace: number;
    sname: string;
}

type TFiles = IFile[];

export default async (files: TFile) => {
    if (!files.length) return [];

    const { store } = di;
    const { reducerBase } = store.getState();
    const { root } = reducerBase;
    const newFileForm = new FormData();
    const existingFilesByMessage: IFileByMessage[] = [];

    files.forEach((file) => {
        if (!file.file) {
            const {
                fileName, folder, sname, mime, fileDescription,
            } = file;
            if (!!folder && !!sname) {
                const fileData: IFileByMessage = {
                    name: fileName,
                    folder,
                    sname,
                    description: fileDescription,
                    mime: mime ?? '',
                };
                existingFilesByMessage.push(fileData);
            }
        }
        if (file.file) {
            newFileForm.append('path', '');
            newFileForm.append('file[]', file.file);
            const fileNumber = newFileForm.getAll('file[]').length - 1;
            if (file.fileDescription) newFileForm.append(`description${fileNumber}`, file.fileDescription);
        }
    });

    const addFilesToTask: number[] | [] = existingFilesByMessage.length
        ? await addMessageFilesToTask(existingFilesByMessage)
        : [];

    const result: TFiles | [] = newFileForm.has('file[]')
        ? await fetchPostFile(`${root}/files/upload_several.php`, newFileForm)
        : false;

    const filesId = result ? result.map(el => el.id) : [];

    return [...addFilesToTask, ...filesId];
};
