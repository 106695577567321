import React, { useState } from 'react';
import dayjs from 'dayjs';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';

import di from 'Services/di.js';

import { ITaskCreationResult } from 'Domain/Tasks/QuickAddTaskForm/types/ITaskCreationResult';
import { IPlanUser } from 'CommonTypes/Scheduler/IResponse';
import showTask from 'fsrc/Task/models/showTask';
import { IDataTask } from 'Domain/Tasks/QuickAddTaskForm/types/IDataTask';
import { planDayApi, useEditEventByIdMutation } from 'Endpoints/PlanDay/planDay.api';
import ConfirmDelete from './ConfirmDelete';

import classes from '../../../assets/style.scss';

interface ICardActionsGroup {
    plan: IPlanUser;
    onClickDelete?: (shedulerId: number) => Promise<void> | undefined;
    onClickEdit?: (
        schedulerName: string,
        comment: string,
        schedulerId: number,
        finished: number,
        ) => void | undefined;
}

function CardActionsGroup({
    plan, onClickDelete, onClickEdit,
}: ICardActionsGroup) {
    const [updateEvent] = useEditEventByIdMutation();
    const [open, setOpen] = useState(false);
    const { taskId } = plan;

    const createClickHandler = () => {
        const { events } = di;
        events.emit('plans__drawer:closeDrawer');

        const taskData = {
            type: 'task',
            params: {
                data: {
                    taskName: plan.schedulerName,
                    desc: plan.comment,
                    projectId: plan.projectId,
                    deadline: plan.endDateEvent,
                },
                observersIds: plan.eventContactsIds,
            },
            callback(result: ITaskCreationResult, createdTask: IDataTask) {
                if (result?.success && +(result?.id || 0) > 0) {
                    const {
                        schedulerName, finished, comment, schedulerId, startDateEvent, userId,
                    } = plan;
                    updateEvent({
                        comment,
                        finished: finished ? 1 : 0,
                        schedulerId,
                        schedulerName,
                        eventDay: dayjs(startDateEvent).format('YYYY-MM-DD'),
                        startDateEvent: plan.startDateEvent,
                        endDateEvent: plan.endDateEvent || dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
                        userId,
                        allDay: plan.allDay,
                        projectId: plan.projectId || 0,
                        participants: plan.participants,
                        contacts: plan.eventContactsIds,
                        taskId: result.id,
                    });

                    const { store } = di;
                    store.dispatch(
                        planDayApi.util.updateQueryData(
                            'getPlanDayUserAvailableTasks',
                            undefined,
                            (draft) => {
                                draft.push({
                                    id: result.id,
                                    name: createdTask.name,
                                });
                            },
                        ),
                    );
                }
            },
        };
        events.emit('tasks__formAddTask:addNewTask', taskData);
    };

    const onClickTaskOpenHandler = () => {
        if (!taskId) return false;
        const { events } = di;
        events.emit('plans__drawer:closeDrawer');
        return showTask(+taskId);
    };

    const onClickConfirm = () => setOpen(!open);

    const onClickConfirmDelete = (result: boolean) => {
        if (result) onClickDelete!(plan.schedulerId);
        onClickConfirm();
    };

    return (
        <>
            <CardActions className={`${classes.action} ${plan.taskId ? classes.action_task : classes.action_calendar}`}>
                { taskId
                    ? (
                        <Tooltip title={`Открыть задачу #${taskId}`}>
                            <IconButton
                                aria-label="open-task"
                                onClick={onClickTaskOpenHandler}
                                style={{ fontSize: '10px' }}
                            >
                                #
                                {taskId}
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Создать задачу">
                            <IconButton
                                aria-label="create"
                                onClick={createClickHandler}
                            >
                                <Icon><i className="fa fa-th-list" /></Icon>
                            </IconButton>
                        </Tooltip>
                    )}
                <Tooltip title="Редактировать">
                    <IconButton
                        aria-label="edit"
                        onClick={onClickEdit
                            ? () => onClickEdit(
                                plan.schedulerName,
                                plan.comment,
                                plan.schedulerId,
                                +plan.finished,
                            )
                            : undefined}
                    >
                        <Icon><i className="fa fa-pencil" /></Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Удалить">
                    <IconButton
                        aria-label="delete"
                        onClick={onClickConfirm}
                    >
                        <Icon><i className="fa fa-trash" /></Icon>
                    </IconButton>
                </Tooltip>
            </CardActions>
            {open && (
                <ConfirmDelete
                    eventName={plan.schedulerName}
                    onClickConfirmDelete={onClickConfirmDelete}
                />
            )}
        </>
    );
}

export default CardActionsGroup;
