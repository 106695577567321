import { initModule } from '../services/initModule.js';

export default {
    '/itservices': {
        as: 'itservices',
        uses: () => {
            import(
                /* webpackChunkName: "itservices" */ '../../ItServices/itServicesController.js'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
