/* eslint-disable react/jsx-props-no-spreading */
import React, { SyntheticEvent } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import drawerTabProps from 'Domain/Drawer/utils/drawerTabProps';
import TabPanel from '../TabPanel/TabPanel';
import TabPlanDay from '../TabPlanDay/TabPlanDay';
import TabNotification from '../TabNotification/TabNotification';
import { DRAWER_ACTIVE_TAB, IDrawerActiveTab } from '../../constants/constants';

import classes from '../../assets/style.scss';

function DrawerContent({ activeTab }: { activeTab: string }) {
    const [value, setValue] = React.useState(
        DRAWER_ACTIVE_TAB[activeTab as keyof IDrawerActiveTab],
    );

    const handleChange = (event: SyntheticEvent, newValue: number) => setValue(newValue);

    return (
        <Box className={classes.drawer}>
            <Box className={classes.drawer__tabs}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs"
                    TabIndicatorProps={{
                        hidden: true,
                    }}
                >
                    <Tab
                        label="События"
                        {...drawerTabProps(0)}
                    />
                    <Tab
                        label="Уведомления"
                        {...drawerTabProps(1)}
                    />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <TabPlanDay />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TabNotification />
            </TabPanel>
        </Box>
    );
}

export default DrawerContent;
