import { IFormConroller } from '../types/IFormConroller';
import { IFormFieldsConfigController } from '../types/IFormFieldsConfigController';
import formAddTaskConfig from './formAddTaskConfig';

export default async (
    formId: keyof IFormConroller,
    isUser: boolean,
    typeTaskId: number,
): Promise<IFormFieldsConfigController> => {
    const formConfig: IFormConroller = {
        formAddTaskId: await formAddTaskConfig(formId, isUser, typeTaskId),
    };

    return formConfig[formId];
};
