import di from 'Services/di.js';
import {
    formAddTaskId, btnSaveId, btnClose,
    btnEdit, topTabbarId, bottomTabbarId, contactId,
    observersId, btnTimerId, dateTaskComplitedId, descriptionTaskId,
} from '../config/constants.js';
import changeStateTabbar from './changeStateTabbar.js';
import createTaskInfo from './createTaskInfo.js';

export default async (dataTask) => {
    $$(btnSaveId).hide();
    $$(btnClose).show();
    $$(btnEdit).show();
    $$(btnTimerId).show();

    changeStateTabbar(topTabbarId);
    changeStateTabbar(bottomTabbarId);

    const data = dataTask ? createTaskInfo(dataTask) : null;
    if (data) {
        const { store } = di;
        const { reducerUser } = store.getState();
        const { id } = reducerUser;
        const { projectOwnerId, managerId } = dataTask;
        if (id === projectOwnerId || id === managerId) $$(dateTaskComplitedId).enable();
        $$(contactId).getList().add(data.currentContact);
        $$(observersId).getList().parse(data.currentObservers);
        $$(formAddTaskId).setValues(data);
    }
    const { elements } = $$(formAddTaskId);
    const setElements = Object.keys(elements);
    setElements.forEach((element) => {
        $$(element).define('readonly', true);
        const { config } = $$(element);
        const clear = (Object.prototype.hasOwnProperty.call(config, 'clear'));
        if (clear) {
            $$(element).define('clear', false);
            $$(element).refresh();
        }
    });

    const editor = await $$(descriptionTaskId).getEditor('wait');
    editor.enableReadOnlyMode('editor');
};
