function action(bugInfo) {
    window.events.emit('tasks__formAddTask:addNewTask', { type: 'issue', params: { data: bugInfo } });
}

export default set => ({
    view: 'button',
    width: 32,
    tooltip: 'Предложение или сообщить о проблеме',
    label: '<i class="fa fa-bullhorn"></i>',
    css: 'bitbucket default',
    click() {
        if (typeof set?.callback === 'function') {
            return set.callback(action);
        }

        return action(set?.bugInfo);
    },
});
