import React from 'react';

import ClassConstructor from 'Ui/Button/utils/constructorClass';
import IButtonProps from './types/IButtonProps';

import style from './assets/style.scss';

function Button({
    children, icon, onClick, form, active, disabled, title, ...config
}: IButtonProps) {
    const currentClass = ClassConstructor(config);

    if (form) {
        return (
            <button type="submit" form={form} className={`${style.btn} ${currentClass} ${active ? style.active : ''}`} disabled={disabled}>
                {children}
            </button>
        );
    }

    return (
        <button
            type="button"
            className={`${style.btn} ${currentClass} ${active ? style.active : ''}`}
            title={title}
            onClick={onClick}
        >
            {icon && children && <i className={`fa ${icon} ${style.margin_right}`} />}
            {icon && !children && <i className={`fa ${icon}`} />}
            {children}
        </button>
    );
}

export default Button;
