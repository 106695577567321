import { initModule, use } from 'Services/initModule.js';

export default {
    '/services-pivot': {
        as: 'services-pivot',
        uses: async () => {
            await import('../Lib/Webix/Pivot/pivot.min.orig.css');
            use(await import('../Lib/Webix/Pivot/pivot.min.orig.js'), 'pivot.min.orig.js');

            import(
                /* webpackChunkName: "servicespivot" */ '../../ServicesPivot/ServicesPivotController.js'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
