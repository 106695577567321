import React from 'react';

interface IProps {
    title: string;
}

function ReactHeaderComponent({ title }: IProps) {
    return (
        <h2>{title}</h2>
    );
}

export default ReactHeaderComponent;
