import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IIFieldsVisibleConfigReqeust } from 'CommonTypes/Forms/FormsFieldsConfig';
import getCurrentFirmByTaskIdService from 'fsrc/Sales/services/getCurrentFirmByTaskIdService';
import useFieldsVisibleHook from 'Endpoints/Forms/useFieldsVisibleHook';
import FirmSelect from './FirmSelect';
import ContragentSelect from './ContragentSelect';
import ContactSelect from './ContactSelect';
import ContractSelect from './ContractSelect';
import ContactsOtherSelect from './ContactsOtherSelect';
import TagsForTask from './TagsForTask';

type TProps = {
    taskId: number,
} & IIFieldsVisibleConfigReqeust

function OldSalesFormComponent({ taskId, formTypeId, taskTypeId }: TProps) {
    const [firmId, setFirmId] = useState<number>(0);
    const [contragentId, setContragentId] = useState<number>(0);
    const [contactId, setContactId] = useState<number>(0);
    const [contractId, setConractId] = useState<number>(0);
    const [contactsOtherIds, setContactsOtherIds] = useState<number[]>([]);
    const [routeId, setRouteId] = useState<number>(0);
    const [tagsIds, setTagsIds] = useState<number[]>([]);

    const [contrForContract, setContrForContract] = useState<number | null>(null);
    const [firmForContract, setFirmForContract] = useState<number | null>(null);
    const [projectForContract, setProjectForContract] = useState<number | null>(null);
    const [assigneeIdForContract, setAssigneeIdForContract] = useState<number | null>(null);
    const [dateForContract, setDateForContract] = useState<string | null>(null);

    const onContragentChangeHandle = useCallback((contrForContactId: number | null) => {
        setContrForContract(contrForContactId);
    }, []);

    const onFirmChangeHandle = useCallback((firmForContactId: number | null) => {
        setFirmForContract(firmForContactId);
    }, []);

    const handleChangeContact = useCallback((newContactId: number) => {
        setContactId(newContactId);
    }, []);

    const handleContractNewAdded = useCallback(
        (newContractId: number) => setConractId(+newContractId),
        [],
    );

    const handleTagsChange = useCallback((ids: number[]) => setTagsIds(ids), []);

    useEffect(() => {
        getCurrentFirmByTaskIdService(taskId).then((response) => {
            if (response?.firm?.id) {
                setFirmId(+response.firm.id);
                setFirmForContract(+response.firm.id);
            }

            if (response?.contragent?.id) {
                setContragentId(+response.contragent.id);
                setContrForContract(+response.contragent.id);
            }

            if (response?.cid) {
                setContactId(+response.cid);
            }

            if (response?.id_outbox) {
                setConractId(+response.id_outbox);
            }

            if (response?.contacts_more && response.contacts_more?.length) {
                const ids = response.contacts_more.map(contact => +contact.id);
                setContactsOtherIds(ids);
            }

            if (response?.id_project) {
                setProjectForContract(+response.id_project);
            }

            if (response?.aid) {
                setAssigneeIdForContract(+response.aid);
            }

            if (response?.date) {
                setDateForContract(response.date);
            }

            if (response?.tags && response.tags?.length) {
                const currentTagsIds = response.tags.map(tag => +tag.id);
                setTagsIds(currentTagsIds);
            }

            if (response?.tasksRoute) {
                const currentRouteId = +response.tasksRoute;
                setRouteId(currentRouteId);
            }
        });
    }, [taskId]);

    const { fieldsVisible } = useFieldsVisibleHook({ taskTypeId, formTypeId, fieldsIds: ['contactSelect', 'firmSelect', 'contragentSelect', 'contractSelect', 'contactsOtherSelect', 'tagsForTaskSelect'] });

    return (
        <Grid container columnSpacing={2} style={{ padding: '15px' }}>
            { fieldsVisible?.contactSelect
            && (
                <Grid item xs={2}>
                    <ContactSelect
                        taskId={taskId}
                        contactId={contactId}
                        setNewContact={handleChangeContact}
                    />
                </Grid>
            )}
            { fieldsVisible?.firmSelect
            && (
                <Grid item xs={3}>
                    <FirmSelect
                        taskId={taskId}
                        firmId={firmId}
                        onFirmChangeHandle={onFirmChangeHandle}
                    />
                </Grid>
            )}
            { fieldsVisible?.contragentSelect
            && (
                <Grid item xs={3}>
                    <ContragentSelect
                        taskId={taskId}
                        contragentId={contragentId}
                        onContragentChangeHandle={onContragentChangeHandle}
                    />
                </Grid>
            )}
            { fieldsVisible?.contractSelect
            && (
                <Grid item xs={4}>
                    <ContractSelect
                        taskId={taskId}
                        contractId={contractId}
                        firmId={firmForContract}
                        contragentId={contrForContract}
                        projectId={projectForContract}
                        assigneeId={assigneeIdForContract}
                        date={dateForContract}
                        handleContractAdded={handleContractNewAdded}
                    />
                </Grid>
            )}

            { fieldsVisible?.tagsForTaskSelect
            && (
                <Grid item xs={2}>
                    <TagsForTask
                        taskId={taskId}
                        tagsIds={tagsIds}
                        routeId={routeId}
                        handleTagsChange={handleTagsChange}
                    />
                </Grid>
            )}

            { fieldsVisible?.contactsOtherSelect
            && (
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Контакты
                            {` (${contactsOtherIds.length})`}
                        </AccordionSummary>
                        <AccordionDetails>
                            <ContactsOtherSelect
                                taskId={taskId}
                                contactsIds={contactsOtherIds}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            )}
        </Grid>
    );
}

export default OldSalesFormComponent;
