import React, { useState, SyntheticEvent } from 'react';
import clsx from 'clsx';

import { PROGRESS_BREAKPOINTS } from 'fsrc/TaskPanel/constants/constants'; // FIXME: Вынести в какие-то общие константы
import ValueLabelComponent from './components/ValueLabelComponent';

import StyledSlider from './assets/StyledSlider';

interface IProgressBar {
    progress: number;
    callback: (value: number | number[]) => void;
}

function ProgressBar(props: IProgressBar) {
    const { progress, callback } = props;

    const [value, setValue] = useState<number | number[]>(progress);

    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue);
    };

    const onChangeCommitted = (
        event: SyntheticEvent | Event,
        newValue: number | number[],
    ) => {
        callback(newValue);
    };

    return (
        <StyledSlider
            classes={{
                track: clsx({
                    low: value < PROGRESS_BREAKPOINTS.low,
                    medium: value >= PROGRESS_BREAKPOINTS.low
                    && value <= PROGRESS_BREAKPOINTS.medium,
                    high: value > PROGRESS_BREAKPOINTS.medium,
                }),
            }}
            value={value}
            defaultValue={value}
            onChange={handleChange}
            onChangeCommitted={onChangeCommitted}
            components={{ ValueLabel: ValueLabelComponent }}
            valueLabelDisplay="auto"
            valueLabelFormat={newValue => `${(newValue).toLocaleString()} %`}
            step={5} // FIXME: 5 шагов оставлять?
        />
    );
}

export default ProgressBar;
