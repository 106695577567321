type T = {
    id: number,
    value: string,
    searchValue: string,
    photo: string,
    disabled?: boolean,
}

type TParticipantsObject = {
    [key: number]: T,
}

function parseArrayOfParticipants(participants: string[]) {
    const result: TParticipantsObject = {};

    participants.forEach((user) => {
        const [id, value = ''] = user.split('~') as [id: number, value: string];
        const fio = value.split(' | ')?.[0]?.trim();
        result[id] = {
            id,
            value: fio,
            searchValue: value,
            photo: '',
        };
    });

    return result;
}

export default (participants: string[], ids: number[], disabled = false) => {
    const result: T[] = [];
    const parsedParticipants = parseArrayOfParticipants(participants);
    ids.forEach((id) => {
        if (disabled) {
            result.push({ ...parsedParticipants[id], disabled: true });
        } else {
            result.push(parsedParticipants[id]);
        }
    });

    return result;
};
