import { storyId, bottomTabbarId, bottomMultiviewId } from '../../../../config/constants.js';
import historyStatesTable from './components/historyStatesTable/historyStatesTable.js';
import toolbar from './components/toolbar/toolbar.js';

export default {
    id: storyId,
    tabbarParent: bottomTabbarId,
    multiviewParent: bottomMultiviewId,
    rows: [
        toolbar,
        historyStatesTable,
    ],
};
