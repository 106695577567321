import { ISetContragentsOldStrucutreRequest } from 'CommonTypes/Contragents/ContragentsOldStrucutre';
import { oldApi } from 'Config/path.js';
import { fetchPostFile as fetchPost } from 'Services/fetchService.js';

export default async ({ taskId, contragentId }: ISetContragentsOldStrucutreRequest) => {
    const form = new FormData();
    form.append('id_task', String(taskId));
    form.append('id_contragent', String(contragentId));
    form.append('type', 'task_contragent');
    const response: number = await fetchPost(`${oldApi}/task_contragent_set.php`, form);

    return Number.isFinite(response) && +response > 0;
};
