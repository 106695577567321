import React from 'react';
import IUser from 'CommonTypes/Users/IUser';

import { Autocomplete } from 'Ui/Form/Autocomplete';
import filterOptionsBySearchValue from '../models/filterOptionsBySearchValue';

function EventParticipantsSection({ options = [] }: { options: IUser[] }) {
    return (
        <Autocomplete
            name="participants"
            label="Участники"
            options={options}
            blurOnSelect
            multiple
            listValue="searchValue"
            limitTags={1}
            filterOptions={filterOptionsBySearchValue}
        />
    );
}

export default EventParticipantsSection;
