/* eslint-disable react/jsx-props-no-spreading */
import React,
{ forwardRef, createContext, useContext } from 'react';
import { FixedSizeList } from 'react-window';
import renderRow from './renderRow';

const OuterElementContext = createContext({});
const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = useContext(OuterElementContext);

    return <div ref={ref} {...props} {...outerProps} />;
});

const ListboxComponent = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
>((props, ref) => {
    const { children, ...other } = props;
    const itemCount = (children as any[]).length;
    const itemSize = 37;

    const getHeight = () => 8 * itemSize;

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <FixedSizeList
                    itemData={children}
                    height={getHeight() + 2 * 9}
                    width="100%"
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={itemSize}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </FixedSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

export default ListboxComponent;
