import postTaskPriceItemRecompute from '../services/postTaskPriceItemRecompute.js';

import { ITaskPriceItemRecompute } from '../types/ITaskPriceItemRecompute';

function taskPriceRecompute(idTaskPrice: string, income: number) {
    postTaskPriceItemRecompute(idTaskPrice, income)
        .then((res: ITaskPriceItemRecompute) => {
            if (!res) return console.warn(res);

            return webix.message({
                text: 'Значения пересчитаны',
                type: 'success',
            });
        });
}

export default taskPriceRecompute;
