import days from './adapters/days';
import hours from './adapters/hours';
import months from './adapters/months';
import workingDays from './adapters/workingDays';

export default {
    356: hours,
    359: days,
    362: months,
    3591: workingDays,
};
