import stringFromParams from '../utils/stringFromParams.js';

export default (issueDiscription, solutionIssue, params) => {
    const currentDate = new Date();
    const { userAgent } = navigator;
    const { href, search, hash } = window.location;
    const extraParams = params?.data ? stringFromParams(params.data) : '';
    const currentData = `
    ${issueDiscription}
    ${solutionIssue}<pre>
    ${userAgent}\r\n
    ${currentDate}\r\n
    ${href}\r\n
    ${search}\r\n
    ${hash}
    ${extraParams}</pre>`;

    return currentData;
};
