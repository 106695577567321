import { initModule } from '../services/initModule.js';

export default {
    '/buttons-mui': {
        as: 'buttons-mui',
        uses: () => {
            import(
                /* webpackChunkName: "buttonsMui" */ '../../ButtonsMuiModule/ButtonsMuiController'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
