import { initModule } from '../services/initModule.js';

export default {
    '/sprints-table': {
        as: 'sprints-table',
        uses: () => {
            import(
                /* webpackChunkName: "sprints-table" */ '../../SprintsTableModule/SprintsTableController.js'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
