import {
    IFieldsVisibleConfig,
    IFieldsVisibleConfigSet,
    IFieldsVisibleConfigSetRequest,
    IFieldsVisibleConfigSetResponse,
    IIFieldsVisibleConfigReqeust,
} from 'CommonTypes/Forms/FormsFieldsConfig';
import { IFormsType } from 'CommonTypes/Forms/FormsTypes';
import emptySplitApi from 'Endpoints/emptySplitApi';

export const formsApi = emptySplitApi.injectEndpoints({
    endpoints: build => ({

        getFormsTypes: build.query<IFormsType[], void>({
            query: () => ({
                url: 'form/types',
            }),
        }),

        getFormsFieldsVisibleConfig: build.query<
            IFieldsVisibleConfigSet[],
            IIFieldsVisibleConfigReqeust
        >({
            query: params => ({
                url: 'form/fields/visible-config',
                params,
            }),
            transformResponse(respone: IFieldsVisibleConfig[]) {
                const result = respone.map(item => ({
                    id: item.id,
                    fieldId: item.fieldId,
                    name: item.name,
                    checked: Boolean(item.visible),
                }));

                return result;
            },
            providesTags: (result, error, { formTypeId, taskTypeId }) => [
                { type: 'FormsFieldsVisibleConfig', formTypeId, taskTypeId },
            ],
        }),

        setFormsFieldsVisibleConfig: build.mutation<
            IFieldsVisibleConfigSetResponse,
            IFieldsVisibleConfigSetRequest
        >({
            query: params => ({
                url: 'form/fields/visible-config',
                body: params,
                method: 'PATCH',
            }),
            invalidatesTags: (result, error, { formTypeId, taskTypeId }) => [
                { type: 'FormsFieldsVisibleConfig', formTypeId, taskTypeId },
                { type: 'TasksTypesSimpleSet', formTypeId },
            ],
        }),

    }),
});

export const {
    useGetFormsTypesQuery,
    useGetFormsFieldsVisibleConfigQuery,
    useSetFormsFieldsVisibleConfigMutation,
} = formsApi;
