import { formAddTaskId } from '../../../../../../../config/constants.js';
import addFilesToTask from './addFilesToTask.js';

export default (setfiles) => {
    const values = $$(formAddTaskId).getValues();
    const { taskId } = values;

    const currentTaskId = +taskId;
    const newTask = Number.isNaN(currentTaskId);

    return !newTask ? addFilesToTask(setfiles, currentTaskId) : false;
};
