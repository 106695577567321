export default (currentNewValueId, id) => {
    const { value } = $$(id).getList().getItem(currentNewValueId);
    switch (id) {
    case 'modules':
        webix.message({
            text: `${value} не является модулем`,
            type: 'error',
        });
        break;
    case 'functionId':
        webix.message({
            text: `${value} не является функцией`,
            type: 'error',
        });
        break;
    default:
        break;
    }
};
