import React from 'react';
import { useGetTimesheetsCountByTaskIdQuery } from 'Endpoints/Timesheets/timesheets.api';

interface IProps {
    taskId: number;
}

function TimesheetTabCount({ taskId }: IProps) {
    const { data } = useGetTimesheetsCountByTaskIdQuery({ taskId });

    return (
        <>
            { Boolean(data?.count) && (
                <span>
                    (
                    {data?.count}
                    )
                </span>
            )}
        </>
    );
}

export default TimesheetTabCount;
