import React from 'react';

type TProps = {
    value: boolean;
}

function BulletMarkMatrix({ value }: TProps) {
    return (
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <div style={{
                borderRadius: '15px',
                width: '15px',
                height: '15px',
                border: `1px solid ${value ? 'green' : 'gray'}`,
                backgroundColor: value ? 'green' : 'white',
            }}
            />
        </div>
    );
}

export default BulletMarkMatrix;
