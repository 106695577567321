import React, { useState, useRef, useEffect } from 'react';
import { Paper, PaperProps } from '@mui/material';
import Draggable from 'react-draggable';
import { Resizable } from 're-resizable';

import { TPaperProps } from '../types/TPaperProps';

function PaperComponent(props: PaperProps<'div', TPaperProps>) {
    const {
        fullScreen,
        minWidthModal,
        widthModal,
        heightModal,
        draggableWindow,
        resizable,
        callback,
        ...paperProps
    } = props;
    const transformValue = useRef('');
    const nodeRef = useRef<HTMLDivElement | null>(null);
    const boxRef = useRef<Resizable | null>(null);
    const [boxWidth, setBoxWidth] = useState<string | number>(widthModal || 900);
    const [boxHeight, setBoxHeight] = useState<string | number>(heightModal || 600);

    useEffect(() => {
        if (fullScreen) {
            boxRef?.current?.updateSize({ width: '100vw', height: '100vh' });
            if (nodeRef.current) {
                transformValue.current = nodeRef.current.style.transform;
                nodeRef.current.style.transform = 'translate(0px, 0px)';
            }
        } else {
            boxRef?.current?.updateSize({ width: boxWidth, height: boxHeight });
            if (nodeRef.current) {
                nodeRef.current.style.transform = transformValue.current;
            }
        }
    }, [fullScreen]);

    // eslint-disable-next-line react/jsx-props-no-spreading
    const dialogContent = <Paper {...paperProps} />;

    const modalResizableBlock = (
        <div ref={nodeRef}>
            { resizable ? (
                <Resizable
                    minWidth={minWidthModal}
                    ref={(c) => { boxRef.current = c; }}
                    defaultSize={{
                        width: boxWidth,
                        height: boxHeight,
                    }}
                    resizeRatio={2}
                    onResizeStop={(e, direction, ref) => {
                        const { width } = ref.style;
                        const { height } = ref.style;

                        if (callback) callback({ width, height });

                        setBoxWidth(width);
                        setBoxHeight(height);
                    }}
                >
                    { dialogContent }
                </Resizable>
            ) : dialogContent }
        </div>
    );

    return (
        <Draggable
            axis={!fullScreen ? 'both' : 'none'}
            handle="#dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
            nodeRef={nodeRef}
        >
            {modalResizableBlock}
        </Draggable>
    );
}

export default PaperComponent;
