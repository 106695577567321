import React from 'react';
import Chip from '@mui/material/Chip';

import classes from '../../assets/style.scss';

function StateButtons() {
    const onClickChip = () => {
        console.log('Нажали на чип');
    };

    return (
        <div className={classes['state-chips']}>
            <Chip label="Черновик" color="blue" size="medium" onClick={onClickChip} />
            <Chip label="К выполнению" color="lightOrange" onClick={onClickChip} />
            <Chip label="В работе" color="lightPrimary" onClick={onClickChip} />
            <Chip label="На проверке" color="lightInfo" onClick={onClickChip} />
            <Chip label="Остановлено" color="lightViolet" onClick={onClickChip} />
            <Chip label="Завершено" color="lightPink" onClick={onClickChip} />
            <Chip label="Аннулировано" color="lightRed" onClick={onClickChip} />
        </div>
    );
}

export default StateButtons;
