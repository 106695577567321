import { TTypeTask } from '../types/TTypeTask';

export default (type: TTypeTask) => {
    const title = {
        task: 'Создать задачу',
        issue: 'Сообщить о проблеме',
        taskByInputMail: 'Создать задачу',
        submissionTask: 'Создать связанную задачу',
        subTask: 'Создать подзадачу',
        taskMessage: 'Создать связанный процесс',
    };

    return title[type];
};
