import dayjs from 'dayjs';
import di from 'Services/di.js';
import defaultTasksConfig from 'Config/default/defaultTasksConfig';

import { IResponse } from 'CommonTypes/Tasks/QuickAddTaskForm/IResponse';

import getMessageData from '../../services/getMessageData';
import getDefaultContact from './getDefaultContact';
import getDefaultObservers from './getDefaultObservers';
import getDefaultServiceClasse from './getDefaultServiceClasse';
import getDefaultProject from './getDefaultProject';
import getDefaultTaskType from './getDefaultTaskType';
import getDefaultAssignee from './getDefaultAssignee';
import getDefaultFiles from './getDefaultFiles';
import getSubdivision from './getSubdivision';

export default async (data: IResponse, messageId: string) => {
    const { store } = di;
    const { reducerUser } = store.getState();
    const { taskTypeId, id } = reducerUser;

    const messageDataByMessageId = await getMessageData(messageId!);
    const { messageData, messageFiles } = messageDataByMessageId;

    const {
        desc,
        observersId,
        contactId,
        taskName,
    } = messageData;

    const replyPrefixesFromEmailSubject = /((?:[\t ]*(?:R|RE|F|FW|FWD)+(?:\[[0-9]+\])?:[\t ]*)*(?:\[[Зз]адача [0-9]+\][\t ]*))|((?:[\t ]*(?:R|RE|F|FW|FWD)+(?:\[[0-9]+\])?:[\t ]*)+)/gim;

    const doctype = data.docTypes.find(type => type.id === 1);
    const currentDayEnd = dayjs().endOf('day');
    const indate = currentDayEnd;
    const deadline = currentDayEnd;

    const name = taskName.replace(replyPrefixesFromEmailSubject, '');

    const contact = getDefaultContact(data.contacts, contactId);
    const phone = contact?.phone || '';
    const orgstructure = getSubdivision(data.orgstructure, contact?.subdivisionId || 0);

    const observersIds = observersId ? observersId.split(',').map(observerId => +observerId) : null;
    const observers = observersIds ? getDefaultObservers(
        data.contacts,
        observersIds,
    ) : [];

    const serviceClasse = getDefaultServiceClasse(
        data.serviceClasses,
        defaultTasksConfig.taskByInputMail.serviceClassId,
    );

    const project = getDefaultProject(
        data,
        contact,
    );

    const taskTypes = getDefaultTaskType(
        data.taskTypes,
        defaultTasksConfig.taskByInputMail.taskTypeId,
        project?.taskTypeId || (taskTypeId ?? 0),
    );

    const assignee = getDefaultAssignee(
        data.users,
        id,
    );

    const filesUploader = getDefaultFiles(
        messageFiles,
    );

    return {
        doctype,
        indate,
        deadline,
        desc,
        taskName: name,
        contact,
        phone,
        orgstructure,
        observers,
        serviceClasse,
        project,
        taskTypes,
        assignee,
        filesUploader,
        uid: messageId!,
    };
};
