import SpinnerRotatingPlane from './SpinnerRotatingPlane';
import SpinnerDoubleBounce from './SpinnerDoubleBounce';
import SpinnerWave from './SpinnerWave';
import SpinnerWanderingCubes from './SpinnerWanderingCubes';
import SpinnerPulse from './SpinnerPulse';
import SpinnerChasingDots from './SpinnerChasingDots';
import SpinnerThreeBounce from './SpinnerThreeBounce';
import SpinnerCircle from './SpinnerCircle';
import SpinnerCubeGrid from './SpinnerCubeGrid';
import SpinnerFadingCircle from './SpinnerFadingCircle';

const spinnersTypes = {
    rotatingPlane: SpinnerRotatingPlane,
    doubleBounce: SpinnerDoubleBounce,
    wave: SpinnerWave,
    wanderingCubes: SpinnerWanderingCubes,
    pulse: SpinnerPulse,
    chasingDots: SpinnerChasingDots,
    threeBounce: SpinnerThreeBounce,
    circle: SpinnerCircle,
    cubeGrid: SpinnerCubeGrid,
    fadingCircle: SpinnerFadingCircle,
};

export default function Spinner({ type = 'wave' }: { type?: keyof typeof spinnersTypes}) {
    return spinnersTypes[type]();
}
