import { projectsId } from '../../../config/constants.js';

export default (dataTask) => {
    const { project } = dataTask;
    const requestsProjectsList = $$('requests-projects-filter').serialize().reduce((acc, el) => {
        if (+el.id > 0) acc.push(el.id);
        return acc;
    }, []);
    switch (true) {
    case requestsProjectsList.includes(project): {
        const itemCurrent = $$('requests-projects-filter').getItem(project);
        const itemTotal = $$('requests-projects-filter').getItem(0);
        itemCurrent.task_count += 1;
        itemTotal.task_count += 1;
        $$('requests-projects-filter').updateItem(project, itemCurrent);
        $$('requests-projects-filter').updateItem(0, itemTotal);
        return 'updated';
    }
    default: {
        const itemCurrent = $$(projectsId).getList().getItem(project);
        const itemTotal = $$('requests-projects-filter').getItem(0);
        itemCurrent.task_count = 1;
        itemCurrent.name = itemCurrent.value;
        itemTotal.task_count += 1;
        $$('requests-projects-filter').add(itemCurrent);
        $$('requests-projects-filter').updateItem(0, itemTotal);
        return 'added';
    }
    }
};
