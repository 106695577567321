import Slider, { sliderClasses } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

const StyledSlider = styled(Slider)(({ theme }) => ({
    display: 'flex',
    height: '15px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    backgroundColor: '#D9D9D9',
    [`& .${sliderClasses.rail}`]: {
        backgroundColor: 'transparent',
    },
    [`& .${sliderClasses.track}`]: {
        border: 'transparent',
        height: '100%',
        transition: theme.transitions.create('background-color', {
            duration: theme.transitions.duration.shorter,
        }),
        '&.low': {
            backgroundColor: '#f499a2',
        },
        '&.medium': {
            backgroundColor: '#facd9b',
        },
        '&.high': {
            backgroundColor: '#75d1be',
        },
    },
    [`& .${sliderClasses.thumb}`]: {
        background: '#D9D9D9',
        '&:hover': {
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
        },
    },
}));

export default StyledSlider;
