import { saveOptionsService } from 'Domain/Options/optionsService.js';

export default (idTable: string) => {
    const currentOptions = ($$(idTable) as webix.ui.datatable).getState();
    const {
        hidden, size, order, ids,
    } = currentOptions;
    const saveOptions = [{ hidden }, { size }, { order }, { ids }];
    saveOptionsService(idTable, saveOptions)
        .then((result) => {
            const { success } = result;

            if (success) return webix.message({ text: 'Конфигурация таблицы успешно сохранена', type: 'success' });

            return webix.message(
                {
                    text: 'Конфигурация таблицы не сохранена. Попробуйте изменить конфигурации и сохранить повторно',
                    type: 'error',
                },
            );
        });
};
