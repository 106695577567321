import { IFormData } from '../types/IFormData';
import toggleTabbar from './toggleTabbar';

interface IExtendedBaseviewConfig extends webix.ui.baseviewConfig {
    tabbarParent?: string;
    multiviewParent?: string;
}

export default (fieldsConfig: string): void => {
    const fields: IFormData = JSON.parse(fieldsConfig);
    const fieldNames = Object.keys(JSON.parse(fieldsConfig));
    fieldNames.forEach((el) => {
        const fieldId = el.slice(el.indexOf('__') + 2);
        const tabbarId = ($$(fieldId)?.config as IExtendedBaseviewConfig)?.tabbarParent;
        const multiviewId = ($$(fieldId)?.config as IExtendedBaseviewConfig)?.multiviewParent;
        if (fields[el]) {
            if (tabbarId && multiviewId) return toggleTabbar(tabbarId, multiviewId, fieldId, true);
            return $$(fieldId)?.show();
        }
        if (tabbarId && multiviewId) toggleTabbar(tabbarId, multiviewId, fieldId, false);
        return $$(fieldId)?.hide();
    });
};
