import { checkListId, formAddTaskId, checklistView } from 'fsrc/AddTaskWindow/config/constants.js';
import checklistLayout from './component/checklistLayout.js';

export default () => {
    const values = $$(formAddTaskId).getValues();
    const { taskId, project } = values;

    if (+taskId && project && !$$(checklistView)) {
        $$(checkListId).removeView('checklist__placeholder');
        $$(checkListId).addView(webix.copy(checklistLayout(+taskId, +project)));
    }
};
