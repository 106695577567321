import { initModule, use } from 'Services/initModule.js';

export default {
    '/wkan': {
        as: 'webix-kanban',
        uses: async () => {
            const lib = await Promise.all([
                import('Lib/Webix/Kanban/kanban.min.orig.js'),
                import('Lib/Webix/Kanban/kanban.min.orig.css'),
            ]);

            use(lib[0], 'kanban.min.orig.js');

            import(
                /* webpackChunkName: "wkan" */ 'fsrc/KanbanWebix/KanbanWebixController'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
