import emptySplitApi from 'Endpoints/emptySplitApi';
import container from 'Services/container';

import { IResponseGetCommentsByTaskId, TResponsePostCommentByTaskId } from 'CommonTypes/Tasks/Comments/IResponse';
import dayjs from 'dayjs';

interface IGetCommentByTaskId {
    taskId: number;
    pos: number;
    count: number;
}

interface IPostCommentByTaskId {
    taskId: number;
    comment: string;
}

export const commentsApi = emptySplitApi.injectEndpoints({
    endpoints: build => ({
        getCommentsByTaskId: build.query<IResponseGetCommentsByTaskId, IGetCommentByTaskId>({
            query: data => ({
                url: '/comments',
                params: data,
            }),
            serializeQueryArgs: ({ endpointName }) => endpointName,
            merge: (currentCache, newItems) => {
                currentCache.push(...newItems);
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
        }),
        postCommentByTaskId: build.mutation<TResponsePostCommentByTaskId, IPostCommentByTaskId>({
            query: data => ({
                url: '/comments',
                method: 'POST',
                body: data,
            }),
            async onQueryStarted({ comment }, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                if (!data) return;
                dispatch(
                    commentsApi.util.updateQueryData(
                        'getCommentsByTaskId',
                        undefined as unknown as IGetCommentByTaskId,
                        (comments) => {
                            const { reducerUser } = container.store.getState();
                            const { name, photo, id } = reducerUser;
                            comments.unshift({
                                commentId: data,
                                commentText: comment,
                                userId: id,
                                userName: name,
                                userPhoto: photo,
                                commentsCount: comments?.at(-1)?.commentsCount ?? 0,
                                recipients: null,
                                commentDate: dayjs().toString(),
                                flags: {
                                    flagBug: 0,
                                    flagEmail: 0,
                                    flagState: 0,
                                    flagDeadline: 0,
                                },
                            });

                            return comments;
                        },
                    ),
                );
            },
        }),
    }),
    overrideExisting: false,
});

export const { useGetCommentsByTaskIdQuery, usePostCommentByTaskIdMutation } = commentsApi;
