import React, { memo } from 'react';
import { Button, Icon, Tooltip } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

import { IReducer } from '../../models/reducer';
import SwitchStyle from '../../assets/SwitchStyle';

import classes from '../../assets/style.scss';

function Footer({
    handleSubmit, type, checked, dispatch, saveOptions,
}:
    { handleSubmit: () => void, type: string, checked: boolean, dispatch: React.Dispatch<IReducer>,
        saveOptions: () => void;
}) {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: 'changeViewMiniForm',
            payload: event.target.checked,
        });
    };

    return (
        <div className={classes.footer_wrapper}>
            <div className={classes.footer_wrapper__settings}>
                <Tooltip title="Сохранить настройки формы">
                    <Button variant="contained" color="secondary" size="square" onClick={saveOptions}>
                        <Icon><i className="fa fa-save" /></Icon>
                    </Button>
                </Tooltip>
            </div>
            <div className={classes.footer_wrapper__btns}>
                <Button
                    id="create"
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Создать задачу
                </Button>
                <Button
                    id="start"
                    variant="outlined"
                    onClick={handleSubmit}
                >
                    и приступить к работе
                </Button>
                <Button
                    id="favorite"
                    variant="outlined"
                    onClick={handleSubmit}
                >
                    и добавить в избранное
                </Button>
                <Button
                    id="end"
                    variant="outlined"
                    onClick={handleSubmit}
                >
                    и завершить
                </Button>
                {type === 'taskMessage' && (
                    <Button
                        id="send"
                        variant="outlined"
                        onClick={handleSubmit}
                    >
                        и отправить
                    </Button>
                )}
                <Button
                    id="template"
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit}
                >
                    Создать шаблон
                </Button>
            </div>
        </div>
    );
}

export default memo(Footer);
