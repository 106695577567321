import type { createInstance } from '../config/types';
import diType from './container';

const getFactory = (di: typeof diType) => (
    function get(ClassName: typeof createInstance, args: any) {
        return new (ClassName as any)(di, args);
    }
);

export default getFactory;
