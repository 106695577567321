import { descriptionTaskId, topTabbarId, topMultiviewId } from '../../../../config/constants.js';
import checklistPlaceholder from './component/checklistPlaceholderView.js';
import requirementsPlaceholder from './component/requirements/requirementsPlaceholderView.js';
import servicesPlaceholder from './component/servicesPlaceholder/servicesPlaceholderView.js';

export default {
    id: topMultiviewId,
    animate: false,
    fitBiggest: true,
    cells: [
        {
            id: descriptionTaskId,
            name: descriptionTaskId,
            tabbarParent: topTabbarId,
            multiviewParent: topMultiviewId,
            view: 'ckeditor5',
        },
        servicesPlaceholder,
        checklistPlaceholder,
        requirementsPlaceholder,
    ],
};
