import React, { Dispatch } from 'react';

import { Confirm } from 'Ui/Modal';
import { IReducer } from '../../models/reducer';
import handleOnClose from '../../models/handleOnClose';

function ModalConfirm({ dispatch }: { dispatch: Dispatch<IReducer> }) {
    const onCloseConfirm = (result: boolean) => {
        if (result) handleOnClose();

        dispatch({
            type: 'openConfirm',
            payload: false,
        });
    };

    return (
        <Confirm
            open
            title="Вы уверены, что хотите выйти?"
            onClose={onCloseConfirm}
        >
            <p>
                <strong>Предупреждение!</strong>
                <br />
                Данные формы сохранены не будут.
            </p>
        </Confirm>
    );
}

export default ModalConfirm;
