import emptySplitApi from 'Endpoints/emptySplitApi';
import IUser from 'CommonTypes/Users/IUser';

export const usersApi = emptySplitApi
    .injectEndpoints({
        endpoints: build => ({

            getUsersActiveSimpleSet: build.query<IUser[], void>({
                query: () => ({
                    url: 'users',
                }),
            }),

            getUsersWithBlock: build.query<IUser[], void>({
                query: () => ({
                    url: 'users/with-block',
                }),
            }),
        }),
    });

export const {
    useGetUsersActiveSimpleSetQuery,
    useGetUsersWithBlockQuery,
} = usersApi;
