import changeTabHandler from 'fsrc/AddTaskWindow/models/changeTabHandler.js';
import {
    bottomTabbarId, commentsId, filesId, subTasksId, relatedTasksId, timeManagementId, storyId,
} from '../../../../config/constants.js';

export default {
    id: bottomTabbarId,
    view: 'tabbar',
    multiview: true,
    value: filesId,
    borderless: true,
    options: [
        { value: 'Файлы (0)', id: filesId },
        { value: 'Комментарии (0)', id: commentsId, disabled: true },
        { value: 'Подзадачи (0)', id: subTasksId, disabled: true },
        { value: 'Связанные задачи(0)', id: relatedTasksId, disabled: true },
        { value: 'Учёт времени(0)', id: timeManagementId, disabled: true },
        { value: 'История', id: storyId, disabled: true },
    ],
    on: {
        onChange: changeTabHandler,
    },
};
