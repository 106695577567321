/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { ITaskViewFormState } from '../types/IState';

const INITIAL_STATE: ITaskViewFormState = {
    visibleAccordions: [],
};

const taskViewFormSlice = createSlice({
    name: 'TASK_VIEW_FORM_SLICE',
    initialState: INITIAL_STATE,
    reducers: {
        addVisibleAccordion: (state, action) => {
            state.visibleAccordions = [...state.visibleAccordions, action.payload];
        },
        deleteVisibleAccordion: (state, action) => {
            state.visibleAccordions = action.payload;
        },
    },
});

export const {
    addVisibleAccordion,
    deleteVisibleAccordion,
} = taskViewFormSlice.actions;

export default taskViewFormSlice.reducer;
