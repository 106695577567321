import React from 'react';
import { createTheme } from '@mui/material/styles';
import { ruRU } from '@mui/material/locale';
import { ruRU as dataGridruRU } from '@mui/x-data-grid';
import { ruRU as dataGridPremiumruRU } from '@mui/x-data-grid-premium';
import { ruRU as dataGridProruRU } from '@mui/x-data-grid-pro/locales';
import type {} from '@mui/x-data-grid/themeAugmentation';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import type {} from '@mui/x-data-grid-premium/themeAugmentation';

declare module '@mui/material/Button' {
    interface ButtonPropsSizeOverrides {
        wm: true;
        mini: true;
        superMini: true;
        square: true;
    }
}
declare module '@mui/material/ToggleButtonGroup' {
    interface ToggleButtonGroupPropsColorOverrides {
        outlinedPrimary: true;
        outlinedError: true;
        outlinedWarning: true;
        outlinedInfo: true;
        outlinedSuccess: true;
    }
}
declare module '@mui/material/ToggleButton' {
    interface ToggleButtonPropsColorOverrides {
        outlinedPrimary: true;
        outlinedError: true;
        outlinedWarning: true;
        outlinedInfo: true;
        outlinedSuccess: true;
    }
}
declare module '@mui/material/Paper' {
    interface PaperPropsVariantOverrides {
        plan: true;
        kanban: true;
    }
}

declare module '@mui/material/styles' {
    interface TypographyVariants {
        planTitle: React.CSSProperties;
        planSubtitle: React.CSSProperties;
        planSmallText: React.CSSProperties;
        kanbanTitle: React.CSSProperties;
        kanbanSubtitle: React.CSSProperties;
        kanbanSmallText: React.CSSProperties;
    }
    interface TypographyVariantsOptions {
        planTitle: React.CSSProperties;
        planTitleS: React.CSSProperties;
        planSubtitle: React.CSSProperties;
        planSmallText: React.CSSProperties;
        kanbanTitle: React.CSSProperties;
        kanbanSubtitle: React.CSSProperties;
        kanbanSmallText: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        planTitle: true;
        planTitleS: true;
        planSubtitle: true;
        planSmallText: true;
        kanbanTitle: true;
        kanbanSubtitle: true;
        kanbanSmallText: true;
    }
}

declare module '@mui/material/styles' {
    interface Palette {
        outlinedPrimary: Palette['primary'];
        outlinedError: Palette['error'];
        outlinedWarning: Palette['warning'];
        outlinedInfo: Palette['info'];
        outlinedSuccess: Palette['success'];
        blue?: Palette['success'];
        lightOrange?: Palette['warning'];
        lightPrimary?: Palette['primary'];
        lightInfo?: Palette['info'];
        lightViolet?: Palette['error'];
        lightPink?: Palette['error'];
        lightRed?: Palette['error'];
    }
    interface PaletteOptions {
        outlinedPrimary?: PaletteOptions['primary'];
        outlinedError?: PaletteOptions['error'];
        outlinedWarning?: PaletteOptions['warning'];
        outlinedInfo?: PaletteOptions['info'];
        outlinedSuccess?: PaletteOptions['success'];
        blue?: PaletteOptions['success'];
        lightOrange?: PaletteOptions['warning'];
        lightPrimary?: PaletteOptions['primary'];
        lightInfo?: PaletteOptions['info'];
        lightViolet?: PaletteOptions['error'];
        lightPink?: PaletteOptions['error'];
        lightRed?: PaletteOptions['error'];
    }
}

declare module '@mui/material/Chip' {
    interface ChipPropsColorOverrides {
        blue: true;
        lightOrange: true;
        lightPrimary: true;
        lightInfo: true;
        lightViolet: true;
        lightPink: true;
        lightRed: true;
    }
}

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1ab394', light: '#cdffda', contrastText: '#fff' },
            error: { main: '#ed5565', light: '#f8d7da', contrastText: '#fff' },
            warning: { main: '#f8ac59', light: '#fff3cd', contrastText: '#fff' },
            info: { main: '#23c6c8', light: '#cdf2ff', contrastText: '#fff' },
            success: { main: '#1c84c6', light: '#d2f0fa', contrastText: '#fff' },
            secondary: { main: '#ffffff', contrastText: '#676a6c' },

            outlinedPrimary: { main: '#ffffff', contrastText: '#1ab394' },
            outlinedError: { main: '#ffffff', contrastText: '#ed5565' },
            outlinedWarning: { main: '#ffffff', contrastText: '#f8ac59' },
            outlinedInfo: { main: '#ffffff', contrastText: '#23c6c8' },
            outlinedSuccess: { main: '#ffffff', contrastText: '#1c84c6' },

            blue: { main: '#ffffff', contrastText: '#808080' },
            lightOrange: { main: '#ffffff', contrastText: '#808080' },
            lightPrimary: { main: '#ffffff', contrastText: '#808080' },
            lightInfo: { main: '#ffffff', contrastText: '#808080' },
            lightViolet: { main: '#ffffff', contrastText: '#808080' },
            lightPink: { main: '#ffffff', contrastText: '#808080' },
            lightRed: { main: '#ffffff', contrastText: '#808080' },
        },
        typography: {
            planTitle: {
                fontSize: '14px !important',
                fontWeight: '600',
            },
            planTitleS: {
                fontSize: '14px !important',
                fontWeight: '600',
                textDecoration: 'line-through',
            },
            planSubtitle: {
                fontSize: '13px',
                lineHeight: '1',
            },
            planSmallText: {
                fontSize: '10px',
                lineHeight: '1.3',
            },
            kanbanTitle: {
                fontSize: '13px',
                fontWeight: '700',
            },
            kanbanSubtitle: {
                fontSize: '12px',
            },
            kanbanSmallText: {
                fontSize: '9px',
            },
        },
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        background: 'white',
                        borderRadius: '1px',
                        '&.Mui-focused fieldset': {
                            borderColor: '#1ab394 !important',
                            borderWidth: '1px !important',
                        },
                        '&.Mui-error.Mui-focused fieldset': {
                            borderColor: 'red !important',
                        },
                        '&.Mui-error fieldset': {
                            borderColor: 'red !important',
                        },
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '&:hover fieldset': {
                            borderColor: '#8e8d8d !important',
                        },
                        '& .MuiOutlinedInput-root': {
                            paddingRight: '20px',
                        },
                    },
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        color: '#1ab394',
                        marginLeft: '0',
                        marginTop: '0',
                    },
                },
            },
            MuiButton: {
                variants: [
                    {
                        props: { size: 'wm' },
                        style: { minWidth: '120px', padding: '6px 12px' },
                    },
                    {
                        props: { size: 'mini' },
                        style: { padding: '3px 6px', fontSize: '11px', height: '25px' },
                    },
                    {
                        props: { size: 'superMini' },
                        style: { padding: '0 5px', height: '22px', minWidth: 'auto' },
                    },
                    {
                        props: { size: 'square' },
                        style: {
                            minWidth: '33px', width: '33px', height: '33px',
                        },
                    },
                ],
                styleOverrides: {
                    root: {
                        borderRadius: '3px',
                        padding: '6px 12px',
                        boxShadow: 'none',
                        textTransform: 'none',
                        fontSize: '13px',
                        fontWeight: '400',
                        lineHeight: '1.5',
                        transition: '0.3s',

                        '&.MuiButton-containedPrimary:hover': {
                            backgroundColor: '#18a689',
                            border: '1px solid #18a689',
                        },
                        '&.MuiButton-containedSuccess:hover': {
                            backgroundColor: '#1a7bb9',
                            border: '1px solid #1a7bb9',
                        },
                        '&.MuiButton-containedInfo:hover': {
                            backgroundColor: '#21b9bb',
                            border: '1px solid #21b9bb',
                        },
                        '&.MuiButton-containedWarning:hover': {
                            backgroundColor: '#f7a54a',
                            border: '1px solid #f7a54a',
                        },
                        '&.MuiButton-containedError:hover': {
                            backgroundColor: '#ed5565',
                            border: '1px solid #ed5565',
                        },
                        '& input': {
                            display: 'none',
                        },
                    },
                    contained: {
                        border: '1px solid transparent',
                    },
                    containedSecondary: {
                        border: '1px solid #e7eaec',

                        '&:hover': {
                            backgroundColor: '#ffffff',
                            border: '1px solid #d2d2d2',
                        },
                    },
                    outlined: {
                        '&.MuiButton-outlinedPrimary:hover': {
                            backgroundColor: '#18a689',
                            border: '1px solid #18a689',
                            color: '#ffffff',
                        },
                        '&.MuiButton-outlinedSuccess:hover': {
                            backgroundColor: '#1a7bb9',
                            border: '1px solid #1a7bb9',
                            color: '#ffffff',
                        },
                        '&.MuiButton-outlinedInfo:hover': {
                            backgroundColor: '#21b9bb',
                            border: '1px solid #21b9bb',
                            color: '#ffffff',
                        },
                        '&.MuiButton-outlinedWarning:hover': {
                            backgroundColor: '#f7a54a',
                            border: '1px solid #f7a54a',
                            color: '#ffffff',
                        },
                        '&.MuiButton-outlinedError:hover': {
                            backgroundColor: '#ed5565',
                            border: '1px solid #ed5565',
                            color: '#ffffff',
                        },
                    },
                    containedSizeLarge: {
                        padding: '8px 16px',
                        fontSize: '13px',
                        height: '37px',
                    },
                    containedSizeMedium: {
                        padding: '6px 12px',
                        fontSize: '13px',
                        height: '33px',
                    },
                    containedSizeSmall: {
                        padding: '4px 8px',
                        fontSize: '12px',
                        height: '29px',
                    },
                    startIcon: {
                        display: 'block',
                        marginRight: '0',
                        marginLeft: '0',
                        '& span': {
                            fontSize: '13px !important',
                            overflow: 'inherit',
                            marginRight: '5px',
                        },
                    },
                    endIcon: {
                        display: 'block',
                        marginRight: '0',
                        marginLeft: '0',
                        '& span': {
                            fontSize: '13px !important',
                        },
                    },
                },
            },
            MuiToggleButton: {
                variants: [
                    {
                        props: { color: 'outlinedPrimary' },
                        style: { border: '1px solid #1ab394', color: '#1ab394' },
                    },
                    {
                        props: { color: 'outlinedError' },
                        style: { border: '1px solid #ed5565', color: '#ed5565' },
                    },
                    {
                        props: { color: 'outlinedWarning' },
                        style: { border: '1px solid #f8ac59', color: '#f8ac59' },
                    },
                    {
                        props: { color: 'outlinedInfo' },
                        style: { border: '1px solid #23c6c8', color: '#23c6c8' },
                    },
                    {
                        props: { color: 'outlinedSuccess' },
                        style: { border: '1px solid #1c84c6', color: '#1c84c6' },
                    },
                ],
                styleOverrides: {
                    root: {
                        borderRadius: '3px',
                        padding: '6px 12px',
                        boxShadow: 'none',
                        textTransform: 'none',
                        fontSize: '13px',
                        fontWeight: '400',
                        lineHeight: '1.5',
                        transition: '0.3s',
                        display: 'flex',
                        gap: '3px',

                        '&.MuiToggleButton-outlinedPrimary': {
                            '&:hover': {
                                backgroundColor: '#1ab394',
                                color: '#ffffff',
                            },
                            '&.Mui-selected': {
                                backgroundColor: '#1ab394',
                                color: '#ffffff',
                            },
                        },
                        '&.MuiToggleButton-outlinedError': {
                            '&:hover': {
                                backgroundColor: '#ed5565',
                                color: '#ffffff',
                            },
                            '&.Mui-selected': {
                                backgroundColor: '#ed5565',
                                color: '#ffffff',
                            },
                        },
                        '&.MuiToggleButton-outlinedWarning': {
                            '&:hover': {
                                backgroundColor: '#f8ac59',
                                color: '#ffffff',
                            },
                            '&.Mui-selected': {
                                backgroundColor: '#f8ac59',
                                color: '#ffffff',
                            },
                        },
                        '&.MuiToggleButton-outlinedInfo': {
                            '&:hover': {
                                backgroundColor: '#23c6c8',
                                color: '#ffffff',
                            },
                            '&.Mui-selected': {
                                backgroundColor: '#23c6c8',
                                color: '#ffffff',
                            },
                        },
                        '&.MuiToggleButton-outlinedSuccess': {
                            '&:hover': {
                                backgroundColor: '#1c84c6',
                                color: '#ffffff',
                            },
                            '&.Mui-selected': {
                                backgroundColor: '#1c84c6',
                                color: '#ffffff',
                            },
                        },
                        '&.Mui-selected': {
                            boxShadow: 'inset 0 3px 5px rgba(0, 0, 0, 0.125)',
                        },

                        '&.MuiToggleButton-secondary': {
                            color: '#676a6c !important',
                            border: '1px solid #e7eaec',

                            '&:hover': {
                                backgroundColor: '#ffffff',
                            },
                        },
                        '&.MuiToggleButton-primary': {
                            color: '#ffffff',
                            backgroundColor: '#1ab394',
                            borderColor: '#1ab394',

                            '&.Mui-selected': {
                                backgroundColor: '#18a689',
                            },

                            '&:hover': {
                                backgroundColor: '#18a689',
                            },
                        },
                        '&.MuiToggleButton-success': {
                            color: '#ffffff',
                            backgroundColor: '#1c84c6',
                            borderColor: '#1c84c6',

                            '&.Mui-selected': {
                                backgroundColor: '#1a7bb9',
                            },

                            '&:hover': {
                                backgroundColor: '#1a7bb9',
                            },
                        },
                        '&.MuiToggleButton-warning': {
                            color: '#ffffff',
                            backgroundColor: '#f8ac59',
                            borderColor: '#f8ac59',

                            '&.Mui-selected': {
                                backgroundColor: '#f7a54a',
                            },

                            '&:hover': {
                                backgroundColor: '#f7a54a',
                            },
                        },
                        '&.Mui-error': {
                            color: '#ffffff',
                            backgroundColor: '#ed5565',
                            borderColor: '#ed5565',

                            '&.Mui-selected': {
                                backgroundColor: '#ec4758',
                            },

                            '&:hover': {
                                backgroundColor: '#ec4758',
                            },
                        },
                        '&.MuiToggleButton-info': {
                            color: '#ffffff',
                            backgroundColor: '#23c6c8',
                            borderColor: '#23c6c8',

                            '&.Mui-selected': {
                                backgroundColor: '#21b9bb',
                            },

                            '&:hover': {
                                backgroundColor: '#21b9bb',
                            },
                        },
                    },
                    sizeLarge: {
                        padding: '8px 16px',
                        fontSize: '13px',
                        height: '37px',
                    },
                    sizeMedium: {
                        padding: '6px 12px',
                        fontSize: '13px',
                        height: '33px',
                    },
                    sizeSmall: {
                        padding: '4px 8px',
                        fontSize: '12px',
                        height: '29px',
                    },
                },
            },
            MuiToggleButtonGroup: {
                styleOverrides: {
                    grouped: {
                        '&:not(:first-of-type)': {
                            borderLeft: 'none',
                            marginLeft: '0',
                        },
                    },
                },
            },
            MuiButtonGroup: {
                defaultProps: {
                    disableRipple: true,
                },
                styleOverrides: {
                    root: {
                        boxShadow: 'none',
                        borderRadius: '3px',
                    },
                    grouped: {
                        '&:active': {
                            boxShadow: 'inset 0 3px 5px rgba(0, 0, 0, 0.125)',
                        },
                        '&:not(:last-of-type)': {
                            '&.MuiButtonGroup-groupedContainedSecondary': {
                                borderColor: 'transparent',
                                borderRightColor: '#cbd1d5',
                            },
                            '&.MuiButtonGroup-groupedContainedPrimary': {
                                borderColor: 'transparent',
                                borderRightColor: '#14866f',
                            },
                            '&.MuiButtonGroup-groupedContainedSuccess': {
                                borderColor: 'transparent',
                                borderRightColor: '#166699',
                            },
                            '&.MuiButtonGroup-groupedContainedWarning': {
                                borderColor: 'transparent',
                                borderRightColor: '#f69428',
                            },
                            '&.MuiButtonGroup-groupedContainedError': {
                                borderColor: 'transparent',
                                borderRightColor: '#c71528',
                            },
                            '&.MuiButtonGroup-groupedContainedInfo': {
                                borderColor: 'transparent',
                                borderRightColor: '#1b9b9d',
                            },
                        },
                    },
                },
            },
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: true,
                },
                styleOverrides: {
                    root: {
                        '&.MuiButton-root:hover': {
                            boxShadow: 'none',
                        },
                        '&.MuiButton-root:active': {
                            boxShadow: 'inset 0 3px 5px rgba(0, 0, 0, 0.125)',
                        },
                        '&.MuiPickersDay-today': {
                            border: '1px solid #1ab394 !important',
                        },
                        '&.MuiIconButton-root': {
                            padding: '0',
                            color: 'black',
                            opacity: '0.5',

                            '&:hover': {
                                backgroundColor: 'transparent',
                                color: 'black',
                                opacity: '2',
                            },

                            '& i': {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            },
                        },
                    },
                },
            },
            MuiCardActions: {
                styleOverrides: {
                    root: {
                        padding: 0,
                    },
                },
            },
            MuiIcon: {
                styleOverrides: {
                    root: {
                        fontSize: '13px',
                        overflow: 'inherit',
                        lineHeight: '0',
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        borderRadius: '3px',

                        '&.MuiPickersPopper-paper .Mui-selected': {
                            color: 'white !important',
                            background: '#1ab394 !important',
                        },

                        '&.MuiPickersPopper-paper .Mui-selected:hover': {
                            background: '#18a689 !important',
                        },
                    },
                },
            },
            MuiList: {
                styleOverrides: {
                    root: {
                        paddingTop: '1px',
                        paddingBottom: '1px',
                        '& li': {
                            fontSize: '13px',
                            color: '#212529',
                            margin: '4px',
                            padding: '3px 20px',
                            borderRadius: '3px',
                            display: 'flex',
                            gap: '5px',
                        },
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    listbox: {
                        paddingTop: '1px',
                        paddingBottom: '1px',
                    },
                    option: {
                        fontSize: '13px',
                        color: '#212529',
                        margin: '4px',
                        padding: '3px 20px',
                        borderRadius: '3px',
                    },
                    endAdornment: {
                        top: '50%',
                    },
                    hasPopupIcon: {
                        '& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-sizeSmall': {
                            paddingRight: '30px',
                        },
                    },
                    hasClearIcon: {
                        '& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-sizeSmall': {
                            paddingRight: '47px',
                        },
                    },
                    popupIndicator: {
                        '&:hover': {
                            backgroundColor: 'transparent !important',
                            color: 'black',
                        },
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        '& span': {
                            color: 'rgb(128, 128, 128)',
                            fontSize: '12px',
                        },
                    },
                },
            },
            MuiDialog: {
                styleOverrides: {
                    root: {
                        '& .MuiDialogTitle-root': {
                            fontSize: '14px',
                            fontWeight: 600,
                            lineHeight: '20px',
                            padding: '15px',
                            display: 'flex',
                            justifyContent: 'space-between',

                            '& :hover': {
                                backgroundColor: 'transparent',
                            },

                            '& .buttons-wrapper': {
                                display: 'flex',
                                gap: '10px',
                                '& span': {
                                    fontSize: '16px',
                                },
                            },

                            '& .MuiIconButton-root': {
                                padding: '0',
                                color: 'black',
                                opacity: '0.5',

                                '& :hover': {
                                    color: 'black',
                                    opacity: '2',
                                },

                                '& i': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                },

                                '& .MuiIcon-root': {
                                    display: 'flex',
                                    justifyContent: 'center',
                                },
                            },
                        },
                        '& .MuiDialogContent-root': {
                            backgroundColor: '#f3f3f4',
                            padding: '20px 30px 30px 30px',
                            display: 'flex',
                            flexDirection: 'column',
                            flex: '1',
                        },
                        '& .MuiDialogActions-root': {
                            padding: '15px',
                        },
                    },
                },
            },
            MuiCollapse: {
                styleOverrides: {
                    root: {
                        '& .MuiIconButton-root:hover': {
                            color: 'black',
                            opacity: '0.7',
                            backgroundColor: 'transparent !important',
                        },
                        '& .MuiIconButton-root': {
                            padding: '0',

                            '& .MuiIcon-root': {
                                fontSize: '17px',
                                display: 'flex',
                                justifyContent: 'center',
                            },
                        },
                    },
                },
            },
            MuiInputAdornment: {
                styleOverrides: {
                    root: {
                        '& .MuiIconButton-root > .fa': {
                            fontSize: '15px',
                        },
                    },
                },
            },
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'white',
                        '& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell': {
                            borderRight: '1px solid #e0e0e0',
                        },
                        '& .MuiDataGrid-menuIcon': {
                            padding: '5px',
                        },
                    },
                    columnHeaders: {
                        fontSize: '14px',
                        fontWeight: '600',
                        color: '#000000',
                        backgroundColor: '#F5F5F6',
                        '& .MuiDataGrid-columnSeparator--sideRight': {
                            right: '-10px',
                        },
                    },
                    cell: {
                        '& .wrapper-column': {
                            display: 'flex',
                            flexDirection: 'column',
                        },
                        '& .color-blue': {
                            color: '#23c6c8',
                        },
                    },
                    toolbarContainer: {
                        backgroundColor: '#F5F5F6',
                        '& .MuiButton-startIcon': {
                            marginRight: '0px',
                            display: 'inherit',
                            '& .MuiSvgIcon-root': {
                                fontSize: '1.2rem',
                            },
                        },
                        '& .MuiBadge-root': {
                            marginRight: '0px',
                        },
                    },
                },
            },
            MuiCard: {
                variants: [
                    {
                        props: { variant: 'plan' },
                        style: { borderRadius: 0, boxShadow: 'none', borderBottom: '1px solid #e7eaec' },
                    },
                    {
                        props: { variant: 'kanban' },
                        style: {
                            border: '1px solid #E7EAEC', borderRadius: '2px', color: '#676a6c',
                        },
                    },
                ],
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        '& .MuiSwitch-root': {
                            margin: '0px',
                        },
                    },
                },
            },
            MuiChip: {
                variants: [
                    {
                        props: { color: 'blue' },
                        style: { backgroundColor: '#D1E6F3' },
                    },
                    {
                        props: { color: 'lightOrange' },
                        style: { backgroundColor: '#FDEEDD' },
                    },
                    {
                        props: { color: 'lightPrimary' },
                        style: { backgroundColor: '#D1EFE9' },
                    },
                    {
                        props: { color: 'lightInfo' },
                        style: { backgroundColor: '#D3F3F4' },
                    },
                    {
                        props: { color: 'lightViolet' },
                        style: { backgroundColor: '#F2E6F9' },
                    },
                    {
                        props: { color: 'lightPink' },
                        style: { backgroundColor: '#F3D5E7' },
                    },
                    {
                        props: { color: 'lightRed' },
                        style: { backgroundColor: '#FBDDE0' },
                    },
                ],
                styleOverrides: {
                    root: {
                        '&.MuiChip-colorBlue:hover': {
                            backgroundColor: '#bddbee',
                            transition: '0.3s',
                        },
                        '&.MuiChip-colorLightOrange:hover': {
                            backgroundColor: '#fce2c5',
                            transition: '0.3s',
                        },
                        '&.MuiChip-colorLightPrimary:hover': {
                            backgroundColor: '#bee8e0',
                            transition: '0.3s',
                        },
                        '&.MuiChip-colorLightInfo:hover': {
                            backgroundColor: '#bfedef',
                            transition: '0.3s',
                        },
                        '&.MuiChip-colorLightViolet:hover': {
                            backgroundColor: '#e7d1f4',
                            transition: '0.3s',
                        },
                        '&.MuiChip-colorLightPink:hover': {
                            backgroundColor: '#F3D5E7',
                            transition: '0.3s',
                        },
                        '&.MuiChip-colorLightRed:hover': {
                            backgroundColor: '#f8c6cb',
                            transition: '0.3s',
                        },
                    },
                },
            },
        },
    },
    dataGridruRU,
    dataGridProruRU,
    dataGridPremiumruRU,
    ruRU,
);

export default theme;
