import 'Lib/Webix/Components/reactProto.js';
import 'Lib/Webix/Components/commentsCkEditorProto.js';
import 'Lib/nicedit/webixNicEdit.js';
import 'Lib/ckeditor5/webixCkEditor5.js';
import 'Lib/ckeditor5/ckeditor.orig.scss';

export default () => {
    if (webix.env.touch) {
        webix.ui.fullScreen();
    }

    if (webix.env.scrollSize) {
        webix.CustomScroll.init();
    }

    webix.Date.startOnMonday = true;
};
