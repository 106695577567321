import createIssueDescription from 'fsrc/BugReport/models/currentData.js';

import { ITaskData } from '../types/ITaskData';
import { TTypeTask } from '../types/TTypeTask';

export default (data: ITaskData, type: TTypeTask) => {
    const { desc, criteria, bugInfo } = data;

    const criteriaData = criteria ? `<hr><h2>Критерии приемки:</h2>${criteria}` : '';

    switch (type) {
    case 'issue': {
        const description = createIssueDescription(desc, criteriaData, bugInfo);

        return description;
    }
    case 'submissionTask': {
        const description = createIssueDescription(desc, criteriaData, bugInfo);

        return description;
    }
    default: {
        const description = `${desc || ''} ${criteriaData}`;

        return description;
    }
    }
};
