import { useSnackbar } from 'notistack';
import CreateNoticeContent from './utils/CreateNoticeContent';
import { TTypeNotice } from './types/TTypeNotice';

interface ICustomAction {
    callback?: () => void;
    actionText?: string;
    onEnter?: () => void;
    onEntered?: () => void;
    onClose?: () => void;
    onExit?: () => void;
    onExited?: () => void;
}

function useEnqueueSnackbar() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const baseFunction = (type: keyof TTypeNotice) => (
        message: string,
        {
            callback,
            actionText,
            onEnter,
            onEntered,
            onClose,
            onExit,
            onExited,
        }: ICustomAction = {},
    ) => {
        const callbacksStack = callback
            ? () => {
                callback();
                closeSnackbar();
            }
            : null;

        enqueueSnackbar(
            CreateNoticeContent(
                message,
                {
                    actionText,
                    callbacksStack,
                    typeNotice: type,
                },
            ),
            {
                variant: type,
                onEnter: onEnter ? () => onEnter : undefined,
                onEntered: onEntered ? () => onEntered : undefined,
                onClose: onClose ? () => onClose : undefined,
                onExit: onExit ? () => onExit : undefined,
                onExited: onExited ? () => onExited() : undefined,
            },
        );
    };

    const success = baseFunction('success');
    const error = baseFunction('error');
    const info = baseFunction('info');
    const warning = baseFunction('warning');

    return {
        success, error, info, warning,
    };
}

export default useEnqueueSnackbar;
