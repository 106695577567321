import React from 'react';
import { useFormContext, useController, UseControllerReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';

import useEnqueueSnackbar from 'Lib/Notistack/useEnqueueSnackbar';
import { useAddChecklistToTaskMutation, useUpdateChecklistToTaskMutation } from 'Endpoints/Checklists/checklist.api';
import { getEventId } from 'fsrc/PlanDay/utils/selectors';

function CreateChecklistButton({ linkedToChecklist = false }: { linkedToChecklist: boolean }) {
    const { success } = useEnqueueSnackbar();
    const [addChecklist] = useAddChecklistToTaskMutation();
    const [updateChecklist] = useUpdateChecklistToTaskMutation();
    const eventId = useSelector(getEventId);

    const { control } = useFormContext();
    const tasksController: UseControllerReturn = useController({
        name: 'tasks',
        control,
    });
    const nameController: UseControllerReturn = useController({
        name: 'schedulerName',
        control,
    });
    const commentController: UseControllerReturn = useController({
        name: 'comment',
        control,
    });

    const onClickHandler = async () => {
        const data = {
            name: nameController.field.value,
            taskId: tasksController.field.value.id,
            description: commentController.field.value,
            eventId,
        };
        if (linkedToChecklist) {
            const result = await updateChecklist(data).unwrap();
            if (result?.success) {
                success('Обновлён пункт чек-листа для привязанной задачи');
            }
        } else {
            const result = await addChecklist(data).unwrap();
            if (result?.id) {
                success('Создан пункт чек-листа для привязанной задачи');
            }
        }
    };

    return (
        <>
            { tasksController.field.value && !!eventId
        && (
            <Button
                variant="contained"
                color="secondary"
                onClick={onClickHandler}
            >
                { `${linkedToChecklist ? 'Обновить ' : 'В '} чек-лист` }
            </Button>
        ) }
        </>
    );
}

export default CreateChecklistButton;
