import IFunctionWithPath from 'CommonTypes/Functions/IFunctionWithPath';
import { Dayjs } from 'dayjs';
import calculateMaxDateByFunctions from './calculateMaxDateByFunctions';

export default async (
    functionsLimitationsStr: string,
    currentDeadline: Dayjs,
    currentFunctions: IFunctionWithPath[],
    startDate?: Dayjs,
) => {
    const functionsLimitation = functionsLimitationsStr.split(',').filter(el => el);
    const maxDate = await calculateMaxDateByFunctions(
        currentDeadline,
        functionsLimitation,
        currentFunctions,
        startDate,
    );

    return maxDate;
};
