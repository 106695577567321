import { ThemeProvider } from '@mui/material';
import React, { StrictMode } from 'react';
import { Root, createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import theme from 'Lib/Mui/theme';
import container from 'Services/container';
import StyledSnackbarProvider from 'Lib/Notistack/StyledSnackbarProvider';
import Action from 'Lib/Notistack/DefaultAction';
import { IIFieldsVisibleConfigReqeust } from 'CommonTypes/Forms/FormsFieldsConfig';
import OldSalesFormComponent from '../components/OldSalesForm/OldSalesFormComponent';

type TData = {
    taskId: number;
} & IIFieldsVisibleConfigReqeust;

let rootContainer: Root;

function initFormOldSalesForm({ taskId, formTypeId, taskTypeId }: TData) {
    const root = document.getElementById(`salesModule_oldFormContainer_${taskId}`) as HTMLDivElement;
    rootContainer = createRoot(root);

    const { store } = container;

    rootContainer.render(
        <StrictMode>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <StyledSnackbarProvider
                        action={snackbarId => Action(snackbarId)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        hideIconVariant
                    >
                        <OldSalesFormComponent
                            taskId={taskId}
                            formTypeId={formTypeId}
                            taskTypeId={taskTypeId}
                        />
                    </StyledSnackbarProvider>
                </ThemeProvider>
            </Provider>
        </StrictMode>,
    );
}

function closeOldSalesForm() {
    rootContainer?.unmount();
}

export default () => {
    const { events } = container;
    events.on(
        'sales__formInOldSalesForm:initFormInOldSalesForm',
        initFormOldSalesForm,
        false,
    );
    events.on(
        'sales__formInOldSalesForm:closeOldSalesForm',
        closeOldSalesForm,
        false,
    );
    events.on(
        'router:before-route',
        closeOldSalesForm,
        false,
    );
};
