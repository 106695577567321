import { nodePath } from 'Config/path.js';

const deleteTimeTrack = async (timeTrackId) => {
    const result = await webix.ajax()
        .del(`${nodePath}/tasks/deleteTimeTrack?id=${timeTrackId}`)
        .then(res => res.json());

    return result;
};

export default deleteTimeTrack;
