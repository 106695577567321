import { requirementsId, formAddTaskId, requirementsView } from 'fsrc/AddTaskWindow/config/constants.js';
import requierementsLayout from './component/requirements/requierementsLayout.js';

export default () => {
    const values = $$(formAddTaskId).getValues();
    const { taskId, project } = values;

    if (+taskId && project && !$$(requirementsView)) {
        $$(requirementsId).removeView('requirements__placeholder');
        $$(requirementsId).addView(webix.copy(requierementsLayout(+taskId, +project)));
    }
};
