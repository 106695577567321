import { initModule, use } from '../services/initModule.js';

export default {
    '/diagram': {
        as: 'diagram',
        uses: async () => {
            await import('../Lib/Webix/Diagram/diagram.min.orig.css');
            use(await import('../Lib/Webix/Diagram/diagram.min.orig.js'), 'diagram.min.orig.js');

            import(
                /* webpackChunkName: "diagram" */ '../../DiagramModule/DiagramController.js'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
