import s from '../assets/scss/mainMenu.scss';

export default (url, common) => {
    const {
        open,
        menu,
        hash,
        value,
        icon,
    } = url;

    let str = `<span class="${icon} menu__icon"></span>`;

    if (hash) {
        str += `<a class="${s['main-menu__link']}" href="${hash}" onclick="return false;">${value}</a>`;
    } else {
        str += `<span>${value}</span>`;
    }

    if (open) {
        str += '<span class="fa fa-chevron-down menu__arrow"></span>';
    } else if (menu) {
        str += '<span class="fa fa-chevron-left menu__arrow"></span>';
    }

    return str;
};
