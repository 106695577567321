import { nodePath } from 'Config/path.js';
import { fetchPatch } from 'Services/fetchService.js';
import { IDocumentNew } from 'CommonTypes/DocsTemplates/DocsTemplate';

type TResponse = {
    id: number,
    success: boolean,
}

export default async (data: IDocumentNew) => {
    const response: TResponse = await fetchPatch(`${nodePath}/docs-templates/doc/${data.outboxId}-${data.templateId}`, { document: data.document });
    return response;
};
