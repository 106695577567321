import { checkListId, topTabbarId, topMultiviewId } from 'fsrc/AddTaskWindow/config/constants.js';

import checklistPlaceholder from './checklistPlaceholder.js';

export default {
    id: checkListId,
    tabbarParent: topTabbarId,
    multiviewParent: topMultiviewId,
    borderless: true,
    rows: [
        checklistPlaceholder,
    ],
};
