import favoritesData from '../../listFavorites/services/favoritesData.js';
import { templatesData } from '../../services/services.js';

export default async function handlerItemClick() {
    const dataFavorites = await favoritesData();
    const dataTemplate = await templatesData();

    if (dataFavorites.length > 5) {
        $$('favorites').collapse();
    } else {
        $$('favorites').expand();
    }
    if (dataTemplate.length > 5) {
        $$('tasks').collapse();
    } else {
        $$('tasks').expand();
    }

    $$('tasks').config.header = webix.template(`Шаблоны <span class='webix_badge accordion__badge'>${dataTemplate.length}</span>`);
    $$('tasks').refresh();
    $$('favorites').config.header = webix.template(`Избранные <span class='webix_badge accordion__badge'>${dataFavorites.length}</span>`);
    $$('favorites').refresh();

    $$('listFavorite').parse(dataFavorites, null, true);
    $$('listTasks').parse(dataTemplate, null, true);
}
