/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';

import { IInitialUserInfo } from 'CommonTypes/Users/InitialUser';

export const initialState: IInitialUserInfo = {
    id: 0,
    name: '',
    email: '',
    photo: '',
    guest: false,
    admin: false,
    postId: 0,
    contactId: 0,
    contactName: '',
    contactPhone: '',
    contactPhoto: '',
    useForm: 0,
    taskTypeId: 0,
};

export const userSlice = createSlice({
    name: 'USER_SLICE',
    initialState,
    reducers: {
        set(state, action) {
            const { guest, admin } = action.payload;
            const newState = {
                ...action.payload,
                guest: Boolean(guest),
                admin: Boolean(admin),
            };

            return newState;
        },
    },
});

export const { set } = userSlice.actions;
export default userSlice.reducer;
