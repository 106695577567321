import React from 'react';
import LinksTableDataGrid from './LinksTableDataGrid';

interface IProps {
    taskId: number;
}

function LinksTableView({ taskId }: IProps) {
    return (
        <LinksTableDataGrid taskId={taskId} />
    );
}

export default LinksTableView;
