import React, { ReactNode } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';

function CustomDialogTitle(
    {
        children,
        draggableWindow,
        onClose,
        onFullScreen,
        id,
        fullScreen,
    }:
  {
        draggableWindow: boolean,
        children: ReactNode,
        onClose: (() => void) | boolean,
        onFullScreen: (() => void) | boolean,
        id: string,
        fullScreen: boolean,
  },
) {
    return (
        <DialogTitle id={id} style={draggableWindow && !fullScreen ? { cursor: 'move' } : {}}>
            {children}
            <div className="buttons-wrapper">
                { (typeof onFullScreen === 'function') ? (
                    <IconButton
                        aria-label="close"
                        onClick={onFullScreen}
                    >
                        <Icon><i className={!fullScreen ? 'fa fa-expand' : 'fa fa-compress'} /></Icon>
                    </IconButton>
                ) : null}
                { (typeof onClose === 'function') ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                    >
                        <Icon><i className="fa fa-times" /></Icon>
                    </IconButton>
                ) : null}
            </div>
        </DialogTitle>
    );
}

export default CustomDialogTitle;
