/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
    UseFormReturn, UseFormProps, FormProvider, useForm,
} from 'react-hook-form';
import { Resizable } from 're-resizable';
import Box from '@mui/material/Box';
import dayjs, { Dayjs } from 'dayjs';

import { Modal } from 'Ui/Modal';
import { TextField } from 'Ui/Form/TextField';

import { Editor } from 'Ui/Form/Editor';
import { IClassicEditor } from 'Ui/Editor/types/ICKEditorClassic';

import IProjectSimpleSet from 'CommonTypes/Projects/IProjectSimpleSet';
import IUser from 'CommonTypes/Users/IUser';
import IContactSimpleSet from 'CommonTypes/Contacts/IContactSimpleSet';
import { IEventsChecklist } from 'CommonTypes/Scheduler/IEventsChecklist';

import { stopEditing } from '../../utils/planDaySlice';
import { IFormState } from '../../types/IFormState';

import FooterEventEditing from './FooterEventEditing';
import EventsDateSection from './components/EventsDateSection';
import EventsProjectSection from './components/EventsProjectSection';
import findProjectById from './models/findProjectById';
import EventParticipantsSection from './components/EventParticipantsSection';
import findOptionsByIds from './models/findOptionsByIds';
import parseOptionsWithSearchValue from './models/parseOptionsWithSearchValue';
import EventContactsSection from './components/EventContactsSection';
import EventTasksSection from './components/EventTasksSection';
import TTaskForInput from './types/TTaskForInput';
import findOptionTask from './models/findOptionTask';
import EventChecklistSection from './components/EventChecklistSection';
import getCheckedListItems from './models/getCheckedListItems';

import styles from '../../assets/styles.scss';
import getProjectIdBySearchOrId from './models/getProjectIdBySearchOrId';

interface IProps {
    dataProjectsList: IProjectSimpleSet[];
    dataUsersActiveSimpleSet: IUser[];
    dataContactsSimpleSet: IContactSimpleSet[];
    tasks: TTaskForInput[];
    eventData: IFormState;
    callback: Function;
    open: boolean;
    checklist: IEventsChecklist[];
    type?: 'create' | 'update' | null;
}

interface IFormData {
    schedulerName: string;
    comment: string;
    startDate: Dayjs;
    endDate: Dayjs;
    allDay: boolean;
    project: IProjectSimpleSet | null;
    participants: IUser[];
    contacts: IContactSimpleSet[];
    tasks: TTaskForInput;
    finished: boolean;
    checkedList: number[];
    dayMon: boolean;
    dayTue: boolean;
    dayWed: boolean;
    dayThu: boolean;
    dayFri: boolean;
    daySat: boolean;
    daySun: boolean;
    copyDateTo: Dayjs | null;
}

type TGetArrayProps = {
    [key: string]: boolean,
    dayMon: boolean,
    dayTue: boolean,
    dayWed: boolean,
    dayThu: boolean,
    dayFri: boolean,
    daySat: boolean,
    daySun: boolean,
}

function getArrayOfDays(params: TGetArrayProps) {
    const result: number[] = [];

    const days = ['daySun', 'dayMon', 'dayTue', 'dayWed', 'dayThu', 'dayFri', 'daySat'];

    days.forEach((day, index) => {
        if (params?.[day]) {
            result.push(index);
        }
    });

    return result;
}

export default function EventEditing({
    dataProjectsList, dataUsersActiveSimpleSet,
    dataContactsSimpleSet, eventData, callback, open, tasks,
    checklist,
}: IProps) {
    const commentRef = useRef<IClassicEditor | null>(null);
    const dispatch = useDispatch();
    const eventProjectId = useMemo(() => +(eventData?.projectId || 0), [eventData?.projectId]);

    const addedDataUsersSet = useMemo(() => {
        const currentParticipants = eventData?.participants || [];
        const usersActiveIds = dataUsersActiveSimpleSet.map(user => user.id);
        const missingIds: number[] = [];

        currentParticipants.forEach((participanId) => {
            if (!usersActiveIds.includes(participanId)) {
                missingIds.push(participanId);
            }
        });

        if (missingIds.length) {
            const participantsToAdd = parseOptionsWithSearchValue(
                eventData.participantsSearchValue,
                missingIds,
                true,
            );
            return [
                ...participantsToAdd,
                ...dataUsersActiveSimpleSet,
            ];
        }

        return dataUsersActiveSimpleSet;
    }, [dataUsersActiveSimpleSet, eventData.participants, eventData.participantsSearchValue]);

    const addedDataContactsSimpleSet = useMemo(() => {
        const currentContactsIds = eventData?.eventContactsIds || [];
        const contactsIdsFromSet = dataContactsSimpleSet.map(contact => contact.id);
        const missindIds: number[] = [];

        currentContactsIds.forEach((contactId) => {
            if (!contactsIdsFromSet.includes(contactId)) {
                missindIds.push(contactId);
            }
        });

        if (missindIds.length) {
            const contactsToAdd = parseOptionsWithSearchValue(
                eventData.eventContacts,
                missindIds,
                true,
            );
            return [
                ...contactsToAdd,
                ...dataContactsSimpleSet,
            ];
        }

        return dataContactsSimpleSet;
    }, [dataContactsSimpleSet, eventData.eventContacts, eventData?.eventContactsIds]);

    const projectsList = useMemo(() => {
        if (!eventData?.projectId) return dataProjectsList;
        const currentProject = findProjectById(dataProjectsList || [], eventData.projectId);
        if (currentProject) return dataProjectsList;

        const firstOption = {
            id: eventData.projectId,
            value: eventData.projectName,
            disabled: true,
        };
        const result = [firstOption, ...(dataProjectsList || [])];
        return result;
    }, [dataProjectsList, eventData.projectId, eventData.projectName]);

    const linkedToChecklist = useMemo(
        () => !!checklist.find(value => value.eventId === eventData.schedulerId),
        [checklist, eventData.schedulerId],
    );

    const onHundleCloseEditing = () => dispatch(stopEditing());

    const form: UseFormReturn<IFormData, UseFormProps> = useForm<IFormData>({
        defaultValues: {
            schedulerName: eventData.schedulerName,
            comment: eventData.comment,
            startDate: dayjs(eventData.startDateEvent),
            endDate: dayjs(eventData.endDateEvent),
            allDay: !!eventData.allDay,
            project: getProjectIdBySearchOrId(
                projectsList,
                eventData.schedulerName,
                eventProjectId,
            ),
            participants: findOptionsByIds(
                addedDataUsersSet,
                eventData?.participants || [],
            ),
            contacts: findOptionsByIds(
                addedDataContactsSimpleSet,
                eventData?.eventContactsIds || [],
            ),
            tasks: findOptionTask(tasks, eventData.taskId || 0),
            finished: eventData?.finished || false,
            checkedList: getCheckedListItems(checklist),
            dayMon: false,
            dayTue: false,
            dayWed: false,
            dayThu: false,
            dayFri: false,
            daySat: false,
            daySun: false,
            copyDateTo: null,
        },
    });

    const titleDate = useMemo(() => {
        const str = eventData.startDateEvent ? dayjs(eventData.startDateEvent).format('DD.MM.YYYY') : dayjs().format('DD.MM.YYYY');
        return str;
    }, [eventData.startDateEvent]);

    useEffect(() => {
        const formData = form.getValues();
        form.reset({
            ...formData,
            checkedList: getCheckedListItems(checklist),
        });
    }, [checklist, form]);

    const onSubmit = (formData: IFormData) => {
        onHundleCloseEditing();
        const data = {
            ...eventData,
            comment: formData.comment,
            schedulerName: formData.schedulerName,
            startDateEvent: formData.startDate.format('YYYY-MM-DD HH:mm:ss'),
            endDateEvent: formData.allDay ? formData.startDate.format('YYYY-MM-DD HH:mm:ss') : formData.endDate.format('YYYY-MM-DD HH:mm:ss'),
            allDay: +formData.allDay,
            projectId: formData.project?.id || 0,
            participants: formData.participants.map(value => value.id),
            contacts: formData.contacts.map(contact => contact.id),
            taskId: formData?.tasks?.id || 0,
            finished: +formData.finished,
            checklist: formData.checkedList,
            copyDateTo: formData.copyDateTo ? formData.copyDateTo.format('YYYY-MM-DD HH:mm:ss') : formData.startDate.format('YYYY-MM-DD HH:mm:ss'),
            copyDays: getArrayOfDays({
                daySun: formData.daySun,
                dayMon: formData.dayMon,
                dayTue: formData.dayTue,
                dayWed: formData.dayWed,
                dayThu: formData.dayThu,
                dayFri: formData.dayFri,
                daySat: formData.daySat,
            }),
        };

        return callback(data);
    };

    return (
        <FormProvider {...form}>
            <Modal
                open={open}
                title={`Редактирование события ${titleDate}`}
                width={985}
                height={585}
                draggableWindow={false}
                fullScreen={false}
                onClose={onHundleCloseEditing}
                footer={
                    (
                        <FooterEventEditing
                            handleSubmit={form.handleSubmit(onSubmit)}
                            linkedToChecklist={linkedToChecklist}
                        />
                    )
                }
            >
                <form className={`${styles['event-form']} event-form`}>
                    <Box className={styles['event-form_content']}>
                        <Resizable
                            className={styles['event-form_resizeble']}
                            defaultSize={{
                                width: '65%',
                                height: 'inherit%',
                            }}
                            minHeight="inherit"
                            maxWidth="100%"
                            minWidth="275"
                        >
                            <Box className={styles['event-form_left-side']}>
                                <EventsDateSection />
                                <TextField
                                    name="schedulerName"
                                    label="Название события"
                                    required={{
                                        required: {
                                            value: true,
                                            message: 'Поле не может быть пустым',
                                        },
                                        maxLength: {
                                            value: 256,
                                            message: 'Превышено максимальное количество символов',
                                        },
                                    }}
                                />
                                <Editor
                                    name="comment"
                                    editorRef={commentRef}
                                    required={{
                                        maxLength: {
                                            value: 1024,
                                            message: 'Превышено максимальное количество символов',
                                        },
                                    }}
                                />
                            </Box>
                        </Resizable>
                        <Box className={styles['event-form_right-side']}>
                            <EventsProjectSection data={projectsList} />
                            <EventParticipantsSection options={addedDataUsersSet} />
                            <EventContactsSection options={addedDataContactsSimpleSet} />
                            <EventTasksSection options={tasks} />
                            <EventChecklistSection options={checklist} />
                        </Box>
                    </Box>
                </form>
            </Modal>
        </FormProvider>
    );
}
