import { Match } from 'navigo';
import { initModule } from '../services/initModule.js';

export default {
    '/user-story/:id': {
        as: 'user-story',
        uses: (data: Match) => {
            import(
                /* webpackChunkName: "user-story" */ '../../UserStory/UserStoriesController'
            ).then((module) => {
                initModule(module.default, data);
            });
        },
    },
};
