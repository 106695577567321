import React from 'react';

import { IResponsePlanDay } from 'CommonTypes/Scheduler/IResponse';
import CardTask from './cardTask/CardTask';
import groupingTasks from '../utils/groupingTasks';

import classes from '../assets/style.scss';

export interface IGroupingTasksObj {
    [key: string]: IResponsePlanDay;
}

function GroupTasks(
    {
        plans, onClickCard, onClickDelete, onClickEdit,
    }:
    { plans: IResponsePlanDay, onClickCard: (taskId: number) => Promise<void>,
        onClickDelete?: (schedulerId: number) => Promise<void> | undefined;
        onClickEdit?: (
            schedulerName: string,
            comment: string,
            schedulerId: number,
            finished: number,
        ) => void | undefined; },
) {
    const groupByServiceClass: IGroupingTasksObj = groupingTasks(plans);
    // FIXME: 'serviceClassName' - удалил второй параметр, захардкодил его.

    const serviceClasses = Object.keys(groupByServiceClass);

    return (
        <>
            {serviceClasses.map((serviceClass: string) => (
                <div key={serviceClass}>
                    <p className={classes.group_title}>
                        {serviceClass}
                    </p>
                    {groupByServiceClass[serviceClass].map(plan => (
                        <CardTask
                            plan={plan}
                            onClickCard={onClickCard}
                            onClickDelete={onClickDelete}
                            onClickEdit={onClickEdit}
                            key={plan.schedulerId}
                        />
                    ))}
                </div>
            ))}
        </>
    );
}

export default GroupTasks;
