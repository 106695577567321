/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import { Button } from '@mui/material';

import { Modal } from 'Ui/Modal';
import { DateTimePicker } from 'Ui/Form/DatePicker';
import { Editor } from 'Ui/Form/Editor';
import { IClassicEditor } from 'Ui/Editor/types/ICKEditorClassic';

import { useGetTimesheetsRecordsByTaskIdQuery, useUpdateTimesheetRecordMutation } from 'Endpoints/Timesheets/timesheets.api';
import { ITimesheetTableRecordResponse } from 'CommonTypes/Timesheets/TimesheetTableEdit';
import useEnqueueSnackbar from 'Lib/Notistack/useEnqueueSnackbar';

interface IFooterProps {
    handleClose: (result: boolean) => void;
}

function Footer({ handleClose }: IFooterProps) {
    return (
        <>
            <Button variant="contained" size="wm" onClick={() => handleClose(true)}>Сохранить</Button>
            <Button variant="contained" color="secondary" size="wm" onClick={() => handleClose(false)}>Отмена</Button>
        </>
    );
}

interface IProps {
    taskId: number;
    recordId: number;
    handleSubmitForm: (result: boolean) => void;
}

interface IForm {
    id: number;
    start: Dayjs | null;
    finish: Dayjs | null;
    duration: number;
    comment: string;
}

function TimesheetRecordModal({ taskId, recordId, handleSubmitForm }: IProps) {
    const commentRef = useRef<IClassicEditor | null>(null);
    const { success, error, warning } = useEnqueueSnackbar();

    const { data }:
    { data: ITimesheetTableRecordResponse | undefined } = useGetTimesheetsRecordsByTaskIdQuery(
        { taskId },
        {
            skip: !taskId,
            selectFromResult:
            ({ data: records }) => ({ data: records?.find(record => record.id === recordId) }),
        },
    );

    const [updateRecord] = useUpdateTimesheetRecordMutation();

    const form = useForm<IForm>({
        defaultValues: {
            id: data?.id,
            start: dayjs(data?.start),
            finish: dayjs(data?.finish),
            duration: data?.duration,
            comment: data?.comment || '',
        },
    });

    const handleButtonsClick = useCallback((result: boolean) => {
        if (result) {
            const formData = form.getValues();
            const updateData = {
                recordId,
                taskId,
                startDate: dayjs(formData.start).format('YYYY-MM-DD HH:mm:ss'),
                finishDate: dayjs(formData.finish).format('YYYY-MM-DD HH:mm:ss'),
                comment: formData.comment,
            };
            updateRecord(updateData).unwrap().then((response) => {
                if (response?.permissions) {
                    return warning('Нет прав на редактирование этой записи');
                }

                if (response?.success) {
                    return success('Запись успешно изменена');
                }

                return error('На сервере возникла ошибка');
            });
        }

        handleSubmitForm(false);
    }, [error, form, handleSubmitForm, recordId, success, taskId, updateRecord, warning]);

    return (
        <Modal
            title="Редактирование записи"
            open={Boolean(taskId)}
            onClose={() => handleButtonsClick(false)}
            width={500}
            height={500}
            footer={(
                <Footer handleClose={handleButtonsClick} />
            )}
        >
            <FormProvider {...form}>
                <form
                    style={{
                        display: 'flex',
                        flex: 1,
                        gap: '15px',
                        width: '100%',
                        padding: '0 15px',
                        flexDirection: 'column',
                    }}
                >
                    <DateTimePicker
                        name="start"
                        label="Начало"
                    />
                    <DateTimePicker
                        name="finish"
                        label="Завершение"
                    />
                    <Editor
                        name="comment"
                        editorRef={commentRef}
                        config={{ placeholder: 'Комментарии' }}
                    />
                </form>
            </FormProvider>
        </Modal>
    );
}

export default TimesheetRecordModal;
