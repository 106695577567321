import { timeManagementId, bottomTabbarId, bottomMultiviewId } from '../../../../config/constants.js';
import timeTrackingTable from './components/timeTrackingTable/timeTrackingTable.js';
import toolbar from './components/toolbar/toolbar.js';

export default {
    id: timeManagementId,
    tabbarParent: bottomTabbarId,
    multiviewParent: bottomMultiviewId,
    rows: [
        timeTrackingTable,
        toolbar,
    ],
};
