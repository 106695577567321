import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { nodePath } from 'Config/path.js';

const emptySplitApi = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: `${nodePath}/`,
    }),
    endpoints: () => ({}),
    tagTypes: [
        'Tasks',
        'EventsList',
        'EventsChecklist',
        'OrgTree',
        'OrgTreeItemFullSet',
        'ContactsSimpleSet',
        'BudgetsInTree',
        'TasksLinksById',
        'TasksHistoryCountByTaskId',
        'TasksHistoryRecordsByTaskId',
        'FormsFieldsVisibleConfig',
        'TasksTypesSimpleSet',
        'RolesUsersByTaskTypeConfig',
        'RolesUsersByTaskTypeRsIdConfig',
        'GetTasksRoutestatesByTasksTypeInOrder',
        'GetRoutestatesArrow',
        'GetNewsRoutestates',
        'GetNewsRoutestatesFullRecord',
    ],
});

export default emptySplitApi;
