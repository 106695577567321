import changeFormState from 'Domain/LayoutConfiguration/models/changeFormState';
import comboFilter from '../../models/comboFilter.js';
import {
    typeTaskId, contactId, departmentId, prioritiesId,
    projectsId, userId, roleId, serviceId, functionId, modulesId,
    startTaskId, endTaskId, dateTaskComplitedId, observersId,
    subprojectId, planId, sprintId, authorId, formRightSidebar,
    dateTaskCreateId, assetsId, tagsId, plannedTaskId, formAddTaskId, serviceClassId,
} from '../../../../config/constants.js';
import changeDataUserId from '../../models/changeDataUserId.js';
import changeDataSubprojectId from '../../models/changeDataSubprojectId.js';
import changeDataPlanId from '../../models/changeDataPlanId.js';
import changeDataSprintId from '../../models/changeDataSprintId.js';
import { nodePath } from '../../../../../configModule/config.js';
import validationRules from '../../models/validationRules.js';
import changeDataModules from '../../models/changeDataModules.js';
import changeDataAssets from '../../models/changeDataAssets.js';
import changeDataModulesByAssets from '../../models/changeDataModulesByAssets.js';
import createTagsTemplate from '../../models/createTagsTemplate.js';
import filterTagsData from '../../models/filterTagsData.js';
import getFormConfig from '../../../../service/getFormConfig';
import getDefaultConfig from '../../models/getDefaultConfig';
import checkValidatePlannedTask from '../../models/checkValidatePlannedTask';
import changeCurrentValueByProjectId from '../../models/changeCurrentValueByProjectId';
import setPriorityByServiceClass from '../../models/setPriorityByServiceClass';

export default (allProperty, reducerUser, { typeTaskValue, isReadOnly }) => ({
    id: formRightSidebar,
    scroll: true,
    borderless: true,
    width: 400,
    rows: [
        {
            id: typeTaskId,
            name: typeTaskId,
            view: 'richselect',
            label: 'Тип',
            value: typeTaskValue,
            height: 40,
            labelWidth: 100,
            readonly: isReadOnly,
            options: allProperty.taskTypeId,
            on: {
                async onChange(newValue) {
                    const formConfig = await getFormConfig(newValue, 0, formAddTaskId, true);
                    const config = formConfig ?? getDefaultConfig();
                    changeFormState(JSON.stringify(config));
                },
            },
        },
        {
            id: serviceClassId,
            view: 'combo',
            name: 'serviceClassId',
            label: 'Класс обслуживания',
            labelPosition: 'top',
            clear: true,
            options: {
                body: {
                    data: allProperty.serviceClass,
                },
            },
            on: {
                onChange: setPriorityByServiceClass,
            },
        },
        {
            id: contactId,
            name: contactId,
            view: 'combo',
            label: 'Контакт',
            invalidMessage: 'Контакт не выбран',
            required: true,
            clear: true,
            height: 40,
            labelWidth: 100,
            options: {
                body: {
                    dynamic: true,
                    url: `${nodePath}/contacts/filter-contacts`,
                    yCount: 10,
                    dataFeed(str) {
                        this.clearAll();
                        let url = `${nodePath}/contacts/filter-contacts`;
                        url += str ? `?filter[value]=${str}` : '';
                        this.loadNext(0, 0, {}, url);
                    },
                    loadahead: 100,
                },
                keyPressTimeout: 500,
            },
        },
        {
            id: departmentId,
            name: departmentId,
            view: 'combo',
            label: 'Подразделение',
            height: 55,
            clear: true,
            labelPosition: 'top',
            options: {
                body: {
                    view: 'tree',
                    data: allProperty.departmentId,
                    yCount: 10,
                },
            },
        },
        {
            id: prioritiesId,
            name: prioritiesId,
            view: 'richselect',
            label: 'Приоритет',
            value: 2,
            clear: true,
            height: 40,
            labelWidth: 100,
            options: allProperty.prioritiesId,
        },
        {
            id: projectsId,
            view: 'combo',
            label: 'Проект',
            name: 'project',
            clear: true,
            required: true,
            invalidMessage: 'Проект не выбран',
            height: 40,
            labelWidth: 100,
            options: {
                body: {
                    data: allProperty.projectsId,
                    yCount: 10,
                },
            },
            on: {
                onChange: () => {
                    changeDataUserId(allProperty);
                    changeDataSubprojectId(allProperty);
                    changeDataPlanId(allProperty);
                    changeDataModules(allProperty);
                    changeDataAssets(allProperty);
                    changeCurrentValueByProjectId(roleId);
                    changeCurrentValueByProjectId(plannedTaskId);
                },
            },
        },
        {
            id: userId,
            name: userId,
            view: 'combo',
            label: 'Исполнитель',
            disabled: true,
            clear: true,
            height: 40,
            labelWidth: 100,
            options: {
                filter: comboFilter,
                body: {
                    data: allProperty.usersId,
                    yCount: 10,
                },
            },
            on: {
                onChange: (...[, , config]) => {
                    if (config === 'user') $$(roleId).setValue('');
                },
            },
        },
        {
            id: roleId,
            name: roleId,
            view: 'combo',
            label: 'Роль',
            disabled: true,
            height: 40,
            clear: true,
            labelWidth: 100,
            options: {
                body: {
                    data: allProperty.rolesId,
                    yCount: 10,
                },
                config: {
                    id: roleId,
                },
            },
            on: {
                onChange: (...[, , config]) => {
                    if (config === 'user') $$(userId).setValue('');
                },
            },
        },
        {
            id: serviceId,
            name: serviceId,
            label: 'Сервис',
            view: 'combo',
            clear: true,
            height: 40,
            labelWidth: 100,
            options: {
                body: {
                    view: 'tree',
                    data: allProperty.servicesId,
                    yCount: 10,
                },
            },
        },
        {
            id: functionId,
            name: functionId,
            label: 'Функции',
            view: 'multicombo',
            height: 40,
            labelPosition: 'top',
            options: {
                body: {
                    view: 'tree',
                    data: allProperty.functionId,
                    yCount: 10,
                },
            },
            on: {
                onChange(newValue, oldValue, config) {
                    validationRules(newValue, oldValue, config, functionId);
                },
            },
        },
        {
            id: assetsId,
            name: assetsId,
            label: 'Активы',
            view: 'multicombo',
            disabled: true,
            clear: true,
            height: 40,
            labelWidth: 100,
            options: {
                body: {
                    yCount: 20,
                },
            },
            on: {
                onChange() {
                    changeDataModulesByAssets(allProperty);
                },
            },
        },
        {
            id: modulesId,
            name: modulesId,
            label: 'Модули',
            view: 'multicombo',
            disabled: true,
            clear: true,
            height: 40,
            labelWidth: 100,
            options: {
                body: {
                    view: 'tree',
                    yCount: 20,
                },
            },
            on: {
                onChange(newValue, oldValue, config) {
                    validationRules(newValue, oldValue, config, modulesId);
                },
            },
        },
        {
            cols: [
                {
                    id: startTaskId,
                    name: startTaskId,
                    height: 60,
                    view: 'datepicker',
                    label: 'Начало',
                    value: new Date(),
                    labelPosition: 'top',
                },
                {
                    id: endTaskId,
                    name: endTaskId,
                    height: 60,
                    view: 'datepicker',
                    label: 'Срок',
                    value: new Date(),
                    labelPosition: 'top',
                },
            ],
        },
        {
            cols: [
                {
                    id: dateTaskCreateId,
                    name: dateTaskCreateId,
                    height: 60,
                    view: 'datepicker',
                    timepicker: true,
                    label: 'Дата создания',
                    labelPosition: 'top',
                },
                {
                    id: dateTaskComplitedId,
                    name: dateTaskComplitedId,
                    height: 60,
                    view: 'datepicker',
                    timepicker: true,
                    label: 'Дата завершения',
                    readonly: false,
                    labelPosition: 'top',
                },
            ],
        },
        {
            id: observersId,
            name: observersId,
            view: 'multicombo',
            label: 'Наблюдатели',
            height: 50,
            labelPosition: 'top',
            clear: true,
            options: {
                body: {
                    dynamic: true,
                    url: `${nodePath}/contacts/filter-contacts`,
                    yCount: 10,
                    dataFeed(str) {
                        this.clearAll();
                        let url = `${nodePath}/contacts/filter-contacts`;
                        url += str ? `?filter[value]=${str}` : '';
                        this.loadNext(0, 0, {}, url);
                    },
                    loadahead: 100,
                },
                keyPressTimeout: 500,
            },
        },
        {
            id: subprojectId,
            name: subprojectId,
            view: 'combo',
            label: 'Подпроект',
            clear: true,
            disabled: true,
            labelWidth: 100,
            height: 40,
            options: {
                body: {
                    yCount: 10,
                },
            },
        },
        {
            view: 'text',
            id: plannedTaskId,
            name: plannedTaskId,
            label: 'Плановая задача',
            labelPosition: 'top',
            placeholder: 'Номер плановой задачи',
            clear: true,
            disabled: true,
            validationFlag: true,
            pattern: {
                mask: '########',
                allow: /[0-9]/g,
            },
            validate() {
                return $$(plannedTaskId).config.validationFlag;
            },
            on: {
                onChange(value) {
                    checkValidatePlannedTask(value);
                },
            },
        },
        {
            id: planId,
            name: planId,
            view: 'combo',
            label: 'План',
            labelWidth: 100,
            clear: true,
            disabled: true,
            height: 40,
            options: allProperty.plansId,
            on: {
                onChange: () => {
                    changeDataSprintId(allProperty);
                },
            },
        },
        {
            id: sprintId,
            name: sprintId,
            view: 'combo',
            label: 'Спринт',
            labelWidth: 100,
            clear: true,
            disabled: true,
            height: 40,
            options: {
                body: {
                    view: 'list',
                    data: allProperty.sprintsId,
                },
            },
        },
        {
            id: tagsId,
            name: tagsId,
            label: 'Теги',
            view: 'multicombo',
            clear: true,
            height: 40,
            labelWidth: 100,
            options: {
                body: {
                    data: filterTagsData(allProperty.tagsId),
                    yCount: 20,
                    template: createTagsTemplate,
                },
            },
        },
        {
            id: authorId,
            name: authorId,
            view: 'combo',
            label: 'Автор',
            clear: true,
            value: reducerUser.id,
            height: 40,
            disabled: true,
            labelWidth: 100,
            options: [{ id: reducerUser.id, value: reducerUser.name }],
        },
    ],
});
