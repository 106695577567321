import React, { StrictMode } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';

import di from 'Services/di.js';
import theme from 'Lib/Mui/theme';
import StyledSnackbarProvider from 'Lib/Notistack/StyledSnackbarProvider';
import Action from 'Lib/Notistack/DefaultAction';
import TemporaryDrawer from '../TemporaryDrawer';

let rootContainer: Root;
let root: HTMLDivElement | undefined | null;

const openDrawer = ({ activeTab } = { activeTab: 'planDay' }) => {
    if (root) return;
    const { store } = di;

    root = document.createElement('div');
    rootContainer = createRoot(root);
    document.body.append(root);
    rootContainer.render(
        <StrictMode>
            <ThemeProvider theme={theme}>
                <StyledSnackbarProvider
                    action={snackbarId => Action(snackbarId)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    hideIconVariant
                >
                    <Provider store={store}>
                        <TemporaryDrawer activeTab={activeTab} />
                    </Provider>
                </StyledSnackbarProvider>
            </ThemeProvider>
        </StrictMode>,
    );
};

const closeDrawer = () => {
    rootContainer?.unmount();
    if (root) root?.remove();
    root = null;
};

export default () => {
    const { events } = di;
    events.on(
        'plans__drawer:openDrawer',
        openDrawer,
        false,
    );
    events.on(
        'plans__drawer:closeDrawer',
        closeDrawer,
        false,
    );
};
