import container from 'Services/container';

export default {
    '/list-change-requests': {
        as: 'listChangeRequests',
        uses: () => {
            const { router } = container;
            router.navigateByName('tasksPanel', { filterType: 'requests' });
        },
    },
};
