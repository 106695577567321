import React from 'react';
import Drawer from '@mui/material/Drawer';

import di from 'Services/di.js';
import DrawerContent from './components/DrawerContent/DrawerContent';

function TemporaryDrawer({ activeTab }: { activeTab: string}) {
    const { events } = di;

    return (
        <Drawer
            anchor="right"
            open
            onClose={() => events.emit('plans__drawer:closeDrawer')}
        >
            <DrawerContent activeTab={activeTab} />
        </Drawer>
    );
}

export default TemporaryDrawer;
