import emptySplitApi from 'Endpoints/emptySplitApi';
import { IGetSubordinatesByUserId } from 'CommonTypes/PlanDay/Subordinates/IGetSubordinatesByUserId';
import { ITaskByUser, ITaskByUserBack } from 'CommonTypes/PlanDay/Subordinates/IGetTasksByAssigneeId';
import { IResponsePlanDay, TPlanUserBackResponse } from 'CommonTypes/Scheduler/IResponse';
import { IGetAllEventDates } from 'CommonTypes/Scheduler/IGetAllEventDates';
import { TGetSubordinatesWithEvents } from 'CommonTypes/PlanDay/Subordinates/IGetSubordinatesWithEvents';
import { IFormState } from 'fsrc/PlanDay/types/IFormState';
import IEventData from 'CommonTypes/Scheduler/IEventDataSimple';
import { IEventsChecklist, IEventsChecklistResponse } from 'CommonTypes/Scheduler/IEventsChecklist';
import IUsersAvailableTasksResponse from 'CommonTypes/Tasks/UsersAvailableTasks/IUsersAvailableTasksResponse';
import { IChecklistWithCheckedEventsRequest } from 'CommonTypes/Checklist/ChecklistWithPlanday/ChecklistFromTaskWithEvents';
import { IEventFullResponse } from 'CommonTypes/Scheduler/CreateEvent';

interface IDeleteEventById {
    id: number;
    userId: number;
    startDateEvent: string;
}

interface IEditEventById {
    userId: number;
    schedulerId: number;
    eventDay: string;
    startDateEvent: string;
    endDateEvent: string;
    comment: string;
    schedulerName: string;
    finished?: 0 | 1;
    allDay: 0 | 1;
    projectId: number;
    participants: number[];
    contacts: number[];
    taskId: number;
}

export const planDayApi = emptySplitApi
    .injectEndpoints({

        endpoints: build => ({

            getSubordinatesByUserId: build.query<IGetSubordinatesByUserId, void>({
                query: () => ({
                    url: 'user/subordinates',
                }),
            }),

            getEventCheckList: build.query<IEventsChecklist[], IChecklistWithCheckedEventsRequest>({
                query: params => ({
                    url: 'checklist',
                    params,
                }),
                transformResponse(response: IEventsChecklistResponse[]) {
                    const result = response.map(item => ({
                        ...item,
                        checked: !!item.checked,
                        finished: !!item.finished,
                    }));

                    return result;
                },
                providesTags: (result, error, { eventId, taskId }) => [
                    { type: 'EventsChecklist', eventId, taskId },
                ],
            }),

            getTasksByAssigneeId: build.query<ITaskByUser[], number>({
                query: id => ({
                    url: 'tasks',
                    params: {
                        assigneeId: id,
                        state: '!archive',
                    },
                }),
                transformResponse(response: ITaskByUserBack[]) {
                    const result = response.map(value => ({
                        ...value,
                        taskContactsIds: (value?.taskContactsIds || '').split(',').filter(Boolean).map(Number),
                    }));

                    return result;
                },
                providesTags: (result, error, arg) => [{ type: 'Tasks', id: arg }],
            }),

            getPlanDayUserAvailableTasks: build.query<IUsersAvailableTasksResponse[], void>({
                query: () => ({
                    url: 'tasks',
                    params: {
                        type: 'available',
                    },
                }),
            }),

            getPlanDayUser: build.query<IResponsePlanDay,
            { userId?: number, startDate: string }>({
                query: data => ({
                    url: 'scheduler/plan-day',
                    params: {
                        userId: data.userId,
                        startDate: data.startDate,
                    },
                }),
                transformResponse(response: TPlanUserBackResponse[]) {
                    const result = response.map(value => ({
                        ...value,
                        finished: !!value?.finished,
                        participants: (value?.participants || '').split(',').filter(Boolean).map(Number),
                        participantsFio: (value?.participantsFio || '').split(',').filter(Boolean),
                        participantsSearchValue: (value?.participantsSearchValue || '').split(',').filter(Boolean),
                        eventContactsIds: (value?.eventContactsIds || '').split(',').filter(Boolean).map(Number),
                        eventContacts: (value?.eventContacts || '').split(',').filter(Boolean),
                    }));
                    return result;
                },
                providesTags: ['EventsList'],
            }),

            addEventSimple: build.mutation<
                { success: boolean, insertedId: number }, IEventData
            >({
                query: data => ({
                    url: 'scheduler/events-simple',
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: ['EventsList'],
            }),

            addEventToPlanDay: build.mutation<
                IEventFullResponse, IFormState
            >({
                query: data => ({
                    url: 'scheduler/plan-day',
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: ['EventsList'],
                async onQueryStarted(
                    { userId, eventDay: startDateEvent = '' },
                    { dispatch, queryFulfilled },
                ) {
                    await queryFulfilled;
                    dispatch(
                        planDayApi.util.updateQueryData(
                            'getAllEventDates',
                            userId,
                            (draft) => {
                                draft.push(startDateEvent);
                            },
                        ),
                    );
                    dispatch(
                        planDayApi.util.updateQueryData(
                            'getSubordinatesWithEventsByDate',
                            startDateEvent,
                            (draft) => {
                                if (!draft.includes(userId)) {
                                    draft.push(userId);
                                }
                            },
                        ),
                    );
                },
            }),

            deleteEventById: build.mutation<boolean, IDeleteEventById>({
                query: ({ id }) => ({
                    url: 'scheduler/plan-day',
                    method: 'DELETE',
                    params: {
                        id,
                    },
                }),
                onQueryStarted({ userId, startDateEvent, id }, { dispatch, queryFulfilled }) {
                    const deleteEvent = dispatch(
                        planDayApi.util.updateQueryData(
                            'getPlanDayUser',
                            { userId, startDate: startDateEvent },
                            (draft) => {
                                const newState = draft.filter(event => event.schedulerId !== id);
                                if (!newState.length) {
                                    dispatch(
                                        planDayApi.util.updateQueryData(
                                            'getAllEventDates',
                                            userId,
                                            (allEventDates) => {
                                                const newEventDates = allEventDates
                                                    .filter(day => day !== startDateEvent);

                                                return newEventDates;
                                            },
                                        ),
                                    );
                                    dispatch(
                                        planDayApi.util.updateQueryData(
                                            'getSubordinatesWithEventsByDate',
                                            startDateEvent,
                                            (subordinates) => {
                                                const newEventDates = subordinates
                                                    .filter(user => user !== userId);

                                                return newEventDates;
                                            },
                                        ),
                                    );
                                }
                                return newState;
                            },
                        ),
                    );
                    queryFulfilled.catch(deleteEvent.undo);
                },
            }),

            editEventById: build.mutation<boolean, IEditEventById>({
                query: data => ({
                    url: 'scheduler/plan-day',
                    method: 'PATCH',
                    body: data,
                }),
                invalidatesTags: (result, error, { schedulerId: eventId, taskId }) => [
                    { type: 'EventsChecklist', eventId, taskId },
                    'EventsList',
                ],
            }),

            getAllEventDates: build.query<string[], number>({
                query: id => ({
                    url: 'scheduler/event-dates-by-user-id',
                    params: {
                        userId: id,
                    },
                }),
                transformResponse: (response: IGetAllEventDates) => {
                    const transform = response.map(event => event.eventDate);

                    return transform;
                },
            }),

            getSubordinatesWithEventsByDate: build.query<number[], string>({
                query: date => ({
                    url: 'scheduler/subordinates-with-events-by-date',
                    params: {
                        dateEvent: date,
                    },
                }),
                transformResponse: (response: TGetSubordinatesWithEvents) => {
                    const transform = response.map(user => user.userId);

                    return transform;
                },
            }),

        }),
        overrideExisting: false,
    });

export const {
    useGetSubordinatesByUserIdQuery,
    useGetEventCheckListQuery,
    useGetTasksByAssigneeIdQuery,
    useGetPlanDayUserAvailableTasksQuery,
    useGetPlanDayUserQuery,
    useAddEventSimpleMutation,
    useAddEventToPlanDayMutation,
    useDeleteEventByIdMutation,
    useEditEventByIdMutation,
    useGetAllEventDatesQuery,
    useGetSubordinatesWithEventsByDateQuery,
} = planDayApi;
