import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';

const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => next => (action) => {
    if (isRejectedWithValue(action)) {
        console.log('BUG:', action); // FIXME: Удалить этот console log
        console.log('Ну типа стрельнула бага!!!'); // FIXME: Удалить этот console log
    }

    return next(action);
};

export default rtkQueryErrorLogger;
