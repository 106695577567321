import {
    serviceClassId,
    prioritiesId,
} from '../../../config/constants.js';

interface IPriorities {
    id: number;
    value: string;
}

export default (newValue: string) => {
    if (!+newValue) return;

    const prioritiesArr = ($$(prioritiesId) as webix.ui.combo).getList().serialize();
    const serviceItem = ($$(serviceClassId) as webix.ui.combo).getList().getItem(+newValue);

    if (serviceItem.value.includes('Ускоренный')) {
        const priorityItem = prioritiesArr.find((el: IPriorities) => el.value.includes('Срочные и важные'));
        ($$(prioritiesId) as webix.ui.combo).setValue(priorityItem.id);
    }
};
