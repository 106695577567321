import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';

const columns: GridColDef[] = [
    {
        field: 'userId',
        headerName: 'id',
        editable: false,
        width: 70,
    },
    {
        field: 'userName',
        headerName: 'Пользователь',
        editable: false,
        width: 300,
    },
    {
        field: 'guest',
        headerName: 'Гость',
        editable: false,
        width: 70,
        valueFormatter(params: GridValueFormatterParams<0 | 1>) {
            return params.value ? 'Да' : '';
        },
    },
];

export default columns;
