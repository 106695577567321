import di from './di.js';

const loaded = [];

export function use(module, file) {
    if (!file) {
        throw new Error('File cannot be empty');
    }

    if (!loaded.includes(file)) {
        eval.call(null, module.default);
        loaded.push(file);
    }
}

export function initModule(config, data) {
    if (typeof config === 'function') {
        const controller = new config(di);
        controller.init(data);
    } else {
        di.render(config);
    }
}

export default () => {
    throw new Error('В этом модуле нет default import');
};
