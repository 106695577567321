import React, { useState } from 'react';
import { Resizable } from 're-resizable';
import Drawer from '@mui/material/Drawer';

import SORTED_FIELDS from '../../constants/constants';
import getFields from '../../utils/getFields';

import classes from '../../assets/style.scss';

interface IPersistentDrawer {
    open: boolean;
    widthRightSide: number;
    callback: (width: number) => void;
    data: any;
}

function PersistentDrawer({
    open, widthRightSide, callback, data,
}: IPersistentDrawer) {
    const [widthDrawer, setWidthDrawer] = useState(widthRightSide);

    const drawerItems = SORTED_FIELDS.reduce((acc: any, cur: any) => {
        const field = getFields(cur);
        if (field && data[cur]) {
            acc.push({ [field]: data[cur] });
        }
        return acc;
    }, []);

    return (
        <Resizable
            style={{
                alignItems: 'center',
                justifyContent: 'center',
                borderLeft: 'solid 1px #ddd',
                background: '#f0f0f0',
                display: `${open ? 'flex' : 'none'}`,
            }}
            minWidth="200px"
            size={{
                width: widthDrawer,
                height: '100%',
            }}
            enable={{ left: true }}
            onResize={(e, direction, ref) => {
                const { width } = ref.style;
                setWidthDrawer(parseFloat(width));
            }}
            onResizeStop={(e, direction, ref) => {
                const { width } = ref.style;
                callback(parseFloat(width));
            }}
        >
            <Drawer
                sx={{
                    width: '100%',
                    flexShrink: 0,
                    height: '100%',
                    '& .MuiDrawer-paper': {
                        width: '100%',
                        position: 'inherit',
                        color: '#676a6c',
                    },
                }}
                variant="persistent"
                anchor="right"
                open
            >
                <ul className={classes['list-items']}>
                    {drawerItems.map((item: { [key: string ]: string }) => {
                        const [field] = Object.keys(item);
                        return (
                            <li key={field}>
                                <strong>{field}</strong>
                                :
                                {' '}
                                {item[field]}
                            </li>
                        );
                    })}
                </ul>
            </Drawer>
        </Resizable>
    );
}

export default PersistentDrawer;
