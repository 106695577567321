import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ru';
import { nodePath } from 'Config/path.js';
import { fetchGet } from 'Services/fetchService.js';

export default async (limitation: number, startDate: Dayjs) => {
    const dateString = startDate.format('YYYY-MM-DD HH:mm:ss');
    const resultDay = await fetchGet(`${nodePath}/calendar/working-days?add=${limitation}&start-date=${dateString}&type=calculate`);
    const result = dayjs(resultDay);
    return result.endOf('day');
};
