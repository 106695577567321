import { fetchPost } from 'Services/fetchService.js';
import { nodePath } from 'Config/path.js';

interface IData {
    layoutId: string;
    layoutName: string;
    isUser: boolean;
    settings: string;
    typeTaskId: number;
}

export default async (data: IData): Promise<boolean> => {
    const response: boolean = await fetchPost(`${nodePath}/form/update-form-config`, data);

    return response;
};
