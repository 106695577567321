export const service = {
    templatesData,
    contactData,
    userData,
    roleData,
    projectData,
    deleteTemplate
}

function templatesData(){
    const requestOptions = {
        method: 'GET'
    }
    return new Promise(function(resolve, reject) {
        fetch('./ajax/task_templates.php', requestOptions)
        .then(handleResponse)
        .then(function (response){
            resolve(response);
        })
        .catch(function(error) {
            reject(error);
        });
    });
}

function contactData(id_user){
    const requestOptions = {
        method: 'GET'
    }
    return new Promise(function(resolve, reject) {
        fetch('./ajax/contact_info.php?id='+id_user, requestOptions)
        .then(handleResponse)
        .then(function (response){
            resolve(response);
        })
        .catch(function(error) {
            reject(error);
        });
    });
}
function roleData(id_role){
    const requestOptions = {
        method: 'GET'
    }
    return new Promise(function(resolve, reject) {
        fetch('./ajax/autocomplete.roles.php?id_role='+id_role, requestOptions)
        .then(handleResponse)
        .then(function (response){
            resolve(response);
        })
        .catch(function(error) {
            reject(error);
        });
    });
}
function userData(id_user){
    const requestOptions = {
        method: 'GET'
    }
    return new Promise(function(resolve, reject) {
        fetch('./ajax/user.php?id='+id_user, requestOptions)
        .then(handleResponse)
        .then(function (response){
            resolve(response);
        })
        .catch(function(error) {
            reject(error);
        });
    });
}

function projectData(id_project){
    const requestOptions = {
        method: 'GET'
    }
    return new Promise(function(resolve, reject) {
        fetch('./ajax/project_one.php?id='+id_project, requestOptions)
        .then(handleResponse)
        .then(function (response){
            resolve(response);
        })
        .catch(function(error) {
            reject(error);
        });
    });
}

function deleteTemplate(template_id){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({"template_id":template_id, "delete":"1"})
    }
    return new Promise(function(resolve, reject) {
        fetch('./ajax/task_templates.php', requestOptions)
        .then(handleResponse)
        .then(function (response){
            resolve(response);
        })
        .catch(function(error) {
            reject(error);
        });
    });
}

function handleResponse(response) {
    return response.text().then(text => {
        let data = JSON.parse(text);
        if (!response.ok) {
            return Promise.reject(data);
        }
        return data;
    });
}

