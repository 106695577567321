import React from 'react';
import { useFormContext, useController, UseControllerReturn } from 'react-hook-form';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

import { IEventsChecklist } from 'CommonTypes/Scheduler/IEventsChecklist';

interface IProps {
    options: IEventsChecklist[];
}

function EventChecklistSection({ options }: IProps) {
    const { control } = useFormContext();
    const controller: UseControllerReturn = useController({
        name: 'checkedList',
        control,
    });

    const commentController = useController({ name: 'comment', control });

    const onClickHandler = (id: number, name: string) => {
        const currentData = [...controller.field.value];
        const currentIdIndex = currentData.indexOf(id);

        if (currentIdIndex === -1) {
            currentData.push(id);
            const currentComment = `${commentController.field.value}\n${name}`;
            commentController.field.onChange(currentComment);
        } else {
            currentData.splice(currentIdIndex, 1);
        }

        controller.field.onChange(currentData);
    };

    return (
        <>
            { !!options.length && (
                <List sx={{
                    width: '100%', bgcolor: 'background.paper', overflowY: 'scroll',
                }}
                >
                    {options.map((value) => {
                        const labelId = `checkbox-list-label-${value.checkId}`;

                        return (
                            <ListItem
                                key={value.checkId}
                                sx={{ margin: '0 !important', padding: '0 !important' }}
                            // FIXME: исправить это через theme
                            >
                                <ListItemButton
                                    role={undefined}
                                    onClick={() => onClickHandler(value.checkId, value.name)}
                                    dense
                                >
                                    <ListItemIcon
                                        sx={{ minWidth: 'auto' }}
                                    >
                                        <Checkbox
                                            edge="start"
                                            checked={controller.field.value.includes(value.checkId)}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                            sx={{ padding: 0 }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{ textDecoration: value.finished ? 'line-through' : 'none' }}
                                        primaryTypographyProps={{
                                            fontSize: '10px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }}
                                        id={labelId}
                                        primary={value.name}
                                    />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            ) }
        </>
    );
}

export default EventChecklistSection;
