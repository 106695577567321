import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';

import getVisibleAccordions from 'Domain/Tasks/TaskViewForm/utils/selectors';
import { addVisibleAccordion, deleteVisibleAccordion } from 'Domain/Tasks/TaskViewForm/utils/taskViewFormReducer';

import IHyperlink from '../../types/IHyperlink';

function Hyperlink({ link }: { link: IHyperlink }) {
    const { id, value, icon } = link;

    const currentAccordion = useSelector(getVisibleAccordions);
    const dispatch = useDispatch();

    const onClickHyperlink = () => {
        if (currentAccordion.includes(id)) {
            const curAcc = currentAccordion.filter((el: any) => el !== id);
            return dispatch(deleteVisibleAccordion(curAcc));
        }
        return dispatch(addVisibleAccordion(id));
    };

    return (
        <Button
            variant="contained"
            size="superMini"
            color="secondary"
            startIcon={(<Icon><i className={icon} /></Icon>)}
            onClick={onClickHyperlink}
            sx={{
                border: 'none',
                '&:hover': {
                    border: 'none',
                    color: '#3e4041',
                },
            }}
        >
            {value}
        </Button>
    );
}

export default Hyperlink;
