function printElement(html: string) {
    const mywindow = window.open('', 'PRINT', 'height=725,width=734');

    if (!mywindow) {
        return false;
    }

    mywindow.document.write('<html><head>');
    mywindow.document.write('<link rel="stylesheet" href="/assets/lib/bootstrap/dist/css/bootstrap.css">');
    mywindow.document.write('<link rel="stylesheet" href="/assets/css/skin_md.css">');
    mywindow.document.write('<link rel="stylesheet" href="/js/src/Application/Domain/Ui/Editor/stylesforprint.css">');
    mywindow.document.write('</head><body class="md-skin"><div class="ck-content">');
    mywindow.document.write(html);
    mywindow.document.write('</div></body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    // mywindow.print();
    // mywindow.close();

    return true;
}

export default printElement;
