import emptySplitApi from 'Endpoints/emptySplitApi';
import {
    IRolesSimpleSet,
    IRolesUsersProjectsMatching,
    IRolesUsersProjectsMatchingRequest,
    IRolesUsersProjectsMatchingGrid,
} from 'CommonTypes/Roles/RolesTypes';
import IDeleteUserFromShareProjectRequest, { IDeleteUserFromShareProjectResponse } from 'CommonTypes/Projects/IDeleteUserFromShareProjectRequest';
import { IRolesProjectsMatchingByUser, IRolesProjectsMatchingByUserGrid, IRolesProjectsMatchingByUserRequest } from 'CommonTypes/Roles/RolesProjectsByUser';
import { IChangeRoleMatchingRequest, IChangeRoleMatchingResponse } from 'CommonTypes/Roles/ChangeRolesMatching';
import { IUpdateGuestForUserRequest, IUpdateGuestForUserResponse } from 'CommonTypes/Projects/UpdateGuestForUser';

export const rolesApi = emptySplitApi.injectEndpoints({
    endpoints: build => ({

        getRolesSimpleSet: build.query<IRolesSimpleSet[], void>({
            query: () => ({
                url: 'roles',
            }),
        }),

        getRolesUsersProjectsMatching: build.query<
            IRolesUsersProjectsMatchingGrid[],
            IRolesUsersProjectsMatchingRequest
        >({
            query: params => ({
                url: 'roles/users-projects-matching',
                params,
            }),
            transformResponse(response: IRolesUsersProjectsMatching[]) {
                const result = response.map((item) => {
                    const roles = item.roles?.split(',')?.map(role => `role${role}`) || [];
                    const res: IRolesUsersProjectsMatchingGrid = {
                        ...item,
                    };

                    roles.forEach((role) => {
                        res[role] = 1;
                    });
                    return res;
                });

                return result;
            },
        }),

        getRolesProjectsMatchingByUser: build.query<
            IRolesProjectsMatchingByUserGrid[],
            IRolesProjectsMatchingByUserRequest
        >({
            query: params => ({
                url: 'roles/projects-matching',
                params,
            }),
            transformResponse(response: IRolesProjectsMatchingByUser[]) {
                const result = response.map((item) => {
                    const roles = item.roles?.split(',')?.map(role => `role${role}`) || [];
                    const res: IRolesProjectsMatchingByUserGrid = {
                        ...item,
                    };

                    roles.forEach((role) => {
                        res[role] = 1;
                    });

                    return res;
                });

                return result;
            },
        }),

        deleteRolesProjectShare: build.mutation<
            IDeleteUserFromShareProjectResponse,
            IDeleteUserFromShareProjectRequest
        >({
            query: params => ({
                url: 'projects/shareprojects',
                method: 'DELETE',
                params,
            }),
            async onQueryStarted({ projectId, userId }, { dispatch, queryFulfilled }) {
                const { data: response } = await queryFulfilled;
                if (response && response.success) {
                    dispatch(
                        rolesApi.util.updateQueryData('getRolesUsersProjectsMatching', { projectId }, (draft) => {
                            const newUsersList = draft.filter(
                                row => row.userId !== userId,
                            );
                            return newUsersList;
                        }),
                    );
                    dispatch(
                        rolesApi.util.updateQueryData('getRolesProjectsMatchingByUser', { userId }, (draft) => {
                            const newProjectsList = draft.filter(
                                row => row.projectId !== projectId,
                            );
                            return newProjectsList;
                        }),
                    );
                }
            },
        }),

        changeRolesMatching: build.mutation<
            IChangeRoleMatchingResponse,
            IChangeRoleMatchingRequest
        >({
            query: params => ({
                url: 'roles/users-projects-matching',
                method: 'PATCH',
                body: params,
            }),
            async onQueryStarted({ projectId, userId, roleId }, { dispatch, queryFulfilled }) {
                const { data: response } = await queryFulfilled;
                if (response && response.success) {
                    const value = response.type === 'set' ? 1 : 0;
                    dispatch(
                        rolesApi.util.updateQueryData('getRolesUsersProjectsMatching', { projectId }, (draft) => {
                            const newUsersList = draft.map(
                                row => (row.userId === userId ? { ...row, [`role${roleId}`]: value } : row),
                            );

                            return newUsersList;
                        }),
                    );

                    dispatch(
                        rolesApi.util.updateQueryData('getRolesProjectsMatchingByUser', { userId }, (draft) => {
                            const newProjectsList = draft.map(
                                row => (row.projectId === projectId ? { ...row, [`role${roleId}`]: value } : row),
                            );

                            return newProjectsList;
                        }),
                    );
                }
            },
        }),

        updateProjectsGuestForUser: build.mutation<
                IUpdateGuestForUserResponse,
                IUpdateGuestForUserRequest
        >({
            query: params => ({
                url: 'projects/guest',
                method: 'PATCH',
                body: params,
            }),
            async onQueryStarted({ projectId, userId }, { dispatch, queryFulfilled }) {
                const { data: response } = await queryFulfilled;
                if (response && response.success) {
                    dispatch(
                        rolesApi.util.updateQueryData('getRolesUsersProjectsMatching', { projectId }, (draft) => {
                            const newUsersList = draft.map(
                                row => (row.userId === userId
                                    ? { ...row, guest: response.currentGuest } : row),
                            );
                            return newUsersList;
                        }),
                    );

                    dispatch(
                        rolesApi.util.updateQueryData('getRolesProjectsMatchingByUser', { userId }, (draft) => {
                            const newProjectsList = draft.map(
                                row => (row.projectId === projectId
                                    ? { ...row, guest: response.currentGuest } : row),
                            );
                            return newProjectsList;
                        }),
                    );
                }
            },
        }),

    }),
});

export const {
    useGetRolesSimpleSetQuery,
    useGetRolesUsersProjectsMatchingQuery,
    useGetRolesProjectsMatchingByUserQuery,
    useDeleteRolesProjectShareMutation,
    useChangeRolesMatchingMutation,
    useUpdateProjectsGuestForUserMutation,
} = rolesApi;
