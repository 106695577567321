import { IIFieldsVisibleConfigReqeust } from 'CommonTypes/Forms/FormsFieldsConfig';
import { useGetFormsFieldsVisibleConfigQuery } from 'Endpoints/Forms/forms.api';

interface IProps extends IIFieldsVisibleConfigReqeust {
    fieldsIds: string[];
}

function useFieldsVisibleHook({ fieldsIds, formTypeId, taskTypeId }: IProps) {
    const {
        data: fieldsVisible,
        isFetching,
        isLoading,
    } = useGetFormsFieldsVisibleConfigQuery({ formTypeId, taskTypeId });

    const fieldsObject = fieldsVisible ? Object.fromEntries(
        fieldsVisible?.map(value => [value.fieldId, value.checked]),
    ) : {};

    const result = Object.fromEntries(fieldsIds.map(
        k => [
            k,
            !fieldsVisible
                ? false
                : fieldsObject[k] ?? true,
        ],
    ));

    return {
        fieldsVisible: result,
        isLoading: isFetching || isLoading,
    };
}

export default useFieldsVisibleHook;
