import { fetchPost } from 'Services/fetchService.js';
import { slimPath } from '../../../../configModule/config.js';
import { formAddTaskId, btnTimerId } from '../../../config/constants.js';
import changeButtonsByCurrentState from './changeButtonsByCurrentState.js';
import updateFormTask from './updateFormTask.js';

export default async () => {
    const taskData = $$(formAddTaskId).getValues();
    const { taskId } = taskData;

    const formData = new FormData();
    formData.append('id_task', taskId);
    const response = await fetch(`${slimPath}/tasks/toggletimer`, {
        method: 'POST',
        body: formData,
    });
    const timer = await response.json();

    const idTask = timer.id_task;


    if (idTask) {
        $$(btnTimerId).define('label', '<span class="fa fas fa-stop-circle-o task__btn-timer"');
        $$(btnTimerId).refresh();
        const currentState = timer.default_state_after_timer;
        taskData.currentState = timer.default_state_after_timer;
        taskData.state = timer.work_route_id;
        if (currentState) {
            changeButtonsByCurrentState(taskData);

            if (!taskData?.assignee && taskData?.role) {
                updateFormTask(taskData?.taskId);
            }
        }


        return app.timer.init();
    }

    $$(btnTimerId).define('label', '<span class="fa fas fa-play-circle-o task__btn-timer"');
    $$(btnTimerId).refresh();

    return app.timer.draw();
};
