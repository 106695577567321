import React from 'react';
import { createRoot } from 'react-dom/client';
import { servicesView } from 'fsrc/AddTaskWindow/config/constants.js';
import DataGridView from './dataGrid/components/DataGridView';

export default (taskId, project) => ({
    id: servicesView,
    css: { display: 'flex' },
    view: 'reactWrapper',
    createRoot,
    reactUi: <DataGridView taskId={taskId} />,
});
