import emptySplitApi from 'Endpoints/emptySplitApi';
import { set } from 'Utils/store/userSlice';

import { IInitialUserInfo } from 'CommonTypes/Users/InitialUser';

export const initAppApi = emptySplitApi.injectEndpoints({
    endpoints: build => ({
        getUserInfo: build.query<IInitialUserInfo, void>({
            query: () => ({
                url: '/user',
            }),
            async onQueryStarted(queryData, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch(set(data));
            },
        }),
    }),
    overrideExisting: false,
});

export const { useGetUserInfoQuery } = initAppApi;
