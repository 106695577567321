import { fetchGet } from 'Services/fetchService.js';
import { nodePath } from 'Config/path.js';
import { ICurrentFormConfig } from '../../Application/Domain/LayoutConfiguration/types/ICurrentFormConfig';
import { IFormFieldsConfigController } from '../../Application/Domain/LayoutConfiguration/types/IFormFieldsConfigController';

export default async (
    typeTaskValue: number,
    taskId: number,
    formAddTaskId: keyof IFormFieldsConfigController,
    isUser: boolean,
): Promise<ICurrentFormConfig | null> => {
    const response: ICurrentFormConfig | null = await fetchGet(`
    ${nodePath}/tasks/get-form-config?typeTaskId=${typeTaskValue}&taskId=${taskId}&formId=${formAddTaskId}&user=${+isUser}
    `);

    return response;
};
