/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useMemo, memo } from 'react';
import {
    Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useController, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import { TextField } from 'Ui/Form/TextField';
import { Editor } from 'Ui/Form/Editor';
import { FilesUploader } from 'Ui/Form/FilesUploader';
import { IClassicEditor } from 'Ui/Editor/types/ICKEditorClassic';
import MainSection from '../MainSection/MainSection';
import responsiveAccordionClassName from '../../constants/constants';
import configurationСontroller from '../../models/configurationСontroller';
import Context from '../../models/context';

import { TTypeTask } from '../../types/TTypeTask';
import { IDataForm } from '../../types/IDataForm';
import TNewValue from '../../types/TNewValue';

import classes from '../../assets/style.scss';

interface IProps {
    type: TTypeTask;
    data: IDataForm;
}

function AccordionView(
    {
        type,
        data,
    }: IProps,
) {
    const descriptionRef = useRef<IClassicEditor | null>(null);
    const commentRef = useRef<IClassicEditor | null>(null);

    const form = useFormContext();

    const projectController = useController({ name: 'project', control: form.control });
    const contactController = useController({ name: 'contact', control: form.control });
    const assigneeController = useController({ name: 'assignee', control: form.control });
    const roleController = useController({ name: 'role', control: form.control });
    const dataPickerController = useController({ name: 'deadline', control: form.control });
    const serviceClasseController = useController({ name: 'serviceClasse', control: form.control });
    const taskTypesController = useController({ name: 'taskTypes', control: form.control });

    const onChangeProjectByContact = useMemo(() => (newValue: TNewValue) => {
        contactController.field.onChange(newValue);

        if (type === 'subTask') return;

        const projectTmp = form.getValues('project');
        if (!projectTmp) {
            const currentProject = newValue.projectId
                ? data.projects.find(project => project.id === newValue.projectId)
                : null;

            projectController.field.onChange(currentProject);
        }
    }, [contactController.field, projectController.field, data.projects]);

    const onChangeUsersAndRolesByProject = useMemo(() => (newValue: TNewValue) => {
        projectController.field.onChange(newValue);
        const { assignee } = form.formState.defaultValues!;

        assigneeController.field.onChange(assignee);
        roleController.field.onChange(null);
    }, [
        form.formState.defaultValues,
        projectController.field,
        roleController.field,
        assigneeController.field,
    ]);

    const onChangeUsersByRoles = useMemo(() => (newValue: TNewValue) => {
        roleController.field.onChange(newValue);
        assigneeController.field.onChange(null);
    }, [roleController.field, assigneeController.field]);

    const onChangeRolesByUsers = useMemo(() => (newValue: TNewValue) => {
        assigneeController.field.onChange(newValue);
        roleController.field.onChange(null);
    }, [roleController.field, assigneeController.field]);

    const onChangeContactsByDefault = useMemo(() => () => {
        const { contact } = form.formState.defaultValues!;
        contactController.field.onChange(contact);
    }, [contactController.field, form.formState.defaultValues]);

    const onChangeUsersByDefault = useMemo(() => () => {
        const { assignee } = form.formState.defaultValues!;
        assigneeController.field.onChange(assignee);
        roleController.field.onChange(null);
    }, [assigneeController.field, form.formState.defaultValues, roleController.field]);

    const contextFunctions = useMemo(() => (
        {
            onChangeProjectByContact,
            onChangeUsersAndRolesByProject,
            onChangeUsersByRoles,
            onChangeRolesByUsers,
            onChangeContactsByDefault,
            onChangeUsersByDefault,
        }
    ), [
        onChangeProjectByContact,
        onChangeUsersAndRolesByProject,
        onChangeUsersByRoles,
        onChangeRolesByUsers,
        onChangeContactsByDefault,
        onChangeUsersByDefault,
    ]);

    return (
        <form className={classes.modal_wrapper}>
            <TextField
                name="taskName"
                label="Наименование задачи"
                required={{
                    required: {
                        value: true,
                        message: 'Поле не может быть пустым',
                    },
                    maxLength: {
                        value: 512,
                        message: 'Превышено максимальное количество символов',
                    },
                }}
                autoFocus
            />
            <Accordion
                defaultExpanded={configurationСontroller(type, 'mainSection', 'expanded')}
                hidden={configurationСontroller(type, 'mainSection', 'hidden')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        <strong>Основные</strong>
                        {' '}
                        <span>
                            (
                            {`${contactController.field?.value?.value || 'Контакты'}`}
                            {' / Наблюдатели'}
                            {` / ${serviceClasseController.field?.value?.value || 'Класс обслуживания'}`}
                            {` / ${dayjs(dataPickerController.field?.value)?.format('DD.MM.YYYY') || 'Срок'}`}
                            {` / ${projectController.field?.value?.value || 'Проект'}`}
                            {` / ${taskTypesController.field?.value?.value || 'Бизнес процесс'}`}
                            {` / ${assigneeController.field?.value?.value || 'Исполнитель'}`}
                            {` / ${roleController.field?.value?.value || 'Роль'}`}
                            )
                        </span>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Context.Provider value={contextFunctions}>
                        <MainSection dataForm={data} />
                    </Context.Provider>
                </AccordionDetails>
            </Accordion>
            <Accordion
                sx={responsiveAccordionClassName}
                defaultExpanded={configurationСontroller(type, 'descriptionSection', 'expanded')}
                TransitionProps={{ mountOnEnter: true }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        <strong>Описание</strong>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Editor
                        name="desc"
                        editorRef={descriptionRef}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        <strong>Критерии приёмки</strong>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={classes.modal_wrapper}>
                        <TextField
                            name="criteria"
                            multiline
                            rows={5}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion
                sx={responsiveAccordionClassName}
                TransitionProps={{ mountOnEnter: true }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        <strong>Комментарии</strong>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={classes.modal_wrapper}>
                        <Editor
                            name="comment"
                            editorRef={commentRef}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography><strong>Файлы</strong></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FilesUploader name="filesUploader" />
                </AccordionDetails>
            </Accordion>
        </form>
    );
}

export default memo(AccordionView);
