import React, { useEffect, useRef } from 'react';
import Event from '@ckeditor/ckeditor5-utils/src/eventinfo';
import { EditorConfig } from '@ckeditor/ckeditor5-core/src/editor/editorconfig';

import ClassicEditorCreator from './ClassicEditorCreator.js';

import { IClassicEditor, IEditor, TClassicEditorCreator } from './types/ICKEditorClassic';

import './assets/ckeditor.orig.scss';

function Editor({
    id = 'ckEditorId',
    value = '',
    disabled,
    config,
    onReady,
    onChange,
    onBlur,
    onFocus,
}: IEditor) {
    const editorConfig: EditorConfig = {
        toolbar: {
            shouldNotGroupWhenFull: false,
        },
        language: 'ru',
        initialData: value,
        ...(config || {}),
    };

    const editorDivRef = useRef<HTMLDivElement>(null);
    const componentRef = useRef({ isRendered: false });
    const editorRef = useRef<IClassicEditor | null>(null);

    const onFocusHandler = (e: Event, name: string, isFocused: boolean) => {
        if (typeof onFocus === 'function' && editorRef.current) onFocus(e, editorRef.current, isFocused);
    };

    const onBlurHandler = (e: Event) => {
        if (typeof onBlur === 'function' && editorRef.current) onBlur(e, editorRef.current);
    };

    const setReadOnlyMode = () => {
        if (disabled) {
            editorRef.current?.enableReadOnlyMode(id);
        }

        if (!disabled) {
            editorRef.current?.disableReadOnlyMode(id);
        }
    };

    const onReadyHandler = (newInstance: IClassicEditor) => {
        editorRef.current = newInstance;
        setReadOnlyMode();
        if (typeof onReady === 'function') onReady(newInstance);
        newInstance.model.document.on('change:data', (e: Event) => {
            if (typeof onChange === 'function' && editorRef.current) {
                onChange(e, editorRef.current);
            }
        });
        newInstance.editing.view.document.on('change:isFocused', onFocusHandler);
        newInstance.editing.view.document.on('blur', onBlurHandler);

        const toolbarElement = newInstance.ui.view.toolbar.element as HTMLDivElement;
        newInstance.on('change:isReadOnly', (e: Event, propertyName: string, isReadOnly: boolean) => {
            if (isReadOnly) {
                toolbarElement.style.display = 'none';
            } else {
                toolbarElement.style.display = 'flex';
            }
        });
    };

    useEffect(() => {
        const editor = ClassicEditorCreator as TClassicEditorCreator;
        if (!componentRef.current.isRendered) {
            editor.create(editorDivRef.current, editorConfig)
                .then(onReadyHandler)
                .catch((e) => {
                    console.error(e);
                });
            componentRef.current.isRendered = true;
        }

        return () => {
            editorRef.current?.destroy();
        };
    }, []);

    useEffect(() => setReadOnlyMode, [disabled]);

    return (
        <div ref={editorDivRef} />
    );
}

export default Editor;
