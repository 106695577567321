import {
    progressTaskId, taskNumberId, btnSaveId, btnEdit, taskInfoId,
    btnClose, menuId, formAddTaskId, typeTaskId, btnSubmissionTask,
} from '../../../../config/constants.js';
import saveData from '../../models/saveData.js';
import toggleReadonly from '../../../../models/toggleReadonly.js';
import closeTaskForm from '../../models/closeTaskForm.js';
import templateTaskInfo from '../../models/templateTaskInfo.js';
import btnTimer from './components/btnTimer.js';
import menuSwitcher from './utils/menuSwitcher.js';
import updateFormTask from '../../models/updateFormTask.js';
import changeTabSubtasks from '../bottomTabbar/models/changeTabSubtasks.js';

export default (fieldsConfig, dataTask, allProperty, dataFiles) => ({
    cols: [
        {
            view: 'text',
            id: taskNumberId,
            name: taskNumberId,
            label: 'Рег. номер',
            labelWidth: 85,
            placeholder: 'Рег. номер...',
            width: 250,
        },
        {
            view: 'formtemplateOfTaskForm',
            id: taskInfoId,
            name: taskInfoId,
            template(obj) {
                return templateTaskInfo(obj.value);
            },
            borderless: true,
        },
        {
            view: 'counter',
            id: progressTaskId,
            name: progressTaskId,
            label: 'Прогресс',
            value: 0,
            max: 100,
            width: 195,
        },
        {
            id: btnSaveId,
            view: 'button',
            width: 45,
            label: '<span class="fa fa-floppy-o">',
            click() {
                saveData(dataTask, fieldsConfig);
            },
        },
        btnTimer(dataTask),
        {
            id: btnEdit,
            view: 'button',
            width: 45,
            tooltip: 'Редактировать форму',
            label: '<span class="fa fa-pencil">',
            hidden: true,
            click() {
                toggleReadonly(fieldsConfig);
            },
        },
        {
            view: 'button',
            width: 45,
            tooltip: 'Обновить данные задачи',
            label: '<span class="fa fa-refresh">',
            click() {
                updateFormTask(dataTask?.taskId);
            },
        },
        {
            id: btnSubmissionTask,
            view: 'button',
            width: 45,
            tooltip: 'Связанные процессы',
            label: '<span class="fa fa-usb">',
            hidden: dataTask?.submissionTask ? !dataTask?.submissionTask.length : false,
            popup: {
                view: 'popup',
                body: {
                    view: 'list',
                    width: 260,
                    data: dataTask?.submissionTask,
                    select: true,
                    type: {
                        height: 'auto',
                    },
                    on: {
                        onItemClick(id) {
                            this.getParentView().hide();

                            dataTask?.submissionTask.filter((el) => {
                                if (el.id === +id) {
                                    window.events.emit(
                                        'tasks__formAddTask:addNewTask',
                                        {
                                            type: 'taskMessage',
                                            params: {
                                                data:
                                                { taskId: dataTask.taskId, submissionId: el.id },
                                            },
                                            dataFiles,
                                            callback: () => changeTabSubtasks(true),
                                        },
                                    );
                                }
                            });
                        },
                    },
                },
            },
        },
        {
            id: menuId,
            view: 'button',
            width: 45,
            tooltip: 'Действия',
            label: '<span class="fa fa-ellipsis-v">',
            popup: {
                view: 'popup',
                body: {
                    view: 'list',
                    width: 260,
                    data: [
                        { id: 'fields', value: '<i class="fa fa-cogs"></i> Настройка видимости реквизитов' },
                        { id: 'bug', value: '<i class="fa fa-bullhorn"></i> Предложения и замечания' },
                        { id: 'openTask', value: '<i class="fa fa-external-link"></i> Открыть в новой вкладке' },
                        { id: 'storyTask', value: '<i class="fa fa-th-large"></i> Открыть story' },
                        { id: 'resetAccess', value: '<i class="fa fa-users"></i> Обновить доступы к задаче' },
                        { id: 'deleteTask', value: '<i class="fa fa-trash"></i> Удалить' },
                    ],
                    autoheight: true,
                    select: true,
                    on: {
                        onAfterSelect(id) {
                            this.getParentView().hide();

                            menuSwitcher[id](formAddTaskId, typeTaskId, dataTask);
                            // TODO: требует рефакторинга
                        },
                    },
                },
            },
        },
        {
            id: btnClose,
            view: 'button',
            width: 45,
            tooltip: 'Закрыть форму',
            label: '<span class="fa fa-times">',
            click() {
                closeTaskForm(dataTask);
            },
        },
    ],
    height: 38,
    margin: 10,
    paddingX: 10,
    borderless: true,
});
