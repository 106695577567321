import { GridColDef } from '@mui/x-data-grid-premium';
import { useGetRolesSimpleSetQuery } from 'Endpoints/Roles/roles.api';
import { useGetUsersActiveSimpleSetQuery } from 'Endpoints/Users/users.api';
import { useCanUserByTaskIdActionQuery } from 'Endpoints/Roles/rolesMatching.api';
import AutocompleteSelectField from '../../RolesConfig/models/ui/AutocompleteSelectField';

interface IProps {
    taskId: number;
}

function useColumnsRolesConfigTasks({ taskId }: IProps) {
    const { data: canEditRecords } = useCanUserByTaskIdActionQuery({ taskId, action: 'tasks__roleuser-config:editRecord' });

    const { data: roles = [] } = useGetRolesSimpleSetQuery(undefined, {
        selectFromResult: ({ data: response = [] }) => ({
            data: response.map(
                record => ({ value: record.id, label: record.value }),
            ),
        }),
    });

    const { data: users = [] } = useGetUsersActiveSimpleSetQuery(undefined, {
        selectFromResult: ({ data: response = [] }) => ({
            data: response.map(
                record => ({ value: record.id, label: record.value }),
            ),
        }),
    });

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Id',
            type: 'number',
            width: 90,
        },
        {
            field: 'stateId',
            headerName: 'Id статуса',
            type: 'number',
            width: 90,
        },
        {
            field: 'stateName',
            headerName: 'Статус',
            width: 270,
        },
        {
            field: 'roleId',
            headerName: 'Роль',
            type: 'singleSelect',
            valueOptions: roles,
            valueFormatter: params => roles.find(role => +role.value === +params.value)?.label || '',
            editable: Boolean(canEditRecords?.can),
            renderEditCell: AutocompleteSelectField,
            flex: 1,
        },
        {
            field: 'userId',
            headerName: 'Пользователь',
            type: 'singleSelect',
            valueOptions: users,
            valueFormatter: params => users.find(user => +user.value === +params.value)?.label || '',
            editable: Boolean(canEditRecords?.can),
            renderEditCell: AutocompleteSelectField,
            flex: 1,
        },
        {
            field: 'planDuration',
            headerName: 'Плановая длительность',
            type: 'number',
            width: 270,
            editable: Boolean(canEditRecords?.can),
        },
    ];

    return columns;
}

export default useColumnsRolesConfigTasks;
