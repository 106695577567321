export default (value) => {
    const { module } = value;
    switch (module) {
    case 0:
        return `#${value.value}`;
    case 1:
        return `(кат) ${value.value}`;
    case 2:
        return `(пд) ${value.value}`;
    default:
        break;
    }
    return false;
};
