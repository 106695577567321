/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import IContactSimpleSet from 'CommonTypes/Contacts/IContactSimpleSet';

import { useGetContactsSimpleSetQuery } from 'Endpoints/Contacts/contacts.api';
import setContactsOtherToTaskService from 'fsrc/Sales/services/setContactsOtherToTaskService';

type TProps = {
    taskId: number,
    contactsIds: number[],
}

function ContactsOtherSelect({ taskId, contactsIds }: TProps) {
    const { data: contactsOptions = [] } = useGetContactsSimpleSetQuery();
    const [value, setValue] = useState<IContactSimpleSet[]>([]);

    const onChangeHandle = useCallback((
        event: React.SyntheticEvent<Element, Event>,
        newValue: IContactSimpleSet[],
    ) => {
        const ids = newValue?.map(contact => contact.id) || [];
        setContactsOtherToTaskService({ taskId, contactsIds: ids });
        setValue(newValue);
    }, [taskId]);

    useEffect(() => {
        const currentValue = contactsOptions.filter(contact => contactsIds.includes(+contact.id));
        setValue(currentValue);
    }, [contactsIds, contactsOptions]);

    return (
        <Autocomplete
            size="small"
            options={contactsOptions}
            value={value}
            multiple
            blurOnSelect
            handleHomeEndKeys={false}
            renderInput={params => <TextField {...params} />}
            onChange={onChangeHandle}
            getOptionLabel={option => option.searchValue}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.searchValue}
                </li>
            )}
            isOptionEqualToValue={(
                option: IContactSimpleSet,
                currentValue: IContactSimpleSet,
            ) => option.id === currentValue.id}
        />
    );
}

export default ContactsOtherSelect;
